import {employees} from './employees';

let db = [];

const measureDepartment = (employee) => {
    let emp = {
        employeeId: employee.employeeId,
        firstName: employee.firstName,
        lastName: employee.lastName,
        department: employee.department
    };
    let count = 0;

    employee.reports.forEach(e => {
        if(e.department === employee.department) {
            count++;
            count += measureDepartment(e);
        } else {
            measureDepartment(e);
        }
    });

    emp.count = count;
    db.push(emp);
    return count;
}

// let t0 = performance.now();
measureDepartment(employees[0], 0);
// let t1 = performance.now();
// console.log('time', t1-t0);

db.sort((a, b) => parseInt(b.count) - parseInt(a.count));

export {db};