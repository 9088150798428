// interface Employee {
//   employeeId: string;
//   firstName: string;
//   lastName: string;
//   jobTitle: string;
//   officeLocation: string;
//   department: string;
//   managerEmployeeId: string | null;
//   reports: Employee[];
// };

var employees /*: Employee[]*/ = [
  {
    employeeId: "1",
    firstName: "Aiden",
    lastName: "Chung",
    jobTitle: "First Lord of intervieworg",
    officeLocation: "Paris",
    department: "intervieworg",
    managerEmployeeId: null,
    reports: [
      {
        employeeId: "763",
        firstName: "Nico",
        lastName: "Pittman",
        jobTitle: "Senior Fellow Architect",
        officeLocation: "Paris",
        department: "Design",
        managerEmployeeId: "1",
        reports: [
          {
            employeeId: "1118",
            firstName: "Kaitlyn",
            lastName: "Bowers",
            jobTitle: "Vice President of Residential Architecture",
            officeLocation: "Paris",
            department: "Residential Architecture",
            managerEmployeeId: "763",
            reports: [
              {
                employeeId: "1191",
                firstName: "Brylee",
                lastName: "Sparks",
                jobTitle: "Residential Architecture Director",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1118",
                reports: [
                  {
                    employeeId: "1204",
                    firstName: "Jaxton",
                    lastName: "Freeman",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1191",
                    reports: [
                      {
                        employeeId: "1205",
                        firstName: "Mackenzie",
                        lastName: "Cross",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1204",
                        reports: []
                      },
                      {
                        employeeId: "1207",
                        firstName: "Deangelo",
                        lastName: "Beard",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1204",
                        reports: []
                      },
                      {
                        employeeId: "1206",
                        firstName: "Coleman",
                        lastName: "Costa",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1204",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1200",
                    firstName: "Brielle",
                    lastName: "Gonzalez",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1191",
                    reports: [
                      {
                        employeeId: "1201",
                        firstName: "Sylas",
                        lastName: "Shields",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1200",
                        reports: []
                      },
                      {
                        employeeId: "1202",
                        firstName: "Emmeline",
                        lastName: "Flynn",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1200",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1192",
                    firstName: "Cayson",
                    lastName: "Walls",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1191",
                    reports: [
                      {
                        employeeId: "1193",
                        firstName: "Sylvia",
                        lastName: "Wolfe",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1192",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1209",
                    firstName: "Raphael",
                    lastName: "Phillips",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1191",
                    reports: [
                      {
                        employeeId: "1213",
                        firstName: "Tessa",
                        lastName: "Wiley",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1209",
                        reports: []
                      },
                      {
                        employeeId: "1210",
                        firstName: "Peyton",
                        lastName: "Murray",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1209",
                        reports: []
                      },
                      {
                        employeeId: "1212",
                        firstName: "Dax",
                        lastName: "Ibarra",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1209",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1195",
                    firstName: "Brixton",
                    lastName: "Golden",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1191",
                    reports: [
                      {
                        employeeId: "1196",
                        firstName: "Tobias",
                        lastName: "Fuller",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1195",
                        reports: []
                      },
                      {
                        employeeId: "1198",
                        firstName: "Deborah",
                        lastName: "Alvarez",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1195",
                        reports: []
                      },
                      {
                        employeeId: "1197",
                        firstName: "Zoe",
                        lastName: "Oneal",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1195",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1240",
                firstName: "Corbin",
                lastName: "Chandler",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1118",
                reports: [
                  {
                    employeeId: "1253",
                    firstName: "Kaiya",
                    lastName: "Palmer",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1240",
                    reports: [
                      {
                        employeeId: "1255",
                        firstName: "Sariyah",
                        lastName: "Mills",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1253",
                        reports: []
                      },
                      {
                        employeeId: "1254",
                        firstName: "Gary",
                        lastName: "Harrington",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1253",
                        reports: []
                      },
                      {
                        employeeId: "1256",
                        firstName: "Timothy",
                        lastName: "Adams",
                        jobTitle: "Architect-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1253",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1248",
                    firstName: "Sean",
                    lastName: "Smith",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1240",
                    reports: [
                      {
                        employeeId: "1251",
                        firstName: "Bishop",
                        lastName: "Bryan",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1248",
                        reports: []
                      },
                      {
                        employeeId: "1249",
                        firstName: "Ayleen",
                        lastName: "Payne",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1248",
                        reports: []
                      },
                      {
                        employeeId: "1250",
                        firstName: "Sabrina",
                        lastName: "Jacobson",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1248",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1241",
                    firstName: "Reuben",
                    lastName: "Clark",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1240",
                    reports: [
                      {
                        employeeId: "1242",
                        firstName: "Cristiano",
                        lastName: "Fleming",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1241",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1244",
                    firstName: "Tara",
                    lastName: "Macdonald",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1240",
                    reports: [
                      {
                        employeeId: "1246",
                        firstName: "Urijah",
                        lastName: "Roberts",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1244",
                        reports: []
                      },
                      {
                        employeeId: "1245",
                        firstName: "Scarlette",
                        lastName: "Wells",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1244",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1258",
                    firstName: "Bianca",
                    lastName: "Howe",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1240",
                    reports: [
                      {
                        employeeId: "1259",
                        firstName: "Marianna",
                        lastName: "Waller",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1258",
                        reports: []
                      },
                      {
                        employeeId: "1260",
                        firstName: "Madeline",
                        lastName: "Oconnor",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1258",
                        reports: []
                      },
                      {
                        employeeId: "1261",
                        firstName: "Kyra",
                        lastName: "Reynolds",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1258",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1119",
                firstName: "Hudson",
                lastName: "Dodson",
                jobTitle: "Residential Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1118",
                reports: [
                  {
                    employeeId: "1120",
                    firstName: "Rebekah",
                    lastName: "Mason",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1119",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1173",
                firstName: "Jaylee",
                lastName: "Sanders",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1118",
                reports: [
                  {
                    employeeId: "1184",
                    firstName: "Janessa",
                    lastName: "Rogers",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1173",
                    reports: [
                      {
                        employeeId: "1185",
                        firstName: "Joshua",
                        lastName: "Foley",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1184",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1174",
                    firstName: "Weston",
                    lastName: "Owens",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1173",
                    reports: []
                  },
                  {
                    employeeId: "1186",
                    firstName: "Kairi",
                    lastName: "Hopkins",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1173",
                    reports: [
                      {
                        employeeId: "1188",
                        firstName: "Yahya",
                        lastName: "Bright",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1186",
                        reports: []
                      },
                      {
                        employeeId: "1189",
                        firstName: "Arya",
                        lastName: "Walker",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1186",
                        reports: []
                      },
                      {
                        employeeId: "1187",
                        firstName: "Sara",
                        lastName: "Sanders",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1186",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1177",
                    firstName: "Jaylee",
                    lastName: "Arias",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1173",
                    reports: [
                      {
                        employeeId: "1178",
                        firstName: "Junior",
                        lastName: "Joseph",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1177",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1179",
                    firstName: "Adrianna",
                    lastName: "Mahoney",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1173",
                    reports: [
                      {
                        employeeId: "1180",
                        firstName: "Finnegan",
                        lastName: "Grimes",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1179",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1175",
                    firstName: "Joaquin",
                    lastName: "Friedman",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1173",
                    reports: [
                      {
                        employeeId: "1176",
                        firstName: "Kinsley",
                        lastName: "Mooney",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1175",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1181",
                    firstName: "London",
                    lastName: "Rivera",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1173",
                    reports: [
                      {
                        employeeId: "1182",
                        firstName: "Warren",
                        lastName: "Frost",
                        jobTitle: "Architect-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1181",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1121",
                firstName: "Kailey",
                lastName: "Figueroa",
                jobTitle: "Residential Architecture Director",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1118",
                reports: [
                  {
                    employeeId: "1132",
                    firstName: "Arlo",
                    lastName: "Morton",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1121",
                    reports: [
                      {
                        employeeId: "1133",
                        firstName: "Johnathan",
                        lastName: "Webster",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1132",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1128",
                    firstName: "Juliana",
                    lastName: "Cherry",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1121",
                    reports: [
                      {
                        employeeId: "1130",
                        firstName: "Jane",
                        lastName: "Gregory",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1128",
                        reports: []
                      },
                      {
                        employeeId: "1129",
                        firstName: "Bianca",
                        lastName: "Stephenson",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1128",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1135",
                    firstName: "Griffin",
                    lastName: "Mayer",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1121",
                    reports: [
                      {
                        employeeId: "1137",
                        firstName: "Russell",
                        lastName: "Murray",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1135",
                        reports: []
                      },
                      {
                        employeeId: "1136",
                        firstName: "Angie",
                        lastName: "Nguyen",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1135",
                        reports: []
                      },
                      {
                        employeeId: "1138",
                        firstName: "Bruce",
                        lastName: "Jacobs",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1135",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1125",
                    firstName: "Autumn",
                    lastName: "Li",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1121",
                    reports: [
                      {
                        employeeId: "1126",
                        firstName: "Elaine",
                        lastName: "Nguyen",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1125",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1140",
                    firstName: "Shawn",
                    lastName: "Case",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1121",
                    reports: [
                      {
                        employeeId: "1143",
                        firstName: "Yosef",
                        lastName: "Bradley",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1140",
                        reports: []
                      },
                      {
                        employeeId: "1144",
                        firstName: "Laylah",
                        lastName: "Moon",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1140",
                        reports: []
                      },
                      {
                        employeeId: "1141",
                        firstName: "Jacob",
                        lastName: "Malone",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1140",
                        reports: []
                      },
                      {
                        employeeId: "1142",
                        firstName: "Jett",
                        lastName: "Hensley",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1140",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1122",
                    firstName: "Lilian",
                    lastName: "Bender",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1121",
                    reports: [
                      {
                        employeeId: "1123",
                        firstName: "Todd",
                        lastName: "Hobbs",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1122",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1146",
                firstName: "Milo",
                lastName: "Kane",
                jobTitle: "Residential Architecture Director",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1118",
                reports: [
                  {
                    employeeId: "1166",
                    firstName: "Gael",
                    lastName: "Vargas",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1146",
                    reports: [
                      {
                        employeeId: "1171",
                        firstName: "Eden",
                        lastName: "Mcclain",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1166",
                        reports: []
                      },
                      {
                        employeeId: "1170",
                        firstName: "Alana",
                        lastName: "Meadows",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1166",
                        reports: []
                      },
                      {
                        employeeId: "1167",
                        firstName: "Jayvion",
                        lastName: "Carter",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1166",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1162",
                    firstName: "Iris",
                    lastName: "Carson",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1146",
                    reports: [
                      {
                        employeeId: "1163",
                        firstName: "Fiona",
                        lastName: "Chase",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1162",
                        reports: []
                      },
                      {
                        employeeId: "1164",
                        firstName: "Jane",
                        lastName: "Chase",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1162",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1147",
                    firstName: "Kade",
                    lastName: "Rosales",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1146",
                    reports: [
                      {
                        employeeId: "1148",
                        firstName: "Bronson",
                        lastName: "Flores",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1147",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1152",
                    firstName: "Alvin",
                    lastName: "Deleon",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1146",
                    reports: [
                      {
                        employeeId: "1154",
                        firstName: "Jorge",
                        lastName: "Frank",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1152",
                        reports: []
                      },
                      {
                        employeeId: "1153",
                        firstName: "Gary",
                        lastName: "Cardenas",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1152",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1149",
                    firstName: "Tyrone",
                    lastName: "Glover",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1146",
                    reports: [
                      {
                        employeeId: "1150",
                        firstName: "Avery",
                        lastName: "Johns",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1149",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1159",
                    firstName: "Shaun",
                    lastName: "Mcconnell",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1146",
                    reports: [
                      {
                        employeeId: "1160",
                        firstName: "Nikolas",
                        lastName: "Hatfield",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1159",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1156",
                    firstName: "Avalyn",
                    lastName: "Mason",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1146",
                    reports: [
                      {
                        employeeId: "1157",
                        firstName: "Liliana",
                        lastName: "Butler",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1156",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1215",
                firstName: "Channing",
                lastName: "Livingston",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1118",
                reports: [
                  {
                    employeeId: "1237",
                    firstName: "Lailah",
                    lastName: "Lee",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1215",
                    reports: [
                      {
                        employeeId: "1238",
                        firstName: "Jocelyn",
                        lastName: "Alexander",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1237",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1236",
                    firstName: "Gracelyn",
                    lastName: "Hudson",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1215",
                    reports: []
                  },
                  {
                    employeeId: "1216",
                    firstName: "Sarai",
                    lastName: "Huff",
                    jobTitle: "Residential Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1215",
                    reports: [
                      {
                        employeeId: "1220",
                        firstName: "Victor",
                        lastName: "Golden",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1216",
                        reports: []
                      },
                      {
                        employeeId: "1228",
                        firstName: "Nicholas",
                        lastName: "Morse",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1216",
                        reports: []
                      },
                      {
                        employeeId: "1218",
                        firstName: "Skye",
                        lastName: "Porter",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1216",
                        reports: []
                      },
                      {
                        employeeId: "1222",
                        firstName: "Kyree",
                        lastName: "Cox",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1216",
                        reports: []
                      },
                      {
                        employeeId: "1217",
                        firstName: "Clark",
                        lastName: "Rojas",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1216",
                        reports: []
                      },
                      {
                        employeeId: "1225",
                        firstName: "Carter",
                        lastName: "Harris",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1216",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1234",
                    firstName: "Jazmin",
                    lastName: "Byrd",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1215",
                    reports: []
                  },
                  {
                    employeeId: "1235",
                    firstName: "Ellis",
                    lastName: "Baker",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1215",
                    reports: []
                  },
                  {
                    employeeId: "1233",
                    firstName: "Corey",
                    lastName: "Boyle",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1215",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1263",
                firstName: "Heidi",
                lastName: "Parker",
                jobTitle: "Senior Director of Residential Architecture",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1118",
                reports: [
                  {
                    employeeId: "1272",
                    firstName: "Jaziel",
                    lastName: "Arnold",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1263",
                    reports: [
                      {
                        employeeId: "1273",
                        firstName: "Perla",
                        lastName: "Molina",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1272",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1284",
                    firstName: "Heavenly",
                    lastName: "Gould",
                    jobTitle: "Residential Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1263",
                    reports: [
                      {
                        employeeId: "1285",
                        firstName: "Branson",
                        lastName: "Delgado",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1284",
                        reports: []
                      },
                      {
                        employeeId: "1289",
                        firstName: "Hayley",
                        lastName: "Carey",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1284",
                        reports: []
                      },
                      {
                        employeeId: "1288",
                        firstName: "Savannah",
                        lastName: "Harper",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1284",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1264",
                    firstName: "Juliana",
                    lastName: "Novak",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1263",
                    reports: [
                      {
                        employeeId: "1266",
                        firstName: "Neymar",
                        lastName: "Russo",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1264",
                        reports: []
                      },
                      {
                        employeeId: "1265",
                        firstName: "Gabriela",
                        lastName: "Bennett",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1264",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1275",
                    firstName: "Ayden",
                    lastName: "Glass",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1263",
                    reports: [
                      {
                        employeeId: "1277",
                        firstName: "Clarissa",
                        lastName: "Briggs",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1275",
                        reports: []
                      },
                      {
                        employeeId: "1276",
                        firstName: "Emory",
                        lastName: "Hull",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1275",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1268",
                    firstName: "Emilia",
                    lastName: "Noble",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1263",
                    reports: [
                      {
                        employeeId: "1270",
                        firstName: "Emmeline",
                        lastName: "Fischer",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1268",
                        reports: []
                      },
                      {
                        employeeId: "1269",
                        firstName: "Camille",
                        lastName: "Maynard",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1268",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1279",
                    firstName: "Brooklynn",
                    lastName: "Burgess",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1263",
                    reports: [
                      {
                        employeeId: "1282",
                        firstName: "Gregory",
                        lastName: "Velez",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1279",
                        reports: []
                      },
                      {
                        employeeId: "1280",
                        firstName: "Adelina",
                        lastName: "Schultz",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1279",
                        reports: []
                      },
                      {
                        employeeId: "1281",
                        firstName: "Zendaya",
                        lastName: "Salinas",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1279",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "923",
            firstName: "Alexander",
            lastName: "Reyes",
            jobTitle: "Vice President of Commercial Architecture",
            officeLocation: "Paris",
            department: "Commercial Architecture",
            managerEmployeeId: "763",
            reports: [
              {
                employeeId: "1042",
                firstName: "Annabel",
                lastName: "Sims",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1043",
                    firstName: "Axel",
                    lastName: "Martinez",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1042",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1105",
                firstName: "Waylon",
                lastName: "Gallegos",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1108",
                    firstName: "Joshua",
                    lastName: "Wagner",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1105",
                    reports: []
                  },
                  {
                    employeeId: "1109",
                    firstName: "Gemma",
                    lastName: "Tanner",
                    jobTitle: "Commercial Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1105",
                    reports: [
                      {
                        employeeId: "1110",
                        firstName: "Joselyn",
                        lastName: "Navarro",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1109",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1106",
                    firstName: "Aileen",
                    lastName: "Hammond",
                    jobTitle: "Commercial Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1105",
                    reports: [
                      {
                        employeeId: "1107",
                        firstName: "Kenya",
                        lastName: "Brewer",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1106",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1069",
                firstName: "Noemi",
                lastName: "Figueroa",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1070",
                    firstName: "Christine",
                    lastName: "Pham",
                    jobTitle: "Commercial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1069",
                    reports: [
                      {
                        employeeId: "1071",
                        firstName: "Belen",
                        lastName: "Forbes",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1070",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1036",
                firstName: "Juniper",
                lastName: "Hobbs",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1037",
                    firstName: "Benton",
                    lastName: "Little",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1036",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1030",
                firstName: "Remington",
                lastName: "Hatfield",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1031",
                    firstName: "Aurora",
                    lastName: "Stewart",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1030",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1063",
                firstName: "Hunter",
                lastName: "Richard",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1064",
                    firstName: "Rylee",
                    lastName: "Hurley",
                    jobTitle: "Commercial Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1063",
                    reports: [
                      {
                        employeeId: "1065",
                        firstName: "Zane",
                        lastName: "Cohen",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1064",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1032",
                firstName: "Parker",
                lastName: "Faulkner",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1033",
                    firstName: "Kian",
                    lastName: "Wade",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1032",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1038",
                firstName: "Fernando",
                lastName: "Richardson",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1039",
                    firstName: "Marcos",
                    lastName: "Trujillo",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1038",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1087",
                firstName: "London",
                lastName: "Hurst",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1090",
                    firstName: "Lizbeth",
                    lastName: "Monroe",
                    jobTitle: "Commercial Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1087",
                    reports: [
                      {
                        employeeId: "1091",
                        firstName: "Orion",
                        lastName: "Parker",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1090",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1088",
                    firstName: "Dimitri",
                    lastName: "Bean",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1087",
                    reports: []
                  },
                  {
                    employeeId: "1089",
                    firstName: "John",
                    lastName: "English",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1087",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1072",
                firstName: "Marleigh",
                lastName: "Kramer",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1073",
                    firstName: "Lawrence",
                    lastName: "Clayton",
                    jobTitle: "Commercial Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1072",
                    reports: [
                      {
                        employeeId: "1074",
                        firstName: "Maddox",
                        lastName: "Hayden",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1073",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1028",
                firstName: "Korbin",
                lastName: "Graves",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1029",
                    firstName: "Derrick",
                    lastName: "Russo",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1028",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1040",
                firstName: "Camila",
                lastName: "Khan",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1041",
                    firstName: "Markus",
                    lastName: "Leon",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1040",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1034",
                firstName: "Harrison",
                lastName: "Lee",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1035",
                    firstName: "Lionel",
                    lastName: "Mclaughlin",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1034",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1092",
                firstName: "Lily",
                lastName: "Browning",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1094",
                    firstName: "Isaiah",
                    lastName: "Rush",
                    jobTitle: "Architect-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1092",
                    reports: [
                      {
                        employeeId: "1095",
                        firstName: "Solomon",
                        lastName: "Hanna",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1094",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1093",
                    firstName: "Makayla",
                    lastName: "Good",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1092",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1060",
                firstName: "Esmeralda",
                lastName: "Mcknight",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1061",
                    firstName: "Mario",
                    lastName: "Yang",
                    jobTitle: "Commercial Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1060",
                    reports: [
                      {
                        employeeId: "1062",
                        firstName: "Abby",
                        lastName: "Rich",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1061",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1044",
                firstName: "Kelsey",
                lastName: "Archer",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1045",
                    firstName: "Beatrice",
                    lastName: "Richards",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1044",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1050",
                firstName: "Trenton",
                lastName: "Cochran",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1051",
                    firstName: "Zechariah",
                    lastName: "Pacheco",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1050",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1055",
                firstName: "Hector",
                lastName: "Oliver",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1056",
                    firstName: "Journee",
                    lastName: "Mccann",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1055",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1066",
                firstName: "Kaleb",
                lastName: "Melton",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1067",
                    firstName: "Edgar",
                    lastName: "Brennan",
                    jobTitle: "Commercial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1066",
                    reports: [
                      {
                        employeeId: "1068",
                        firstName: "Willie",
                        lastName: "Barker",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1067",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1046",
                firstName: "Aurelia",
                lastName: "Rivera",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1047",
                    firstName: "Kylen",
                    lastName: "Avila",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1046",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1052",
                firstName: "Matteo",
                lastName: "Silva",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1053",
                    firstName: "Ruben",
                    lastName: "Reese",
                    jobTitle: "Commercial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1052",
                    reports: [
                      {
                        employeeId: "1054",
                        firstName: "Karla",
                        lastName: "Romero",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1053",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1111",
                firstName: "Milania",
                lastName: "Graves",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1115",
                    firstName: "Sky",
                    lastName: "Charles",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1111",
                    reports: []
                  },
                  {
                    employeeId: "1112",
                    firstName: "Amalia",
                    lastName: "Porter",
                    jobTitle: "Commercial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1111",
                    reports: [
                      {
                        employeeId: "1113",
                        firstName: "Matthias",
                        lastName: "Butler",
                        jobTitle: "Commercial Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1112",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1116",
                    firstName: "Amiya",
                    lastName: "Barker",
                    jobTitle: "Commercial Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1111",
                    reports: [
                      {
                        employeeId: "1117",
                        firstName: "Harmoni",
                        lastName: "Bruce",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1116",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1101",
                firstName: "Brenna",
                lastName: "Bradshaw",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1102",
                    firstName: "Dalton",
                    lastName: "Long",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1101",
                    reports: []
                  },
                  {
                    employeeId: "1103",
                    firstName: "Gary",
                    lastName: "Archer",
                    jobTitle: "Commercial Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1101",
                    reports: [
                      {
                        employeeId: "1104",
                        firstName: "Malaya",
                        lastName: "Velez",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1103",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1096",
                firstName: "Emma",
                lastName: "Coleman",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1097",
                    firstName: "Royce",
                    lastName: "Leon",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1096",
                    reports: []
                  },
                  {
                    employeeId: "1098",
                    firstName: "Braiden",
                    lastName: "Watson",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1096",
                    reports: []
                  },
                  {
                    employeeId: "1099",
                    firstName: "Ashley",
                    lastName: "Ibarra",
                    jobTitle: "Architect-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1096",
                    reports: [
                      {
                        employeeId: "1100",
                        firstName: "Maxton",
                        lastName: "Cole",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1099",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "924",
                firstName: "Jace",
                lastName: "Irwin",
                jobTitle: "Distinguished Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1012",
                    firstName: "Sloane",
                    lastName: "Bailey",
                    jobTitle: "Commercial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "924",
                    reports: [
                      {
                        employeeId: "1013",
                        firstName: "Kendall",
                        lastName: "Russell",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1012",
                        reports: []
                      },
                      {
                        employeeId: "1015",
                        firstName: "Addison",
                        lastName: "Owens",
                        jobTitle: "Commercial Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1012",
                        reports: []
                      },
                      {
                        employeeId: "1014",
                        firstName: "Khalil",
                        lastName: "Barr",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1012",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "925",
                    firstName: "Brynlee",
                    lastName: "Hopkins",
                    jobTitle: "Principal Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "924",
                    reports: [
                      {
                        employeeId: "973",
                        firstName: "Ulises",
                        lastName: "Villegas",
                        jobTitle: "Commercial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "925",
                        reports: []
                      },
                      {
                        employeeId: "926",
                        firstName: "Karla",
                        lastName: "Wilson",
                        jobTitle: "Commercial Architecture Director",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "925",
                        reports: []
                      },
                      {
                        employeeId: "977",
                        firstName: "Kendra",
                        lastName: "Vance",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "925",
                        reports: []
                      },
                      {
                        employeeId: "968",
                        firstName: "Anika",
                        lastName: "Duke",
                        jobTitle: "Commercial Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "925",
                        reports: []
                      },
                      {
                        employeeId: "982",
                        firstName: "Oscar",
                        lastName: "Davila",
                        jobTitle: "Commercial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "925",
                        reports: []
                      },
                      {
                        employeeId: "958",
                        firstName: "Madden",
                        lastName: "Silva",
                        jobTitle: "Commercial Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "925",
                        reports: []
                      },
                      {
                        employeeId: "963",
                        firstName: "Penny",
                        lastName: "Becker",
                        jobTitle: "Commercial Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "925",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "992",
                    firstName: "Collin",
                    lastName: "Osborne",
                    jobTitle: "Commercial Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "924",
                    reports: [
                      {
                        employeeId: "997",
                        firstName: "Amya",
                        lastName: "Ellison",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "992",
                        reports: []
                      },
                      {
                        employeeId: "993",
                        firstName: "Corbin",
                        lastName: "Chapman",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "992",
                        reports: []
                      },
                      {
                        employeeId: "996",
                        firstName: "Fatima",
                        lastName: "Krause",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "992",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "999",
                    firstName: "Arya",
                    lastName: "Clark",
                    jobTitle: "Senior Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "924",
                    reports: [
                      {
                        employeeId: "1003",
                        firstName: "Sylas",
                        lastName: "Preston",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "999",
                        reports: []
                      },
                      {
                        employeeId: "1000",
                        firstName: "Ahmad",
                        lastName: "Tucker",
                        jobTitle: "Commercial Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "999",
                        reports: []
                      },
                      {
                        employeeId: "1004",
                        firstName: "Tatum",
                        lastName: "Summers",
                        jobTitle: "Commercial Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "999",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1017",
                    firstName: "Annie",
                    lastName: "Silva",
                    jobTitle: "Commercial Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "924",
                    reports: [
                      {
                        employeeId: "1023",
                        firstName: "Dominic",
                        lastName: "Raymond",
                        jobTitle: "Commercial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1017",
                        reports: []
                      },
                      {
                        employeeId: "1018",
                        firstName: "Kinslee",
                        lastName: "Landry",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1017",
                        reports: []
                      },
                      {
                        employeeId: "1019",
                        firstName: "Cadence",
                        lastName: "Skinner",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1017",
                        reports: []
                      },
                      {
                        employeeId: "1021",
                        firstName: "Ignacio",
                        lastName: "Mosley",
                        jobTitle: "Commercial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1017",
                        reports: []
                      },
                      {
                        employeeId: "1020",
                        firstName: "Beckett",
                        lastName: "Houston",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1017",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1006",
                    firstName: "Tinley",
                    lastName: "Petty",
                    jobTitle: "Commercial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "924",
                    reports: [
                      {
                        employeeId: "1007",
                        firstName: "Selena",
                        lastName: "Porter",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1006",
                        reports: []
                      },
                      {
                        employeeId: "1008",
                        firstName: "Ariah",
                        lastName: "Frederick",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1006",
                        reports: []
                      },
                      {
                        employeeId: "1010",
                        firstName: "Marco",
                        lastName: "Pittman",
                        jobTitle: "Commercial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1006",
                        reports: []
                      },
                      {
                        employeeId: "1009",
                        firstName: "Emory",
                        lastName: "Garcia",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1006",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "991",
                    firstName: "Adrian",
                    lastName: "Ray",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "924",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1075",
                firstName: "Enoch",
                lastName: "Pierce",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1076",
                    firstName: "Micah",
                    lastName: "Adams",
                    jobTitle: "Commercial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1075",
                    reports: [
                      {
                        employeeId: "1077",
                        firstName: "Aiden",
                        lastName: "Tran",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1076",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1084",
                firstName: "Grayson",
                lastName: "Kerr",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1085",
                    firstName: "Jordan",
                    lastName: "Kelley",
                    jobTitle: "Architect-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1084",
                    reports: [
                      {
                        employeeId: "1086",
                        firstName: "Lochlan",
                        lastName: "Villanueva",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1085",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1078",
                firstName: "Jonael",
                lastName: "Wilkerson",
                jobTitle: "Staff Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1079",
                    firstName: "Dominique",
                    lastName: "Glover",
                    jobTitle: "Architect-II",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1078",
                    reports: [
                      {
                        employeeId: "1080",
                        firstName: "Augustus",
                        lastName: "Schultz",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1079",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1081",
                firstName: "Valeria",
                lastName: "Kim",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1082",
                    firstName: "Danny",
                    lastName: "Rush",
                    jobTitle: "Commercial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1081",
                    reports: [
                      {
                        employeeId: "1083",
                        firstName: "Allyson",
                        lastName: "Gregory",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1082",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1026",
                firstName: "Damian",
                lastName: "Monroe",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1027",
                    firstName: "Janiya",
                    lastName: "Ortega",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1026",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1057",
                firstName: "Abby",
                lastName: "Hancock",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1058",
                    firstName: "Iliana",
                    lastName: "Ewing",
                    jobTitle: "Commercial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1057",
                    reports: [
                      {
                        employeeId: "1059",
                        firstName: "Jaiden",
                        lastName: "Smith",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "1058",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1048",
                firstName: "Vera",
                lastName: "King",
                jobTitle: "Staff Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "923",
                reports: [
                  {
                    employeeId: "1049",
                    firstName: "Jaziel",
                    lastName: "Little",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "1048",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "1291",
            firstName: "Bryant",
            lastName: "Galloway",
            jobTitle: "Senior Director of Residential Architecture",
            officeLocation: "Paris",
            department: "Residential Architecture",
            managerEmployeeId: "763",
            reports: [
              {
                employeeId: "1460",
                firstName: "Mara",
                lastName: "Wilkerson",
                jobTitle: "Senior Director of Residential Architecture",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1291",
                reports: [
                  {
                    employeeId: "1461",
                    firstName: "Emmett",
                    lastName: "Potts",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1460",
                    reports: [
                      {
                        employeeId: "1463",
                        firstName: "Trent",
                        lastName: "Browning",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1461",
                        reports: []
                      },
                      {
                        employeeId: "1462",
                        firstName: "Todd",
                        lastName: "Hendrix",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1461",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1485",
                    firstName: "Lyric",
                    lastName: "Williams",
                    jobTitle: "Residential Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1460",
                    reports: [
                      {
                        employeeId: "1489",
                        firstName: "Jeremiah",
                        lastName: "Levy",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1485",
                        reports: []
                      },
                      {
                        employeeId: "1486",
                        firstName: "Imani",
                        lastName: "Mckinney",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1485",
                        reports: []
                      },
                      {
                        employeeId: "1490",
                        firstName: "Ronin",
                        lastName: "French",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1485",
                        reports: []
                      },
                      {
                        employeeId: "1487",
                        firstName: "Blake",
                        lastName: "Fowler",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1485",
                        reports: []
                      },
                      {
                        employeeId: "1488",
                        firstName: "Sawyer",
                        lastName: "Merritt",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1485",
                        reports: []
                      },
                      {
                        employeeId: "1491",
                        firstName: "Zaiden",
                        lastName: "Tucker",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1485",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1465",
                    firstName: "Delilah",
                    lastName: "Greene",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1460",
                    reports: [
                      {
                        employeeId: "1468",
                        firstName: "Heaven",
                        lastName: "Bullock",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1465",
                        reports: []
                      },
                      {
                        employeeId: "1466",
                        firstName: "Devin",
                        lastName: "Ellis",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1465",
                        reports: []
                      },
                      {
                        employeeId: "1467",
                        firstName: "Cameron",
                        lastName: "Dean",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1465",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1475",
                    firstName: "Kai",
                    lastName: "Mckay",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1460",
                    reports: [
                      {
                        employeeId: "1477",
                        firstName: "Brodie",
                        lastName: "Padilla",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1475",
                        reports: []
                      },
                      {
                        employeeId: "1476",
                        firstName: "Avalyn",
                        lastName: "Booth",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1475",
                        reports: []
                      },
                      {
                        employeeId: "1478",
                        firstName: "Madison",
                        lastName: "Walker",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1475",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1480",
                    firstName: "Guadalupe",
                    lastName: "Page",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1460",
                    reports: [
                      {
                        employeeId: "1483",
                        firstName: "Emilie",
                        lastName: "Maldonado",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1480",
                        reports: []
                      },
                      {
                        employeeId: "1482",
                        firstName: "Wilson",
                        lastName: "Woodard",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1480",
                        reports: []
                      },
                      {
                        employeeId: "1481",
                        firstName: "Lauryn",
                        lastName: "Powell",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1480",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1470",
                    firstName: "Eric",
                    lastName: "Cochran",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1460",
                    reports: [
                      {
                        employeeId: "1471",
                        firstName: "Dominique",
                        lastName: "King",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1470",
                        reports: []
                      },
                      {
                        employeeId: "1472",
                        firstName: "Cassandra",
                        lastName: "Fischer",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1470",
                        reports: []
                      },
                      {
                        employeeId: "1473",
                        firstName: "Gwendolyn",
                        lastName: "Glass",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1470",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1408",
                firstName: "Keenan",
                lastName: "Jordan",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1291",
                reports: [
                  {
                    employeeId: "1427",
                    firstName: "Ronin",
                    lastName: "Cobb",
                    jobTitle: "Residential Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1408",
                    reports: [
                      {
                        employeeId: "1430",
                        firstName: "Gage",
                        lastName: "Steele",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1427",
                        reports: []
                      },
                      {
                        employeeId: "1431",
                        firstName: "Jordy",
                        lastName: "Christian",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1427",
                        reports: []
                      },
                      {
                        employeeId: "1429",
                        firstName: "Leilani",
                        lastName: "Tanner",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1427",
                        reports: []
                      },
                      {
                        employeeId: "1428",
                        firstName: "Elaina",
                        lastName: "Waters",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1427",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1418",
                    firstName: "Leonardo",
                    lastName: "Case",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1408",
                    reports: [
                      {
                        employeeId: "1420",
                        firstName: "Andy",
                        lastName: "Ramsey",
                        jobTitle: "Architect-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1418",
                        reports: []
                      },
                      {
                        employeeId: "1419",
                        firstName: "Gannon",
                        lastName: "Bush",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1418",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1414",
                    firstName: "Sariah",
                    lastName: "Salas",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1408",
                    reports: [
                      {
                        employeeId: "1415",
                        firstName: "Raelyn",
                        lastName: "Webb",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1414",
                        reports: []
                      },
                      {
                        employeeId: "1416",
                        firstName: "Kathleen",
                        lastName: "Mann",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1414",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1409",
                    firstName: "Deshawn",
                    lastName: "Stevenson",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1408",
                    reports: [
                      {
                        employeeId: "1411",
                        firstName: "Elliott",
                        lastName: "Wolf",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1409",
                        reports: []
                      },
                      {
                        employeeId: "1412",
                        firstName: "Gordon",
                        lastName: "Wolf",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1409",
                        reports: []
                      },
                      {
                        employeeId: "1410",
                        firstName: "Talia",
                        lastName: "Arias",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1409",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1422",
                    firstName: "Harley",
                    lastName: "Estrada",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1408",
                    reports: [
                      {
                        employeeId: "1423",
                        firstName: "Gia",
                        lastName: "Schmidt",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1422",
                        reports: []
                      },
                      {
                        employeeId: "1424",
                        firstName: "Dominick",
                        lastName: "Garza",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1422",
                        reports: []
                      },
                      {
                        employeeId: "1425",
                        firstName: "Aryana",
                        lastName: "Norton",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1422",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1380",
                firstName: "Chandler",
                lastName: "Knapp",
                jobTitle: "Senior Director of Residential Architecture",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1291",
                reports: [
                  {
                    employeeId: "1381",
                    firstName: "Trent",
                    lastName: "Lin",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1380",
                    reports: []
                  },
                  {
                    employeeId: "1382",
                    firstName: "Terrell",
                    lastName: "Lozano",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1380",
                    reports: [
                      {
                        employeeId: "1384",
                        firstName: "Jovani",
                        lastName: "Delgado",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1382",
                        reports: []
                      },
                      {
                        employeeId: "1383",
                        firstName: "Nala",
                        lastName: "Guerra",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1382",
                        reports: []
                      },
                      {
                        employeeId: "1385",
                        firstName: "Turner",
                        lastName: "Stuart",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1382",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1401",
                    firstName: "Lola",
                    lastName: "Mckay",
                    jobTitle: "Residential Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1380",
                    reports: [
                      {
                        employeeId: "1406",
                        firstName: "Levi",
                        lastName: "Bender",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1401",
                        reports: []
                      },
                      {
                        employeeId: "1402",
                        firstName: "Azaria",
                        lastName: "Lutz",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1401",
                        reports: []
                      },
                      {
                        employeeId: "1405",
                        firstName: "Kassidy",
                        lastName: "Singh",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1401",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1387",
                    firstName: "Dash",
                    lastName: "Frazier",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1380",
                    reports: [
                      {
                        employeeId: "1389",
                        firstName: "Sean",
                        lastName: "Banks",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1387",
                        reports: []
                      },
                      {
                        employeeId: "1388",
                        firstName: "Virginia",
                        lastName: "Houston",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1387",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1395",
                    firstName: "Nicolas",
                    lastName: "Carter",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1380",
                    reports: [
                      {
                        employeeId: "1399",
                        firstName: "Atticus",
                        lastName: "Reyes",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1395",
                        reports: []
                      },
                      {
                        employeeId: "1398",
                        firstName: "Zaniyah",
                        lastName: "Ferrell",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1395",
                        reports: []
                      },
                      {
                        employeeId: "1396",
                        firstName: "Reyna",
                        lastName: "Vaughan",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1395",
                        reports: []
                      },
                      {
                        employeeId: "1397",
                        firstName: "Forrest",
                        lastName: "Lambert",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1395",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1391",
                    firstName: "Joslyn",
                    lastName: "Stewart",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1380",
                    reports: [
                      {
                        employeeId: "1393",
                        firstName: "Harvey",
                        lastName: "Sanders",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1391",
                        reports: []
                      },
                      {
                        employeeId: "1392",
                        firstName: "Haven",
                        lastName: "Shaffer",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1391",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1434",
                firstName: "Ford",
                lastName: "Hensley",
                jobTitle: "Residential Architecture Director",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1291",
                reports: [
                  {
                    employeeId: "1444",
                    firstName: "Gerardo",
                    lastName: "Jacobs",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1434",
                    reports: [
                      {
                        employeeId: "1445",
                        firstName: "Cali",
                        lastName: "Hebert",
                        jobTitle: "Architect-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1444",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1441",
                    firstName: "Maryam",
                    lastName: "Sherman",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1434",
                    reports: [
                      {
                        employeeId: "1442",
                        firstName: "Andrew",
                        lastName: "Walters",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1441",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1451",
                    firstName: "Annabelle",
                    lastName: "Kirby",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1434",
                    reports: [
                      {
                        employeeId: "1452",
                        firstName: "Xander",
                        lastName: "Cantrell",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1451",
                        reports: []
                      },
                      {
                        employeeId: "1453",
                        firstName: "Zainab",
                        lastName: "Ayers",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1451",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1438",
                    firstName: "Oliver",
                    lastName: "Pierce",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1434",
                    reports: [
                      {
                        employeeId: "1439",
                        firstName: "Lillie",
                        lastName: "Tapia",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1438",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1435",
                    firstName: "Oliver",
                    lastName: "Hays",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1434",
                    reports: []
                  },
                  {
                    employeeId: "1455",
                    firstName: "June",
                    lastName: "Knapp",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1434",
                    reports: [
                      {
                        employeeId: "1456",
                        firstName: "Kinslee",
                        lastName: "Poole",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1455",
                        reports: []
                      },
                      {
                        employeeId: "1457",
                        firstName: "Edgar",
                        lastName: "Yoder",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1455",
                        reports: []
                      },
                      {
                        employeeId: "1458",
                        firstName: "Conrad",
                        lastName: "Hancock",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1455",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1436",
                    firstName: "Aaliyah",
                    lastName: "Newman",
                    jobTitle: "Architect-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1434",
                    reports: [
                      {
                        employeeId: "1437",
                        firstName: "Brecken",
                        lastName: "Garrison",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1436",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1447",
                    firstName: "Sage",
                    lastName: "Andrade",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1434",
                    reports: [
                      {
                        employeeId: "1449",
                        firstName: "Emerson",
                        lastName: "Reilly",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1447",
                        reports: []
                      },
                      {
                        employeeId: "1448",
                        firstName: "Lennox",
                        lastName: "Andrews",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1447",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1292",
                firstName: "Jade",
                lastName: "Durham",
                jobTitle: "Senior Director of Residential Architecture",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1291",
                reports: [
                  {
                    employeeId: "1293",
                    firstName: "Martin",
                    lastName: "Oconnell",
                    jobTitle: "Senior Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1292",
                    reports: [
                      {
                        employeeId: "1295",
                        firstName: "Zavier",
                        lastName: "Armstrong",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1293",
                        reports: []
                      },
                      {
                        employeeId: "1301",
                        firstName: "Khaleesi",
                        lastName: "Butler",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1293",
                        reports: []
                      },
                      {
                        employeeId: "1297",
                        firstName: "Annie",
                        lastName: "Mckenzie",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1293",
                        reports: []
                      },
                      {
                        employeeId: "1299",
                        firstName: "Khaleesi",
                        lastName: "Macdonald",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1293",
                        reports: []
                      },
                      {
                        employeeId: "1303",
                        firstName: "Talon",
                        lastName: "Rasmussen",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1293",
                        reports: []
                      },
                      {
                        employeeId: "1294",
                        firstName: "Yehuda",
                        lastName: "Forbes",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1293",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1312",
                    firstName: "Ayla",
                    lastName: "Herring",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1292",
                    reports: [
                      {
                        employeeId: "1313",
                        firstName: "Libby",
                        lastName: "Stein",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1312",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1310",
                    firstName: "Josiah",
                    lastName: "Richardson",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1292",
                    reports: [
                      {
                        employeeId: "1311",
                        firstName: "Nevaeh",
                        lastName: "Cline",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1310",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1318",
                    firstName: "Lina",
                    lastName: "Hill",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1292",
                    reports: [
                      {
                        employeeId: "1319",
                        firstName: "Greyson",
                        lastName: "Glass",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1318",
                        reports: []
                      },
                      {
                        employeeId: "1320",
                        firstName: "Javier",
                        lastName: "Coffey",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1318",
                        reports: []
                      },
                      {
                        employeeId: "1321",
                        firstName: "Ronan",
                        lastName: "Cain",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1318",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1308",
                    firstName: "Cain",
                    lastName: "Hunt",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1292",
                    reports: [
                      {
                        employeeId: "1309",
                        firstName: "Emmitt",
                        lastName: "Townsend",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1308",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1315",
                    firstName: "Mathias",
                    lastName: "Stevenson",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1292",
                    reports: [
                      {
                        employeeId: "1316",
                        firstName: "Davis",
                        lastName: "Velasquez",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1315",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1348",
                firstName: "Leonel",
                lastName: "Clay",
                jobTitle: "Senior Director of Residential Architecture",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1291",
                reports: [
                  {
                    employeeId: "1370",
                    firstName: "Chevy",
                    lastName: "Cameron",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1348",
                    reports: [
                      {
                        employeeId: "1371",
                        firstName: "Denver",
                        lastName: "Jennings",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1370",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1372",
                    firstName: "Caroline",
                    lastName: "Preston",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1348",
                    reports: [
                      {
                        employeeId: "1373",
                        firstName: "Jovani",
                        lastName: "Calderon",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1372",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1349",
                    firstName: "Adilynn",
                    lastName: "Trujillo",
                    jobTitle: "Residential Architecture Director",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1348",
                    reports: [
                      {
                        employeeId: "1357",
                        firstName: "Curtis",
                        lastName: "Branch",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1349",
                        reports: []
                      },
                      {
                        employeeId: "1351",
                        firstName: "Caitlyn",
                        lastName: "Bailey",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1349",
                        reports: []
                      },
                      {
                        employeeId: "1353",
                        firstName: "Clark",
                        lastName: "Booker",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1349",
                        reports: []
                      },
                      {
                        employeeId: "1350",
                        firstName: "Analia",
                        lastName: "Huff",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1349",
                        reports: []
                      },
                      {
                        employeeId: "1360",
                        firstName: "Anson",
                        lastName: "Golden",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1349",
                        reports: []
                      },
                      {
                        employeeId: "1364",
                        firstName: "Wilder",
                        lastName: "Sanchez",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1349",
                        reports: []
                      },
                      {
                        employeeId: "1355",
                        firstName: "Ashton",
                        lastName: "Harper",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1349",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1374",
                    firstName: "Rebecca",
                    lastName: "Hayes",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1348",
                    reports: [
                      {
                        employeeId: "1375",
                        firstName: "Anabella",
                        lastName: "Romero",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1374",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1376",
                    firstName: "Moshe",
                    lastName: "Blackburn",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1348",
                    reports: [
                      {
                        employeeId: "1378",
                        firstName: "Dana",
                        lastName: "Tucker",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1376",
                        reports: []
                      },
                      {
                        employeeId: "1377",
                        firstName: "Kensington",
                        lastName: "Franco",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1376",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1369",
                    firstName: "Miranda",
                    lastName: "Patrick",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1348",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1323",
                firstName: "Lyric",
                lastName: "Thompson",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1291",
                reports: [
                  {
                    employeeId: "1327",
                    firstName: "Ahmed",
                    lastName: "Peck",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1323",
                    reports: [
                      {
                        employeeId: "1328",
                        firstName: "Andres",
                        lastName: "Gibson",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1327",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1335",
                    firstName: "Harlan",
                    lastName: "Cunningham",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1323",
                    reports: [
                      {
                        employeeId: "1336",
                        firstName: "Marleigh",
                        lastName: "Mann",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1335",
                        reports: []
                      },
                      {
                        employeeId: "1337",
                        firstName: "Thiago",
                        lastName: "Contreras",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1335",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1332",
                    firstName: "Callum",
                    lastName: "Mcneil",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1323",
                    reports: [
                      {
                        employeeId: "1333",
                        firstName: "Yusuf",
                        lastName: "Charles",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1332",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1343",
                    firstName: "Cadence",
                    lastName: "Heath",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1323",
                    reports: [
                      {
                        employeeId: "1345",
                        firstName: "Jeremy",
                        lastName: "Howe",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1343",
                        reports: []
                      },
                      {
                        employeeId: "1346",
                        firstName: "Sienna",
                        lastName: "Spence",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1343",
                        reports: []
                      },
                      {
                        employeeId: "1344",
                        firstName: "Braylon",
                        lastName: "Conley",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1343",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1330",
                    firstName: "Sariah",
                    lastName: "Ellis",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1323",
                    reports: [
                      {
                        employeeId: "1331",
                        firstName: "Dayton",
                        lastName: "Juarez",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1330",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1325",
                    firstName: "Gerardo",
                    lastName: "Walters",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1323",
                    reports: [
                      {
                        employeeId: "1326",
                        firstName: "Aniya",
                        lastName: "Cunningham",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1325",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1324",
                    firstName: "Neriah",
                    lastName: "Cannon",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1323",
                    reports: []
                  },
                  {
                    employeeId: "1339",
                    firstName: "Emery",
                    lastName: "Winters",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1323",
                    reports: [
                      {
                        employeeId: "1341",
                        firstName: "Sasha",
                        lastName: "Zhang",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1339",
                        reports: []
                      },
                      {
                        employeeId: "1340",
                        firstName: "Miguel",
                        lastName: "Irwin",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1339",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "768",
            firstName: "Connor",
            lastName: "Crane",
            jobTitle: "Senior Director of Industrial Architecture",
            officeLocation: "Paris",
            department: "Industrial Architecture",
            managerEmployeeId: "763",
            reports: [
              {
                employeeId: "786",
                firstName: "Thomas",
                lastName: "Dickerson",
                jobTitle: "Principal Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "768",
                reports: [
                  {
                    employeeId: "802",
                    firstName: "Zaria",
                    lastName: "Nash",
                    jobTitle: "Industrial Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "786",
                    reports: [
                      {
                        employeeId: "803",
                        firstName: "Wilson",
                        lastName: "Rivas",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "802",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "798",
                    firstName: "Andy",
                    lastName: "West",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "786",
                    reports: []
                  },
                  {
                    employeeId: "800",
                    firstName: "Rhys",
                    lastName: "Jensen",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "786",
                    reports: []
                  },
                  {
                    employeeId: "787",
                    firstName: "Braiden",
                    lastName: "Vazquez",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "786",
                    reports: [
                      {
                        employeeId: "796",
                        firstName: "Patrick",
                        lastName: "Morris",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "787",
                        reports: []
                      },
                      {
                        employeeId: "788",
                        firstName: "Ivory",
                        lastName: "Dickson",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "787",
                        reports: []
                      },
                      {
                        employeeId: "795",
                        firstName: "Kyra",
                        lastName: "Pratt",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "787",
                        reports: []
                      },
                      {
                        employeeId: "793",
                        firstName: "Zain",
                        lastName: "Oconnor",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "787",
                        reports: []
                      },
                      {
                        employeeId: "794",
                        firstName: "Alice",
                        lastName: "Pineda",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "787",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "799",
                    firstName: "Serenity",
                    lastName: "Conner",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "786",
                    reports: []
                  },
                  {
                    employeeId: "801",
                    firstName: "Kayden",
                    lastName: "Anderson",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "786",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "804",
                firstName: "Paige",
                lastName: "Pennington",
                jobTitle: "Principal Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "768",
                reports: [
                  {
                    employeeId: "809",
                    firstName: "Kamari",
                    lastName: "Mckee",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "804",
                    reports: [
                      {
                        employeeId: "811",
                        firstName: "Brianna",
                        lastName: "Mercer",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "809",
                        reports: []
                      },
                      {
                        employeeId: "810",
                        firstName: "Lance",
                        lastName: "Cook",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "809",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "818",
                    firstName: "Alfredo",
                    lastName: "Arroyo",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "804",
                    reports: [
                      {
                        employeeId: "820",
                        firstName: "Rayden",
                        lastName: "Mcmahon",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "818",
                        reports: []
                      },
                      {
                        employeeId: "819",
                        firstName: "Jack",
                        lastName: "Mcmahon",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "818",
                        reports: []
                      },
                      {
                        employeeId: "821",
                        firstName: "Jameson",
                        lastName: "Marshall",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "818",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "813",
                    firstName: "Jamir",
                    lastName: "Coffey",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "804",
                    reports: [
                      {
                        employeeId: "814",
                        firstName: "Guadalupe",
                        lastName: "Ramirez",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "813",
                        reports: []
                      },
                      {
                        employeeId: "816",
                        firstName: "Clyde",
                        lastName: "Leon",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "813",
                        reports: []
                      },
                      {
                        employeeId: "815",
                        firstName: "Alani",
                        lastName: "Terry",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "813",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "823",
                    firstName: "Abby",
                    lastName: "Mayo",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "804",
                    reports: [
                      {
                        employeeId: "826",
                        firstName: "Porter",
                        lastName: "Villarreal",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "823",
                        reports: []
                      },
                      {
                        employeeId: "827",
                        firstName: "Whitney",
                        lastName: "Webb",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "823",
                        reports: []
                      },
                      {
                        employeeId: "824",
                        firstName: "Remy",
                        lastName: "Copeland",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "823",
                        reports: []
                      },
                      {
                        employeeId: "825",
                        firstName: "Rory",
                        lastName: "Banks",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "823",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "805",
                    firstName: "Serena",
                    lastName: "Rowland",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "804",
                    reports: [
                      {
                        employeeId: "806",
                        firstName: "Alaina",
                        lastName: "Hoffman",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "805",
                        reports: []
                      },
                      {
                        employeeId: "807",
                        firstName: "Stetson",
                        lastName: "Hansen",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "805",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "848",
                firstName: "Diana",
                lastName: "Wu",
                jobTitle: "Industrial Architecture Director",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "768",
                reports: [
                  {
                    employeeId: "861",
                    firstName: "Cecelia",
                    lastName: "Valdez",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "848",
                    reports: [
                      {
                        employeeId: "862",
                        firstName: "Alondra",
                        lastName: "Pruitt",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "861",
                        reports: []
                      },
                      {
                        employeeId: "863",
                        firstName: "Miah",
                        lastName: "Moreno",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "861",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "865",
                    firstName: "Raelynn",
                    lastName: "Mason",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "848",
                    reports: [
                      {
                        employeeId: "870",
                        firstName: "Ares",
                        lastName: "Christian",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "865",
                        reports: []
                      },
                      {
                        employeeId: "869",
                        firstName: "Sienna",
                        lastName: "Moses",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "865",
                        reports: []
                      },
                      {
                        employeeId: "866",
                        firstName: "Yamileth",
                        lastName: "Banks",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "865",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "856",
                    firstName: "Zion",
                    lastName: "Petty",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "848",
                    reports: [
                      {
                        employeeId: "858",
                        firstName: "Kannon",
                        lastName: "Johnston",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "856",
                        reports: []
                      },
                      {
                        employeeId: "857",
                        firstName: "Kristian",
                        lastName: "Beck",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "856",
                        reports: []
                      },
                      {
                        employeeId: "859",
                        firstName: "Farrah",
                        lastName: "Bush",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "856",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "853",
                    firstName: "Daleyza",
                    lastName: "Allen",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "848",
                    reports: [
                      {
                        employeeId: "854",
                        firstName: "Arden",
                        lastName: "Allen",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "853",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "849",
                    firstName: "Jesse",
                    lastName: "Velez",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "848",
                    reports: [
                      {
                        employeeId: "851",
                        firstName: "Izaiah",
                        lastName: "Kramer",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "849",
                        reports: []
                      },
                      {
                        employeeId: "850",
                        firstName: "Ivory",
                        lastName: "Boyd",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "849",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "893",
                firstName: "Kaelynn",
                lastName: "Walter",
                jobTitle: "Distinguished Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "768",
                reports: [
                  {
                    employeeId: "902",
                    firstName: "Aubrielle",
                    lastName: "Hernandez",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "893",
                    reports: [
                      {
                        employeeId: "905",
                        firstName: "Alfred",
                        lastName: "Barrett",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "902",
                        reports: []
                      },
                      {
                        employeeId: "903",
                        firstName: "Thaddeus",
                        lastName: "Bernard",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "902",
                        reports: []
                      },
                      {
                        employeeId: "904",
                        firstName: "Livia",
                        lastName: "Farmer",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "902",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "894",
                    firstName: "Bowen",
                    lastName: "Fletcher",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "893",
                    reports: [
                      {
                        employeeId: "896",
                        firstName: "Luz",
                        lastName: "Waters",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "894",
                        reports: []
                      },
                      {
                        employeeId: "895",
                        firstName: "Dylan",
                        lastName: "Glass",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "894",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "907",
                    firstName: "Blake",
                    lastName: "Castillo",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "893",
                    reports: [
                      {
                        employeeId: "908",
                        firstName: "Sincere",
                        lastName: "Christensen",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "907",
                        reports: []
                      },
                      {
                        employeeId: "909",
                        firstName: "Mateo",
                        lastName: "Bush",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "907",
                        reports: []
                      },
                      {
                        employeeId: "910",
                        firstName: "Nash",
                        lastName: "Patel",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "907",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "912",
                    firstName: "Lawson",
                    lastName: "Edwards",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "893",
                    reports: [
                      {
                        employeeId: "913",
                        firstName: "Lee",
                        lastName: "Martin",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "912",
                        reports: []
                      },
                      {
                        employeeId: "914",
                        firstName: "Jonah",
                        lastName: "Murphy",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "912",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "898",
                    firstName: "Alina",
                    lastName: "Bell",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "893",
                    reports: [
                      {
                        employeeId: "900",
                        firstName: "Julieta",
                        lastName: "Nicholson",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "898",
                        reports: []
                      },
                      {
                        employeeId: "899",
                        firstName: "Kameron",
                        lastName: "Baker",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "898",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "916",
                    firstName: "Urijah",
                    lastName: "Patrick",
                    jobTitle: "Senior Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "893",
                    reports: [
                      {
                        employeeId: "921",
                        firstName: "Zachary",
                        lastName: "Evans",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "916",
                        reports: []
                      },
                      {
                        employeeId: "920",
                        firstName: "Ameer",
                        lastName: "Burch",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "916",
                        reports: []
                      },
                      {
                        employeeId: "917",
                        firstName: "Kairi",
                        lastName: "Porter",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "916",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "872",
                firstName: "Piper",
                lastName: "Tyler",
                jobTitle: "Industrial Architecture Director",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "768",
                reports: [
                  {
                    employeeId: "874",
                    firstName: "Ana",
                    lastName: "Wolfe",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "872",
                    reports: [
                      {
                        employeeId: "875",
                        firstName: "Julien",
                        lastName: "Mitchell",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "874",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "873",
                    firstName: "Dayton",
                    lastName: "Gordon",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "872",
                    reports: []
                  },
                  {
                    employeeId: "880",
                    firstName: "Immanuel",
                    lastName: "Stephens",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "872",
                    reports: [
                      {
                        employeeId: "881",
                        firstName: "Julia",
                        lastName: "Benjamin",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "880",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "876",
                    firstName: "Astrid",
                    lastName: "Galvan",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "872",
                    reports: [
                      {
                        employeeId: "877",
                        firstName: "Rayan",
                        lastName: "Hanson",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "876",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "878",
                    firstName: "Saige",
                    lastName: "Sawyer",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "872",
                    reports: [
                      {
                        employeeId: "879",
                        firstName: "Ashley",
                        lastName: "Church",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "878",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "888",
                    firstName: "Jasmin",
                    lastName: "Thompson",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "872",
                    reports: [
                      {
                        employeeId: "890",
                        firstName: "Adeline",
                        lastName: "Malone",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "888",
                        reports: []
                      },
                      {
                        employeeId: "891",
                        firstName: "Sara",
                        lastName: "Le",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "888",
                        reports: []
                      },
                      {
                        employeeId: "889",
                        firstName: "Leland",
                        lastName: "Lucas",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "888",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "885",
                    firstName: "Alexander",
                    lastName: "Haley",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "872",
                    reports: [
                      {
                        employeeId: "886",
                        firstName: "Lindsay",
                        lastName: "Fisher",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "885",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "882",
                    firstName: "Gary",
                    lastName: "Lara",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "872",
                    reports: [
                      {
                        employeeId: "883",
                        firstName: "Finnegan",
                        lastName: "Warren",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "882",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "769",
                firstName: "Anahi",
                lastName: "Knox",
                jobTitle: "Industrial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "768",
                reports: [
                  {
                    employeeId: "770",
                    firstName: "Preston",
                    lastName: "Poole",
                    jobTitle: "Industrial Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "769",
                    reports: [
                      {
                        employeeId: "778",
                        firstName: "Kaydence",
                        lastName: "Acevedo",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "770",
                        reports: []
                      },
                      {
                        employeeId: "771",
                        firstName: "Willow",
                        lastName: "Hatfield",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "770",
                        reports: []
                      },
                      {
                        employeeId: "776",
                        firstName: "Kaydence",
                        lastName: "Short",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "770",
                        reports: []
                      },
                      {
                        employeeId: "777",
                        firstName: "Paisley",
                        lastName: "Butler",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "770",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "780",
                    firstName: "Giselle",
                    lastName: "Sharp",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "769",
                    reports: []
                  },
                  {
                    employeeId: "782",
                    firstName: "Kaitlyn",
                    lastName: "Decker",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "769",
                    reports: []
                  },
                  {
                    employeeId: "783",
                    firstName: "Maverick",
                    lastName: "Blevins",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "769",
                    reports: [
                      {
                        employeeId: "784",
                        firstName: "Nala",
                        lastName: "Mclean",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "783",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "781",
                    firstName: "Jazmine",
                    lastName: "Jennings",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "769",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "829",
                firstName: "Lilith",
                lastName: "Fritz",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "768",
                reports: [
                  {
                    employeeId: "845",
                    firstName: "Emory",
                    lastName: "Walter",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "829",
                    reports: [
                      {
                        employeeId: "846",
                        firstName: "Mayson",
                        lastName: "Ryan",
                        jobTitle: "Architect-I",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "845",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "844",
                    firstName: "Blake",
                    lastName: "Cameron",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "829",
                    reports: []
                  },
                  {
                    employeeId: "842",
                    firstName: "Rodrigo",
                    lastName: "Hart",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "829",
                    reports: []
                  },
                  {
                    employeeId: "843",
                    firstName: "Nataly",
                    lastName: "Mcneil",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "829",
                    reports: []
                  },
                  {
                    employeeId: "830",
                    firstName: "Dayana",
                    lastName: "Carrillo",
                    jobTitle: "Industrial Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "829",
                    reports: [
                      {
                        employeeId: "832",
                        firstName: "Ronald",
                        lastName: "Odonnell",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "830",
                        reports: []
                      },
                      {
                        employeeId: "833",
                        firstName: "Donovan",
                        lastName: "Gay",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "830",
                        reports: []
                      },
                      {
                        employeeId: "831",
                        firstName: "Callie",
                        lastName: "Scott",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "830",
                        reports: []
                      },
                      {
                        employeeId: "838",
                        firstName: "Trace",
                        lastName: "Anderson",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "830",
                        reports: []
                      },
                      {
                        employeeId: "834",
                        firstName: "Kaiden",
                        lastName: "Braun",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "830",
                        reports: []
                      },
                      {
                        employeeId: "835",
                        firstName: "Nyla",
                        lastName: "Roth",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "830",
                        reports: []
                      },
                      {
                        employeeId: "836",
                        firstName: "Bode",
                        lastName: "Orr",
                        jobTitle: "Industrial Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "830",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "764",
            firstName: "Davian",
            lastName: "Ryan",
            jobTitle: "Commercial Architecture Manager-VII",
            officeLocation: "Paris",
            department: "Commercial Architecture",
            managerEmployeeId: "763",
            reports: [
              {
                employeeId: "766",
                firstName: "Jaxen",
                lastName: "Combs",
                jobTitle: "Architect-III",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "764",
                reports: [
                  {
                    employeeId: "767",
                    firstName: "Denise",
                    lastName: "Reeves",
                    jobTitle: "Architect-III",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "766",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "765",
                firstName: "Cecilia",
                lastName: "Frey",
                jobTitle: "Senior Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "764",
                reports: []
              }
            ]
          },
          {
            employeeId: "1494",
            firstName: "Miranda",
            lastName: "Suarez",
            jobTitle: "Vice President of Residential Architecture",
            officeLocation: "Paris",
            department: "Residential Architecture",
            managerEmployeeId: "763",
            reports: [
              {
                employeeId: "1686",
                firstName: "Yusuf",
                lastName: "Moon",
                jobTitle: "Residential Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1494",
                reports: [
                  {
                    employeeId: "1687",
                    firstName: "Eli",
                    lastName: "Powers",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1686",
                    reports: [
                      {
                        employeeId: "1691",
                        firstName: "Ivy",
                        lastName: "Spears",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1687",
                        reports: []
                      },
                      {
                        employeeId: "1692",
                        firstName: "Skyla",
                        lastName: "Tanner",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1687",
                        reports: []
                      },
                      {
                        employeeId: "1688",
                        firstName: "Barbara",
                        lastName: "Ibarra",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1687",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1696",
                    firstName: "Nevaeh",
                    lastName: "Barker",
                    jobTitle: "Residential Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1686",
                    reports: [
                      {
                        employeeId: "1697",
                        firstName: "Brody",
                        lastName: "Zimmerman",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1696",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1695",
                    firstName: "Esme",
                    lastName: "Butler",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1686",
                    reports: []
                  },
                  {
                    employeeId: "1694",
                    firstName: "Reagan",
                    lastName: "Villegas",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1686",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1622",
                firstName: "Jairo",
                lastName: "Mccarthy",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1494",
                reports: []
              },
              {
                employeeId: "1652",
                firstName: "Teagan",
                lastName: "Andrade",
                jobTitle: "Residential Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1494",
                reports: [
                  {
                    employeeId: "1659",
                    firstName: "Oscar",
                    lastName: "Pierce",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1652",
                    reports: [
                      {
                        employeeId: "1660",
                        firstName: "Cora",
                        lastName: "Hinton",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1659",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1653",
                    firstName: "Noe",
                    lastName: "Bird",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1652",
                    reports: [
                      {
                        employeeId: "1656",
                        firstName: "Emilie",
                        lastName: "Cooke",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1653",
                        reports: []
                      },
                      {
                        employeeId: "1655",
                        firstName: "Lyla",
                        lastName: "Mcdowell",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1653",
                        reports: []
                      },
                      {
                        employeeId: "1654",
                        firstName: "Aranza",
                        lastName: "Barrera",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1653",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1658",
                    firstName: "David",
                    lastName: "Holt",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1652",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1661",
                firstName: "Teresa",
                lastName: "Ramsey",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1494",
                reports: [
                  {
                    employeeId: "1667",
                    firstName: "Sutton",
                    lastName: "Bullock",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1661",
                    reports: [
                      {
                        employeeId: "1668",
                        firstName: "Roger",
                        lastName: "Gregory",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1667",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1669",
                    firstName: "Chloe",
                    lastName: "Bradley",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1661",
                    reports: [
                      {
                        employeeId: "1671",
                        firstName: "Jaycee",
                        lastName: "Boone",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1669",
                        reports: []
                      },
                      {
                        employeeId: "1670",
                        firstName: "Zendaya",
                        lastName: "Spencer",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1669",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1665",
                    firstName: "Madilynn",
                    lastName: "Wu",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1661",
                    reports: [
                      {
                        employeeId: "1666",
                        firstName: "Brynn",
                        lastName: "Huff",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1665",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1662",
                    firstName: "Anthony",
                    lastName: "Heath",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1661",
                    reports: []
                  },
                  {
                    employeeId: "1663",
                    firstName: "Byron",
                    lastName: "Moreno",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1661",
                    reports: [
                      {
                        employeeId: "1664",
                        firstName: "Charlee",
                        lastName: "Barrera",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1663",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1623",
                firstName: "Aniyah",
                lastName: "Wilcox",
                jobTitle: "Residential Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1494",
                reports: [
                  {
                    employeeId: "1626",
                    firstName: "Sophia",
                    lastName: "Valdez",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1623",
                    reports: []
                  },
                  {
                    employeeId: "1629",
                    firstName: "Mara",
                    lastName: "Cross",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1623",
                    reports: []
                  },
                  {
                    employeeId: "1630",
                    firstName: "Jesse",
                    lastName: "Brandt",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1623",
                    reports: [
                      {
                        employeeId: "1631",
                        firstName: "Noah",
                        lastName: "Howe",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1630",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1628",
                    firstName: "Coen",
                    lastName: "Richard",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1623",
                    reports: []
                  },
                  {
                    employeeId: "1627",
                    firstName: "Chelsea",
                    lastName: "Stuart",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1623",
                    reports: []
                  },
                  {
                    employeeId: "1625",
                    firstName: "Kellen",
                    lastName: "Randall",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1623",
                    reports: []
                  },
                  {
                    employeeId: "1624",
                    firstName: "Abdullah",
                    lastName: "Gallagher",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1623",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1495",
                firstName: "Danica",
                lastName: "Roberson",
                jobTitle: "Vice President of Residential Architecture",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1494",
                reports: [
                  {
                    employeeId: "1575",
                    firstName: "Dash",
                    lastName: "Dunlap",
                    jobTitle: "Residential Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1495",
                    reports: [
                      {
                        employeeId: "1581",
                        firstName: "Michael",
                        lastName: "Robertson",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1575",
                        reports: []
                      },
                      {
                        employeeId: "1579",
                        firstName: "Roger",
                        lastName: "Skinner",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1575",
                        reports: []
                      },
                      {
                        employeeId: "1583",
                        firstName: "Roger",
                        lastName: "Liu",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1575",
                        reports: []
                      },
                      {
                        employeeId: "1577",
                        firstName: "Justus",
                        lastName: "Payne",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1575",
                        reports: []
                      },
                      {
                        employeeId: "1586",
                        firstName: "Javon",
                        lastName: "Mcclure",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1575",
                        reports: []
                      },
                      {
                        employeeId: "1576",
                        firstName: "Avalynn",
                        lastName: "Benton",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1575",
                        reports: []
                      },
                      {
                        employeeId: "1590",
                        firstName: "Drew",
                        lastName: "Carney",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1575",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1595",
                    firstName: "Ruby",
                    lastName: "Mclean",
                    jobTitle: "Principal Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1495",
                    reports: [
                      {
                        employeeId: "1615",
                        firstName: "Anabella",
                        lastName: "Salas",
                        jobTitle: "Residential Architecture Manager-VII",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1595",
                        reports: []
                      },
                      {
                        employeeId: "1605",
                        firstName: "Violet",
                        lastName: "Stephenson",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1595",
                        reports: []
                      },
                      {
                        employeeId: "1600",
                        firstName: "Jace",
                        lastName: "Miles",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1595",
                        reports: []
                      },
                      {
                        employeeId: "1609",
                        firstName: "Charlotte",
                        lastName: "Parrish",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1595",
                        reports: []
                      },
                      {
                        employeeId: "1596",
                        firstName: "Aislinn",
                        lastName: "Parker",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1595",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1522",
                    firstName: "Diana",
                    lastName: "Haney",
                    jobTitle: "Residential Architecture Director",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1495",
                    reports: [
                      {
                        employeeId: "1523",
                        firstName: "Alessandra",
                        lastName: "Whitney",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1522",
                        reports: []
                      },
                      {
                        employeeId: "1531",
                        firstName: "Sandra",
                        lastName: "Bowman",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1522",
                        reports: []
                      },
                      {
                        employeeId: "1538",
                        firstName: "Hayley",
                        lastName: "Thompson",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1522",
                        reports: []
                      },
                      {
                        employeeId: "1525",
                        firstName: "Giovani",
                        lastName: "Hines",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1522",
                        reports: []
                      },
                      {
                        employeeId: "1528",
                        firstName: "Kelsey",
                        lastName: "Strong",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1522",
                        reports: []
                      },
                      {
                        employeeId: "1542",
                        firstName: "Rocco",
                        lastName: "Garrison",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1522",
                        reports: []
                      },
                      {
                        employeeId: "1535",
                        firstName: "Judith",
                        lastName: "Jennings",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1522",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1548",
                    firstName: "Amber",
                    lastName: "Cain",
                    jobTitle: "Residential Architecture Director",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1495",
                    reports: [
                      {
                        employeeId: "1564",
                        firstName: "Westin",
                        lastName: "Byrd",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1548",
                        reports: []
                      },
                      {
                        employeeId: "1549",
                        firstName: "Kendall",
                        lastName: "Ponce",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1548",
                        reports: []
                      },
                      {
                        employeeId: "1560",
                        firstName: "Kadence",
                        lastName: "Gould",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1548",
                        reports: []
                      },
                      {
                        employeeId: "1553",
                        firstName: "Frankie",
                        lastName: "Johnson",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1548",
                        reports: []
                      },
                      {
                        employeeId: "1550",
                        firstName: "Leland",
                        lastName: "Ferrell",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1548",
                        reports: []
                      },
                      {
                        employeeId: "1557",
                        firstName: "Eugene",
                        lastName: "Mckee",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1548",
                        reports: []
                      },
                      {
                        employeeId: "1569",
                        firstName: "Daniel",
                        lastName: "Ayala",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1548",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1496",
                    firstName: "Tanner",
                    lastName: "Cooke",
                    jobTitle: "Residential Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1495",
                    reports: [
                      {
                        employeeId: "1511",
                        firstName: "Kingsley",
                        lastName: "Moore",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1496",
                        reports: []
                      },
                      {
                        employeeId: "1497",
                        firstName: "Paisley",
                        lastName: "Garza",
                        jobTitle: "Residential Architecture Manager-VII",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1496",
                        reports: []
                      },
                      {
                        employeeId: "1512",
                        firstName: "Faith",
                        lastName: "Meza",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1496",
                        reports: []
                      },
                      {
                        employeeId: "1510",
                        firstName: "Myla",
                        lastName: "Dean",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1496",
                        reports: []
                      },
                      {
                        employeeId: "1514",
                        firstName: "Rylan",
                        lastName: "Yoder",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1496",
                        reports: []
                      },
                      {
                        employeeId: "1513",
                        firstName: "Wesley",
                        lastName: "Coleman",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1496",
                        reports: []
                      },
                      {
                        employeeId: "1516",
                        firstName: "Brett",
                        lastName: "Middleton",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1496",
                        reports: []
                      },
                      {
                        employeeId: "1518",
                        firstName: "Gracelyn",
                        lastName: "Dyer",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1496",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1633",
                firstName: "Juliana",
                lastName: "Velazquez",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1494",
                reports: [
                  {
                    employeeId: "1638",
                    firstName: "Dahlia",
                    lastName: "Schmidt",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1633",
                    reports: [
                      {
                        employeeId: "1639",
                        firstName: "Brenna",
                        lastName: "Dyer",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1638",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1637",
                    firstName: "Kyla",
                    lastName: "Roth",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1633",
                    reports: []
                  },
                  {
                    employeeId: "1636",
                    firstName: "Justice",
                    lastName: "Gaines",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1633",
                    reports: []
                  },
                  {
                    employeeId: "1635",
                    firstName: "Crosby",
                    lastName: "Barton",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1633",
                    reports: []
                  },
                  {
                    employeeId: "1634",
                    firstName: "Aydan",
                    lastName: "Spence",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1633",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1641",
                firstName: "Alyssa",
                lastName: "Christensen",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1494",
                reports: [
                  {
                    employeeId: "1642",
                    firstName: "Hallie",
                    lastName: "Orozco",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1641",
                    reports: []
                  },
                  {
                    employeeId: "1648",
                    firstName: "Kieran",
                    lastName: "Weiss",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1641",
                    reports: [
                      {
                        employeeId: "1649",
                        firstName: "Uriel",
                        lastName: "Fox",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1648",
                        reports: []
                      },
                      {
                        employeeId: "1650",
                        firstName: "Kelsey",
                        lastName: "Massey",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1648",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1644",
                    firstName: "Braiden",
                    lastName: "Knox",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1641",
                    reports: [
                      {
                        employeeId: "1645",
                        firstName: "Eric",
                        lastName: "Acevedo",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1644",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1646",
                    firstName: "Sage",
                    lastName: "Knapp",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1641",
                    reports: [
                      {
                        employeeId: "1647",
                        firstName: "Irene",
                        lastName: "Short",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1646",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1643",
                    firstName: "Cohen",
                    lastName: "Bean",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1641",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1673",
                firstName: "Joziah",
                lastName: "Terrell",
                jobTitle: "Residential Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "1494",
                reports: [
                  {
                    employeeId: "1681",
                    firstName: "Emilee",
                    lastName: "Oconnell",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1673",
                    reports: []
                  },
                  {
                    employeeId: "1683",
                    firstName: "Everett",
                    lastName: "Steele",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1673",
                    reports: []
                  },
                  {
                    employeeId: "1684",
                    firstName: "Sophia",
                    lastName: "Doyle",
                    jobTitle: "Residential Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1673",
                    reports: [
                      {
                        employeeId: "1685",
                        firstName: "Maliyah",
                        lastName: "Benson",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1684",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1682",
                    firstName: "Beatrice",
                    lastName: "Riddle",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1673",
                    reports: []
                  },
                  {
                    employeeId: "1674",
                    firstName: "Laura",
                    lastName: "Small",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "1673",
                    reports: [
                      {
                        employeeId: "1676",
                        firstName: "Leon",
                        lastName: "Robles",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1674",
                        reports: []
                      },
                      {
                        employeeId: "1675",
                        firstName: "Terrell",
                        lastName: "Diaz",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1674",
                        reports: []
                      },
                      {
                        employeeId: "1677",
                        firstName: "Hadley",
                        lastName: "Burke",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1674",
                        reports: []
                      },
                      {
                        employeeId: "1678",
                        firstName: "Emilia",
                        lastName: "Cortez",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "1674",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        employeeId: "2",
        firstName: "Ivy",
        lastName: "Mills",
        jobTitle: "Vice President of Sales",
        officeLocation: "Paris",
        department: "Sales",
        managerEmployeeId: "1",
        reports: [
          {
            employeeId: "498",
            firstName: "Dax",
            lastName: "Reese",
            jobTitle: "Vice President of Sales / Americas",
            officeLocation: "Paris",
            department: "Sales / Americas",
            managerEmployeeId: "2",
            reports: [
              {
                employeeId: "499",
                firstName: "Celeste",
                lastName: "Watts",
                jobTitle: "Sales / Canada Director",
                officeLocation: "Paris",
                department: "Sales / Canada",
                managerEmployeeId: "498",
                reports: [
                  {
                    employeeId: "525",
                    firstName: "Sienna",
                    lastName: "Levy",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "499",
                    reports: [
                      {
                        employeeId: "526",
                        firstName: "Graham",
                        lastName: "Elliott",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "525",
                        reports: []
                      },
                      {
                        employeeId: "528",
                        firstName: "Erika",
                        lastName: "Mckay",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "525",
                        reports: []
                      },
                      {
                        employeeId: "531",
                        firstName: "Layton",
                        lastName: "Calderon",
                        jobTitle: "Sales / Canada Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "525",
                        reports: []
                      },
                      {
                        employeeId: "529",
                        firstName: "Avalynn",
                        lastName: "Nelson",
                        jobTitle: "Sales / Canada Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "525",
                        reports: []
                      },
                      {
                        employeeId: "527",
                        firstName: "Nixon",
                        lastName: "Barrett",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "525",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "517",
                    firstName: "Kamila",
                    lastName: "Bowen",
                    jobTitle: "Sales / Canada Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "499",
                    reports: [
                      {
                        employeeId: "519",
                        firstName: "Judith",
                        lastName: "Abbott",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "517",
                        reports: []
                      },
                      {
                        employeeId: "520",
                        firstName: "Taylor",
                        lastName: "Berry",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "517",
                        reports: []
                      },
                      {
                        employeeId: "521",
                        firstName: "Valerie",
                        lastName: "Monroe",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "517",
                        reports: []
                      },
                      {
                        employeeId: "522",
                        firstName: "Erica",
                        lastName: "Howard",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "517",
                        reports: []
                      },
                      {
                        employeeId: "518",
                        firstName: "Kaitlynn",
                        lastName: "Gay",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "517",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "542",
                    firstName: "Asher",
                    lastName: "Mccarthy",
                    jobTitle: "Sales / Canada Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "499",
                    reports: [
                      {
                        employeeId: "543",
                        firstName: "Lillie",
                        lastName: "Hubbard",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "542",
                        reports: []
                      },
                      {
                        employeeId: "547",
                        firstName: "Saul",
                        lastName: "Paul",
                        jobTitle: "Sales / Canada Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "542",
                        reports: []
                      },
                      {
                        employeeId: "544",
                        firstName: "Jocelynn",
                        lastName: "Flores",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "542",
                        reports: []
                      },
                      {
                        employeeId: "545",
                        firstName: "Casen",
                        lastName: "Howard",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "542",
                        reports: []
                      },
                      {
                        employeeId: "546",
                        firstName: "Zendaya",
                        lastName: "Carney",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "542",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "500",
                    firstName: "Jason",
                    lastName: "Kelley",
                    jobTitle: "Sales / Canada Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "499",
                    reports: [
                      {
                        employeeId: "507",
                        firstName: "Elisa",
                        lastName: "Salazar",
                        jobTitle: "Sales / Canada Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "500",
                        reports: []
                      },
                      {
                        employeeId: "501",
                        firstName: "Meredith",
                        lastName: "Weaver",
                        jobTitle: "Sales / Canada Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "500",
                        reports: []
                      },
                      {
                        employeeId: "506",
                        firstName: "Karina",
                        lastName: "Gillespie",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "500",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "550",
                    firstName: "Cedric",
                    lastName: "Mann",
                    jobTitle: "Sales / Canada Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "499",
                    reports: [
                      {
                        employeeId: "560",
                        firstName: "Malakai",
                        lastName: "Padilla",
                        jobTitle: "Sales / Canada Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "550",
                        reports: []
                      },
                      {
                        employeeId: "552",
                        firstName: "Brynn",
                        lastName: "Jensen",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "550",
                        reports: []
                      },
                      {
                        employeeId: "555",
                        firstName: "Quincy",
                        lastName: "Shepard",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "550",
                        reports: []
                      },
                      {
                        employeeId: "557",
                        firstName: "Paityn",
                        lastName: "Dyer",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "550",
                        reports: []
                      },
                      {
                        employeeId: "553",
                        firstName: "Alonso",
                        lastName: "Guerra",
                        jobTitle: "Sales / Canada Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "550",
                        reports: []
                      },
                      {
                        employeeId: "551",
                        firstName: "Kinslee",
                        lastName: "Orr",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "550",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "534",
                    firstName: "Carmelo",
                    lastName: "Levine",
                    jobTitle: "Sales / Canada Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "499",
                    reports: [
                      {
                        employeeId: "540",
                        firstName: "Julieta",
                        lastName: "Mosley",
                        jobTitle: "Sales / Canada Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "534",
                        reports: []
                      },
                      {
                        employeeId: "539",
                        firstName: "Gunnar",
                        lastName: "Townsend",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "534",
                        reports: []
                      },
                      {
                        employeeId: "535",
                        firstName: "Cash",
                        lastName: "Montes",
                        jobTitle: "Sales / Canada Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "534",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "509",
                    firstName: "Ally",
                    lastName: "Kane",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "499",
                    reports: [
                      {
                        employeeId: "510",
                        firstName: "Alexia",
                        lastName: "Anderson",
                        jobTitle: "Sales / Canada Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "509",
                        reports: []
                      },
                      {
                        employeeId: "514",
                        firstName: "Jaylah",
                        lastName: "Terrell",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "509",
                        reports: []
                      },
                      {
                        employeeId: "515",
                        firstName: "Uriah",
                        lastName: "Herman",
                        jobTitle: "Sales / Canada Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "509",
                        reports: []
                      },
                      {
                        employeeId: "513",
                        firstName: "Chace",
                        lastName: "Rodgers",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "509",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "564",
                firstName: "Kyree",
                lastName: "Barron",
                jobTitle: "Sales / Argentina Manager-VI",
                officeLocation: "Paris",
                department: "Sales / Argentina",
                managerEmployeeId: "498",
                reports: [
                  {
                    employeeId: "570",
                    firstName: "Clara",
                    lastName: "Beard",
                    jobTitle: "Sales / Argentina Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "564",
                    reports: [
                      {
                        employeeId: "571",
                        firstName: "Nala",
                        lastName: "Daniel",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "570",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "566",
                    firstName: "Phillip",
                    lastName: "Wells",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "564",
                    reports: []
                  },
                  {
                    employeeId: "569",
                    firstName: "Samson",
                    lastName: "Hunt",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "564",
                    reports: []
                  },
                  {
                    employeeId: "565",
                    firstName: "Layne",
                    lastName: "Pineda",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "564",
                    reports: []
                  },
                  {
                    employeeId: "568",
                    firstName: "Zahra",
                    lastName: "Hernandez",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "564",
                    reports: []
                  },
                  {
                    employeeId: "567",
                    firstName: "Marcelo",
                    lastName: "Nash",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "564",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "604",
                firstName: "Gavin",
                lastName: "Morales",
                jobTitle: "Sales / Canada Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Canada",
                managerEmployeeId: "498",
                reports: [
                  {
                    employeeId: "606",
                    firstName: "Winston",
                    lastName: "Castillo",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "604",
                    reports: []
                  },
                  {
                    employeeId: "607",
                    firstName: "Arya",
                    lastName: "Floyd",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "604",
                    reports: []
                  },
                  {
                    employeeId: "608",
                    firstName: "Katelynn",
                    lastName: "Wright",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "604",
                    reports: []
                  },
                  {
                    employeeId: "609",
                    firstName: "Cali",
                    lastName: "Mcclure",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "604",
                    reports: []
                  },
                  {
                    employeeId: "610",
                    firstName: "Sterling",
                    lastName: "Zamora",
                    jobTitle: "Sales / Canada Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "604",
                    reports: [
                      {
                        employeeId: "611",
                        firstName: "Nolan",
                        lastName: "Lindsey",
                        jobTitle: "Sales / Canada Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "610",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "605",
                    firstName: "Addison",
                    lastName: "Snyder",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "604",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "593",
                firstName: "Rory",
                lastName: "Meadows",
                jobTitle: "Sales / Canada Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Canada",
                managerEmployeeId: "498",
                reports: [
                  {
                    employeeId: "598",
                    firstName: "Stefan",
                    lastName: "Nicholson",
                    jobTitle: "Sales / Canada Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "593",
                    reports: [
                      {
                        employeeId: "599",
                        firstName: "Christopher",
                        lastName: "Moran",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "598",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "595",
                    firstName: "Yasmin",
                    lastName: "Garrett",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "593",
                    reports: []
                  },
                  {
                    employeeId: "600",
                    firstName: "Billy",
                    lastName: "Patterson",
                    jobTitle: "Sales / Canada Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "593",
                    reports: [
                      {
                        employeeId: "601",
                        firstName: "Carmelo",
                        lastName: "Barrera",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "600",
                        reports: []
                      },
                      {
                        employeeId: "602",
                        firstName: "Erin",
                        lastName: "House",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "600",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "597",
                    firstName: "Joel",
                    lastName: "Melendez",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "593",
                    reports: []
                  },
                  {
                    employeeId: "596",
                    firstName: "Damari",
                    lastName: "Robles",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "593",
                    reports: []
                  },
                  {
                    employeeId: "594",
                    firstName: "Edison",
                    lastName: "Braun",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "593",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "573",
                firstName: "Perla",
                lastName: "Mcdowell",
                jobTitle: "Sales / US Manager-VI",
                officeLocation: "Paris",
                department: "Sales / US",
                managerEmployeeId: "498",
                reports: [
                  {
                    employeeId: "574",
                    firstName: "Silas",
                    lastName: "Butler",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "573",
                    reports: []
                  },
                  {
                    employeeId: "578",
                    firstName: "Jamison",
                    lastName: "Landry",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "573",
                    reports: []
                  },
                  {
                    employeeId: "580",
                    firstName: "Kendra",
                    lastName: "Townsend",
                    jobTitle: "Sales / US Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "573",
                    reports: [
                      {
                        employeeId: "581",
                        firstName: "Carter",
                        lastName: "Hahn",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "580",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "576",
                    firstName: "Salma",
                    lastName: "Velazquez",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "573",
                    reports: []
                  },
                  {
                    employeeId: "575",
                    firstName: "Rylee",
                    lastName: "Ewing",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "573",
                    reports: []
                  },
                  {
                    employeeId: "579",
                    firstName: "Jaylynn",
                    lastName: "Boyd",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "573",
                    reports: []
                  },
                  {
                    employeeId: "577",
                    firstName: "Adonis",
                    lastName: "Phillips",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "573",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "583",
                firstName: "Judson",
                lastName: "Browning",
                jobTitle: "Sales / US Manager-VI",
                officeLocation: "Paris",
                department: "Sales / US",
                managerEmployeeId: "498",
                reports: [
                  {
                    employeeId: "586",
                    firstName: "Allan",
                    lastName: "Santana",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "583",
                    reports: []
                  },
                  {
                    employeeId: "587",
                    firstName: "Kareem",
                    lastName: "Mooney",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "583",
                    reports: []
                  },
                  {
                    employeeId: "588",
                    firstName: "Wilson",
                    lastName: "Glover",
                    jobTitle: "Sales / US Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "583",
                    reports: [
                      {
                        employeeId: "589",
                        firstName: "Tristan",
                        lastName: "Roberson",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "588",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "585",
                    firstName: "Emely",
                    lastName: "Buck",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "583",
                    reports: []
                  },
                  {
                    employeeId: "590",
                    firstName: "Javier",
                    lastName: "Hurley",
                    jobTitle: "Sales / US Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "583",
                    reports: [
                      {
                        employeeId: "591",
                        firstName: "Samantha",
                        lastName: "Mccormick",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "590",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "584",
                    firstName: "Elliana",
                    lastName: "Blackburn",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "583",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "3",
            firstName: "Zeke",
            lastName: "Gomez",
            jobTitle: "Senior Director of Sales / Americas",
            officeLocation: "Paris",
            department: "Sales / Americas",
            managerEmployeeId: "2",
            reports: [
              {
                employeeId: "110",
                firstName: "Alana",
                lastName: "Jarvis",
                jobTitle: "Sales / Mexico Director",
                officeLocation: "Paris",
                department: "Sales / Mexico",
                managerEmployeeId: "3",
                reports: [
                  {
                    employeeId: "112",
                    firstName: "Lea",
                    lastName: "Lozano",
                    jobTitle: "Sales / Mexico Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "110",
                    reports: [
                      {
                        employeeId: "113",
                        firstName: "Kristina",
                        lastName: "Jackson",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "112",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "114",
                    firstName: "Kairi",
                    lastName: "Mathis",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "110",
                    reports: [
                      {
                        employeeId: "115",
                        firstName: "Virginia",
                        lastName: "Sheppard",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "114",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "111",
                    firstName: "Bryan",
                    lastName: "Kelly",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "110",
                    reports: []
                  },
                  {
                    employeeId: "122",
                    firstName: "Helen",
                    lastName: "Zuniga",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "110",
                    reports: [
                      {
                        employeeId: "123",
                        firstName: "Dallas",
                        lastName: "Mcgee",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "122",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "124",
                    firstName: "Lachlan",
                    lastName: "Lopez",
                    jobTitle: "Sales / Mexico Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "110",
                    reports: [
                      {
                        employeeId: "126",
                        firstName: "Brianna",
                        lastName: "Cochran",
                        jobTitle: "Sales / Mexico Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "124",
                        reports: []
                      },
                      {
                        employeeId: "125",
                        firstName: "Ellie",
                        lastName: "Becker",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "124",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "128",
                    firstName: "Naomi",
                    lastName: "Page",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "110",
                    reports: [
                      {
                        employeeId: "129",
                        firstName: "Ignacio",
                        lastName: "Fox",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "128",
                        reports: []
                      },
                      {
                        employeeId: "131",
                        firstName: "Carlos",
                        lastName: "Duarte",
                        jobTitle: "Sales / Mexico Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "128",
                        reports: []
                      },
                      {
                        employeeId: "130",
                        firstName: "Madelyn",
                        lastName: "Reynolds",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "128",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "116",
                    firstName: "Raven",
                    lastName: "Knox",
                    jobTitle: "Sales / Mexico Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "110",
                    reports: [
                      {
                        employeeId: "117",
                        firstName: "Ali",
                        lastName: "David",
                        jobTitle: "Sales / Mexico Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "116",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "119",
                    firstName: "Rafael",
                    lastName: "Barnes",
                    jobTitle: "Sales / Mexico Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "110",
                    reports: [
                      {
                        employeeId: "120",
                        firstName: "Alice",
                        lastName: "Bennett",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "119",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "83",
                firstName: "Beau",
                lastName: "Lane",
                jobTitle: "Sales / Mexico Director",
                officeLocation: "Paris",
                department: "Sales / Mexico",
                managerEmployeeId: "3",
                reports: [
                  {
                    employeeId: "102",
                    firstName: "Jade",
                    lastName: "Rodgers",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "83",
                    reports: [
                      {
                        employeeId: "106",
                        firstName: "Anika",
                        lastName: "Coffey",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "102",
                        reports: []
                      },
                      {
                        employeeId: "107",
                        firstName: "Laylah",
                        lastName: "Conner",
                        jobTitle: "Sales / Mexico Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "102",
                        reports: []
                      },
                      {
                        employeeId: "105",
                        firstName: "Nathalie",
                        lastName: "Clayton",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "102",
                        reports: []
                      },
                      {
                        employeeId: "104",
                        firstName: "Leo",
                        lastName: "Clarke",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "102",
                        reports: []
                      },
                      {
                        employeeId: "103",
                        firstName: "Mabel",
                        lastName: "Singh",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "102",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "97",
                    firstName: "Baylee",
                    lastName: "Glover",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "83",
                    reports: [
                      {
                        employeeId: "99",
                        firstName: "Freddy",
                        lastName: "King",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "97",
                        reports: []
                      },
                      {
                        employeeId: "100",
                        firstName: "Yamileth",
                        lastName: "Stout",
                        jobTitle: "Sales / Mexico Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "97",
                        reports: []
                      },
                      {
                        employeeId: "98",
                        firstName: "Markus",
                        lastName: "Villanueva",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "97",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "92",
                    firstName: "Conor",
                    lastName: "Barrett",
                    jobTitle: "Sales / Mexico Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "83",
                    reports: [
                      {
                        employeeId: "94",
                        firstName: "Samantha",
                        lastName: "Foster",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "92",
                        reports: []
                      },
                      {
                        employeeId: "95",
                        firstName: "Triston",
                        lastName: "Stone",
                        jobTitle: "Sales / Mexico Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "92",
                        reports: []
                      },
                      {
                        employeeId: "93",
                        firstName: "Ismael",
                        lastName: "Li",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "92",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "88",
                    firstName: "Braxton",
                    lastName: "Baker",
                    jobTitle: "Sales / Mexico Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "83",
                    reports: [
                      {
                        employeeId: "90",
                        firstName: "Kaliyah",
                        lastName: "Fernandez",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "88",
                        reports: []
                      },
                      {
                        employeeId: "89",
                        firstName: "Robert",
                        lastName: "Shepard",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "88",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "84",
                    firstName: "Ellison",
                    lastName: "Haynes",
                    jobTitle: "Sales / Mexico Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "83",
                    reports: [
                      {
                        employeeId: "85",
                        firstName: "Mackenzie",
                        lastName: "Crosby",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "84",
                        reports: []
                      },
                      {
                        employeeId: "86",
                        firstName: "Jayden",
                        lastName: "Mercado",
                        jobTitle: "Sales / Mexico Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "84",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "25",
                firstName: "Darren",
                lastName: "Luna",
                jobTitle: "Principal Account Manager",
                officeLocation: "Paris",
                department: "Sales / Mexico",
                managerEmployeeId: "3",
                reports: [
                  {
                    employeeId: "40",
                    firstName: "Guillermo",
                    lastName: "Parsons",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "25",
                    reports: []
                  },
                  {
                    employeeId: "39",
                    firstName: "Journee",
                    lastName: "Best",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "25",
                    reports: []
                  },
                  {
                    employeeId: "41",
                    firstName: "Vihaan",
                    lastName: "Parks",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "25",
                    reports: []
                  },
                  {
                    employeeId: "26",
                    firstName: "Isabelle",
                    lastName: "Sosa",
                    jobTitle: "Sales / Mexico Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "25",
                    reports: [
                      {
                        employeeId: "32",
                        firstName: "Marshall",
                        lastName: "Hudson",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "26",
                        reports: []
                      },
                      {
                        employeeId: "33",
                        firstName: "Justice",
                        lastName: "Combs",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "26",
                        reports: []
                      },
                      {
                        employeeId: "34",
                        firstName: "Jimena",
                        lastName: "Pham",
                        jobTitle: "Sales / Mexico Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "26",
                        reports: []
                      },
                      {
                        employeeId: "27",
                        firstName: "Grant",
                        lastName: "Arellano",
                        jobTitle: "Sales / Mexico Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "26",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "38",
                    firstName: "Kayden",
                    lastName: "Bentley",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "25",
                    reports: []
                  },
                  {
                    employeeId: "42",
                    firstName: "Princeton",
                    lastName: "Chung",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "25",
                    reports: [
                      {
                        employeeId: "43",
                        firstName: "Alaya",
                        lastName: "Kaufman",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "42",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "36",
                    firstName: "Ryland",
                    lastName: "Bolton",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "25",
                    reports: []
                  },
                  {
                    employeeId: "37",
                    firstName: "Sadie",
                    lastName: "Clayton",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "25",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "4",
                firstName: "Bruno",
                lastName: "Leonard",
                jobTitle: "Sales / Mexico Director",
                officeLocation: "Paris",
                department: "Sales / Mexico",
                managerEmployeeId: "3",
                reports: [
                  {
                    employeeId: "9",
                    firstName: "Dorothy",
                    lastName: "Zavala",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "4",
                    reports: [
                      {
                        employeeId: "10",
                        firstName: "Aaliyah",
                        lastName: "Orozco",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "9",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "20",
                    firstName: "Zion",
                    lastName: "Pruitt",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "4",
                    reports: [
                      {
                        employeeId: "23",
                        firstName: "Uriah",
                        lastName: "Villa",
                        jobTitle: "Sales / Mexico Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "20",
                        reports: []
                      },
                      {
                        employeeId: "22",
                        firstName: "Lucas",
                        lastName: "Scott",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "20",
                        reports: []
                      },
                      {
                        employeeId: "21",
                        firstName: "Ben",
                        lastName: "Chambers",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "20",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "16",
                    firstName: "Jazmin",
                    lastName: "Proctor",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "4",
                    reports: [
                      {
                        employeeId: "18",
                        firstName: "Libby",
                        lastName: "Robertson",
                        jobTitle: "Sales / Mexico Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "16",
                        reports: []
                      },
                      {
                        employeeId: "17",
                        firstName: "Ariel",
                        lastName: "Becker",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "16",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "14",
                    firstName: "Carolina",
                    lastName: "Gray",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "4",
                    reports: [
                      {
                        employeeId: "15",
                        firstName: "Angel",
                        lastName: "Hunter",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "14",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "11",
                    firstName: "Layton",
                    lastName: "Combs",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "4",
                    reports: [
                      {
                        employeeId: "12",
                        firstName: "Jonathon",
                        lastName: "Savage",
                        jobTitle: "Sales / Mexico Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "11",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "7",
                    firstName: "Rene",
                    lastName: "Colon",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "4",
                    reports: [
                      {
                        employeeId: "8",
                        firstName: "Julieta",
                        lastName: "George",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "7",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "5",
                    firstName: "Jaelynn",
                    lastName: "Lang",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "4",
                    reports: [
                      {
                        employeeId: "6",
                        firstName: "Ford",
                        lastName: "Wells",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "5",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "66",
                firstName: "Violet",
                lastName: "Armstrong",
                jobTitle: "Sales / Canada Director",
                officeLocation: "Paris",
                department: "Sales / Canada",
                managerEmployeeId: "3",
                reports: [
                  {
                    employeeId: "76",
                    firstName: "Conor",
                    lastName: "Mckenzie",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "66",
                    reports: [
                      {
                        employeeId: "77",
                        firstName: "Tyson",
                        lastName: "Sparks",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "76",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "71",
                    firstName: "Mikaela",
                    lastName: "Osborn",
                    jobTitle: "Sales / Canada Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "66",
                    reports: [
                      {
                        employeeId: "72",
                        firstName: "Reagan",
                        lastName: "Figueroa",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "71",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "78",
                    firstName: "Kali",
                    lastName: "Schroeder",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "66",
                    reports: [
                      {
                        employeeId: "80",
                        firstName: "Konnor",
                        lastName: "Wallace",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "78",
                        reports: []
                      },
                      {
                        employeeId: "79",
                        firstName: "Antonio",
                        lastName: "Mccormick",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "78",
                        reports: []
                      },
                      {
                        employeeId: "81",
                        firstName: "Alijah",
                        lastName: "Cowan",
                        jobTitle: "Sales / Canada Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "78",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "69",
                    firstName: "Zariyah",
                    lastName: "Rasmussen",
                    jobTitle: "Sales / Canada Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "66",
                    reports: [
                      {
                        employeeId: "70",
                        firstName: "Kyler",
                        lastName: "Copeland",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "69",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "67",
                    firstName: "Blair",
                    lastName: "Nixon",
                    jobTitle: "Sales / Canada Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "66",
                    reports: [
                      {
                        employeeId: "68",
                        firstName: "Evelynn",
                        lastName: "Barron",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "67",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "73",
                    firstName: "Barrett",
                    lastName: "Beltran",
                    jobTitle: "Sales / Canada Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "66",
                    reports: [
                      {
                        employeeId: "74",
                        firstName: "Arian",
                        lastName: "Osborne",
                        jobTitle: "Sales / Canada Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "73",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "45",
                firstName: "Skylar",
                lastName: "Crane",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Paris",
                department: "Sales / Argentina",
                managerEmployeeId: "3",
                reports: [
                  {
                    employeeId: "62",
                    firstName: "Elise",
                    lastName: "Hinton",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "45",
                    reports: [
                      {
                        employeeId: "64",
                        firstName: "Bennett",
                        lastName: "Nash",
                        jobTitle: "Sales / Argentina Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "62",
                        reports: []
                      },
                      {
                        employeeId: "63",
                        firstName: "Salvatore",
                        lastName: "Meadows",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "62",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "57",
                    firstName: "Amber",
                    lastName: "Sims",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "45",
                    reports: []
                  },
                  {
                    employeeId: "60",
                    firstName: "Jake",
                    lastName: "Cox",
                    jobTitle: "Sales / Argentina Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "45",
                    reports: [
                      {
                        employeeId: "61",
                        firstName: "Roberto",
                        lastName: "Wang",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "60",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "46",
                    firstName: "Selena",
                    lastName: "Swanson",
                    jobTitle: "Sales / Argentina Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "45",
                    reports: [
                      {
                        employeeId: "52",
                        firstName: "Kelly",
                        lastName: "Holland",
                        jobTitle: "Sales / Argentina Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "46",
                        reports: []
                      },
                      {
                        employeeId: "54",
                        firstName: "Davion",
                        lastName: "Stein",
                        jobTitle: "Sales / Argentina Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "46",
                        reports: []
                      },
                      {
                        employeeId: "50",
                        firstName: "Devon",
                        lastName: "Gay",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "46",
                        reports: []
                      },
                      {
                        employeeId: "49",
                        firstName: "Hayden",
                        lastName: "Singh",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "46",
                        reports: []
                      },
                      {
                        employeeId: "47",
                        firstName: "Mallory",
                        lastName: "Meyer",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "46",
                        reports: []
                      },
                      {
                        employeeId: "51",
                        firstName: "Israel",
                        lastName: "Wolfe",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "46",
                        reports: []
                      },
                      {
                        employeeId: "48",
                        firstName: "Jaida",
                        lastName: "Alvarez",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "46",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "59",
                    firstName: "Cayson",
                    lastName: "Harris",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "45",
                    reports: []
                  },
                  {
                    employeeId: "58",
                    firstName: "Gabriela",
                    lastName: "Browning",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "45",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "282",
            firstName: "Brooklyn",
            lastName: "Hicks",
            jobTitle: "Fellow Account Manager",
            officeLocation: "Paris",
            department: "Sales / Americas",
            managerEmployeeId: "2",
            reports: [
              {
                employeeId: "284",
                firstName: "Augustine",
                lastName: "Smith",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Paris",
                department: "Sales / Mexico",
                managerEmployeeId: "282",
                reports: [
                  {
                    employeeId: "294",
                    firstName: "Barrett",
                    lastName: "Mcintosh",
                    jobTitle: "Sales / Mexico Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "284",
                    reports: [
                      {
                        employeeId: "295",
                        firstName: "Trent",
                        lastName: "George",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "294",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "287",
                    firstName: "Jaliyah",
                    lastName: "Carpenter",
                    jobTitle: "Sales / Mexico Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "284",
                    reports: [
                      {
                        employeeId: "288",
                        firstName: "Kingston",
                        lastName: "Lang",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "287",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "297",
                    firstName: "Jake",
                    lastName: "Gallagher",
                    jobTitle: "Sales / Mexico Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "284",
                    reports: [
                      {
                        employeeId: "298",
                        firstName: "Ryan",
                        lastName: "Fowler",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "297",
                        reports: []
                      },
                      {
                        employeeId: "300",
                        firstName: "Celeste",
                        lastName: "Welch",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "297",
                        reports: []
                      },
                      {
                        employeeId: "299",
                        firstName: "Dakota",
                        lastName: "Cook",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "297",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "291",
                    firstName: "Austin",
                    lastName: "Patton",
                    jobTitle: "Sales / Mexico Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "284",
                    reports: [
                      {
                        employeeId: "292",
                        firstName: "Helen",
                        lastName: "Cordova",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "291",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "285",
                    firstName: "Lucca",
                    lastName: "Garcia",
                    jobTitle: "Sales / Mexico Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "284",
                    reports: [
                      {
                        employeeId: "286",
                        firstName: "Drew",
                        lastName: "Coleman",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "285",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "289",
                    firstName: "Lana",
                    lastName: "Bartlett",
                    jobTitle: "Sales / Mexico Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "284",
                    reports: [
                      {
                        employeeId: "290",
                        firstName: "Anson",
                        lastName: "Delacruz",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "289",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "302",
                firstName: "Gael",
                lastName: "Galloway",
                jobTitle: "Sales / Mexico Manager-VI",
                officeLocation: "Paris",
                department: "Sales / Mexico",
                managerEmployeeId: "282",
                reports: [
                  {
                    employeeId: "314",
                    firstName: "Jonael",
                    lastName: "Ryan",
                    jobTitle: "Sales / Mexico Manager-II",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "302",
                    reports: [
                      {
                        employeeId: "315",
                        firstName: "Kyndall",
                        lastName: "Dillon",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "314",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "313",
                    firstName: "Maliyah",
                    lastName: "Wong",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "302",
                    reports: []
                  },
                  {
                    employeeId: "303",
                    firstName: "Nickolas",
                    lastName: "Dominguez",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "302",
                    reports: [
                      {
                        employeeId: "305",
                        firstName: "Scott",
                        lastName: "Sexton",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "303",
                        reports: []
                      },
                      {
                        employeeId: "308",
                        firstName: "Davin",
                        lastName: "Woodward",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "303",
                        reports: []
                      },
                      {
                        employeeId: "304",
                        firstName: "Amber",
                        lastName: "Browning",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "303",
                        reports: []
                      },
                      {
                        employeeId: "306",
                        firstName: "Arya",
                        lastName: "Yang",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "303",
                        reports: []
                      },
                      {
                        employeeId: "307",
                        firstName: "Molly",
                        lastName: "Lozano",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Mexico",
                        managerEmployeeId: "303",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "312",
                    firstName: "Amanda",
                    lastName: "Stark",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "302",
                    reports: []
                  },
                  {
                    employeeId: "311",
                    firstName: "Jaxton",
                    lastName: "Jimenez",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "302",
                    reports: []
                  },
                  {
                    employeeId: "310",
                    firstName: "Elliott",
                    lastName: "Savage",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Mexico",
                    managerEmployeeId: "302",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "354",
                firstName: "Neil",
                lastName: "Clayton",
                jobTitle: "Sales / Argentina Director",
                officeLocation: "Paris",
                department: "Sales / Argentina",
                managerEmployeeId: "282",
                reports: [
                  {
                    employeeId: "368",
                    firstName: "Kellan",
                    lastName: "Howard",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "354",
                    reports: []
                  },
                  {
                    employeeId: "370",
                    firstName: "Valentin",
                    lastName: "Mckenzie",
                    jobTitle: "Sales / Argentina Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "354",
                    reports: [
                      {
                        employeeId: "371",
                        firstName: "Wren",
                        lastName: "Holder",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "370",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "355",
                    firstName: "Darwin",
                    lastName: "Fowler",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "354",
                    reports: [
                      {
                        employeeId: "363",
                        firstName: "Heather",
                        lastName: "Cervantes",
                        jobTitle: "Sales / Argentina Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "355",
                        reports: []
                      },
                      {
                        employeeId: "359",
                        firstName: "Emory",
                        lastName: "Bautista",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "355",
                        reports: []
                      },
                      {
                        employeeId: "358",
                        firstName: "Kara",
                        lastName: "Everett",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "355",
                        reports: []
                      },
                      {
                        employeeId: "361",
                        firstName: "Cassius",
                        lastName: "Duncan",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "355",
                        reports: []
                      },
                      {
                        employeeId: "360",
                        firstName: "Miller",
                        lastName: "Mcdaniel",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "355",
                        reports: []
                      },
                      {
                        employeeId: "362",
                        firstName: "Sylvia",
                        lastName: "Baldwin",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "355",
                        reports: []
                      },
                      {
                        employeeId: "356",
                        firstName: "Brynn",
                        lastName: "Jensen",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "355",
                        reports: []
                      },
                      {
                        employeeId: "357",
                        firstName: "Spencer",
                        lastName: "Bruce",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Argentina",
                        managerEmployeeId: "355",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "369",
                    firstName: "Duke",
                    lastName: "Mann",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "354",
                    reports: []
                  },
                  {
                    employeeId: "367",
                    firstName: "Vivienne",
                    lastName: "Gamble",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "354",
                    reports: []
                  },
                  {
                    employeeId: "366",
                    firstName: "Zaylee",
                    lastName: "Nicholson",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Argentina",
                    managerEmployeeId: "354",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "316",
                firstName: "Amir",
                lastName: "Oliver",
                jobTitle: "Sales / Canada Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Canada",
                managerEmployeeId: "282",
                reports: [
                  {
                    employeeId: "330",
                    firstName: "Isaias",
                    lastName: "Oconnell",
                    jobTitle: "Sales / Canada Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "316",
                    reports: [
                      {
                        employeeId: "333",
                        firstName: "Megan",
                        lastName: "Hooper",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "330",
                        reports: []
                      },
                      {
                        employeeId: "332",
                        firstName: "Brooklynn",
                        lastName: "Watts",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "330",
                        reports: []
                      },
                      {
                        employeeId: "331",
                        firstName: "Joanna",
                        lastName: "Pham",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "330",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "323",
                    firstName: "Haven",
                    lastName: "Casey",
                    jobTitle: "Account Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "316",
                    reports: [
                      {
                        employeeId: "324",
                        firstName: "Derrick",
                        lastName: "Bryant",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "323",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "325",
                    firstName: "Bailee",
                    lastName: "Velazquez",
                    jobTitle: "Sales / Canada Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "316",
                    reports: [
                      {
                        employeeId: "326",
                        firstName: "Karla",
                        lastName: "Horton",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "325",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "317",
                    firstName: "Evie",
                    lastName: "Frost",
                    jobTitle: "Account Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "316",
                    reports: [
                      {
                        employeeId: "318",
                        firstName: "Magnus",
                        lastName: "Pugh",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "317",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "319",
                    firstName: "Aniyah",
                    lastName: "Shah",
                    jobTitle: "Account Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "316",
                    reports: [
                      {
                        employeeId: "320",
                        firstName: "Colette",
                        lastName: "Henson",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "319",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "328",
                    firstName: "Dillon",
                    lastName: "Wilkinson",
                    jobTitle: "Sales / Canada Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "316",
                    reports: [
                      {
                        employeeId: "329",
                        firstName: "Yahya",
                        lastName: "Rosales",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "328",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "321",
                    firstName: "Kash",
                    lastName: "Patton",
                    jobTitle: "Sales / Canada Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "316",
                    reports: [
                      {
                        employeeId: "322",
                        firstName: "Zuri",
                        lastName: "Holt",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "321",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "335",
                firstName: "Brooks",
                lastName: "Garrison",
                jobTitle: "Sales / US Director",
                officeLocation: "Paris",
                department: "Sales / US",
                managerEmployeeId: "282",
                reports: [
                  {
                    employeeId: "342",
                    firstName: "Caylee",
                    lastName: "Gomez",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "335",
                    reports: [
                      {
                        employeeId: "343",
                        firstName: "Matthew",
                        lastName: "Landry",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "342",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "346",
                    firstName: "Darrell",
                    lastName: "Lyons",
                    jobTitle: "Sales / US Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "335",
                    reports: [
                      {
                        employeeId: "347",
                        firstName: "Greyson",
                        lastName: "Skinner",
                        jobTitle: "Sales / US Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "346",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "338",
                    firstName: "Jamari",
                    lastName: "Dalton",
                    jobTitle: "Sales / US Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "335",
                    reports: [
                      {
                        employeeId: "339",
                        firstName: "Abraham",
                        lastName: "Keith",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "338",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "336",
                    firstName: "Harlow",
                    lastName: "Hart",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "335",
                    reports: [
                      {
                        employeeId: "337",
                        firstName: "Diana",
                        lastName: "Arias",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "336",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "344",
                    firstName: "Luna",
                    lastName: "Gregory",
                    jobTitle: "Sales / US Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "335",
                    reports: [
                      {
                        employeeId: "345",
                        firstName: "Cataleya",
                        lastName: "Whitney",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "344",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "340",
                    firstName: "Haiden",
                    lastName: "Rojas",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "335",
                    reports: [
                      {
                        employeeId: "341",
                        firstName: "Theodore",
                        lastName: "Cochran",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "340",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "349",
                    firstName: "Kingsley",
                    lastName: "Dudley",
                    jobTitle: "Sales / US Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / US",
                    managerEmployeeId: "335",
                    reports: [
                      {
                        employeeId: "352",
                        firstName: "Libby",
                        lastName: "Douglas",
                        jobTitle: "Sales / US Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "349",
                        reports: []
                      },
                      {
                        employeeId: "350",
                        firstName: "Siena",
                        lastName: "Lin",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "349",
                        reports: []
                      },
                      {
                        employeeId: "351",
                        firstName: "Adelyn",
                        lastName: "Carney",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / US",
                        managerEmployeeId: "349",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "373",
                firstName: "Juniper",
                lastName: "Nicholson",
                jobTitle: "Principal Account Manager",
                officeLocation: "Paris",
                department: "Sales / Canada",
                managerEmployeeId: "282",
                reports: [
                  {
                    employeeId: "384",
                    firstName: "Kensington",
                    lastName: "Rivera",
                    jobTitle: "Sales / Canada Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "373",
                    reports: [
                      {
                        employeeId: "385",
                        firstName: "Joshua",
                        lastName: "Stanton",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "384",
                        reports: []
                      },
                      {
                        employeeId: "386",
                        firstName: "Raelynn",
                        lastName: "Gaines",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "384",
                        reports: []
                      },
                      {
                        employeeId: "387",
                        firstName: "Beckett",
                        lastName: "Gould",
                        jobTitle: "Sales / Canada Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "384",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "381",
                    firstName: "Bryanna",
                    lastName: "Harrison",
                    jobTitle: "Sales / Canada Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "373",
                    reports: [
                      {
                        employeeId: "382",
                        firstName: "Paige",
                        lastName: "Cherry",
                        jobTitle: "Sales / Canada Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "381",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "378",
                    firstName: "Mekhi",
                    lastName: "Lucas",
                    jobTitle: "Sales / Canada Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "373",
                    reports: [
                      {
                        employeeId: "379",
                        firstName: "Dimitri",
                        lastName: "Horn",
                        jobTitle: "Sales / Canada Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "378",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "376",
                    firstName: "Lindsey",
                    lastName: "Krueger",
                    jobTitle: "Sales / Canada Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "373",
                    reports: [
                      {
                        employeeId: "377",
                        firstName: "Kaylin",
                        lastName: "Mclean",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "376",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "374",
                    firstName: "Raelyn",
                    lastName: "Barr",
                    jobTitle: "Sales / Canada Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Canada",
                    managerEmployeeId: "373",
                    reports: [
                      {
                        employeeId: "375",
                        firstName: "Cynthia",
                        lastName: "Kaiser",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Canada",
                        managerEmployeeId: "374",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "283",
                firstName: "Josie",
                lastName: "Pittman",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Paris",
                department: "Sales / Argentina",
                managerEmployeeId: "282",
                reports: []
              }
            ]
          },
          {
            employeeId: "133",
            firstName: "Dominique",
            lastName: "Paul",
            jobTitle: "Senior Director of Developing Markets",
            officeLocation: "Paris",
            department: "Developing Markets",
            managerEmployeeId: "2",
            reports: [
              {
                employeeId: "225",
                firstName: "Mira",
                lastName: "Lynch",
                jobTitle: "Senior Director of Sales / Lugash",
                officeLocation: "Paris",
                department: "Sales / Lugash",
                managerEmployeeId: "133",
                reports: [
                  {
                    employeeId: "241",
                    firstName: "Madelyn",
                    lastName: "Mercado",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "225",
                    reports: [
                      {
                        employeeId: "242",
                        firstName: "River",
                        lastName: "Benitez",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "241",
                        reports: []
                      },
                      {
                        employeeId: "243",
                        firstName: "Harper",
                        lastName: "Figueroa",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "241",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "245",
                    firstName: "Phillip",
                    lastName: "Herman",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "225",
                    reports: [
                      {
                        employeeId: "246",
                        firstName: "Aiyana",
                        lastName: "Velazquez",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "245",
                        reports: []
                      },
                      {
                        employeeId: "248",
                        firstName: "Callan",
                        lastName: "Lee",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "245",
                        reports: []
                      },
                      {
                        employeeId: "247",
                        firstName: "Jayla",
                        lastName: "Nixon",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "245",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "226",
                    firstName: "Enzo",
                    lastName: "Bryan",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "225",
                    reports: [
                      {
                        employeeId: "227",
                        firstName: "Julian",
                        lastName: "Trujillo",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "226",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "229",
                    firstName: "Westin",
                    lastName: "Franklin",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "225",
                    reports: [
                      {
                        employeeId: "230",
                        firstName: "Elliana",
                        lastName: "Gonzales",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "229",
                        reports: []
                      },
                      {
                        employeeId: "231",
                        firstName: "Brodie",
                        lastName: "Sparks",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "229",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "236",
                    firstName: "Gemma",
                    lastName: "Terrell",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "225",
                    reports: [
                      {
                        employeeId: "238",
                        firstName: "Damon",
                        lastName: "Wilcox",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "236",
                        reports: []
                      },
                      {
                        employeeId: "237",
                        firstName: "Derek",
                        lastName: "Williams",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "236",
                        reports: []
                      },
                      {
                        employeeId: "239",
                        firstName: "Emilie",
                        lastName: "Savage",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "236",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "250",
                    firstName: "Parker",
                    lastName: "Sellers",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "225",
                    reports: [
                      {
                        employeeId: "251",
                        firstName: "Bronson",
                        lastName: "Colon",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "250",
                        reports: []
                      },
                      {
                        employeeId: "254",
                        firstName: "Mustafa",
                        lastName: "Meadows",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "250",
                        reports: []
                      },
                      {
                        employeeId: "252",
                        firstName: "Ahmed",
                        lastName: "Vincent",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "250",
                        reports: []
                      },
                      {
                        employeeId: "253",
                        firstName: "Nikolas",
                        lastName: "Ballard",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "250",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "233",
                    firstName: "Angelo",
                    lastName: "Hubbard",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "225",
                    reports: [
                      {
                        employeeId: "234",
                        firstName: "Jace",
                        lastName: "Clayton",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "233",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "134",
                firstName: "Eliana",
                lastName: "Shepard",
                jobTitle: "Principal Account Manager",
                officeLocation: "Paris",
                department: "Sales / Lugash",
                managerEmployeeId: "133",
                reports: [
                  {
                    employeeId: "154",
                    firstName: "Sylvia",
                    lastName: "Sparks",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "134",
                    reports: [
                      {
                        employeeId: "155",
                        firstName: "Adrianna",
                        lastName: "Moran",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "154",
                        reports: []
                      },
                      {
                        employeeId: "156",
                        firstName: "Kaleb",
                        lastName: "Bryant",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "154",
                        reports: []
                      },
                      {
                        employeeId: "157",
                        firstName: "Kylan",
                        lastName: "Townsend",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "154",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "135",
                    firstName: "Rohan",
                    lastName: "Glover",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "134",
                    reports: [
                      {
                        employeeId: "137",
                        firstName: "Elsie",
                        lastName: "Bright",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "135",
                        reports: []
                      },
                      {
                        employeeId: "136",
                        firstName: "Ian",
                        lastName: "Larsen",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "135",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "159",
                    firstName: "Alexis",
                    lastName: "Sanchez",
                    jobTitle: "Sales / Lugash Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "134",
                    reports: [
                      {
                        employeeId: "160",
                        firstName: "Jayce",
                        lastName: "Lloyd",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "159",
                        reports: []
                      },
                      {
                        employeeId: "162",
                        firstName: "Sloane",
                        lastName: "Castro",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "159",
                        reports: []
                      },
                      {
                        employeeId: "161",
                        firstName: "Ray",
                        lastName: "Richard",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "159",
                        reports: []
                      },
                      {
                        employeeId: "163",
                        firstName: "Brooke",
                        lastName: "Rodgers",
                        jobTitle: "Sales / Lugash Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "159",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "144",
                    firstName: "Kalani",
                    lastName: "Jenkins",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "134",
                    reports: [
                      {
                        employeeId: "145",
                        firstName: "Alexis",
                        lastName: "Reid",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "144",
                        reports: []
                      },
                      {
                        employeeId: "146",
                        firstName: "Alison",
                        lastName: "Bradshaw",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "144",
                        reports: []
                      },
                      {
                        employeeId: "147",
                        firstName: "Oakley",
                        lastName: "Shah",
                        jobTitle: "Sales / Lugash Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "144",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "139",
                    firstName: "Reyna",
                    lastName: "Valenzuela",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "134",
                    reports: [
                      {
                        employeeId: "142",
                        firstName: "Adilynn",
                        lastName: "Conway",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "139",
                        reports: []
                      },
                      {
                        employeeId: "141",
                        firstName: "Kelvin",
                        lastName: "Hampton",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "139",
                        reports: []
                      },
                      {
                        employeeId: "140",
                        firstName: "Ali",
                        lastName: "Dudley",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "139",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "149",
                    firstName: "Cash",
                    lastName: "Carney",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "134",
                    reports: [
                      {
                        employeeId: "150",
                        firstName: "Noe",
                        lastName: "Mcmillan",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "149",
                        reports: []
                      },
                      {
                        employeeId: "152",
                        firstName: "Myla",
                        lastName: "Fry",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "149",
                        reports: []
                      },
                      {
                        employeeId: "151",
                        firstName: "Bianca",
                        lastName: "Irwin",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "149",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "193",
                firstName: "Dwayne",
                lastName: "Rice",
                jobTitle: "Sales / Lugash Director",
                officeLocation: "Paris",
                department: "Sales / Lugash",
                managerEmployeeId: "133",
                reports: [
                  {
                    employeeId: "194",
                    firstName: "Aydin",
                    lastName: "Medina",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "193",
                    reports: [
                      {
                        employeeId: "197",
                        firstName: "Emmanuel",
                        lastName: "Cummings",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "194",
                        reports: []
                      },
                      {
                        employeeId: "195",
                        firstName: "Jaden",
                        lastName: "Rowe",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "194",
                        reports: []
                      },
                      {
                        employeeId: "196",
                        firstName: "Micheal",
                        lastName: "Valencia",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "194",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "219",
                    firstName: "Victor",
                    lastName: "Chavez",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "193",
                    reports: [
                      {
                        employeeId: "223",
                        firstName: "Elaine",
                        lastName: "Strong",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "219",
                        reports: []
                      },
                      {
                        employeeId: "220",
                        firstName: "Rayan",
                        lastName: "Byrd",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "219",
                        reports: []
                      },
                      {
                        employeeId: "221",
                        firstName: "Craig",
                        lastName: "Carr",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "219",
                        reports: []
                      },
                      {
                        employeeId: "222",
                        firstName: "Nayeli",
                        lastName: "Mccarthy",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "219",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "214",
                    firstName: "Kobe",
                    lastName: "Wilkins",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "193",
                    reports: [
                      {
                        employeeId: "217",
                        firstName: "Raina",
                        lastName: "Carroll",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "214",
                        reports: []
                      },
                      {
                        employeeId: "215",
                        firstName: "Millie",
                        lastName: "Mcknight",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "214",
                        reports: []
                      },
                      {
                        employeeId: "216",
                        firstName: "Alexa",
                        lastName: "Norman",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "214",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "199",
                    firstName: "Joey",
                    lastName: "Moody",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "193",
                    reports: [
                      {
                        employeeId: "201",
                        firstName: "Abel",
                        lastName: "Peters",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "199",
                        reports: []
                      },
                      {
                        employeeId: "202",
                        firstName: "Branson",
                        lastName: "Boyd",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "199",
                        reports: []
                      },
                      {
                        employeeId: "200",
                        firstName: "Nancy",
                        lastName: "Wong",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "199",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "204",
                    firstName: "Ernesto",
                    lastName: "Vasquez",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "193",
                    reports: [
                      {
                        employeeId: "205",
                        firstName: "Zayne",
                        lastName: "Farley",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "204",
                        reports: []
                      },
                      {
                        employeeId: "206",
                        firstName: "Aden",
                        lastName: "Ramirez",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "204",
                        reports: []
                      },
                      {
                        employeeId: "207",
                        firstName: "Castiel",
                        lastName: "Bright",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "204",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "209",
                    firstName: "Baylor",
                    lastName: "Zamora",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "193",
                    reports: [
                      {
                        employeeId: "210",
                        firstName: "Jayleen",
                        lastName: "Sampson",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "209",
                        reports: []
                      },
                      {
                        employeeId: "211",
                        firstName: "Amira",
                        lastName: "Ray",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "209",
                        reports: []
                      },
                      {
                        employeeId: "212",
                        firstName: "Ximena",
                        lastName: "Patterson",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "209",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "256",
                firstName: "Maeve",
                lastName: "Glenn",
                jobTitle: "Sales / Lugash Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Lugash",
                managerEmployeeId: "133",
                reports: [
                  {
                    employeeId: "268",
                    firstName: "Hadleigh",
                    lastName: "Allen",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "256",
                    reports: [
                      {
                        employeeId: "270",
                        firstName: "Jacoby",
                        lastName: "Solomon",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "268",
                        reports: []
                      },
                      {
                        employeeId: "269",
                        firstName: "Dane",
                        lastName: "English",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "268",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "257",
                    firstName: "Joziah",
                    lastName: "Hendricks",
                    jobTitle: "Sales / Lugash Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "256",
                    reports: [
                      {
                        employeeId: "258",
                        firstName: "Mateo",
                        lastName: "Oneal",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "257",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "276",
                    firstName: "Estrella",
                    lastName: "Armstrong",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "256",
                    reports: [
                      {
                        employeeId: "278",
                        firstName: "Angela",
                        lastName: "Mann",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "276",
                        reports: []
                      },
                      {
                        employeeId: "277",
                        firstName: "Alannah",
                        lastName: "Montes",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "276",
                        reports: []
                      },
                      {
                        employeeId: "279",
                        firstName: "Thatcher",
                        lastName: "Gardner",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "276",
                        reports: []
                      },
                      {
                        employeeId: "280",
                        firstName: "Jose",
                        lastName: "Phillips",
                        jobTitle: "Sales / Lugash Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "276",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "259",
                    firstName: "Crew",
                    lastName: "Powers",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "256",
                    reports: [
                      {
                        employeeId: "260",
                        firstName: "Cohen",
                        lastName: "Steele",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "259",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "265",
                    firstName: "Abram",
                    lastName: "Sherman",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "256",
                    reports: [
                      {
                        employeeId: "266",
                        firstName: "Charlee",
                        lastName: "Vang",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "265",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "272",
                    firstName: "Micah",
                    lastName: "Bush",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "256",
                    reports: [
                      {
                        employeeId: "274",
                        firstName: "Walker",
                        lastName: "Rich",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "272",
                        reports: []
                      },
                      {
                        employeeId: "273",
                        firstName: "Kayleigh",
                        lastName: "Huffman",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "272",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "262",
                    firstName: "Leah",
                    lastName: "Chen",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "256",
                    reports: [
                      {
                        employeeId: "263",
                        firstName: "Brent",
                        lastName: "Werner",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "262",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "166",
                firstName: "Hayes",
                lastName: "Sutton",
                jobTitle: "Sales / Lugash Director",
                officeLocation: "Paris",
                department: "Sales / Lugash",
                managerEmployeeId: "133",
                reports: [
                  {
                    employeeId: "187",
                    firstName: "Aydin",
                    lastName: "Moses",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: [
                      {
                        employeeId: "188",
                        firstName: "Lillianna",
                        lastName: "Bautista",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "187",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "180",
                    firstName: "Nathaly",
                    lastName: "Cannon",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "176",
                    firstName: "Mack",
                    lastName: "Lowery",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "168",
                    firstName: "Ray",
                    lastName: "Kelley",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "179",
                    firstName: "Paityn",
                    lastName: "Dudley",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "178",
                    firstName: "Eva",
                    lastName: "Cooper",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "181",
                    firstName: "Briggs",
                    lastName: "Bass",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "175",
                    firstName: "Boston",
                    lastName: "Lloyd",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "167",
                    firstName: "Harper",
                    lastName: "Webster",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "183",
                    firstName: "Ruby",
                    lastName: "Matthews",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: [
                      {
                        employeeId: "184",
                        firstName: "Holland",
                        lastName: "Friedman",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "183",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "189",
                    firstName: "Monroe",
                    lastName: "Villarreal",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: [
                      {
                        employeeId: "191",
                        firstName: "Diana",
                        lastName: "Mendoza",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "189",
                        reports: []
                      },
                      {
                        employeeId: "190",
                        firstName: "Hugo",
                        lastName: "Dickson",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "189",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "170",
                    firstName: "Vivaan",
                    lastName: "Clark",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "171",
                    firstName: "Hadleigh",
                    lastName: "Mccarty",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "172",
                    firstName: "Andrea",
                    lastName: "Michael",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "169",
                    firstName: "Hadley",
                    lastName: "Ball",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "174",
                    firstName: "Rafael",
                    lastName: "Marshall",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "173",
                    firstName: "Aubriella",
                    lastName: "Torres",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "182",
                    firstName: "Seth",
                    lastName: "Medina",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "177",
                    firstName: "Keaton",
                    lastName: "Steele",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: []
                  },
                  {
                    employeeId: "185",
                    firstName: "Aileen",
                    lastName: "Washington",
                    jobTitle: "Sales / Lugash Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "166",
                    reports: [
                      {
                        employeeId: "186",
                        firstName: "Clyde",
                        lastName: "Sherman",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "185",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "389",
            firstName: "Cadence",
            lastName: "Callahan",
            jobTitle: "Vice President of Developing Markets",
            officeLocation: "Cairo",
            department: "Developing Markets",
            managerEmployeeId: "2",
            reports: [
              {
                employeeId: "491",
                firstName: "Jon",
                lastName: "Elliott",
                jobTitle: "Aquatic Sales / Atlantis Manager-VII",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: [
                  {
                    employeeId: "492",
                    firstName: "Dalton",
                    lastName: "Mcclain",
                    jobTitle: "Aquatic Sales / Atlantis Manager-IV",
                    officeLocation: "Cairo",
                    department: "Aquatic Sales / Atlantis",
                    managerEmployeeId: "491",
                    reports: [
                      {
                        employeeId: "493",
                        firstName: "Maeve",
                        lastName: "Hines",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Cairo",
                        department: "Aquatic Sales / Atlantis",
                        managerEmployeeId: "492",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "390",
                firstName: "Holly",
                lastName: "Mcneil",
                jobTitle: "Senior Director of Sales / Elbonia",
                officeLocation: "Cairo",
                department: "Sales / Elbonia",
                managerEmployeeId: "389",
                reports: [
                  {
                    employeeId: "440",
                    firstName: "Mikaela",
                    lastName: "Washington",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "390",
                    reports: []
                  },
                  {
                    employeeId: "453",
                    firstName: "Adele",
                    lastName: "Nichols",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "390",
                    reports: [
                      {
                        employeeId: "455",
                        firstName: "Kennedy",
                        lastName: "Gilbert",
                        jobTitle: "Sales / Elbonia Manager-II",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "453",
                        reports: []
                      },
                      {
                        employeeId: "454",
                        firstName: "Messiah",
                        lastName: "Perez",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "453",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "448",
                    firstName: "Olive",
                    lastName: "Giles",
                    jobTitle: "Sales / Elbonia Manager-V",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "390",
                    reports: [
                      {
                        employeeId: "449",
                        firstName: "Michael",
                        lastName: "Stevens",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "448",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "445",
                    firstName: "Frances",
                    lastName: "Figueroa",
                    jobTitle: "Sales / Elbonia Manager-VI",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "390",
                    reports: [
                      {
                        employeeId: "446",
                        firstName: "Jaida",
                        lastName: "Espinoza",
                        jobTitle: "Sales / Elbonia Manager-III",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "445",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "391",
                    firstName: "Jewel",
                    lastName: "Walls",
                    jobTitle: "Sales / Elbonia Director",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "390",
                    reports: [
                      {
                        employeeId: "408",
                        firstName: "Kendall",
                        lastName: "Acosta",
                        jobTitle: "Sales / Elbonia Manager-V",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "391",
                        reports: []
                      },
                      {
                        employeeId: "392",
                        firstName: "Abby",
                        lastName: "Blair",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "391",
                        reports: []
                      },
                      {
                        employeeId: "414",
                        firstName: "Everett",
                        lastName: "Butler",
                        jobTitle: "Sales / Elbonia Manager-VI",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "391",
                        reports: []
                      },
                      {
                        employeeId: "431",
                        firstName: "Skyler",
                        lastName: "Dunlap",
                        jobTitle: "Sales / Elbonia Manager-VI",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "391",
                        reports: []
                      },
                      {
                        employeeId: "400",
                        firstName: "Annalee",
                        lastName: "Riley",
                        jobTitle: "Senior Staff Account Manager",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "391",
                        reports: []
                      },
                      {
                        employeeId: "420",
                        firstName: "Savannah",
                        lastName: "Kennedy",
                        jobTitle: "Sales / Elbonia Manager-VI",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "391",
                        reports: []
                      },
                      {
                        employeeId: "393",
                        firstName: "Kynlee",
                        lastName: "Wilson",
                        jobTitle: "Sales / Elbonia Manager-VII",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "391",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "450",
                    firstName: "Casey",
                    lastName: "Wilcox",
                    jobTitle: "Sales / Elbonia Manager-V",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "390",
                    reports: [
                      {
                        employeeId: "451",
                        firstName: "Elisa",
                        lastName: "Bonilla",
                        jobTitle: "Sales / Elbonia Manager-II",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "450",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "457",
                    firstName: "Meilani",
                    lastName: "Medina",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "390",
                    reports: [
                      {
                        employeeId: "458",
                        firstName: "Myles",
                        lastName: "Wallace",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "457",
                        reports: []
                      },
                      {
                        employeeId: "459",
                        firstName: "Andrea",
                        lastName: "Bullock",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "457",
                        reports: []
                      },
                      {
                        employeeId: "460",
                        firstName: "Vivian",
                        lastName: "Huff",
                        jobTitle: "Sales / Elbonia Manager-II",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "457",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "443",
                    firstName: "Sabrina",
                    lastName: "Yates",
                    jobTitle: "Sales / Elbonia Manager-VI",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "390",
                    reports: [
                      {
                        employeeId: "444",
                        firstName: "Ahmed",
                        lastName: "Snow",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "443",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "441",
                    firstName: "Laylah",
                    lastName: "Esparza",
                    jobTitle: "Sales / Elbonia Manager-VI",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "390",
                    reports: [
                      {
                        employeeId: "442",
                        firstName: "Castiel",
                        lastName: "Santana",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Cairo",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "441",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "463",
                firstName: "Kaison",
                lastName: "Bird",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "470",
                firstName: "Chace",
                lastName: "Willis",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "485",
                firstName: "Alaina",
                lastName: "Tate",
                jobTitle: "Sales / Elbonia Manager-VII",
                officeLocation: "Cairo",
                department: "Sales / Elbonia",
                managerEmployeeId: "389",
                reports: [
                  {
                    employeeId: "486",
                    firstName: "Cayden",
                    lastName: "Macias",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Cairo",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "485",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "482",
                firstName: "Danna",
                lastName: "Delgado",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Lugash",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "476",
                firstName: "Rory",
                lastName: "Hester",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "477",
                firstName: "Kaelyn",
                lastName: "Evans",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "475",
                firstName: "Whitney",
                lastName: "Taylor",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "483",
                firstName: "Frankie",
                lastName: "Ayers",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Lugash",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "489",
                firstName: "Aitana",
                lastName: "Phillips",
                jobTitle: "Aquatic Sales / Atlantis Manager-VII",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: [
                  {
                    employeeId: "490",
                    firstName: "Kai",
                    lastName: "Hughes",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Cairo",
                    department: "Aquatic Sales / Atlantis",
                    managerEmployeeId: "489",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "465",
                firstName: "Hallie",
                lastName: "Key",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Lugash",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "462",
                firstName: "Dean",
                lastName: "Fitzpatrick",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "481",
                firstName: "Orlando",
                lastName: "Floyd",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Elbonia",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "494",
                firstName: "Ares",
                lastName: "Floyd",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Lugash",
                managerEmployeeId: "389",
                reports: [
                  {
                    employeeId: "496",
                    firstName: "Patricia",
                    lastName: "Carroll",
                    jobTitle: "Sales / Lugash Manager-III",
                    officeLocation: "Cairo",
                    department: "Sales / Lugash",
                    managerEmployeeId: "494",
                    reports: [
                      {
                        employeeId: "497",
                        firstName: "Saniyah",
                        lastName: "Berger",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Cairo",
                        department: "Sales / Lugash",
                        managerEmployeeId: "496",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "495",
                    firstName: "Zoie",
                    lastName: "Rodriguez",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Cairo",
                    department: "Sales / Lugash",
                    managerEmployeeId: "494",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "480",
                firstName: "Jerome",
                lastName: "Castro",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Elbonia",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "473",
                firstName: "Todd",
                lastName: "Ayers",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "468",
                firstName: "Jaylee",
                lastName: "Sellers",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "471",
                firstName: "Nylah",
                lastName: "Fernandez",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Elbonia",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "466",
                firstName: "Camron",
                lastName: "Ritter",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Lugash",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "487",
                firstName: "Skyler",
                lastName: "Cabrera",
                jobTitle: "Aquatic Sales / Atlantis Manager-VI",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: [
                  {
                    employeeId: "488",
                    firstName: "Lance",
                    lastName: "Leon",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Cairo",
                    department: "Aquatic Sales / Atlantis",
                    managerEmployeeId: "487",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "484",
                firstName: "Baylee",
                lastName: "Welch",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "479",
                firstName: "Enoch",
                lastName: "Patrick",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Elbonia",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "472",
                firstName: "Cole",
                lastName: "Bradford",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Lugash",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "467",
                firstName: "Karlie",
                lastName: "Gilmore",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Lugash",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "469",
                firstName: "Francesca",
                lastName: "Berger",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Lugash",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "478",
                firstName: "Marissa",
                lastName: "Howard",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "464",
                firstName: "Archer",
                lastName: "Riley",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Cairo",
                department: "Aquatic Sales / Atlantis",
                managerEmployeeId: "389",
                reports: []
              },
              {
                employeeId: "474",
                firstName: "Khaleesi",
                lastName: "Mendoza",
                jobTitle: "Staff Account Manager",
                officeLocation: "Cairo",
                department: "Sales / Elbonia",
                managerEmployeeId: "389",
                reports: []
              }
            ]
          },
          {
            employeeId: "613",
            firstName: "Esme",
            lastName: "Dunn",
            jobTitle: "Senior Director of Sales / Europe",
            officeLocation: "Mountain View",
            department: "Sales / Europe",
            managerEmployeeId: "2",
            reports: [
              {
                employeeId: "722",
                firstName: "Christine",
                lastName: "Bates",
                jobTitle: "Sales / Russia Director",
                officeLocation: "Mountain View",
                department: "Sales / Russia",
                managerEmployeeId: "613",
                reports: [
                  {
                    employeeId: "731",
                    firstName: "Matilda",
                    lastName: "Benjamin",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "722",
                    reports: [
                      {
                        employeeId: "732",
                        firstName: "Otis",
                        lastName: "Moore",
                        jobTitle: "Sales / Russia Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "731",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "725",
                    firstName: "Stanley",
                    lastName: "Gay",
                    jobTitle: "Sales / Russia Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "722",
                    reports: [
                      {
                        employeeId: "726",
                        firstName: "Ricardo",
                        lastName: "Duarte",
                        jobTitle: "Sales / Russia Manager-III",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "725",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "723",
                    firstName: "Lacey",
                    lastName: "Gray",
                    jobTitle: "Account Manager-IV",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "722",
                    reports: [
                      {
                        employeeId: "724",
                        firstName: "Desiree",
                        lastName: "Buchanan",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "723",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "728",
                    firstName: "Felix",
                    lastName: "Blankenship",
                    jobTitle: "Sales / Russia Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "722",
                    reports: [
                      {
                        employeeId: "729",
                        firstName: "Elisa",
                        lastName: "Buckley",
                        jobTitle: "Sales / Russia Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "728",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "734",
                    firstName: "Hailey",
                    lastName: "Joyce",
                    jobTitle: "Sales / Russia Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "722",
                    reports: [
                      {
                        employeeId: "735",
                        firstName: "Remi",
                        lastName: "Garza",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "734",
                        reports: []
                      },
                      {
                        employeeId: "736",
                        firstName: "Alfred",
                        lastName: "Charles",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "734",
                        reports: []
                      },
                      {
                        employeeId: "738",
                        firstName: "Miller",
                        lastName: "Graham",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "734",
                        reports: []
                      },
                      {
                        employeeId: "737",
                        firstName: "Malaki",
                        lastName: "Terrell",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "734",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "615",
                firstName: "Kai",
                lastName: "Brady",
                jobTitle: "Sales / Russia Director",
                officeLocation: "Mountain View",
                department: "Sales / Russia",
                managerEmployeeId: "613",
                reports: [
                  {
                    employeeId: "634",
                    firstName: "Kelvin",
                    lastName: "Dorsey",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "615",
                    reports: []
                  },
                  {
                    employeeId: "636",
                    firstName: "Camdyn",
                    lastName: "Strickland",
                    jobTitle: "Sales / Russia Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "615",
                    reports: [
                      {
                        employeeId: "637",
                        firstName: "Kieran",
                        lastName: "Joseph",
                        jobTitle: "Sales / Russia Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "636",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "633",
                    firstName: "Martha",
                    lastName: "Lara",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "615",
                    reports: []
                  },
                  {
                    employeeId: "616",
                    firstName: "Thomas",
                    lastName: "Hanson",
                    jobTitle: "Sales / Russia Manager-VII",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "615",
                    reports: [
                      {
                        employeeId: "627",
                        firstName: "Adrian",
                        lastName: "Fritz",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "616",
                        reports: []
                      },
                      {
                        employeeId: "628",
                        firstName: "Lucille",
                        lastName: "Cox",
                        jobTitle: "Sales / Russia Manager-V",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "616",
                        reports: []
                      },
                      {
                        employeeId: "625",
                        firstName: "Jenny",
                        lastName: "Rios",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "616",
                        reports: []
                      },
                      {
                        employeeId: "626",
                        firstName: "Ember",
                        lastName: "Gomez",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "616",
                        reports: []
                      },
                      {
                        employeeId: "617",
                        firstName: "Amos",
                        lastName: "Hickman",
                        jobTitle: "Sales / Russia Manager-VI",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "616",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "635",
                    firstName: "Ann",
                    lastName: "Rivas",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "615",
                    reports: []
                  },
                  {
                    employeeId: "632",
                    firstName: "Andy",
                    lastName: "Morse",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "615",
                    reports: []
                  },
                  {
                    employeeId: "631",
                    firstName: "Thea",
                    lastName: "Rodriguez",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "615",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "705",
                firstName: "Brynn",
                lastName: "Zhang",
                jobTitle: "Sales / Germany Director",
                officeLocation: "Mountain View",
                department: "Sales / Germany",
                managerEmployeeId: "613",
                reports: [
                  {
                    employeeId: "710",
                    firstName: "Ashlyn",
                    lastName: "Cisneros",
                    jobTitle: "Sales / Germany Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "705",
                    reports: [
                      {
                        employeeId: "711",
                        firstName: "Sariah",
                        lastName: "Zavala",
                        jobTitle: "Sales / Germany Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "710",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "708",
                    firstName: "Marley",
                    lastName: "Juarez",
                    jobTitle: "Sales / Germany Manager-IV",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "705",
                    reports: [
                      {
                        employeeId: "709",
                        firstName: "Kylie",
                        lastName: "Keith",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "708",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "706",
                    firstName: "Jeffrey",
                    lastName: "Bernard",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "705",
                    reports: [
                      {
                        employeeId: "707",
                        firstName: "Gibson",
                        lastName: "Skinner",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "706",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "713",
                    firstName: "Rocco",
                    lastName: "Shepherd",
                    jobTitle: "Sales / Germany Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "705",
                    reports: [
                      {
                        employeeId: "714",
                        firstName: "Sophia",
                        lastName: "Stephenson",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "713",
                        reports: []
                      },
                      {
                        employeeId: "715",
                        firstName: "Ruth",
                        lastName: "Wise",
                        jobTitle: "Sales / Germany Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "713",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "717",
                    firstName: "Martin",
                    lastName: "Lawrence",
                    jobTitle: "Sales / Germany Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "705",
                    reports: [
                      {
                        employeeId: "720",
                        firstName: "Paola",
                        lastName: "Boyle",
                        jobTitle: "Sales / Germany Manager-III",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "717",
                        reports: []
                      },
                      {
                        employeeId: "719",
                        firstName: "Tiffany",
                        lastName: "Sanders",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "717",
                        reports: []
                      },
                      {
                        employeeId: "718",
                        firstName: "Bethany",
                        lastName: "Hubbard",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "717",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "684",
                firstName: "Elaine",
                lastName: "Huff",
                jobTitle: "Sales / Germany Manager-VII",
                officeLocation: "Mountain View",
                department: "Sales / Germany",
                managerEmployeeId: "613",
                reports: [
                  {
                    employeeId: "688",
                    firstName: "Greyson",
                    lastName: "Fitzgerald",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "684",
                    reports: [
                      {
                        employeeId: "690",
                        firstName: "Anna",
                        lastName: "Cole",
                        jobTitle: "Sales / Germany Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "688",
                        reports: []
                      },
                      {
                        employeeId: "689",
                        firstName: "Arianna",
                        lastName: "Gonzalez",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "688",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "699",
                    firstName: "Lilyanna",
                    lastName: "Ryan",
                    jobTitle: "Sales / Germany Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "684",
                    reports: [
                      {
                        employeeId: "701",
                        firstName: "Jaylin",
                        lastName: "Dalton",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "699",
                        reports: []
                      },
                      {
                        employeeId: "702",
                        firstName: "Esperanza",
                        lastName: "Irwin",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "699",
                        reports: []
                      },
                      {
                        employeeId: "700",
                        firstName: "Abraham",
                        lastName: "Austin",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "699",
                        reports: []
                      },
                      {
                        employeeId: "703",
                        firstName: "Dominic",
                        lastName: "Blevins",
                        jobTitle: "Sales / Germany Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "699",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "695",
                    firstName: "Annalise",
                    lastName: "Long",
                    jobTitle: "Sales / Germany Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "684",
                    reports: [
                      {
                        employeeId: "696",
                        firstName: "Bryan",
                        lastName: "Wise",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "695",
                        reports: []
                      },
                      {
                        employeeId: "697",
                        firstName: "Bronson",
                        lastName: "Oconnor",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "695",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "692",
                    firstName: "Gabriela",
                    lastName: "Ibarra",
                    jobTitle: "Sales / Germany Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "684",
                    reports: [
                      {
                        employeeId: "693",
                        firstName: "Phoenix",
                        lastName: "Shepherd",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "692",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "685",
                    firstName: "Jaelyn",
                    lastName: "Kemp",
                    jobTitle: "Sales / Germany Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "684",
                    reports: [
                      {
                        employeeId: "686",
                        firstName: "Lucas",
                        lastName: "Barber",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "685",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "662",
                firstName: "Liberty",
                lastName: "Humphrey",
                jobTitle: "Sales / Germany Director",
                officeLocation: "Mountain View",
                department: "Sales / Germany",
                managerEmployeeId: "613",
                reports: [
                  {
                    employeeId: "678",
                    firstName: "Mathias",
                    lastName: "Jenkins",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "662",
                    reports: [
                      {
                        employeeId: "679",
                        firstName: "Jaime",
                        lastName: "Salinas",
                        jobTitle: "Sales / Germany Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "678",
                        reports: []
                      },
                      {
                        employeeId: "681",
                        firstName: "Skylar",
                        lastName: "Baird",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "678",
                        reports: []
                      },
                      {
                        employeeId: "682",
                        firstName: "Alden",
                        lastName: "Morris",
                        jobTitle: "Sales / Germany Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "678",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "675",
                    firstName: "Brynn",
                    lastName: "Le",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "662",
                    reports: [
                      {
                        employeeId: "676",
                        firstName: "Marshall",
                        lastName: "Williamson",
                        jobTitle: "Sales / Germany Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "675",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "663",
                    firstName: "Cash",
                    lastName: "Duarte",
                    jobTitle: "Sales / Germany Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "662",
                    reports: [
                      {
                        employeeId: "664",
                        firstName: "Tomas",
                        lastName: "Downs",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "663",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "671",
                    firstName: "Shelby",
                    lastName: "Crawford",
                    jobTitle: "Sales / Germany Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "662",
                    reports: [
                      {
                        employeeId: "672",
                        firstName: "Colin",
                        lastName: "Michael",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "671",
                        reports: []
                      },
                      {
                        employeeId: "673",
                        firstName: "Gunnar",
                        lastName: "Levine",
                        jobTitle: "Sales / Germany Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "671",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "668",
                    firstName: "Emma",
                    lastName: "Frank",
                    jobTitle: "Sales / Germany Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "662",
                    reports: [
                      {
                        employeeId: "669",
                        firstName: "Caylee",
                        lastName: "Harmon",
                        jobTitle: "Sales / Germany Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "668",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "665",
                    firstName: "Vanessa",
                    lastName: "Johns",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / Germany",
                    managerEmployeeId: "662",
                    reports: [
                      {
                        employeeId: "666",
                        firstName: "Crosby",
                        lastName: "Pittman",
                        jobTitle: "Sales / Germany Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Germany",
                        managerEmployeeId: "665",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "740",
                firstName: "Hamza",
                lastName: "Eaton",
                jobTitle: "Sales / Russia Director",
                officeLocation: "Mountain View",
                department: "Sales / Russia",
                managerEmployeeId: "613",
                reports: [
                  {
                    employeeId: "745",
                    firstName: "Zoie",
                    lastName: "Mcconnell",
                    jobTitle: "Sales / Russia Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "740",
                    reports: [
                      {
                        employeeId: "746",
                        firstName: "Peter",
                        lastName: "Mcknight",
                        jobTitle: "Sales / Russia Manager-III",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "745",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "748",
                    firstName: "Levi",
                    lastName: "Carpenter",
                    jobTitle: "Sales / Russia Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "740",
                    reports: [
                      {
                        employeeId: "749",
                        firstName: "Angelica",
                        lastName: "Ritter",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "748",
                        reports: []
                      },
                      {
                        employeeId: "750",
                        firstName: "Oliver",
                        lastName: "Hall",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "748",
                        reports: []
                      },
                      {
                        employeeId: "751",
                        firstName: "Braylee",
                        lastName: "Rodriguez",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "748",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "758",
                    firstName: "Meredith",
                    lastName: "Cline",
                    jobTitle: "Sales / Russia Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "740",
                    reports: [
                      {
                        employeeId: "759",
                        firstName: "Jaylah",
                        lastName: "Stokes",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "758",
                        reports: []
                      },
                      {
                        employeeId: "761",
                        firstName: "Kieran",
                        lastName: "Mercer",
                        jobTitle: "Sales / Russia Manager-III",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "758",
                        reports: []
                      },
                      {
                        employeeId: "760",
                        firstName: "Maliah",
                        lastName: "Vargas",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "758",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "753",
                    firstName: "Harmoni",
                    lastName: "Stephens",
                    jobTitle: "Sales / Russia Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "740",
                    reports: [
                      {
                        employeeId: "755",
                        firstName: "Lainey",
                        lastName: "Pierce",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "753",
                        reports: []
                      },
                      {
                        employeeId: "754",
                        firstName: "Bianca",
                        lastName: "Ramos",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "753",
                        reports: []
                      },
                      {
                        employeeId: "756",
                        firstName: "Zoe",
                        lastName: "Bauer",
                        jobTitle: "Sales / Russia Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "753",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "741",
                    firstName: "Heaven",
                    lastName: "Avery",
                    jobTitle: "Sales / Russia Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / Russia",
                    managerEmployeeId: "740",
                    reports: [
                      {
                        employeeId: "742",
                        firstName: "Marie",
                        lastName: "Cochran",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "741",
                        reports: []
                      },
                      {
                        employeeId: "743",
                        firstName: "Julio",
                        lastName: "Norris",
                        jobTitle: "Sales / Russia Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / Russia",
                        managerEmployeeId: "741",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "614",
                firstName: "Terrence",
                lastName: "Sherman",
                jobTitle: "Senior Account Manager",
                officeLocation: "Mountain View",
                department: "Sales / Russia",
                managerEmployeeId: "613",
                reports: []
              },
              {
                employeeId: "639",
                firstName: "Aubrielle",
                lastName: "Colon",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Mountain View",
                department: "Sales / UK",
                managerEmployeeId: "613",
                reports: [
                  {
                    employeeId: "641",
                    firstName: "Rebecca",
                    lastName: "Shah",
                    jobTitle: "Sales / UK Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / UK",
                    managerEmployeeId: "639",
                    reports: [
                      {
                        employeeId: "642",
                        firstName: "Reina",
                        lastName: "Lutz",
                        jobTitle: "Sales / UK Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "641",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "644",
                    firstName: "Amirah",
                    lastName: "Montoya",
                    jobTitle: "Sales / UK Manager-III",
                    officeLocation: "Mountain View",
                    department: "Sales / UK",
                    managerEmployeeId: "639",
                    reports: [
                      {
                        employeeId: "645",
                        firstName: "Lily",
                        lastName: "Mclean",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "644",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "657",
                    firstName: "Kai",
                    lastName: "Jacobs",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Mountain View",
                    department: "Sales / UK",
                    managerEmployeeId: "639",
                    reports: [
                      {
                        employeeId: "659",
                        firstName: "Princeton",
                        lastName: "Dillon",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "657",
                        reports: []
                      },
                      {
                        employeeId: "660",
                        firstName: "Julianna",
                        lastName: "Parker",
                        jobTitle: "Sales / UK Manager-III",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "657",
                        reports: []
                      },
                      {
                        employeeId: "658",
                        firstName: "Avah",
                        lastName: "Mooney",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "657",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "649",
                    firstName: "Lila",
                    lastName: "Yang",
                    jobTitle: "Sales / UK Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / UK",
                    managerEmployeeId: "639",
                    reports: [
                      {
                        employeeId: "650",
                        firstName: "Kameron",
                        lastName: "Dorsey",
                        jobTitle: "Sales / UK Manager-II",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "649",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "652",
                    firstName: "Braelyn",
                    lastName: "Page",
                    jobTitle: "Sales / UK Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / UK",
                    managerEmployeeId: "639",
                    reports: [
                      {
                        employeeId: "655",
                        firstName: "Layne",
                        lastName: "Thompson",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "652",
                        reports: []
                      },
                      {
                        employeeId: "654",
                        firstName: "Sariah",
                        lastName: "Clark",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "652",
                        reports: []
                      },
                      {
                        employeeId: "653",
                        firstName: "Priscilla",
                        lastName: "Norman",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "652",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "646",
                    firstName: "Quentin",
                    lastName: "Jackson",
                    jobTitle: "Sales / UK Manager-V",
                    officeLocation: "Mountain View",
                    department: "Sales / UK",
                    managerEmployeeId: "639",
                    reports: [
                      {
                        employeeId: "647",
                        firstName: "Rohan",
                        lastName: "Shields",
                        jobTitle: "Sales / UK Manager-I",
                        officeLocation: "Mountain View",
                        department: "Sales / UK",
                        managerEmployeeId: "646",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "640",
                    firstName: "Luciana",
                    lastName: "Murray",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Mountain View",
                    department: "Sales / UK",
                    managerEmployeeId: "639",
                    reports: []
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        employeeId: "1698",
        firstName: "Sergio",
        lastName: "Shepard",
        jobTitle: "Fellow Compliance Officer",
        officeLocation: "Paris",
        department: "Compliance",
        managerEmployeeId: "1",
        reports: [
          {
            employeeId: "1787",
            firstName: "Heath",
            lastName: "Rodgers",
            jobTitle: "Public Policy Director",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1810",
                firstName: "Kathleen",
                lastName: "Waters",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1787",
                reports: []
              },
              {
                employeeId: "1788",
                firstName: "Jordan",
                lastName: "Bradshaw",
                jobTitle: "Public Policy Manager-VII",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1787",
                reports: [
                  {
                    employeeId: "1790",
                    firstName: "Duke",
                    lastName: "Cain",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1788",
                    reports: [
                      {
                        employeeId: "1791",
                        firstName: "Zain",
                        lastName: "Rocha",
                        jobTitle: "Public Policy Manager-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1790",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1796",
                    firstName: "Kinslee",
                    lastName: "Newton",
                    jobTitle: "Public Policy Manager-V",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1788",
                    reports: [
                      {
                        employeeId: "1797",
                        firstName: "Clayton",
                        lastName: "Jenkins",
                        jobTitle: "Public Policy Manager-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1796",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1802",
                    firstName: "Jayson",
                    lastName: "Pineda",
                    jobTitle: "Public Policy Manager-V",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1788",
                    reports: [
                      {
                        employeeId: "1805",
                        firstName: "Kamari",
                        lastName: "Conway",
                        jobTitle: "Public Policy Manager-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1802",
                        reports: []
                      },
                      {
                        employeeId: "1803",
                        firstName: "Darian",
                        lastName: "Hammond",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1802",
                        reports: []
                      },
                      {
                        employeeId: "1804",
                        firstName: "Elle",
                        lastName: "Patrick",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1802",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1799",
                    firstName: "Blaise",
                    lastName: "Ponce",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1788",
                    reports: [
                      {
                        employeeId: "1800",
                        firstName: "Charleigh",
                        lastName: "Peck",
                        jobTitle: "Public Policy Manager-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1799",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1789",
                    firstName: "Maurice",
                    lastName: "Glass",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1788",
                    reports: []
                  },
                  {
                    employeeId: "1793",
                    firstName: "Angelica",
                    lastName: "Ray",
                    jobTitle: "Public Policy Manager-VI",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1788",
                    reports: [
                      {
                        employeeId: "1794",
                        firstName: "Kali",
                        lastName: "Sampson",
                        jobTitle: "Public Policy Manager-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1793",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1813",
                firstName: "Eric",
                lastName: "Mays",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1787",
                reports: [
                  {
                    employeeId: "1815",
                    firstName: "Maya",
                    lastName: "Wagner",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1813",
                    reports: [
                      {
                        employeeId: "1816",
                        firstName: "Kimber",
                        lastName: "Cooley",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1815",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1814",
                    firstName: "Miles",
                    lastName: "Daniels",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1813",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1811",
                firstName: "Emery",
                lastName: "Horne",
                jobTitle: "Public Policy Manager-IV",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1787",
                reports: [
                  {
                    employeeId: "1812",
                    firstName: "Brody",
                    lastName: "Kemp",
                    jobTitle: "Compliance Officer-IV",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1811",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1809",
                firstName: "Sterling",
                lastName: "Hoffman",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1787",
                reports: []
              },
              {
                employeeId: "1808",
                firstName: "Janessa",
                lastName: "Chambers",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1787",
                reports: []
              },
              {
                employeeId: "1807",
                firstName: "Tiana",
                lastName: "English",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1787",
                reports: []
              }
            ]
          },
          {
            employeeId: "1844",
            firstName: "Mabel",
            lastName: "Underwood",
            jobTitle: "Principal Compliance Officer",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1862",
                firstName: "Stanley",
                lastName: "Johnson",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1844",
                reports: [
                  {
                    employeeId: "1864",
                    firstName: "Anna",
                    lastName: "Hicks",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1862",
                    reports: []
                  },
                  {
                    employeeId: "1863",
                    firstName: "Elian",
                    lastName: "Davies",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1862",
                    reports: []
                  },
                  {
                    employeeId: "1865",
                    firstName: "Fisher",
                    lastName: "Campos",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1862",
                    reports: [
                      {
                        employeeId: "1866",
                        firstName: "Zayden",
                        lastName: "Forbes",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1865",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1872",
                firstName: "Gibson",
                lastName: "Bowman",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1844",
                reports: [
                  {
                    employeeId: "1875",
                    firstName: "Jada",
                    lastName: "Phillips",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1872",
                    reports: []
                  },
                  {
                    employeeId: "1873",
                    firstName: "Rebekah",
                    lastName: "Barrett",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1872",
                    reports: []
                  },
                  {
                    employeeId: "1874",
                    firstName: "Legend",
                    lastName: "Aguilar",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1872",
                    reports: []
                  },
                  {
                    employeeId: "1876",
                    firstName: "Shelby",
                    lastName: "Brennan",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1872",
                    reports: [
                      {
                        employeeId: "1877",
                        firstName: "Kori",
                        lastName: "Guerrero",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1876",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1852",
                firstName: "Francis",
                lastName: "Levy",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1844",
                reports: [
                  {
                    employeeId: "1855",
                    firstName: "Darius",
                    lastName: "Ortiz",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1852",
                    reports: [
                      {
                        employeeId: "1856",
                        firstName: "Moises",
                        lastName: "Kidd",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1855",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1853",
                    firstName: "Meilani",
                    lastName: "Stephenson",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1852",
                    reports: []
                  },
                  {
                    employeeId: "1854",
                    firstName: "Beatrice",
                    lastName: "Wiggins",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1852",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1849",
                firstName: "Anna",
                lastName: "Copeland",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1844",
                reports: [
                  {
                    employeeId: "1850",
                    firstName: "Neymar",
                    lastName: "Reid",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1849",
                    reports: [
                      {
                        employeeId: "1851",
                        firstName: "Imani",
                        lastName: "Stanley",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1850",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1867",
                firstName: "Graham",
                lastName: "Lane",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1844",
                reports: [
                  {
                    employeeId: "1870",
                    firstName: "Tyson",
                    lastName: "Acevedo",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1867",
                    reports: [
                      {
                        employeeId: "1871",
                        firstName: "Kayla",
                        lastName: "Waller",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1870",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1868",
                    firstName: "Vivienne",
                    lastName: "Waller",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1867",
                    reports: []
                  },
                  {
                    employeeId: "1869",
                    firstName: "Denver",
                    lastName: "Vega",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1867",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1845",
                firstName: "Aranza",
                lastName: "Morrison",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1844",
                reports: [
                  {
                    employeeId: "1846",
                    firstName: "Skylar",
                    lastName: "Hogan",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1845",
                    reports: []
                  },
                  {
                    employeeId: "1847",
                    firstName: "Dana",
                    lastName: "Horn",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1845",
                    reports: [
                      {
                        employeeId: "1848",
                        firstName: "Henley",
                        lastName: "Guzman",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1847",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1857",
                firstName: "Malik",
                lastName: "Garner",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1844",
                reports: [
                  {
                    employeeId: "1860",
                    firstName: "Christina",
                    lastName: "Russo",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1857",
                    reports: [
                      {
                        employeeId: "1861",
                        firstName: "Reyansh",
                        lastName: "Sexton",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1860",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1859",
                    firstName: "Giselle",
                    lastName: "Costa",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1857",
                    reports: []
                  },
                  {
                    employeeId: "1858",
                    firstName: "Shiloh",
                    lastName: "Edwards",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1857",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2357",
            firstName: "Avalyn",
            lastName: "Winters",
            jobTitle: "Building Safety Director",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2380",
                firstName: "Victoria",
                lastName: "Rivas",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2357",
                reports: [
                  {
                    employeeId: "2382",
                    firstName: "Marvin",
                    lastName: "Montoya",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2380",
                    reports: []
                  },
                  {
                    employeeId: "2381",
                    firstName: "Lance",
                    lastName: "Dean",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2380",
                    reports: []
                  },
                  {
                    employeeId: "2384",
                    firstName: "Bryce",
                    lastName: "Kerr",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2380",
                    reports: [
                      {
                        employeeId: "2385",
                        firstName: "Kiley",
                        lastName: "Ward",
                        jobTitle: "Building Safety Manager-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2384",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2383",
                    firstName: "Jeremiah",
                    lastName: "Brandt",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2380",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2367",
                firstName: "Rene",
                lastName: "Morrison",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2357",
                reports: [
                  {
                    employeeId: "2368",
                    firstName: "Jayleen",
                    lastName: "Parks",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2367",
                    reports: []
                  },
                  {
                    employeeId: "2370",
                    firstName: "Dahlia",
                    lastName: "Miles",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2367",
                    reports: [
                      {
                        employeeId: "2371",
                        firstName: "Cherish",
                        lastName: "Ross",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2370",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2369",
                    firstName: "Maeve",
                    lastName: "Contreras",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2367",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2372",
                firstName: "Kaylie",
                lastName: "Byrd",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2357",
                reports: [
                  {
                    employeeId: "2374",
                    firstName: "Byron",
                    lastName: "Kelley",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2372",
                    reports: [
                      {
                        employeeId: "2375",
                        firstName: "Rolando",
                        lastName: "Luna",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2374",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2373",
                    firstName: "Spencer",
                    lastName: "Best",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2372",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2362",
                firstName: "Yadiel",
                lastName: "Benitez",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2357",
                reports: [
                  {
                    employeeId: "2364",
                    firstName: "Kelsey",
                    lastName: "Rocha",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2362",
                    reports: []
                  },
                  {
                    employeeId: "2363",
                    firstName: "Lachlan",
                    lastName: "Levine",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2362",
                    reports: []
                  },
                  {
                    employeeId: "2365",
                    firstName: "Angela",
                    lastName: "Nicholson",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2362",
                    reports: [
                      {
                        employeeId: "2366",
                        firstName: "Mya",
                        lastName: "Kennedy",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2365",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2358",
                firstName: "Estrella",
                lastName: "Marshall",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2357",
                reports: [
                  {
                    employeeId: "2360",
                    firstName: "Milena",
                    lastName: "Roberson",
                    jobTitle: "Compliance Officer-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2358",
                    reports: [
                      {
                        employeeId: "2361",
                        firstName: "Marina",
                        lastName: "Levine",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2360",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2359",
                    firstName: "Ayaan",
                    lastName: "Warren",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2358",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2376",
                firstName: "Caleb",
                lastName: "Hensley",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2357",
                reports: [
                  {
                    employeeId: "2377",
                    firstName: "Melissa",
                    lastName: "Morgan",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2376",
                    reports: []
                  },
                  {
                    employeeId: "2378",
                    firstName: "Dalary",
                    lastName: "Case",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2376",
                    reports: [
                      {
                        employeeId: "2379",
                        firstName: "Killian",
                        lastName: "Martinez",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2378",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2257",
            firstName: "Kaden",
            lastName: "Terry",
            jobTitle: "Senior Director of Public Policy",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2261",
                firstName: "Phoenix",
                lastName: "Franco",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2257",
                reports: [
                  {
                    employeeId: "2263",
                    firstName: "Van",
                    lastName: "Velazquez",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2261",
                    reports: [
                      {
                        employeeId: "2264",
                        firstName: "Kennedi",
                        lastName: "Ortiz",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2263",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2262",
                    firstName: "Blaze",
                    lastName: "Michael",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2261",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2278",
                firstName: "Bodie",
                lastName: "Mcfarland",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2257",
                reports: [
                  {
                    employeeId: "2284",
                    firstName: "Briana",
                    lastName: "Rosario",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2278",
                    reports: [
                      {
                        employeeId: "2285",
                        firstName: "Vihaan",
                        lastName: "Howe",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2284",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2283",
                    firstName: "Sariyah",
                    lastName: "Sheppard",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2278",
                    reports: []
                  },
                  {
                    employeeId: "2279",
                    firstName: "Cody",
                    lastName: "Foley",
                    jobTitle: "Public Policy Manager-V",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2278",
                    reports: [
                      {
                        employeeId: "2280",
                        firstName: "Sadie",
                        lastName: "Walker",
                        jobTitle: "Public Policy Manager-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2279",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2282",
                    firstName: "Madden",
                    lastName: "Bailey",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2278",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2274",
                firstName: "Sincere",
                lastName: "Barron",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2257",
                reports: [
                  {
                    employeeId: "2275",
                    firstName: "Emilio",
                    lastName: "Mcintyre",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2274",
                    reports: []
                  },
                  {
                    employeeId: "2276",
                    firstName: "Erin",
                    lastName: "Duke",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2274",
                    reports: [
                      {
                        employeeId: "2277",
                        firstName: "Jamison",
                        lastName: "Fritz",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2276",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2258",
                firstName: "Tinley",
                lastName: "Finley",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2257",
                reports: [
                  {
                    employeeId: "2259",
                    firstName: "Kaylynn",
                    lastName: "Carpenter",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2258",
                    reports: [
                      {
                        employeeId: "2260",
                        firstName: "Liv",
                        lastName: "Perkins",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2259",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2265",
                firstName: "Rowan",
                lastName: "Simmons",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2257",
                reports: [
                  {
                    employeeId: "2268",
                    firstName: "Francisco",
                    lastName: "Finley",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2265",
                    reports: [
                      {
                        employeeId: "2269",
                        firstName: "Cesar",
                        lastName: "James",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2268",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2267",
                    firstName: "Aubriella",
                    lastName: "Zamora",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2265",
                    reports: []
                  },
                  {
                    employeeId: "2266",
                    firstName: "Parker",
                    lastName: "Dunn",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2265",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2270",
                firstName: "Bo",
                lastName: "Long",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2257",
                reports: [
                  {
                    employeeId: "2272",
                    firstName: "Jaycee",
                    lastName: "Haynes",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2270",
                    reports: [
                      {
                        employeeId: "2273",
                        firstName: "Madison",
                        lastName: "Norman",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2272",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2271",
                    firstName: "Diana",
                    lastName: "Fernandez",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2270",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "1878",
            firstName: "Tinley",
            lastName: "Morrow",
            jobTitle: "Principal Compliance Officer",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1879",
                firstName: "Enoch",
                lastName: "Vazquez",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1878",
                reports: []
              },
              {
                employeeId: "1886",
                firstName: "Izabella",
                lastName: "Krueger",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1878",
                reports: [
                  {
                    employeeId: "1887",
                    firstName: "Ethan",
                    lastName: "Gallagher",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1886",
                    reports: []
                  },
                  {
                    employeeId: "1889",
                    firstName: "Reuben",
                    lastName: "Dickson",
                    jobTitle: "Building Safety Manager-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1886",
                    reports: [
                      {
                        employeeId: "1890",
                        firstName: "Emery",
                        lastName: "Thompson",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1889",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1888",
                    firstName: "Amia",
                    lastName: "Randolph",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1886",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1896",
                firstName: "Macy",
                lastName: "Dillon",
                jobTitle: "Building Safety Manager-VII",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1878",
                reports: [
                  {
                    employeeId: "1899",
                    firstName: "Angel",
                    lastName: "Mcpherson",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1896",
                    reports: []
                  },
                  {
                    employeeId: "1900",
                    firstName: "Marianna",
                    lastName: "Jordan",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1896",
                    reports: []
                  },
                  {
                    employeeId: "1897",
                    firstName: "Regina",
                    lastName: "Valenzuela",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1896",
                    reports: []
                  },
                  {
                    employeeId: "1901",
                    firstName: "Melany",
                    lastName: "Hampton",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1896",
                    reports: [
                      {
                        employeeId: "1902",
                        firstName: "Yareli",
                        lastName: "Hayes",
                        jobTitle: "Building Safety Manager-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1901",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1898",
                    firstName: "Galilea",
                    lastName: "Espinoza",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1896",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1891",
                firstName: "Brady",
                lastName: "Finley",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1878",
                reports: [
                  {
                    employeeId: "1893",
                    firstName: "Kristian",
                    lastName: "Ray",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1891",
                    reports: []
                  },
                  {
                    employeeId: "1894",
                    firstName: "Barrett",
                    lastName: "Todd",
                    jobTitle: "Building Safety Manager-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1891",
                    reports: [
                      {
                        employeeId: "1895",
                        firstName: "Rafael",
                        lastName: "Castro",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1894",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1892",
                    firstName: "Darius",
                    lastName: "Ramsey",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1891",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1880",
                firstName: "Arden",
                lastName: "Crawford",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1878",
                reports: [
                  {
                    employeeId: "1884",
                    firstName: "Maddox",
                    lastName: "Barnett",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1880",
                    reports: [
                      {
                        employeeId: "1885",
                        firstName: "Analia",
                        lastName: "Hunter",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1884",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1881",
                    firstName: "Kevin",
                    lastName: "Shields",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1880",
                    reports: [
                      {
                        employeeId: "1882",
                        firstName: "Vanessa",
                        lastName: "Zimmerman",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1881",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1883",
                    firstName: "Vera",
                    lastName: "Shah",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1880",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1904",
                firstName: "Bode",
                lastName: "Morrison",
                jobTitle: "Building Safety Manager-VII",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1878",
                reports: [
                  {
                    employeeId: "1908",
                    firstName: "Lacey",
                    lastName: "Leon",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1904",
                    reports: []
                  },
                  {
                    employeeId: "1905",
                    firstName: "Janelle",
                    lastName: "Lang",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1904",
                    reports: []
                  },
                  {
                    employeeId: "1907",
                    firstName: "Layla",
                    lastName: "Patterson",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1904",
                    reports: []
                  },
                  {
                    employeeId: "1909",
                    firstName: "Harmony",
                    lastName: "Flores",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1904",
                    reports: []
                  },
                  {
                    employeeId: "1906",
                    firstName: "Hayden",
                    lastName: "Dominguez",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1904",
                    reports: []
                  },
                  {
                    employeeId: "1910",
                    firstName: "Giovanni",
                    lastName: "Greer",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1904",
                    reports: [
                      {
                        employeeId: "1911",
                        firstName: "Rashad",
                        lastName: "Bruce",
                        jobTitle: "Building Safety Manager-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1910",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2193",
            firstName: "Rory",
            lastName: "Lara",
            jobTitle: "Senior Director of Building Safety",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2216",
                firstName: "Jayce",
                lastName: "Stanton",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2193",
                reports: [
                  {
                    employeeId: "2220",
                    firstName: "Stetson",
                    lastName: "Sutton",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2216",
                    reports: [
                      {
                        employeeId: "2221",
                        firstName: "Marquis",
                        lastName: "George",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2220",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2219",
                    firstName: "Lilah",
                    lastName: "Hamilton",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2216",
                    reports: []
                  },
                  {
                    employeeId: "2217",
                    firstName: "Bennett",
                    lastName: "Webb",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2216",
                    reports: []
                  },
                  {
                    employeeId: "2218",
                    firstName: "Marilyn",
                    lastName: "Dorsey",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2216",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2203",
                firstName: "Ryker",
                lastName: "Ali",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2193",
                reports: [
                  {
                    employeeId: "2204",
                    firstName: "Amaris",
                    lastName: "Mitchell",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2203",
                    reports: [
                      {
                        employeeId: "2205",
                        firstName: "Tatum",
                        lastName: "Booth",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2204",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2211",
                firstName: "Cayden",
                lastName: "Mcgee",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2193",
                reports: [
                  {
                    employeeId: "2214",
                    firstName: "Dangelo",
                    lastName: "White",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2211",
                    reports: [
                      {
                        employeeId: "2215",
                        firstName: "Juliana",
                        lastName: "Flowers",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2214",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2213",
                    firstName: "Adaline",
                    lastName: "Kaiser",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2211",
                    reports: []
                  },
                  {
                    employeeId: "2212",
                    firstName: "Sincere",
                    lastName: "Madden",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2211",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2200",
                firstName: "Kai",
                lastName: "Lindsey",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2193",
                reports: [
                  {
                    employeeId: "2201",
                    firstName: "Spencer",
                    lastName: "Dougherty",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2200",
                    reports: [
                      {
                        employeeId: "2202",
                        firstName: "Gustavo",
                        lastName: "Luna",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2201",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2197",
                firstName: "Kaitlyn",
                lastName: "Butler",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2193",
                reports: [
                  {
                    employeeId: "2198",
                    firstName: "Briley",
                    lastName: "Vazquez",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2197",
                    reports: [
                      {
                        employeeId: "2199",
                        firstName: "Andy",
                        lastName: "Burch",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2198",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2206",
                firstName: "Clay",
                lastName: "Berg",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2193",
                reports: [
                  {
                    employeeId: "2209",
                    firstName: "Marilyn",
                    lastName: "Frederick",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2206",
                    reports: [
                      {
                        employeeId: "2210",
                        firstName: "Sara",
                        lastName: "Moore",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2209",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2208",
                    firstName: "Lizbeth",
                    lastName: "Brennan",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2206",
                    reports: []
                  },
                  {
                    employeeId: "2207",
                    firstName: "Marquis",
                    lastName: "Galloway",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2206",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2194",
                firstName: "Jace",
                lastName: "Arroyo",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2193",
                reports: [
                  {
                    employeeId: "2195",
                    firstName: "Coleman",
                    lastName: "Lindsey",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2194",
                    reports: [
                      {
                        employeeId: "2196",
                        firstName: "Adriana",
                        lastName: "Wilkins",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2195",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2075",
            firstName: "Isabel",
            lastName: "Leon",
            jobTitle: "Distinguished Compliance Officer",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2098",
                firstName: "Grace",
                lastName: "Franklin",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2075",
                reports: [
                  {
                    employeeId: "2102",
                    firstName: "Roger",
                    lastName: "Ray",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2098",
                    reports: [
                      {
                        employeeId: "2103",
                        firstName: "Legend",
                        lastName: "Parks",
                        jobTitle: "Building Safety Manager-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2102",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2100",
                    firstName: "Samara",
                    lastName: "Park",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2098",
                    reports: []
                  },
                  {
                    employeeId: "2101",
                    firstName: "Anders",
                    lastName: "Arellano",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2098",
                    reports: []
                  },
                  {
                    employeeId: "2099",
                    firstName: "Maryam",
                    lastName: "Dougherty",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2098",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2081",
                firstName: "Hassan",
                lastName: "Duarte",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2075",
                reports: [
                  {
                    employeeId: "2083",
                    firstName: "Misael",
                    lastName: "Howe",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2081",
                    reports: [
                      {
                        employeeId: "2084",
                        firstName: "Musa",
                        lastName: "Mcguire",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2083",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2082",
                    firstName: "Aranza",
                    lastName: "Hampton",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2081",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2085",
                firstName: "Miah",
                lastName: "Richmond",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2075",
                reports: [
                  {
                    employeeId: "2086",
                    firstName: "Aryan",
                    lastName: "Curtis",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2085",
                    reports: []
                  },
                  {
                    employeeId: "2087",
                    firstName: "Giselle",
                    lastName: "Villegas",
                    jobTitle: "Building Safety Manager-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2085",
                    reports: [
                      {
                        employeeId: "2088",
                        firstName: "Zaria",
                        lastName: "Hansen",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2087",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2077",
                firstName: "Lexie",
                lastName: "Freeman",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2075",
                reports: [
                  {
                    employeeId: "2078",
                    firstName: "Jaylene",
                    lastName: "Weeks",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2077",
                    reports: []
                  },
                  {
                    employeeId: "2079",
                    firstName: "Ronan",
                    lastName: "King",
                    jobTitle: "Building Safety Manager-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2077",
                    reports: [
                      {
                        employeeId: "2080",
                        firstName: "Amiya",
                        lastName: "Gibson",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2079",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2089",
                firstName: "Harlow",
                lastName: "Colon",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2075",
                reports: [
                  {
                    employeeId: "2090",
                    firstName: "Xavier",
                    lastName: "Hopkins",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2089",
                    reports: []
                  },
                  {
                    employeeId: "2092",
                    firstName: "Karsyn",
                    lastName: "Joyce",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2089",
                    reports: [
                      {
                        employeeId: "2093",
                        firstName: "Andi",
                        lastName: "Vargas",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2092",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2091",
                    firstName: "Ella",
                    lastName: "Nixon",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2089",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2076",
                firstName: "Lilyana",
                lastName: "David",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2075",
                reports: []
              },
              {
                employeeId: "2094",
                firstName: "Zariah",
                lastName: "Wong",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2075",
                reports: [
                  {
                    employeeId: "2096",
                    firstName: "Kara",
                    lastName: "Montes",
                    jobTitle: "Building Safety Manager-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2094",
                    reports: [
                      {
                        employeeId: "2097",
                        firstName: "Ethan",
                        lastName: "Bridges",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2096",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2095",
                    firstName: "Angel",
                    lastName: "Chase",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2094",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "1973",
            firstName: "Juliet",
            lastName: "Page",
            jobTitle: "Principal Compliance Officer",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1996",
                firstName: "Mina",
                lastName: "Ortiz",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1973",
                reports: [
                  {
                    employeeId: "1998",
                    firstName: "Juelz",
                    lastName: "Pierce",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1996",
                    reports: [
                      {
                        employeeId: "1999",
                        firstName: "Marleigh",
                        lastName: "Herring",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1998",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1997",
                    firstName: "Raina",
                    lastName: "Stephens",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1996",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1991",
                firstName: "Baylor",
                lastName: "Cross",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1973",
                reports: [
                  {
                    employeeId: "1992",
                    firstName: "Angela",
                    lastName: "Camacho",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1991",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1993",
                firstName: "Cullen",
                lastName: "Browning",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1973",
                reports: [
                  {
                    employeeId: "1994",
                    firstName: "Otto",
                    lastName: "Castaneda",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1993",
                    reports: [
                      {
                        employeeId: "1995",
                        firstName: "Jimmy",
                        lastName: "Barrera",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1994",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1989",
                firstName: "Lewis",
                lastName: "Coffey",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1973",
                reports: [
                  {
                    employeeId: "1990",
                    firstName: "Sandra",
                    lastName: "Greer",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1989",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1974",
                firstName: "Melvin",
                lastName: "Mckay",
                jobTitle: "Public Policy Manager-VII",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1973",
                reports: [
                  {
                    employeeId: "1976",
                    firstName: "Brielle",
                    lastName: "Clarke",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1974",
                    reports: []
                  },
                  {
                    employeeId: "1977",
                    firstName: "Jocelynn",
                    lastName: "George",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1974",
                    reports: [
                      {
                        employeeId: "1978",
                        firstName: "Jayde",
                        lastName: "Riddle",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1977",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1975",
                    firstName: "Rene",
                    lastName: "Mcgee",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1974",
                    reports: []
                  },
                  {
                    employeeId: "1979",
                    firstName: "Dakota",
                    lastName: "Harrington",
                    jobTitle: "Public Policy Manager-IV",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1974",
                    reports: [
                      {
                        employeeId: "1980",
                        firstName: "Elliott",
                        lastName: "Bartlett",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1979",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1984",
                    firstName: "Finnley",
                    lastName: "Mueller",
                    jobTitle: "Public Policy Manager-VI",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1974",
                    reports: [
                      {
                        employeeId: "1986",
                        firstName: "Colton",
                        lastName: "Martinez",
                        jobTitle: "Public Policy Manager-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1984",
                        reports: []
                      },
                      {
                        employeeId: "1985",
                        firstName: "Luciano",
                        lastName: "Vazquez",
                        jobTitle: "Staff Compliance Officer",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1984",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1981",
                    firstName: "Camilo",
                    lastName: "Randolph",
                    jobTitle: "Public Policy Manager-V",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1974",
                    reports: [
                      {
                        employeeId: "1982",
                        firstName: "Edgar",
                        lastName: "Wheeler",
                        jobTitle: "Public Policy Manager-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1981",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1988",
                firstName: "Juan",
                lastName: "Howe",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1973",
                reports: []
              }
            ]
          },
          {
            employeeId: "2000",
            firstName: "Stetson",
            lastName: "Bird",
            jobTitle: "Senior Director of Building Safety",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2013",
                firstName: "Alivia",
                lastName: "Rogers",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2000",
                reports: [
                  {
                    employeeId: "2014",
                    firstName: "Linda",
                    lastName: "Horton",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2013",
                    reports: []
                  },
                  {
                    employeeId: "2016",
                    firstName: "Kayden",
                    lastName: "Riley",
                    jobTitle: "Compliance Officer-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2013",
                    reports: [
                      {
                        employeeId: "2017",
                        firstName: "Joselyn",
                        lastName: "Matthews",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2016",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2015",
                    firstName: "Carla",
                    lastName: "Smith",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2013",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2031",
                firstName: "Carlee",
                lastName: "Valdez",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2000",
                reports: [
                  {
                    employeeId: "2032",
                    firstName: "Jakob",
                    lastName: "Perez",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2031",
                    reports: []
                  },
                  {
                    employeeId: "2033",
                    firstName: "Edgar",
                    lastName: "Hodges",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2031",
                    reports: []
                  },
                  {
                    employeeId: "2034",
                    firstName: "Mohammed",
                    lastName: "Rivers",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2031",
                    reports: []
                  },
                  {
                    employeeId: "2035",
                    firstName: "Thatcher",
                    lastName: "Velazquez",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2031",
                    reports: [
                      {
                        employeeId: "2036",
                        firstName: "Rivka",
                        lastName: "Bauer",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2035",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2001",
                firstName: "Blaise",
                lastName: "Riddle",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2000",
                reports: [
                  {
                    employeeId: "2002",
                    firstName: "Layton",
                    lastName: "Riddle",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2001",
                    reports: []
                  },
                  {
                    employeeId: "2003",
                    firstName: "Aniya",
                    lastName: "Peck",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2001",
                    reports: [
                      {
                        employeeId: "2004",
                        firstName: "Mayson",
                        lastName: "Mahoney",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2003",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2009",
                firstName: "Jacoby",
                lastName: "Daniel",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2000",
                reports: [
                  {
                    employeeId: "2010",
                    firstName: "Amari",
                    lastName: "Ellis",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2009",
                    reports: []
                  },
                  {
                    employeeId: "2011",
                    firstName: "Karlie",
                    lastName: "Lara",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2009",
                    reports: [
                      {
                        employeeId: "2012",
                        firstName: "Emerson",
                        lastName: "Rodriguez",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2011",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2024",
                firstName: "Victor",
                lastName: "Petty",
                jobTitle: "Building Safety Manager-VII",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2000",
                reports: [
                  {
                    employeeId: "2027",
                    firstName: "Maddox",
                    lastName: "Christensen",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2024",
                    reports: []
                  },
                  {
                    employeeId: "2025",
                    firstName: "Alexandria",
                    lastName: "Stuart",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2024",
                    reports: []
                  },
                  {
                    employeeId: "2029",
                    firstName: "Kyle",
                    lastName: "Stout",
                    jobTitle: "Building Safety Manager-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2024",
                    reports: [
                      {
                        employeeId: "2030",
                        firstName: "Walker",
                        lastName: "Noble",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2029",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2026",
                    firstName: "Gia",
                    lastName: "Bean",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2024",
                    reports: []
                  },
                  {
                    employeeId: "2028",
                    firstName: "Emma",
                    lastName: "Knapp",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2024",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2005",
                firstName: "Kaya",
                lastName: "Wilkinson",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2000",
                reports: [
                  {
                    employeeId: "2007",
                    firstName: "Aubrielle",
                    lastName: "Ruiz",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2005",
                    reports: [
                      {
                        employeeId: "2008",
                        firstName: "Milania",
                        lastName: "Patterson",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2007",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2006",
                    firstName: "Adalynn",
                    lastName: "Hahn",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2005",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2018",
                firstName: "Jayden",
                lastName: "Villanueva",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2000",
                reports: [
                  {
                    employeeId: "2020",
                    firstName: "Kristina",
                    lastName: "Dorsey",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2018",
                    reports: []
                  },
                  {
                    employeeId: "2021",
                    firstName: "Drew",
                    lastName: "Branch",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2018",
                    reports: []
                  },
                  {
                    employeeId: "2019",
                    firstName: "Natalie",
                    lastName: "Trujillo",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2018",
                    reports: []
                  },
                  {
                    employeeId: "2022",
                    firstName: "Astrid",
                    lastName: "Charles",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2018",
                    reports: [
                      {
                        employeeId: "2023",
                        firstName: "Turner",
                        lastName: "Hansen",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2022",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2387",
            firstName: "Skylar",
            lastName: "Stephens",
            jobTitle: "Senior Director of Building Safety",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2403",
                firstName: "Lola",
                lastName: "Foley",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2387",
                reports: [
                  {
                    employeeId: "2405",
                    firstName: "Roselyn",
                    lastName: "Frazier",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2403",
                    reports: []
                  },
                  {
                    employeeId: "2406",
                    firstName: "Hope",
                    lastName: "Reynolds",
                    jobTitle: "Building Safety Manager-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2403",
                    reports: [
                      {
                        employeeId: "2407",
                        firstName: "Brenda",
                        lastName: "Stark",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2406",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2404",
                    firstName: "Baylee",
                    lastName: "Booker",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2403",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2388",
                firstName: "Michael",
                lastName: "Butler",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2387",
                reports: [
                  {
                    employeeId: "2389",
                    firstName: "Alan",
                    lastName: "Zhang",
                    jobTitle: "Building Safety Manager-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2388",
                    reports: [
                      {
                        employeeId: "2390",
                        firstName: "Layla",
                        lastName: "Winters",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2389",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2395",
                firstName: "Sarah",
                lastName: "David",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2387",
                reports: [
                  {
                    employeeId: "2397",
                    firstName: "Fletcher",
                    lastName: "Soto",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2395",
                    reports: [
                      {
                        employeeId: "2398",
                        firstName: "Cheyenne",
                        lastName: "Carter",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2397",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2396",
                    firstName: "Branson",
                    lastName: "Villa",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2395",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2408",
                firstName: "Frank",
                lastName: "Whitehead",
                jobTitle: "Building Safety Manager-VII",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2387",
                reports: [
                  {
                    employeeId: "2412",
                    firstName: "Olive",
                    lastName: "Perkins",
                    jobTitle: "Building Safety Manager-VI",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2408",
                    reports: [
                      {
                        employeeId: "2413",
                        firstName: "Adley",
                        lastName: "Duarte",
                        jobTitle: "Building Safety Manager-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2412",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2410",
                    firstName: "Derrick",
                    lastName: "Shaw",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2408",
                    reports: []
                  },
                  {
                    employeeId: "2411",
                    firstName: "Blake",
                    lastName: "Hays",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2408",
                    reports: []
                  },
                  {
                    employeeId: "2409",
                    firstName: "Abel",
                    lastName: "Robinson",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2408",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2391",
                firstName: "Brooks",
                lastName: "Ferguson",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2387",
                reports: [
                  {
                    employeeId: "2392",
                    firstName: "Savanna",
                    lastName: "Stuart",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2391",
                    reports: []
                  },
                  {
                    employeeId: "2393",
                    firstName: "Meilani",
                    lastName: "Rowe",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2391",
                    reports: [
                      {
                        employeeId: "2394",
                        firstName: "Kane",
                        lastName: "Bond",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2393",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2399",
                firstName: "Amirah",
                lastName: "Mckay",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2387",
                reports: [
                  {
                    employeeId: "2401",
                    firstName: "Xander",
                    lastName: "Lozano",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2399",
                    reports: [
                      {
                        employeeId: "2402",
                        firstName: "Amaris",
                        lastName: "Wyatt",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2401",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2400",
                    firstName: "Linda",
                    lastName: "Eaton",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2399",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2480",
            firstName: "Madilyn",
            lastName: "Wu",
            jobTitle: "Principal Compliance Officer",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2481",
                firstName: "Antonio",
                lastName: "Pruitt",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2480",
                reports: [
                  {
                    employeeId: "2482",
                    firstName: "Jaziel",
                    lastName: "Henson",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2481",
                    reports: []
                  },
                  {
                    employeeId: "2484",
                    firstName: "Journee",
                    lastName: "Little",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2481",
                    reports: []
                  },
                  {
                    employeeId: "2483",
                    firstName: "Aiyana",
                    lastName: "Larson",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2481",
                    reports: []
                  },
                  {
                    employeeId: "2485",
                    firstName: "Antonio",
                    lastName: "Krause",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2481",
                    reports: [
                      {
                        employeeId: "2486",
                        firstName: "Armani",
                        lastName: "Mcneil",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2485",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2505",
                firstName: "Lane",
                lastName: "Swanson",
                jobTitle: "Public Policy Manager-VII",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2480",
                reports: [
                  {
                    employeeId: "2507",
                    firstName: "Heavenly",
                    lastName: "Norris",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2505",
                    reports: []
                  },
                  {
                    employeeId: "2506",
                    firstName: "Dallas",
                    lastName: "Velez",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2505",
                    reports: []
                  },
                  {
                    employeeId: "2508",
                    firstName: "Dahlia",
                    lastName: "Calderon",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2505",
                    reports: []
                  },
                  {
                    employeeId: "2509",
                    firstName: "Marco",
                    lastName: "Bradley",
                    jobTitle: "Public Policy Manager-VI",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2505",
                    reports: [
                      {
                        employeeId: "2510",
                        firstName: "Alonso",
                        lastName: "Harrison",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2509",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2487",
                firstName: "Piper",
                lastName: "Costa",
                jobTitle: "Public Policy Manager-VII",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2480",
                reports: [
                  {
                    employeeId: "2492",
                    firstName: "Curtis",
                    lastName: "Cummings",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2487",
                    reports: []
                  },
                  {
                    employeeId: "2488",
                    firstName: "Antoine",
                    lastName: "Cain",
                    jobTitle: "Public Policy Manager-VI",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2487",
                    reports: [
                      {
                        employeeId: "2490",
                        firstName: "Leandro",
                        lastName: "Flores",
                        jobTitle: "Public Policy Manager-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2488",
                        reports: []
                      },
                      {
                        employeeId: "2489",
                        firstName: "Harlan",
                        lastName: "Reid",
                        jobTitle: "Staff Compliance Officer",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2488",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2494",
                    firstName: "Lilia",
                    lastName: "Torres",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2487",
                    reports: [
                      {
                        employeeId: "2495",
                        firstName: "Luke",
                        lastName: "Levine",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2494",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2493",
                    firstName: "Malakai",
                    lastName: "Adkins",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2487",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2496",
                firstName: "Desmond",
                lastName: "Villarreal",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2480",
                reports: [
                  {
                    employeeId: "2502",
                    firstName: "Melissa",
                    lastName: "Robinson",
                    jobTitle: "Public Policy Manager-V",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2496",
                    reports: [
                      {
                        employeeId: "2503",
                        firstName: "Joe",
                        lastName: "Palmer",
                        jobTitle: "Public Policy Manager-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2502",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2500",
                    firstName: "Marianna",
                    lastName: "Cabrera",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2496",
                    reports: []
                  },
                  {
                    employeeId: "2498",
                    firstName: "Marleigh",
                    lastName: "Gay",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2496",
                    reports: []
                  },
                  {
                    employeeId: "2497",
                    firstName: "Drew",
                    lastName: "Lester",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2496",
                    reports: []
                  },
                  {
                    employeeId: "2501",
                    firstName: "Braylen",
                    lastName: "Yoder",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2496",
                    reports: []
                  },
                  {
                    employeeId: "2499",
                    firstName: "Valentina",
                    lastName: "Baldwin",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2496",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2512",
                firstName: "Abigail",
                lastName: "Foster",
                jobTitle: "Public Policy Manager-VII",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2480",
                reports: [
                  {
                    employeeId: "2520",
                    firstName: "Major",
                    lastName: "Hawkins",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2512",
                    reports: []
                  },
                  {
                    employeeId: "2519",
                    firstName: "Nova",
                    lastName: "Williamson",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2512",
                    reports: []
                  },
                  {
                    employeeId: "2521",
                    firstName: "Amy",
                    lastName: "Calderon",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2512",
                    reports: [
                      {
                        employeeId: "2522",
                        firstName: "Keith",
                        lastName: "Villegas",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2521",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2513",
                    firstName: "Brooklyn",
                    lastName: "Hopkins",
                    jobTitle: "Public Policy Manager-V",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2512",
                    reports: [
                      {
                        employeeId: "2516",
                        firstName: "Cory",
                        lastName: "Sandoval",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2513",
                        reports: []
                      },
                      {
                        employeeId: "2517",
                        firstName: "Magnolia",
                        lastName: "Jimenez",
                        jobTitle: "Public Policy Manager-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2513",
                        reports: []
                      },
                      {
                        employeeId: "2514",
                        firstName: "Aliza",
                        lastName: "Solomon",
                        jobTitle: "Public Policy Manager-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2513",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "1761",
            firstName: "Davin",
            lastName: "Haas",
            jobTitle: "Public Policy Manager-VII",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1781",
                firstName: "Wilder",
                lastName: "Wang",
                jobTitle: "Public Policy Manager-IV",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1761",
                reports: [
                  {
                    employeeId: "1782",
                    firstName: "Madelyn",
                    lastName: "Salas",
                    jobTitle: "Compliance Officer-IV",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1781",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1779",
                firstName: "Benjamin",
                lastName: "Maldonado",
                jobTitle: "Compliance Officer-IV",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1761",
                reports: [
                  {
                    employeeId: "1780",
                    firstName: "Abby",
                    lastName: "Paul",
                    jobTitle: "Compliance Officer-IV",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1779",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1775",
                firstName: "Ezra",
                lastName: "Buckley",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1761",
                reports: []
              },
              {
                employeeId: "1783",
                firstName: "Madelynn",
                lastName: "Allison",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1761",
                reports: [
                  {
                    employeeId: "1784",
                    firstName: "Adam",
                    lastName: "Cantrell",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1783",
                    reports: []
                  },
                  {
                    employeeId: "1785",
                    firstName: "Kayla",
                    lastName: "Colon",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1783",
                    reports: [
                      {
                        employeeId: "1786",
                        firstName: "Dean",
                        lastName: "Mccarthy",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1785",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1774",
                firstName: "Nathaniel",
                lastName: "Campos",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1761",
                reports: []
              },
              {
                employeeId: "1776",
                firstName: "Rory",
                lastName: "Jefferson",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1761",
                reports: []
              },
              {
                employeeId: "1777",
                firstName: "Tristan",
                lastName: "Ibarra",
                jobTitle: "Public Policy Manager-IV",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1761",
                reports: [
                  {
                    employeeId: "1778",
                    firstName: "Jessica",
                    lastName: "Montes",
                    jobTitle: "Compliance Officer-IV",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1777",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1762",
                firstName: "Nicole",
                lastName: "Orr",
                jobTitle: "Public Policy Manager-VII",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1761",
                reports: [
                  {
                    employeeId: "1763",
                    firstName: "Lina",
                    lastName: "Robertson",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1762",
                    reports: []
                  },
                  {
                    employeeId: "1764",
                    firstName: "Luka",
                    lastName: "Lawson",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1762",
                    reports: []
                  },
                  {
                    employeeId: "1769",
                    firstName: "Josiah",
                    lastName: "Kline",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1762",
                    reports: [
                      {
                        employeeId: "1770",
                        firstName: "Elliott",
                        lastName: "Webb",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1769",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1766",
                    firstName: "Jade",
                    lastName: "Harrison",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1762",
                    reports: []
                  },
                  {
                    employeeId: "1767",
                    firstName: "Milani",
                    lastName: "Haley",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1762",
                    reports: []
                  },
                  {
                    employeeId: "1765",
                    firstName: "Jamari",
                    lastName: "Martinez",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1762",
                    reports: []
                  },
                  {
                    employeeId: "1768",
                    firstName: "Brice",
                    lastName: "Villa",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1762",
                    reports: []
                  },
                  {
                    employeeId: "1771",
                    firstName: "Arjun",
                    lastName: "Bradley",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1762",
                    reports: [
                      {
                        employeeId: "1772",
                        firstName: "Ariel",
                        lastName: "Goodwin",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1771",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2222",
            firstName: "Shelby",
            lastName: "Irwin",
            jobTitle: "Senior Director of Public Policy",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2231",
                firstName: "Aminah",
                lastName: "Oconnor",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2222",
                reports: [
                  {
                    employeeId: "2232",
                    firstName: "Brinley",
                    lastName: "Guerrero",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2231",
                    reports: []
                  },
                  {
                    employeeId: "2233",
                    firstName: "Barrett",
                    lastName: "Pace",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2231",
                    reports: [
                      {
                        employeeId: "2234",
                        firstName: "Yasmin",
                        lastName: "Cline",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2233",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2240",
                firstName: "Tristan",
                lastName: "Sloan",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2222",
                reports: [
                  {
                    employeeId: "2241",
                    firstName: "Alani",
                    lastName: "Landry",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2240",
                    reports: []
                  },
                  {
                    employeeId: "2242",
                    firstName: "Louis",
                    lastName: "Lindsey",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2240",
                    reports: [
                      {
                        employeeId: "2243",
                        firstName: "Kannon",
                        lastName: "David",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2242",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2224",
                firstName: "Kenya",
                lastName: "Landry",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2222",
                reports: [
                  {
                    employeeId: "2225",
                    firstName: "Violet",
                    lastName: "Pollard",
                    jobTitle: "Compliance Officer-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2224",
                    reports: [
                      {
                        employeeId: "2226",
                        firstName: "Kendrick",
                        lastName: "Larsen",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2225",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2244",
                firstName: "Helena",
                lastName: "Garrison",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2222",
                reports: [
                  {
                    employeeId: "2247",
                    firstName: "Carla",
                    lastName: "Moody",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2244",
                    reports: []
                  },
                  {
                    employeeId: "2248",
                    firstName: "Caroline",
                    lastName: "Villegas",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2244",
                    reports: [
                      {
                        employeeId: "2249",
                        firstName: "Westin",
                        lastName: "Benson",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2248",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2246",
                    firstName: "Laurel",
                    lastName: "Brandt",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2244",
                    reports: []
                  },
                  {
                    employeeId: "2245",
                    firstName: "Aldo",
                    lastName: "Lucero",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2244",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2223",
                firstName: "Drake",
                lastName: "Ray",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2222",
                reports: []
              },
              {
                employeeId: "2227",
                firstName: "Kaitlyn",
                lastName: "Lang",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2222",
                reports: [
                  {
                    employeeId: "2229",
                    firstName: "Yareli",
                    lastName: "Rowe",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2227",
                    reports: [
                      {
                        employeeId: "2230",
                        firstName: "Dylan",
                        lastName: "Barrera",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2229",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2228",
                    firstName: "Naya",
                    lastName: "Brandt",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2227",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2235",
                firstName: "Darian",
                lastName: "Donovan",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2222",
                reports: [
                  {
                    employeeId: "2237",
                    firstName: "Skyla",
                    lastName: "Orozco",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2235",
                    reports: []
                  },
                  {
                    employeeId: "2236",
                    firstName: "Aaliyah",
                    lastName: "Chung",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2235",
                    reports: []
                  },
                  {
                    employeeId: "2238",
                    firstName: "Shaun",
                    lastName: "Brown",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2235",
                    reports: [
                      {
                        employeeId: "2239",
                        firstName: "Valentino",
                        lastName: "Glover",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2238",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2250",
                firstName: "Maximilian",
                lastName: "Osborn",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2222",
                reports: [
                  {
                    employeeId: "2252",
                    firstName: "Maeve",
                    lastName: "Wu",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2250",
                    reports: []
                  },
                  {
                    employeeId: "2253",
                    firstName: "Augustus",
                    lastName: "Montoya",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2250",
                    reports: []
                  },
                  {
                    employeeId: "2254",
                    firstName: "Rosie",
                    lastName: "Sanders",
                    jobTitle: "Public Policy Manager-V",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2250",
                    reports: [
                      {
                        employeeId: "2255",
                        firstName: "Keagan",
                        lastName: "Rios",
                        jobTitle: "Public Policy Manager-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2254",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2251",
                    firstName: "Messiah",
                    lastName: "Parker",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2250",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2105",
            firstName: "Brantley",
            lastName: "Sullivan",
            jobTitle: "Distinguished Compliance Officer",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2125",
                firstName: "Bowen",
                lastName: "Weiss",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2105",
                reports: [
                  {
                    employeeId: "2127",
                    firstName: "Kamila",
                    lastName: "Morgan",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2125",
                    reports: [
                      {
                        employeeId: "2128",
                        firstName: "Tori",
                        lastName: "Greene",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2127",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2126",
                    firstName: "Damari",
                    lastName: "Blevins",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2125",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2113",
                firstName: "Jensen",
                lastName: "Dyer",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2105",
                reports: [
                  {
                    employeeId: "2114",
                    firstName: "Rhea",
                    lastName: "Douglas",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2113",
                    reports: []
                  },
                  {
                    employeeId: "2115",
                    firstName: "Jayden",
                    lastName: "Oliver",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2113",
                    reports: [
                      {
                        employeeId: "2116",
                        firstName: "Calvin",
                        lastName: "Decker",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2115",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2121",
                firstName: "Marley",
                lastName: "Thompson",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2105",
                reports: [
                  {
                    employeeId: "2123",
                    firstName: "Amari",
                    lastName: "Henson",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2121",
                    reports: [
                      {
                        employeeId: "2124",
                        firstName: "Francisco",
                        lastName: "Ferrell",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2123",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2122",
                    firstName: "Cara",
                    lastName: "Barajas",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2121",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2129",
                firstName: "Shiloh",
                lastName: "Farley",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2105",
                reports: [
                  {
                    employeeId: "2132",
                    firstName: "Patrick",
                    lastName: "Oneill",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2129",
                    reports: []
                  },
                  {
                    employeeId: "2133",
                    firstName: "Isabela",
                    lastName: "Becker",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2129",
                    reports: []
                  },
                  {
                    employeeId: "2131",
                    firstName: "Aiden",
                    lastName: "Lawson",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2129",
                    reports: []
                  },
                  {
                    employeeId: "2134",
                    firstName: "Rosie",
                    lastName: "Burns",
                    jobTitle: "Public Policy Manager-VI",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2129",
                    reports: [
                      {
                        employeeId: "2135",
                        firstName: "Maximilian",
                        lastName: "Parks",
                        jobTitle: "Public Policy Manager-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2134",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2130",
                    firstName: "Kyler",
                    lastName: "Flores",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2129",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2117",
                firstName: "Lisa",
                lastName: "Hancock",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2105",
                reports: [
                  {
                    employeeId: "2119",
                    firstName: "Libby",
                    lastName: "Reese",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2117",
                    reports: [
                      {
                        employeeId: "2120",
                        firstName: "Tyson",
                        lastName: "Noble",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2119",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2118",
                    firstName: "Tegan",
                    lastName: "Duffy",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2117",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2106",
                firstName: "Neil",
                lastName: "Lawson",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2105",
                reports: [
                  {
                    employeeId: "2107",
                    firstName: "Eliseo",
                    lastName: "Raymond",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2106",
                    reports: [
                      {
                        employeeId: "2108",
                        firstName: "Augustus",
                        lastName: "Holloway",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2107",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2109",
                firstName: "Sky",
                lastName: "Cherry",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2105",
                reports: [
                  {
                    employeeId: "2110",
                    firstName: "Miguel",
                    lastName: "Collins",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2109",
                    reports: []
                  },
                  {
                    employeeId: "2111",
                    firstName: "Marianna",
                    lastName: "Collier",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2109",
                    reports: [
                      {
                        employeeId: "2112",
                        firstName: "Annalise",
                        lastName: "Friedman",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2111",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "1913",
            firstName: "Gabrielle",
            lastName: "Whitehead",
            jobTitle: "Building Safety Director",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1920",
                firstName: "Elaine",
                lastName: "Henderson",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1913",
                reports: [
                  {
                    employeeId: "1922",
                    firstName: "Azaria",
                    lastName: "Olsen",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1920",
                    reports: []
                  },
                  {
                    employeeId: "1923",
                    firstName: "Rylan",
                    lastName: "Rowe",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1920",
                    reports: [
                      {
                        employeeId: "1924",
                        firstName: "Rolando",
                        lastName: "Melton",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1923",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1921",
                    firstName: "Layla",
                    lastName: "Rivera",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1920",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1915",
                firstName: "Selena",
                lastName: "Graham",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1913",
                reports: [
                  {
                    employeeId: "1917",
                    firstName: "Nasir",
                    lastName: "Murray",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1915",
                    reports: []
                  },
                  {
                    employeeId: "1918",
                    firstName: "Zoe",
                    lastName: "Schmitt",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1915",
                    reports: [
                      {
                        employeeId: "1919",
                        firstName: "Katherine",
                        lastName: "Morris",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1918",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1916",
                    firstName: "Milani",
                    lastName: "Travis",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1915",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1939",
                firstName: "Sebastian",
                lastName: "Pacheco",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1913",
                reports: [
                  {
                    employeeId: "1944",
                    firstName: "Kylen",
                    lastName: "Shelton",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1939",
                    reports: []
                  },
                  {
                    employeeId: "1943",
                    firstName: "Gustavo",
                    lastName: "Russell",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1939",
                    reports: []
                  },
                  {
                    employeeId: "1942",
                    firstName: "Jamir",
                    lastName: "Mcdaniel",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1939",
                    reports: []
                  },
                  {
                    employeeId: "1941",
                    firstName: "Ismael",
                    lastName: "Klein",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1939",
                    reports: []
                  },
                  {
                    employeeId: "1945",
                    firstName: "Javier",
                    lastName: "Evans",
                    jobTitle: "Building Safety Manager-VI",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1939",
                    reports: [
                      {
                        employeeId: "1946",
                        firstName: "Daphne",
                        lastName: "Morse",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1945",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1940",
                    firstName: "Jolene",
                    lastName: "Mccann",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1939",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1925",
                firstName: "Hugo",
                lastName: "Delacruz",
                jobTitle: "Building Safety Manager-VII",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1913",
                reports: [
                  {
                    employeeId: "1929",
                    firstName: "Francis",
                    lastName: "Cooke",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1925",
                    reports: []
                  },
                  {
                    employeeId: "1930",
                    firstName: "Kane",
                    lastName: "Flores",
                    jobTitle: "Building Safety Manager-VI",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1925",
                    reports: [
                      {
                        employeeId: "1931",
                        firstName: "Kamron",
                        lastName: "Berger",
                        jobTitle: "Building Safety Manager-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1930",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1928",
                    firstName: "Brendan",
                    lastName: "York",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1925",
                    reports: []
                  },
                  {
                    employeeId: "1926",
                    firstName: "Allen",
                    lastName: "Sharp",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1925",
                    reports: []
                  },
                  {
                    employeeId: "1927",
                    firstName: "Tomas",
                    lastName: "Mcdaniel",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1925",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1914",
                firstName: "Dixie",
                lastName: "Khan",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1913",
                reports: []
              },
              {
                employeeId: "1933",
                firstName: "Emerie",
                lastName: "Ruiz",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1913",
                reports: [
                  {
                    employeeId: "1937",
                    firstName: "Rhys",
                    lastName: "Valenzuela",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1933",
                    reports: [
                      {
                        employeeId: "1938",
                        firstName: "Emilia",
                        lastName: "Pace",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1937",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1936",
                    firstName: "Amelia",
                    lastName: "Conrad",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1933",
                    reports: []
                  },
                  {
                    employeeId: "1935",
                    firstName: "Morgan",
                    lastName: "Stanton",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1933",
                    reports: []
                  },
                  {
                    employeeId: "1934",
                    firstName: "Urijah",
                    lastName: "Roberts",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1933",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2162",
            firstName: "Arianna",
            lastName: "Shepard",
            jobTitle: "Building Safety Director",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2180",
                firstName: "Jaziel",
                lastName: "Rich",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2162",
                reports: [
                  {
                    employeeId: "2183",
                    firstName: "Rosa",
                    lastName: "Hull",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2180",
                    reports: [
                      {
                        employeeId: "2184",
                        firstName: "Averie",
                        lastName: "Velasquez",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2183",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2182",
                    firstName: "Marvin",
                    lastName: "Blackwell",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2180",
                    reports: []
                  },
                  {
                    employeeId: "2181",
                    firstName: "Brett",
                    lastName: "Warner",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2180",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2167",
                firstName: "Beckett",
                lastName: "Reed",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2162",
                reports: [
                  {
                    employeeId: "2170",
                    firstName: "Amya",
                    lastName: "Blackburn",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2167",
                    reports: [
                      {
                        employeeId: "2171",
                        firstName: "Aryan",
                        lastName: "Robertson",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2170",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2169",
                    firstName: "Vicente",
                    lastName: "Sharp",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2167",
                    reports: []
                  },
                  {
                    employeeId: "2168",
                    firstName: "Ernesto",
                    lastName: "Patrick",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2167",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2172",
                firstName: "Ana",
                lastName: "Durham",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2162",
                reports: [
                  {
                    employeeId: "2174",
                    firstName: "Monroe",
                    lastName: "Fitzgerald",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2172",
                    reports: [
                      {
                        employeeId: "2175",
                        firstName: "Tate",
                        lastName: "Holden",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2174",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2173",
                    firstName: "Samson",
                    lastName: "Atkinson",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2172",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2163",
                firstName: "Amara",
                lastName: "Mcneil",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2162",
                reports: [
                  {
                    employeeId: "2164",
                    firstName: "Thomas",
                    lastName: "Arias",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2163",
                    reports: []
                  },
                  {
                    employeeId: "2165",
                    firstName: "Theo",
                    lastName: "Wade",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2163",
                    reports: [
                      {
                        employeeId: "2166",
                        firstName: "Davion",
                        lastName: "Buckley",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2165",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2176",
                firstName: "Kase",
                lastName: "Arroyo",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2162",
                reports: [
                  {
                    employeeId: "2178",
                    firstName: "Kyree",
                    lastName: "Wilkins",
                    jobTitle: "Compliance Officer-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2176",
                    reports: [
                      {
                        employeeId: "2179",
                        firstName: "Kenzie",
                        lastName: "Velazquez",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2178",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2177",
                    firstName: "Callie",
                    lastName: "Potts",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2176",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2185",
                firstName: "Aydan",
                lastName: "Cohen",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2162",
                reports: [
                  {
                    employeeId: "2191",
                    firstName: "Aron",
                    lastName: "Powell",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2185",
                    reports: [
                      {
                        employeeId: "2192",
                        firstName: "Kalani",
                        lastName: "Clarke",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2191",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2190",
                    firstName: "Emery",
                    lastName: "Knight",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2185",
                    reports: []
                  },
                  {
                    employeeId: "2186",
                    firstName: "Alaina",
                    lastName: "Green",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2185",
                    reports: [
                      {
                        employeeId: "2187",
                        firstName: "Nina",
                        lastName: "Velez",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2186",
                        reports: []
                      },
                      {
                        employeeId: "2188",
                        firstName: "Joe",
                        lastName: "Tucker",
                        jobTitle: "Building Safety Manager-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2186",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "1732",
            firstName: "Adley",
            lastName: "Sosa",
            jobTitle: "Senior Director of Building Safety",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1748",
                firstName: "Tyler",
                lastName: "Morton",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1732",
                reports: []
              },
              {
                employeeId: "1751",
                firstName: "Alex",
                lastName: "Wilkerson",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1732",
                reports: [
                  {
                    employeeId: "1752",
                    firstName: "Issac",
                    lastName: "Joyce",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1751",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1749",
                firstName: "Leighton",
                lastName: "Jackson",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1732",
                reports: []
              },
              {
                employeeId: "1757",
                firstName: "Meilani",
                lastName: "Herrera",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1732",
                reports: [
                  {
                    employeeId: "1759",
                    firstName: "Allan",
                    lastName: "Hanson",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1757",
                    reports: [
                      {
                        employeeId: "1760",
                        firstName: "Itzel",
                        lastName: "Cherry",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1759",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1758",
                    firstName: "Elsa",
                    lastName: "Townsend",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1757",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1733",
                firstName: "Liberty",
                lastName: "Hogan",
                jobTitle: "Building Safety Director",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1732",
                reports: [
                  {
                    employeeId: "1741",
                    firstName: "Carson",
                    lastName: "Hanna",
                    jobTitle: "Building Safety Manager-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1733",
                    reports: [
                      {
                        employeeId: "1742",
                        firstName: "Hamza",
                        lastName: "Lopez",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1741",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1735",
                    firstName: "Bennett",
                    lastName: "Kirk",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1733",
                    reports: []
                  },
                  {
                    employeeId: "1739",
                    firstName: "Heidi",
                    lastName: "Tyler",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1733",
                    reports: [
                      {
                        employeeId: "1740",
                        firstName: "Amanda",
                        lastName: "Cuevas",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1739",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1734",
                    firstName: "Beckham",
                    lastName: "Smith",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1733",
                    reports: []
                  },
                  {
                    employeeId: "1736",
                    firstName: "Lilly",
                    lastName: "Cordova",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1733",
                    reports: []
                  },
                  {
                    employeeId: "1737",
                    firstName: "Lina",
                    lastName: "Delgado",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1733",
                    reports: [
                      {
                        employeeId: "1738",
                        firstName: "Noel",
                        lastName: "Bishop",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1737",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1743",
                    firstName: "Jessa",
                    lastName: "Key",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1733",
                    reports: [
                      {
                        employeeId: "1744",
                        firstName: "Isabel",
                        lastName: "Phillips",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1743",
                        reports: []
                      },
                      {
                        employeeId: "1746",
                        firstName: "Ann",
                        lastName: "Hopkins",
                        jobTitle: "Building Safety Manager-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1743",
                        reports: []
                      },
                      {
                        employeeId: "1745",
                        firstName: "Mckinley",
                        lastName: "Koch",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1743",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1753",
                firstName: "Braydon",
                lastName: "Patel",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1732",
                reports: [
                  {
                    employeeId: "1754",
                    firstName: "Eliseo",
                    lastName: "Curtis",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1753",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1755",
                firstName: "Jennifer",
                lastName: "Stein",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1732",
                reports: [
                  {
                    employeeId: "1756",
                    firstName: "Forrest",
                    lastName: "Odom",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1755",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1750",
                firstName: "Duncan",
                lastName: "Sexton",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1732",
                reports: []
              }
            ]
          },
          {
            employeeId: "1817",
            firstName: "Alexzander",
            lastName: "Sanders",
            jobTitle: "Public Policy Director",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1838",
                firstName: "Bonnie",
                lastName: "Cuevas",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1817",
                reports: [
                  {
                    employeeId: "1842",
                    firstName: "Zuri",
                    lastName: "Gilmore",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1838",
                    reports: [
                      {
                        employeeId: "1843",
                        firstName: "Aya",
                        lastName: "Arellano",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1842",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1839",
                    firstName: "Casey",
                    lastName: "Sharp",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1838",
                    reports: []
                  },
                  {
                    employeeId: "1840",
                    firstName: "Nyla",
                    lastName: "Robinson",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1838",
                    reports: []
                  },
                  {
                    employeeId: "1841",
                    firstName: "Wyatt",
                    lastName: "Hanna",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1838",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1826",
                firstName: "Miles",
                lastName: "Mathis",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1817",
                reports: [
                  {
                    employeeId: "1827",
                    firstName: "Braxton",
                    lastName: "Figueroa",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1826",
                    reports: []
                  },
                  {
                    employeeId: "1828",
                    firstName: "Orion",
                    lastName: "Bonilla",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1826",
                    reports: [
                      {
                        employeeId: "1829",
                        firstName: "Moses",
                        lastName: "Wells",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1828",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1830",
                firstName: "Ellis",
                lastName: "Watkins",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1817",
                reports: [
                  {
                    employeeId: "1832",
                    firstName: "Todd",
                    lastName: "Combs",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1830",
                    reports: [
                      {
                        employeeId: "1833",
                        firstName: "Jaelynn",
                        lastName: "Gross",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1832",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1831",
                    firstName: "Terrance",
                    lastName: "Roach",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1830",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1819",
                firstName: "Cristopher",
                lastName: "Frazier",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1817",
                reports: [
                  {
                    employeeId: "1820",
                    firstName: "Annabelle",
                    lastName: "Long",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1819",
                    reports: []
                  },
                  {
                    employeeId: "1821",
                    firstName: "Ellen",
                    lastName: "Ashley",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1819",
                    reports: [
                      {
                        employeeId: "1822",
                        firstName: "Yahir",
                        lastName: "Vaughn",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1821",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1834",
                firstName: "Moses",
                lastName: "Wu",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1817",
                reports: [
                  {
                    employeeId: "1836",
                    firstName: "Gloria",
                    lastName: "Phillips",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1834",
                    reports: [
                      {
                        employeeId: "1837",
                        firstName: "Desiree",
                        lastName: "Olson",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1836",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1835",
                    firstName: "Adrianna",
                    lastName: "Bennett",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1834",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1823",
                firstName: "Albert",
                lastName: "Buckley",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1817",
                reports: [
                  {
                    employeeId: "1824",
                    firstName: "Eddie",
                    lastName: "Vance",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "1823",
                    reports: [
                      {
                        employeeId: "1825",
                        firstName: "Kailey",
                        lastName: "Sawyer",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "1824",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1818",
                firstName: "Amare",
                lastName: "Nunez",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "1817",
                reports: []
              }
            ]
          },
          {
            employeeId: "1699",
            firstName: "Salvatore",
            lastName: "Murillo",
            jobTitle: "Senior Director of Building Safety",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1718",
                firstName: "Edith",
                lastName: "Hubbard",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1699",
                reports: []
              },
              {
                employeeId: "1725",
                firstName: "Aspen",
                lastName: "Cross",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1699",
                reports: [
                  {
                    employeeId: "1726",
                    firstName: "Aliza",
                    lastName: "Russo",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1725",
                    reports: [
                      {
                        employeeId: "1727",
                        firstName: "Reyna",
                        lastName: "Livingston",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1726",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1723",
                firstName: "Kason",
                lastName: "Le",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1699",
                reports: [
                  {
                    employeeId: "1724",
                    firstName: "Kaelynn",
                    lastName: "Raymond",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1723",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1700",
                firstName: "Justus",
                lastName: "Wagner",
                jobTitle: "Building Safety Director",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1699",
                reports: [
                  {
                    employeeId: "1702",
                    firstName: "Ryland",
                    lastName: "Rhodes",
                    jobTitle: "Compliance Officer-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1700",
                    reports: [
                      {
                        employeeId: "1703",
                        firstName: "Blake",
                        lastName: "Parrish",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1702",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1701",
                    firstName: "Maddox",
                    lastName: "Johnson",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1700",
                    reports: []
                  },
                  {
                    employeeId: "1706",
                    firstName: "Gia",
                    lastName: "Woodard",
                    jobTitle: "Building Safety Manager-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1700",
                    reports: [
                      {
                        employeeId: "1707",
                        firstName: "Natalee",
                        lastName: "Floyd",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1706",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1710",
                    firstName: "Harvey",
                    lastName: "Ali",
                    jobTitle: "Building Safety Manager-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1700",
                    reports: [
                      {
                        employeeId: "1711",
                        firstName: "Trevor",
                        lastName: "Mullins",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1710",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1712",
                    firstName: "Kaitlyn",
                    lastName: "David",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1700",
                    reports: [
                      {
                        employeeId: "1714",
                        firstName: "Marleigh",
                        lastName: "Oliver",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1712",
                        reports: []
                      },
                      {
                        employeeId: "1715",
                        firstName: "Eddie",
                        lastName: "Lucas",
                        jobTitle: "Building Safety Manager-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1712",
                        reports: []
                      },
                      {
                        employeeId: "1713",
                        firstName: "Kyler",
                        lastName: "Mcintosh",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1712",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1708",
                    firstName: "Jayson",
                    lastName: "Decker",
                    jobTitle: "Building Safety Manager-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1700",
                    reports: [
                      {
                        employeeId: "1709",
                        firstName: "Mae",
                        lastName: "Beard",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1708",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1704",
                    firstName: "Marcelo",
                    lastName: "Rosales",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1700",
                    reports: [
                      {
                        employeeId: "1705",
                        firstName: "Aisha",
                        lastName: "Bartlett",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1704",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1719",
                firstName: "Emmaline",
                lastName: "Allison",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1699",
                reports: [
                  {
                    employeeId: "1720",
                    firstName: "Crew",
                    lastName: "Lucas",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1719",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1721",
                firstName: "Kayson",
                lastName: "Williams",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1699",
                reports: [
                  {
                    employeeId: "1722",
                    firstName: "Lucy",
                    lastName: "Proctor",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1721",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "1728",
                firstName: "Finn",
                lastName: "Lozano",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1699",
                reports: [
                  {
                    employeeId: "1729",
                    firstName: "Gracelyn",
                    lastName: "Hurst",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1728",
                    reports: []
                  },
                  {
                    employeeId: "1730",
                    firstName: "Matias",
                    lastName: "Blackwell",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1728",
                    reports: [
                      {
                        employeeId: "1731",
                        firstName: "Gerardo",
                        lastName: "Wilson",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1730",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1717",
                firstName: "Emmett",
                lastName: "Rogers",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1699",
                reports: []
              }
            ]
          },
          {
            employeeId: "2325",
            firstName: "Kyson",
            lastName: "Blackburn",
            jobTitle: "Building Safety Director",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2341",
                firstName: "Stanley",
                lastName: "Krueger",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2325",
                reports: [
                  {
                    employeeId: "2342",
                    firstName: "Hailey",
                    lastName: "Greer",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2341",
                    reports: []
                  },
                  {
                    employeeId: "2343",
                    firstName: "Paul",
                    lastName: "Tapia",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2341",
                    reports: []
                  },
                  {
                    employeeId: "2344",
                    firstName: "Lauryn",
                    lastName: "Mercer",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2341",
                    reports: [
                      {
                        employeeId: "2345",
                        firstName: "Arabella",
                        lastName: "Schaefer",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2344",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2327",
                firstName: "Ireland",
                lastName: "Mcfarland",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2325",
                reports: [
                  {
                    employeeId: "2328",
                    firstName: "Belen",
                    lastName: "Sanders",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2327",
                    reports: []
                  },
                  {
                    employeeId: "2330",
                    firstName: "Xzavier",
                    lastName: "Becker",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2327",
                    reports: [
                      {
                        employeeId: "2331",
                        firstName: "Ibrahim",
                        lastName: "Buchanan",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2330",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2329",
                    firstName: "Darren",
                    lastName: "Mcintyre",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2327",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2332",
                firstName: "Rohan",
                lastName: "Nolan",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2325",
                reports: [
                  {
                    employeeId: "2334",
                    firstName: "Delilah",
                    lastName: "Vaughan",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2332",
                    reports: []
                  },
                  {
                    employeeId: "2335",
                    firstName: "Royce",
                    lastName: "Mullen",
                    jobTitle: "Compliance Officer-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2332",
                    reports: [
                      {
                        employeeId: "2336",
                        firstName: "Taya",
                        lastName: "Allison",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2335",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2333",
                    firstName: "Moises",
                    lastName: "Bradford",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2332",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2346",
                firstName: "Jaelyn",
                lastName: "Bernard",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2325",
                reports: [
                  {
                    employeeId: "2348",
                    firstName: "Yehuda",
                    lastName: "Obrien",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2346",
                    reports: [
                      {
                        employeeId: "2349",
                        firstName: "Elena",
                        lastName: "Moore",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2348",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2347",
                    firstName: "Lillian",
                    lastName: "Swanson",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2346",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2326",
                firstName: "Saniyah",
                lastName: "Bean",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2325",
                reports: []
              },
              {
                employeeId: "2337",
                firstName: "Fernanda",
                lastName: "Holden",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2325",
                reports: [
                  {
                    employeeId: "2339",
                    firstName: "Nina",
                    lastName: "Duran",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2337",
                    reports: [
                      {
                        employeeId: "2340",
                        firstName: "Giovanna",
                        lastName: "Reed",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2339",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2338",
                    firstName: "Violet",
                    lastName: "Carroll",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2337",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2350",
                firstName: "Dennis",
                lastName: "Christensen",
                jobTitle: "Building Safety Manager-VII",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2325",
                reports: [
                  {
                    employeeId: "2352",
                    firstName: "Khaleesi",
                    lastName: "Hawkins",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2350",
                    reports: []
                  },
                  {
                    employeeId: "2351",
                    firstName: "Autumn",
                    lastName: "Leonard",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2350",
                    reports: []
                  },
                  {
                    employeeId: "2353",
                    firstName: "Riaan",
                    lastName: "Kennedy",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2350",
                    reports: []
                  },
                  {
                    employeeId: "2354",
                    firstName: "Tatum",
                    lastName: "Gilmore",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2350",
                    reports: [
                      {
                        employeeId: "2355",
                        firstName: "Josie",
                        lastName: "Daugherty",
                        jobTitle: "Building Safety Manager-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2354",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2448",
            firstName: "Dominique",
            lastName: "Arellano",
            jobTitle: "Principal Compliance Officer",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2473",
                firstName: "Nathaly",
                lastName: "Howard",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2448",
                reports: [
                  {
                    employeeId: "2474",
                    firstName: "Maddison",
                    lastName: "Johnson",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2473",
                    reports: []
                  },
                  {
                    employeeId: "2477",
                    firstName: "Julio",
                    lastName: "Thomas",
                    jobTitle: "Building Safety Manager-VI",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2473",
                    reports: [
                      {
                        employeeId: "2478",
                        firstName: "Evan",
                        lastName: "Bishop",
                        jobTitle: "Building Safety Manager-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2477",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2476",
                    firstName: "Aryanna",
                    lastName: "Pineda",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2473",
                    reports: []
                  },
                  {
                    employeeId: "2475",
                    firstName: "Damari",
                    lastName: "Yang",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2473",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2449",
                firstName: "Mina",
                lastName: "Case",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2448",
                reports: [
                  {
                    employeeId: "2450",
                    firstName: "Zariyah",
                    lastName: "Arnold",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2449",
                    reports: []
                  },
                  {
                    employeeId: "2452",
                    firstName: "Charleigh",
                    lastName: "Koch",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2449",
                    reports: [
                      {
                        employeeId: "2453",
                        firstName: "London",
                        lastName: "Rojas",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2452",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2451",
                    firstName: "Helena",
                    lastName: "Solis",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2449",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2459",
                firstName: "Kendrick",
                lastName: "Todd",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2448",
                reports: [
                  {
                    employeeId: "2461",
                    firstName: "Augustus",
                    lastName: "Hale",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2459",
                    reports: []
                  },
                  {
                    employeeId: "2463",
                    firstName: "Celeste",
                    lastName: "Rivers",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2459",
                    reports: []
                  },
                  {
                    employeeId: "2462",
                    firstName: "Layton",
                    lastName: "Ware",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2459",
                    reports: []
                  },
                  {
                    employeeId: "2460",
                    firstName: "Cesar",
                    lastName: "Byrd",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2459",
                    reports: []
                  },
                  {
                    employeeId: "2464",
                    firstName: "Regina",
                    lastName: "Gilmore",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2459",
                    reports: [
                      {
                        employeeId: "2465",
                        firstName: "Brent",
                        lastName: "Gregory",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2464",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2454",
                firstName: "Aislinn",
                lastName: "Nguyen",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2448",
                reports: [
                  {
                    employeeId: "2455",
                    firstName: "Anaya",
                    lastName: "Santana",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2454",
                    reports: []
                  },
                  {
                    employeeId: "2456",
                    firstName: "Esteban",
                    lastName: "Allen",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2454",
                    reports: []
                  },
                  {
                    employeeId: "2457",
                    firstName: "Melina",
                    lastName: "Johnston",
                    jobTitle: "Building Safety Manager-III",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2454",
                    reports: [
                      {
                        employeeId: "2458",
                        firstName: "Eileen",
                        lastName: "Hicks",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2457",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2466",
                firstName: "August",
                lastName: "Oconnell",
                jobTitle: "Senior Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2448",
                reports: [
                  {
                    employeeId: "2470",
                    firstName: "Kason",
                    lastName: "Hendricks",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2466",
                    reports: []
                  },
                  {
                    employeeId: "2469",
                    firstName: "Fletcher",
                    lastName: "Wolf",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2466",
                    reports: []
                  },
                  {
                    employeeId: "2467",
                    firstName: "Ariah",
                    lastName: "George",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2466",
                    reports: []
                  },
                  {
                    employeeId: "2471",
                    firstName: "Phillip",
                    lastName: "Odom",
                    jobTitle: "Building Safety Manager-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2466",
                    reports: [
                      {
                        employeeId: "2472",
                        firstName: "Mackenzie",
                        lastName: "Griffin",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2471",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2468",
                    firstName: "Ezekiel",
                    lastName: "Manning",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2466",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2286",
            firstName: "Kelsey",
            lastName: "Copeland",
            jobTitle: "Building Safety Director",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2287",
                firstName: "Baylee",
                lastName: "Carey",
                jobTitle: "Building Safety Director",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2286",
                reports: [
                  {
                    employeeId: "2290",
                    firstName: "Braelyn",
                    lastName: "Cummings",
                    jobTitle: "Compliance Officer-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2287",
                    reports: [
                      {
                        employeeId: "2291",
                        firstName: "Lachlan",
                        lastName: "Mejia",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2290",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2302",
                    firstName: "Amari",
                    lastName: "David",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2287",
                    reports: [
                      {
                        employeeId: "2305",
                        firstName: "Beckham",
                        lastName: "Delgado",
                        jobTitle: "Building Safety Manager-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2302",
                        reports: []
                      },
                      {
                        employeeId: "2303",
                        firstName: "Elisabeth",
                        lastName: "Benjamin",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2302",
                        reports: []
                      },
                      {
                        employeeId: "2304",
                        firstName: "Haylee",
                        lastName: "Bernard",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2302",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2296",
                    firstName: "Tinley",
                    lastName: "Lane",
                    jobTitle: "Building Safety Manager-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2287",
                    reports: [
                      {
                        employeeId: "2297",
                        firstName: "Tyler",
                        lastName: "Moreno",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2296",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2298",
                    firstName: "Darius",
                    lastName: "Cantrell",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2287",
                    reports: [
                      {
                        employeeId: "2300",
                        firstName: "Jaelyn",
                        lastName: "Flowers",
                        jobTitle: "Building Safety Manager-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2298",
                        reports: []
                      },
                      {
                        employeeId: "2299",
                        firstName: "Quinton",
                        lastName: "Curtis",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2298",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2294",
                    firstName: "Raelynn",
                    lastName: "Neal",
                    jobTitle: "Building Safety Manager-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2287",
                    reports: [
                      {
                        employeeId: "2295",
                        firstName: "Colt",
                        lastName: "Jacobs",
                        jobTitle: "Compliance Officer-IV",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2294",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2288",
                    firstName: "Ariyah",
                    lastName: "Braun",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2287",
                    reports: [
                      {
                        employeeId: "2289",
                        firstName: "Zayne",
                        lastName: "Watson",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2288",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2292",
                    firstName: "Cristiano",
                    lastName: "Walker",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2287",
                    reports: [
                      {
                        employeeId: "2293",
                        firstName: "Jayson",
                        lastName: "Leblanc",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2292",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2310",
                firstName: "Ariya",
                lastName: "Wilson",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2286",
                reports: [
                  {
                    employeeId: "2311",
                    firstName: "Macy",
                    lastName: "Parsons",
                    jobTitle: "Building Safety Manager-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2310",
                    reports: [
                      {
                        employeeId: "2312",
                        firstName: "Osvaldo",
                        lastName: "Bray",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2311",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2307",
                firstName: "Kaison",
                lastName: "David",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2286",
                reports: [
                  {
                    employeeId: "2308",
                    firstName: "Kairi",
                    lastName: "Rasmussen",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2307",
                    reports: [
                      {
                        employeeId: "2309",
                        firstName: "Leanna",
                        lastName: "Christian",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2308",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2313",
                firstName: "Maximus",
                lastName: "Oneill",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2286",
                reports: [
                  {
                    employeeId: "2314",
                    firstName: "Celia",
                    lastName: "Miller",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2313",
                    reports: [
                      {
                        employeeId: "2315",
                        firstName: "Harper",
                        lastName: "Lopez",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2314",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2319",
                firstName: "Ellie",
                lastName: "Moyer",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2286",
                reports: [
                  {
                    employeeId: "2322",
                    firstName: "Jayden",
                    lastName: "Elliott",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2319",
                    reports: []
                  },
                  {
                    employeeId: "2323",
                    firstName: "Marleigh",
                    lastName: "Kelly",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2319",
                    reports: [
                      {
                        employeeId: "2324",
                        firstName: "Immanuel",
                        lastName: "Raymond",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2323",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2321",
                    firstName: "Meadow",
                    lastName: "Perkins",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2319",
                    reports: []
                  },
                  {
                    employeeId: "2320",
                    firstName: "Grady",
                    lastName: "Key",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2319",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2316",
                firstName: "Kymani",
                lastName: "Le",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2286",
                reports: [
                  {
                    employeeId: "2317",
                    firstName: "Camren",
                    lastName: "Ware",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2316",
                    reports: [
                      {
                        employeeId: "2318",
                        firstName: "Serenity",
                        lastName: "Benjamin",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2317",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2038",
            firstName: "Audrina",
            lastName: "Hays",
            jobTitle: "Building Safety Director",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2061",
                firstName: "Jacob",
                lastName: "Pineda",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2038",
                reports: []
              },
              {
                employeeId: "2062",
                firstName: "Nataly",
                lastName: "Santana",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2038",
                reports: [
                  {
                    employeeId: "2063",
                    firstName: "Tenley",
                    lastName: "Sexton",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2062",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2060",
                firstName: "Alessandra",
                lastName: "Maxwell",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2038",
                reports: []
              },
              {
                employeeId: "2039",
                firstName: "William",
                lastName: "Lam",
                jobTitle: "Building Safety Director",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2038",
                reports: [
                  {
                    employeeId: "2052",
                    firstName: "Sarai",
                    lastName: "Hall",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2039",
                    reports: []
                  },
                  {
                    employeeId: "2040",
                    firstName: "Emma",
                    lastName: "Herring",
                    jobTitle: "Building Safety Manager-VI",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2039",
                    reports: [
                      {
                        employeeId: "2048",
                        firstName: "Laney",
                        lastName: "Morse",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2040",
                        reports: []
                      },
                      {
                        employeeId: "2047",
                        firstName: "Kinsley",
                        lastName: "Salazar",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2040",
                        reports: []
                      },
                      {
                        employeeId: "2049",
                        firstName: "Sage",
                        lastName: "Holder",
                        jobTitle: "Senior Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2040",
                        reports: []
                      },
                      {
                        employeeId: "2041",
                        firstName: "Sage",
                        lastName: "Yates",
                        jobTitle: "Staff Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2040",
                        reports: []
                      },
                      {
                        employeeId: "2050",
                        firstName: "Estella",
                        lastName: "Reynolds",
                        jobTitle: "Building Safety Manager-III",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2040",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2053",
                    firstName: "Belen",
                    lastName: "Mueller",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2039",
                    reports: []
                  },
                  {
                    employeeId: "2055",
                    firstName: "Tomas",
                    lastName: "Dorsey",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2039",
                    reports: []
                  },
                  {
                    employeeId: "2054",
                    firstName: "Eloise",
                    lastName: "Rich",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2039",
                    reports: []
                  },
                  {
                    employeeId: "2057",
                    firstName: "Zoie",
                    lastName: "Turner",
                    jobTitle: "Building Safety Manager-V",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2039",
                    reports: [
                      {
                        employeeId: "2058",
                        firstName: "Trent",
                        lastName: "Baker",
                        jobTitle: "Building Safety Manager-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2057",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2056",
                    firstName: "Cameron",
                    lastName: "Carpenter",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2039",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2064",
                firstName: "Ayden",
                lastName: "Bailey",
                jobTitle: "Building Safety Manager-IV",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2038",
                reports: [
                  {
                    employeeId: "2065",
                    firstName: "Jordynn",
                    lastName: "Hayden",
                    jobTitle: "Compliance Officer-IV",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2064",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2066",
                firstName: "Brinley",
                lastName: "Ewing",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2038",
                reports: [
                  {
                    employeeId: "2067",
                    firstName: "Alec",
                    lastName: "Osborne",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2066",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2070",
                firstName: "Katie",
                lastName: "Zamora",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2038",
                reports: [
                  {
                    employeeId: "2072",
                    firstName: "Louie",
                    lastName: "Donaldson",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2070",
                    reports: []
                  },
                  {
                    employeeId: "2073",
                    firstName: "Madelyn",
                    lastName: "Harmon",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2070",
                    reports: [
                      {
                        employeeId: "2074",
                        firstName: "Kylee",
                        lastName: "Lyons",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "2073",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2071",
                    firstName: "Taya",
                    lastName: "Gaines",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2070",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2068",
                firstName: "Marlon",
                lastName: "Odonnell",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "2038",
                reports: [
                  {
                    employeeId: "2069",
                    firstName: "Skyla",
                    lastName: "Barber",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "2068",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2137",
            firstName: "Zane",
            lastName: "Armstrong",
            jobTitle: "Public Policy Manager-VII",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2140",
                firstName: "Atlas",
                lastName: "Hayden",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2158",
                firstName: "Ashton",
                lastName: "Levine",
                jobTitle: "Public Policy Manager-VI",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: [
                  {
                    employeeId: "2159",
                    firstName: "Kenley",
                    lastName: "Blake",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2158",
                    reports: []
                  },
                  {
                    employeeId: "2160",
                    firstName: "Kiana",
                    lastName: "Harvey",
                    jobTitle: "Public Policy Manager-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2158",
                    reports: [
                      {
                        employeeId: "2161",
                        firstName: "Braelynn",
                        lastName: "Daniels",
                        jobTitle: "Compliance Officer-III",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2160",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2142",
                firstName: "Penelope",
                lastName: "Rocha",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2151",
                firstName: "Tinley",
                lastName: "Snow",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2148",
                firstName: "Luca",
                lastName: "Gray",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2156",
                firstName: "Rocco",
                lastName: "Glover",
                jobTitle: "Public Policy Manager-III",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: [
                  {
                    employeeId: "2157",
                    firstName: "Alvaro",
                    lastName: "Gomez",
                    jobTitle: "Compliance Officer-III",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2156",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2141",
                firstName: "Elena",
                lastName: "Savage",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2146",
                firstName: "Raegan",
                lastName: "Sweeney",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2138",
                firstName: "Autumn",
                lastName: "Zhang",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2139",
                firstName: "Tony",
                lastName: "Frazier",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2147",
                firstName: "Harvey",
                lastName: "Garrett",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2144",
                firstName: "Martha",
                lastName: "Krause",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2152",
                firstName: "Mara",
                lastName: "Hanson",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2145",
                firstName: "Vihaan",
                lastName: "Mcknight",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2153",
                firstName: "Lochlan",
                lastName: "Reese",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2154",
                firstName: "Ray",
                lastName: "Davila",
                jobTitle: "Public Policy Manager-IV",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: [
                  {
                    employeeId: "2155",
                    firstName: "Kamryn",
                    lastName: "Williamson",
                    jobTitle: "Compliance Officer-IV",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2154",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2143",
                firstName: "Guadalupe",
                lastName: "Mueller",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2149",
                firstName: "Kristina",
                lastName: "Spence",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              },
              {
                employeeId: "2150",
                firstName: "Sharon",
                lastName: "Patel",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2137",
                reports: []
              }
            ]
          },
          {
            employeeId: "2415",
            firstName: "Layla",
            lastName: "Hancock",
            jobTitle: "Senior Director of Public Policy",
            officeLocation: "Paris",
            department: "Public Policy",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "2416",
                firstName: "Renata",
                lastName: "Wolf",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2415",
                reports: [
                  {
                    employeeId: "2417",
                    firstName: "Kara",
                    lastName: "Galloway",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2416",
                    reports: []
                  },
                  {
                    employeeId: "2418",
                    firstName: "Tara",
                    lastName: "Schroeder",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2416",
                    reports: [
                      {
                        employeeId: "2419",
                        firstName: "Saylor",
                        lastName: "Green",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2418",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2420",
                firstName: "Stevie",
                lastName: "Mcdowell",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2415",
                reports: [
                  {
                    employeeId: "2422",
                    firstName: "Lila",
                    lastName: "Collier",
                    jobTitle: "Compliance Officer-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2420",
                    reports: [
                      {
                        employeeId: "2423",
                        firstName: "Maggie",
                        lastName: "Mccann",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2422",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2421",
                    firstName: "Damari",
                    lastName: "Walker",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2420",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2439",
                firstName: "Chris",
                lastName: "Yates",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2415",
                reports: [
                  {
                    employeeId: "2442",
                    firstName: "Amy",
                    lastName: "Harrington",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2439",
                    reports: []
                  },
                  {
                    employeeId: "2444",
                    firstName: "Gianni",
                    lastName: "Mullins",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2439",
                    reports: []
                  },
                  {
                    employeeId: "2441",
                    firstName: "Emerson",
                    lastName: "Robbins",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2439",
                    reports: []
                  },
                  {
                    employeeId: "2440",
                    firstName: "Amaya",
                    lastName: "Mcdaniel",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2439",
                    reports: []
                  },
                  {
                    employeeId: "2443",
                    firstName: "Ruth",
                    lastName: "Lowe",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2439",
                    reports: []
                  },
                  {
                    employeeId: "2445",
                    firstName: "Billy",
                    lastName: "Peterson",
                    jobTitle: "Public Policy Manager-VI",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2439",
                    reports: [
                      {
                        employeeId: "2446",
                        firstName: "Paul",
                        lastName: "Lindsey",
                        jobTitle: "Public Policy Manager-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2445",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2434",
                firstName: "Kolton",
                lastName: "Larsen",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2415",
                reports: [
                  {
                    employeeId: "2436",
                    firstName: "Pablo",
                    lastName: "Austin",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2434",
                    reports: []
                  },
                  {
                    employeeId: "2437",
                    firstName: "Fabian",
                    lastName: "Silva",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2434",
                    reports: [
                      {
                        employeeId: "2438",
                        firstName: "Marc",
                        lastName: "Marks",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2437",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2435",
                    firstName: "Conrad",
                    lastName: "Patel",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2434",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2430",
                firstName: "Aidan",
                lastName: "Craig",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2415",
                reports: [
                  {
                    employeeId: "2431",
                    firstName: "Kenley",
                    lastName: "Allison",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2430",
                    reports: []
                  },
                  {
                    employeeId: "2432",
                    firstName: "Jaylah",
                    lastName: "Duarte",
                    jobTitle: "Public Policy Manager-II",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2430",
                    reports: [
                      {
                        employeeId: "2433",
                        firstName: "Jonael",
                        lastName: "Yu",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2432",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2424",
                firstName: "Laurel",
                lastName: "Ayala",
                jobTitle: "Public Policy Manager-V",
                officeLocation: "Paris",
                department: "Public Policy",
                managerEmployeeId: "2415",
                reports: [
                  {
                    employeeId: "2427",
                    firstName: "Asia",
                    lastName: "Rubio",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2424",
                    reports: []
                  },
                  {
                    employeeId: "2428",
                    firstName: "Adriana",
                    lastName: "Buchanan",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2424",
                    reports: [
                      {
                        employeeId: "2429",
                        firstName: "Santos",
                        lastName: "Michael",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2428",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2425",
                    firstName: "Jaylynn",
                    lastName: "Dickson",
                    jobTitle: "Public Policy Manager-I",
                    officeLocation: "Paris",
                    department: "Public Policy",
                    managerEmployeeId: "2424",
                    reports: [
                      {
                        employeeId: "2426",
                        firstName: "Armani",
                        lastName: "Evans",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Public Policy",
                        managerEmployeeId: "2425",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "1948",
            firstName: "Kairi",
            lastName: "Vega",
            jobTitle: "Building Safety Manager-VII",
            officeLocation: "Paris",
            department: "Building Safety",
            managerEmployeeId: "1698",
            reports: [
              {
                employeeId: "1963",
                firstName: "Finley",
                lastName: "Caldwell",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1948",
                reports: []
              },
              {
                employeeId: "1964",
                firstName: "Erick",
                lastName: "Rasmussen",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1948",
                reports: []
              },
              {
                employeeId: "1969",
                firstName: "Aiden",
                lastName: "Rowland",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1948",
                reports: []
              },
              {
                employeeId: "1965",
                firstName: "Arabella",
                lastName: "Richards",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1948",
                reports: []
              },
              {
                employeeId: "1968",
                firstName: "Neriah",
                lastName: "Pierce",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1948",
                reports: []
              },
              {
                employeeId: "1967",
                firstName: "Kadence",
                lastName: "Barrera",
                jobTitle: "Staff Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1948",
                reports: []
              },
              {
                employeeId: "1949",
                firstName: "Riley",
                lastName: "Rice",
                jobTitle: "Building Safety Manager-VI",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1948",
                reports: [
                  {
                    employeeId: "1961",
                    firstName: "Jayleen",
                    lastName: "Harrington",
                    jobTitle: "Building Safety Manager-II",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1949",
                    reports: [
                      {
                        employeeId: "1962",
                        firstName: "Otto",
                        lastName: "Beck",
                        jobTitle: "Compliance Officer-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1961",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "1959",
                    firstName: "Virginia",
                    lastName: "Singh",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1949",
                    reports: []
                  },
                  {
                    employeeId: "1958",
                    firstName: "Francesca",
                    lastName: "Leonard",
                    jobTitle: "Senior Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1949",
                    reports: []
                  },
                  {
                    employeeId: "1960",
                    firstName: "Aiden",
                    lastName: "Wiggins",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1949",
                    reports: []
                  },
                  {
                    employeeId: "1950",
                    firstName: "Ava",
                    lastName: "Buchanan",
                    jobTitle: "Staff Compliance Officer",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1949",
                    reports: [
                      {
                        employeeId: "1956",
                        firstName: "Haylee",
                        lastName: "Petty",
                        jobTitle: "Building Safety Manager-II",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1950",
                        reports: []
                      },
                      {
                        employeeId: "1955",
                        firstName: "Grayson",
                        lastName: "Collins",
                        jobTitle: "Staff Compliance Officer",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1950",
                        reports: []
                      },
                      {
                        employeeId: "1951",
                        firstName: "Lucas",
                        lastName: "Snyder",
                        jobTitle: "Building Safety Manager-V",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1950",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "1966",
                firstName: "Kendall",
                lastName: "Hinton",
                jobTitle: "Senior Compliance Officer",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1948",
                reports: []
              },
              {
                employeeId: "1970",
                firstName: "Jackson",
                lastName: "Hubbard",
                jobTitle: "Building Safety Manager-V",
                officeLocation: "Paris",
                department: "Building Safety",
                managerEmployeeId: "1948",
                reports: [
                  {
                    employeeId: "1971",
                    firstName: "Alden",
                    lastName: "Fowler",
                    jobTitle: "Building Safety Manager-I",
                    officeLocation: "Paris",
                    department: "Building Safety",
                    managerEmployeeId: "1970",
                    reports: [
                      {
                        employeeId: "1972",
                        firstName: "Annika",
                        lastName: "Waters",
                        jobTitle: "Compliance Officer-I",
                        officeLocation: "Paris",
                        department: "Building Safety",
                        managerEmployeeId: "1971",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        employeeId: "2523",
        firstName: "Stetson",
        lastName: "Wright",
        jobTitle: "Vice President of Design",
        officeLocation: "Paris",
        department: "Design",
        managerEmployeeId: "1",
        reports: [
          {
            employeeId: "2648",
            firstName: "Serena",
            lastName: "Andersen",
            jobTitle: "Senior Director of Residential Architecture",
            officeLocation: "Paris",
            department: "Residential Architecture",
            managerEmployeeId: "2523",
            reports: [
              {
                employeeId: "2649",
                firstName: "Scott",
                lastName: "Cunningham",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "2648",
                reports: [
                  {
                    employeeId: "2651",
                    firstName: "Ellis",
                    lastName: "Clark",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2649",
                    reports: [
                      {
                        employeeId: "2652",
                        firstName: "Reginald",
                        lastName: "Ruiz",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2651",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2653",
                    firstName: "Jeremy",
                    lastName: "Peterson",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2649",
                    reports: [
                      {
                        employeeId: "2654",
                        firstName: "Jayleen",
                        lastName: "Acevedo",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2653",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2657",
                    firstName: "Evangeline",
                    lastName: "Huff",
                    jobTitle: "Residential Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2649",
                    reports: [
                      {
                        employeeId: "2659",
                        firstName: "Emmanuel",
                        lastName: "Moyer",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2657",
                        reports: []
                      },
                      {
                        employeeId: "2658",
                        firstName: "Major",
                        lastName: "Andrews",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2657",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2650",
                    firstName: "Zaria",
                    lastName: "Logan",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2649",
                    reports: []
                  },
                  {
                    employeeId: "2655",
                    firstName: "Daniella",
                    lastName: "Holt",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2649",
                    reports: [
                      {
                        employeeId: "2656",
                        firstName: "Angel",
                        lastName: "Rosario",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2655",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2684",
                firstName: "Penny",
                lastName: "Mora",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "2648",
                reports: [
                  {
                    employeeId: "2685",
                    firstName: "Justus",
                    lastName: "Guerrero",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2684",
                    reports: []
                  },
                  {
                    employeeId: "2688",
                    firstName: "Joslyn",
                    lastName: "Buchanan",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2684",
                    reports: [
                      {
                        employeeId: "2689",
                        firstName: "Alondra",
                        lastName: "Orr",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2688",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2690",
                    firstName: "Cade",
                    lastName: "Abbott",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2684",
                    reports: [
                      {
                        employeeId: "2691",
                        firstName: "Jesus",
                        lastName: "Proctor",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2690",
                        reports: []
                      },
                      {
                        employeeId: "2692",
                        firstName: "Charlize",
                        lastName: "Richard",
                        jobTitle: "Residential Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2690",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2686",
                    firstName: "Daniella",
                    lastName: "Jefferson",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2684",
                    reports: []
                  },
                  {
                    employeeId: "2687",
                    firstName: "Jayvion",
                    lastName: "Glover",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2684",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2716",
                firstName: "Erick",
                lastName: "Ingram",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "2648",
                reports: [
                  {
                    employeeId: "2717",
                    firstName: "Chloe",
                    lastName: "Santiago",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2716",
                    reports: []
                  },
                  {
                    employeeId: "2723",
                    firstName: "Rosalie",
                    lastName: "Burch",
                    jobTitle: "Architect-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2716",
                    reports: [
                      {
                        employeeId: "2724",
                        firstName: "Scarlet",
                        lastName: "George",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2723",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2718",
                    firstName: "Isabela",
                    lastName: "Schwartz",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2716",
                    reports: []
                  },
                  {
                    employeeId: "2721",
                    firstName: "Noa",
                    lastName: "Phelps",
                    jobTitle: "Architect-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2716",
                    reports: [
                      {
                        employeeId: "2722",
                        firstName: "Terrence",
                        lastName: "Key",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2721",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2725",
                    firstName: "Rylan",
                    lastName: "Noble",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2716",
                    reports: [
                      {
                        employeeId: "2726",
                        firstName: "Cassandra",
                        lastName: "Hunt",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2725",
                        reports: []
                      },
                      {
                        employeeId: "2727",
                        firstName: "Beckham",
                        lastName: "Terrell",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2725",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2719",
                    firstName: "Penelope",
                    lastName: "Trujillo",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2716",
                    reports: []
                  },
                  {
                    employeeId: "2720",
                    firstName: "Serenity",
                    lastName: "Grimes",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2716",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2661",
                firstName: "Santos",
                lastName: "Ray",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "2648",
                reports: [
                  {
                    employeeId: "2665",
                    firstName: "Winter",
                    lastName: "Chapman",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2661",
                    reports: []
                  },
                  {
                    employeeId: "2666",
                    firstName: "Jesus",
                    lastName: "Herman",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2661",
                    reports: []
                  },
                  {
                    employeeId: "2669",
                    firstName: "Cecelia",
                    lastName: "Cannon",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2661",
                    reports: [
                      {
                        employeeId: "2670",
                        firstName: "Brenden",
                        lastName: "Bennett",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2669",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2667",
                    firstName: "Raelyn",
                    lastName: "Gilbert",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2661",
                    reports: [
                      {
                        employeeId: "2668",
                        firstName: "Kate",
                        lastName: "Hartman",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2667",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2662",
                    firstName: "Kye",
                    lastName: "Petersen",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2661",
                    reports: []
                  },
                  {
                    employeeId: "2663",
                    firstName: "Vincent",
                    lastName: "Hale",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2661",
                    reports: []
                  },
                  {
                    employeeId: "2664",
                    firstName: "Lailah",
                    lastName: "Dorsey",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2661",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2705",
                firstName: "Lennox",
                lastName: "Rosales",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "2648",
                reports: [
                  {
                    employeeId: "2706",
                    firstName: "Chandler",
                    lastName: "Gould",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2705",
                    reports: []
                  },
                  {
                    employeeId: "2708",
                    firstName: "Crosby",
                    lastName: "Proctor",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2705",
                    reports: []
                  },
                  {
                    employeeId: "2709",
                    firstName: "Winter",
                    lastName: "Freeman",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2705",
                    reports: []
                  },
                  {
                    employeeId: "2707",
                    firstName: "Orion",
                    lastName: "Watson",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2705",
                    reports: []
                  },
                  {
                    employeeId: "2712",
                    firstName: "Caylee",
                    lastName: "Blankenship",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2705",
                    reports: [
                      {
                        employeeId: "2713",
                        firstName: "Brecken",
                        lastName: "Berg",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2712",
                        reports: []
                      },
                      {
                        employeeId: "2714",
                        firstName: "Miller",
                        lastName: "Abbott",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2712",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2710",
                    firstName: "Leonel",
                    lastName: "Braun",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2705",
                    reports: [
                      {
                        employeeId: "2711",
                        firstName: "Yareli",
                        lastName: "Eaton",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2710",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2694",
                firstName: "Zahra",
                lastName: "Brooks",
                jobTitle: "Residential Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "2648",
                reports: [
                  {
                    employeeId: "2695",
                    firstName: "Conor",
                    lastName: "Zimmerman",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2694",
                    reports: []
                  },
                  {
                    employeeId: "2697",
                    firstName: "Lachlan",
                    lastName: "Bentley",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2694",
                    reports: []
                  },
                  {
                    employeeId: "2696",
                    firstName: "Julieta",
                    lastName: "Simon",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2694",
                    reports: []
                  },
                  {
                    employeeId: "2701",
                    firstName: "Mckenna",
                    lastName: "Mathis",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2694",
                    reports: [
                      {
                        employeeId: "2702",
                        firstName: "Viviana",
                        lastName: "Chandler",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2701",
                        reports: []
                      },
                      {
                        employeeId: "2703",
                        firstName: "Sebastian",
                        lastName: "Henson",
                        jobTitle: "Architect-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2701",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2698",
                    firstName: "Katelyn",
                    lastName: "Saunders",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2694",
                    reports: []
                  },
                  {
                    employeeId: "2699",
                    firstName: "Aydan",
                    lastName: "Underwood",
                    jobTitle: "Residential Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2694",
                    reports: [
                      {
                        employeeId: "2700",
                        firstName: "Makai",
                        lastName: "Elliott",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2699",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2672",
                firstName: "Ashlynn",
                lastName: "Edwards",
                jobTitle: "Staff Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "2648",
                reports: [
                  {
                    employeeId: "2673",
                    firstName: "Odin",
                    lastName: "Medina",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2672",
                    reports: []
                  },
                  {
                    employeeId: "2675",
                    firstName: "Gibson",
                    lastName: "Bird",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2672",
                    reports: []
                  },
                  {
                    employeeId: "2674",
                    firstName: "Wilson",
                    lastName: "Butler",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2672",
                    reports: []
                  },
                  {
                    employeeId: "2681",
                    firstName: "Jamari",
                    lastName: "Andersen",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2672",
                    reports: [
                      {
                        employeeId: "2682",
                        firstName: "Jessica",
                        lastName: "Goodwin",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2681",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2678",
                    firstName: "Elise",
                    lastName: "Carlson",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2672",
                    reports: []
                  },
                  {
                    employeeId: "2676",
                    firstName: "Rosie",
                    lastName: "Holder",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2672",
                    reports: []
                  },
                  {
                    employeeId: "2677",
                    firstName: "Willa",
                    lastName: "Gregory",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2672",
                    reports: []
                  },
                  {
                    employeeId: "2679",
                    firstName: "Jacqueline",
                    lastName: "Chase",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "2672",
                    reports: [
                      {
                        employeeId: "2680",
                        firstName: "Michaela",
                        lastName: "Harmon",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "2679",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2729",
            firstName: "Jabari",
            lastName: "Osborne",
            jobTitle: "Vice President of Industrial Architecture",
            officeLocation: "New York",
            department: "Industrial Architecture",
            managerEmployeeId: "2523",
            reports: [
              {
                employeeId: "2730",
                firstName: "Rylan",
                lastName: "Reeves",
                jobTitle: "Industrial Architecture Manager-VII",
                officeLocation: "New York",
                department: "Industrial Architecture",
                managerEmployeeId: "2729",
                reports: [
                  {
                    employeeId: "2742",
                    firstName: "Shiloh",
                    lastName: "Munoz",
                    jobTitle: "Senior Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2730",
                    reports: [
                      {
                        employeeId: "2744",
                        firstName: "Faye",
                        lastName: "Vang",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2742",
                        reports: []
                      },
                      {
                        employeeId: "2743",
                        firstName: "Aurelia",
                        lastName: "Mcneil",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2742",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2737",
                    firstName: "Cole",
                    lastName: "Booth",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2730",
                    reports: [
                      {
                        employeeId: "2738",
                        firstName: "Fernanda",
                        lastName: "Aguilar",
                        jobTitle: "Architect-III",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2737",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2731",
                    firstName: "Axton",
                    lastName: "Bartlett",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2730",
                    reports: [
                      {
                        employeeId: "2732",
                        firstName: "Jase",
                        lastName: "Howe",
                        jobTitle: "Architect-III",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2731",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2739",
                    firstName: "Braeden",
                    lastName: "Shannon",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2730",
                    reports: [
                      {
                        employeeId: "2740",
                        firstName: "Ezequiel",
                        lastName: "Oliver",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2739",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2735",
                    firstName: "Yasmin",
                    lastName: "Santos",
                    jobTitle: "Industrial Architecture Manager-IV",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2730",
                    reports: [
                      {
                        employeeId: "2736",
                        firstName: "Journee",
                        lastName: "Russell",
                        jobTitle: "Architect-IV",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2735",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2733",
                    firstName: "Valentin",
                    lastName: "Wong",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2730",
                    reports: [
                      {
                        employeeId: "2734",
                        firstName: "Javon",
                        lastName: "Sanders",
                        jobTitle: "Architect-III",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2733",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2778",
                firstName: "Ismael",
                lastName: "Dennis",
                jobTitle: "Industrial Architecture Director",
                officeLocation: "New York",
                department: "Industrial Architecture",
                managerEmployeeId: "2729",
                reports: [
                  {
                    employeeId: "2781",
                    firstName: "Bethany",
                    lastName: "Maxwell",
                    jobTitle: "Staff Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2778",
                    reports: []
                  },
                  {
                    employeeId: "2779",
                    firstName: "Lorelai",
                    lastName: "Paul",
                    jobTitle: "Senior Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2778",
                    reports: []
                  },
                  {
                    employeeId: "2788",
                    firstName: "Bruce",
                    lastName: "Andrews",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2778",
                    reports: [
                      {
                        employeeId: "2790",
                        firstName: "Malaysia",
                        lastName: "Campos",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2788",
                        reports: []
                      },
                      {
                        employeeId: "2791",
                        firstName: "Audrina",
                        lastName: "Everett",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2788",
                        reports: []
                      },
                      {
                        employeeId: "2789",
                        firstName: "Rocco",
                        lastName: "Mejia",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2788",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2780",
                    firstName: "Ronald",
                    lastName: "Hardy",
                    jobTitle: "Senior Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2778",
                    reports: []
                  },
                  {
                    employeeId: "2784",
                    firstName: "Briar",
                    lastName: "Kidd",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2778",
                    reports: [
                      {
                        employeeId: "2785",
                        firstName: "Christian",
                        lastName: "Mejia",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2784",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2782",
                    firstName: "Zander",
                    lastName: "Payne",
                    jobTitle: "Architect-IV",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2778",
                    reports: [
                      {
                        employeeId: "2783",
                        firstName: "Jameson",
                        lastName: "Roach",
                        jobTitle: "Architect-IV",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2782",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2786",
                    firstName: "Valentina",
                    lastName: "Blackwell",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2778",
                    reports: [
                      {
                        employeeId: "2787",
                        firstName: "Quentin",
                        lastName: "Sims",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2786",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2746",
                firstName: "Maddison",
                lastName: "Roy",
                jobTitle: "Industrial Architecture Manager-VII",
                officeLocation: "New York",
                department: "Industrial Architecture",
                managerEmployeeId: "2729",
                reports: [
                  {
                    employeeId: "2756",
                    firstName: "Amiyah",
                    lastName: "Heath",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2746",
                    reports: [
                      {
                        employeeId: "2758",
                        firstName: "Anthony",
                        lastName: "Bolton",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2756",
                        reports: []
                      },
                      {
                        employeeId: "2759",
                        firstName: "Maryam",
                        lastName: "Torres",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2756",
                        reports: []
                      },
                      {
                        employeeId: "2757",
                        firstName: "Kylee",
                        lastName: "Sims",
                        jobTitle: "Staff Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2756",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2751",
                    firstName: "Tiana",
                    lastName: "Beck",
                    jobTitle: "Industrial Architecture Manager-IV",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2746",
                    reports: [
                      {
                        employeeId: "2752",
                        firstName: "Westin",
                        lastName: "Lowe",
                        jobTitle: "Architect-IV",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2751",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2753",
                    firstName: "Gabriel",
                    lastName: "Rose",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2746",
                    reports: [
                      {
                        employeeId: "2754",
                        firstName: "Allie",
                        lastName: "Mcbride",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2753",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2749",
                    firstName: "Aidan",
                    lastName: "Greene",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2746",
                    reports: [
                      {
                        employeeId: "2750",
                        firstName: "Oscar",
                        lastName: "Stuart",
                        jobTitle: "Architect-III",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2749",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2747",
                    firstName: "Ellie",
                    lastName: "Zuniga",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2746",
                    reports: [
                      {
                        employeeId: "2748",
                        firstName: "Madilyn",
                        lastName: "Hart",
                        jobTitle: "Architect-III",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2747",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2820",
                firstName: "Giancarlo",
                lastName: "Davis",
                jobTitle: "Industrial Architecture Director",
                officeLocation: "New York",
                department: "Industrial Architecture",
                managerEmployeeId: "2729",
                reports: [
                  {
                    employeeId: "2827",
                    firstName: "Diego",
                    lastName: "Joseph",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2820",
                    reports: [
                      {
                        employeeId: "2829",
                        firstName: "Adonis",
                        lastName: "Henry",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2827",
                        reports: []
                      },
                      {
                        employeeId: "2828",
                        firstName: "Sam",
                        lastName: "Brown",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2827",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2831",
                    firstName: "Averi",
                    lastName: "Spears",
                    jobTitle: "Senior Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2820",
                    reports: [
                      {
                        employeeId: "2832",
                        firstName: "Adam",
                        lastName: "Carter",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2831",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2821",
                    firstName: "Kaylynn",
                    lastName: "Yang",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2820",
                    reports: [
                      {
                        employeeId: "2822",
                        firstName: "Isabella",
                        lastName: "Cuevas",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2821",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2834",
                    firstName: "Elliana",
                    lastName: "Savage",
                    jobTitle: "Senior Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2820",
                    reports: [
                      {
                        employeeId: "2835",
                        firstName: "Aliya",
                        lastName: "Maddox",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2834",
                        reports: []
                      },
                      {
                        employeeId: "2837",
                        firstName: "Mark",
                        lastName: "Potter",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2834",
                        reports: []
                      },
                      {
                        employeeId: "2836",
                        firstName: "Aarav",
                        lastName: "Sanford",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2834",
                        reports: []
                      },
                      {
                        employeeId: "2838",
                        firstName: "Taliyah",
                        lastName: "Perry",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2834",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2824",
                    firstName: "Rhys",
                    lastName: "Walter",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2820",
                    reports: [
                      {
                        employeeId: "2825",
                        firstName: "Josie",
                        lastName: "Roberts",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2824",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2805",
                firstName: "Skyla",
                lastName: "Decker",
                jobTitle: "Industrial Architecture Manager-VII",
                officeLocation: "New York",
                department: "Industrial Architecture",
                managerEmployeeId: "2729",
                reports: [
                  {
                    employeeId: "2814",
                    firstName: "Donald",
                    lastName: "Aguilar",
                    jobTitle: "Staff Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2805",
                    reports: []
                  },
                  {
                    employeeId: "2817",
                    firstName: "Bethany",
                    lastName: "Duke",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2805",
                    reports: [
                      {
                        employeeId: "2818",
                        firstName: "Anderson",
                        lastName: "Sanchez",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2817",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2806",
                    firstName: "Johanna",
                    lastName: "Cantrell",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2805",
                    reports: [
                      {
                        employeeId: "2809",
                        firstName: "Clark",
                        lastName: "Costa",
                        jobTitle: "Staff Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2806",
                        reports: []
                      },
                      {
                        employeeId: "2807",
                        firstName: "Maliah",
                        lastName: "Escobar",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2806",
                        reports: []
                      },
                      {
                        employeeId: "2810",
                        firstName: "Ally",
                        lastName: "Ingram",
                        jobTitle: "Industrial Architecture Manager-V",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2806",
                        reports: []
                      },
                      {
                        employeeId: "2808",
                        firstName: "Elena",
                        lastName: "Hodge",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2806",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2815",
                    firstName: "Gemma",
                    lastName: "Rollins",
                    jobTitle: "Staff Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2805",
                    reports: []
                  },
                  {
                    employeeId: "2816",
                    firstName: "Alexandria",
                    lastName: "Stewart",
                    jobTitle: "Staff Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2805",
                    reports: []
                  },
                  {
                    employeeId: "2813",
                    firstName: "Daleyza",
                    lastName: "Alvarado",
                    jobTitle: "Staff Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2805",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2793",
                firstName: "Adalyn",
                lastName: "Paul",
                jobTitle: "Staff Architect",
                officeLocation: "New York",
                department: "Industrial Architecture",
                managerEmployeeId: "2729",
                reports: [
                  {
                    employeeId: "2795",
                    firstName: "Melody",
                    lastName: "Clarke",
                    jobTitle: "Senior Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2793",
                    reports: []
                  },
                  {
                    employeeId: "2794",
                    firstName: "Kendall",
                    lastName: "Gould",
                    jobTitle: "Senior Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2793",
                    reports: []
                  },
                  {
                    employeeId: "2796",
                    firstName: "Mohammed",
                    lastName: "Vargas",
                    jobTitle: "Staff Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2793",
                    reports: []
                  },
                  {
                    employeeId: "2797",
                    firstName: "Katelynn",
                    lastName: "Gentry",
                    jobTitle: "Staff Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2793",
                    reports: []
                  },
                  {
                    employeeId: "2799",
                    firstName: "Ashlynn",
                    lastName: "Bishop",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2793",
                    reports: [
                      {
                        employeeId: "2800",
                        firstName: "Kristian",
                        lastName: "Weeks",
                        jobTitle: "Architect-III",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2799",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2801",
                    firstName: "Marcos",
                    lastName: "Rowe",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2793",
                    reports: [
                      {
                        employeeId: "2802",
                        firstName: "Alonzo",
                        lastName: "Oneill",
                        jobTitle: "Staff Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2801",
                        reports: []
                      },
                      {
                        employeeId: "2803",
                        firstName: "Terrell",
                        lastName: "Gonzales",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2801",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2798",
                    firstName: "Atlas",
                    lastName: "Zavala",
                    jobTitle: "Senior Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2793",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2761",
                firstName: "Maliah",
                lastName: "Quinn",
                jobTitle: "Industrial Architecture Director",
                officeLocation: "New York",
                department: "Industrial Architecture",
                managerEmployeeId: "2729",
                reports: [
                  {
                    employeeId: "2767",
                    firstName: "Tegan",
                    lastName: "Mosley",
                    jobTitle: "Industrial Architecture Manager-IV",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2761",
                    reports: [
                      {
                        employeeId: "2768",
                        firstName: "Denver",
                        lastName: "Moreno",
                        jobTitle: "Architect-IV",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2767",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2762",
                    firstName: "Allan",
                    lastName: "Burch",
                    jobTitle: "Senior Architect",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2761",
                    reports: []
                  },
                  {
                    employeeId: "2774",
                    firstName: "Brent",
                    lastName: "Orr",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2761",
                    reports: [
                      {
                        employeeId: "2775",
                        firstName: "Kendall",
                        lastName: "Scott",
                        jobTitle: "Senior Architect",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2774",
                        reports: []
                      },
                      {
                        employeeId: "2776",
                        firstName: "Josie",
                        lastName: "Walsh",
                        jobTitle: "Architect-III",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2774",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2771",
                    firstName: "Kristen",
                    lastName: "Allison",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2761",
                    reports: [
                      {
                        employeeId: "2772",
                        firstName: "Perla",
                        lastName: "Duran",
                        jobTitle: "Architect-II",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2771",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2769",
                    firstName: "Samara",
                    lastName: "Owen",
                    jobTitle: "Industrial Architecture Manager-IV",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2761",
                    reports: [
                      {
                        employeeId: "2770",
                        firstName: "Luna",
                        lastName: "Brooks",
                        jobTitle: "Architect-IV",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2769",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2765",
                    firstName: "Tiana",
                    lastName: "Underwood",
                    jobTitle: "Industrial Architecture Manager-IV",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2761",
                    reports: [
                      {
                        employeeId: "2766",
                        firstName: "Adley",
                        lastName: "Harvey",
                        jobTitle: "Architect-IV",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2765",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2763",
                    firstName: "Stephen",
                    lastName: "Melendez",
                    jobTitle: "Industrial Architecture Manager-IV",
                    officeLocation: "New York",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2761",
                    reports: [
                      {
                        employeeId: "2764",
                        firstName: "Rhett",
                        lastName: "Archer",
                        jobTitle: "Architect-IV",
                        officeLocation: "New York",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2763",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2526",
            firstName: "Emersyn",
            lastName: "Holmes",
            jobTitle: "Fellow Architect",
            officeLocation: "Paris",
            department: "Industrial Architecture",
            managerEmployeeId: "2523",
            reports: [
              {
                employeeId: "2527",
                firstName: "Emiliano",
                lastName: "Stark",
                jobTitle: "Distinguished Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2526",
                reports: [
                  {
                    employeeId: "2578",
                    firstName: "Cason",
                    lastName: "Powell",
                    jobTitle: "Industrial Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2527",
                    reports: [
                      {
                        employeeId: "2581",
                        firstName: "Eric",
                        lastName: "Garrett",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2578",
                        reports: []
                      },
                      {
                        employeeId: "2582",
                        firstName: "Braylee",
                        lastName: "Patton",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2578",
                        reports: []
                      },
                      {
                        employeeId: "2579",
                        firstName: "Eric",
                        lastName: "Boyle",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2578",
                        reports: []
                      },
                      {
                        employeeId: "2584",
                        firstName: "Reina",
                        lastName: "Roth",
                        jobTitle: "Industrial Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2578",
                        reports: []
                      },
                      {
                        employeeId: "2586",
                        firstName: "Gwen",
                        lastName: "Sosa",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2578",
                        reports: []
                      },
                      {
                        employeeId: "2580",
                        firstName: "Jaelyn",
                        lastName: "Salas",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2578",
                        reports: []
                      },
                      {
                        employeeId: "2583",
                        firstName: "Wilder",
                        lastName: "Smith",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2578",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2529",
                    firstName: "Valentin",
                    lastName: "Schultz",
                    jobTitle: "Industrial Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2527",
                    reports: [
                      {
                        employeeId: "2534",
                        firstName: "Kieran",
                        lastName: "Pitts",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2529",
                        reports: []
                      },
                      {
                        employeeId: "2533",
                        firstName: "Yusuf",
                        lastName: "Rose",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2529",
                        reports: []
                      },
                      {
                        employeeId: "2530",
                        firstName: "Francis",
                        lastName: "Harmon",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2529",
                        reports: []
                      },
                      {
                        employeeId: "2535",
                        firstName: "Haley",
                        lastName: "Donovan",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2529",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2543",
                    firstName: "Pedro",
                    lastName: "Oconnell",
                    jobTitle: "Industrial Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2527",
                    reports: [
                      {
                        employeeId: "2546",
                        firstName: "Karla",
                        lastName: "Miller",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2543",
                        reports: []
                      },
                      {
                        employeeId: "2547",
                        firstName: "Neymar",
                        lastName: "Gross",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2543",
                        reports: []
                      },
                      {
                        employeeId: "2545",
                        firstName: "Leona",
                        lastName: "Frazier",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2543",
                        reports: []
                      },
                      {
                        employeeId: "2544",
                        firstName: "Jesus",
                        lastName: "Villanueva",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2543",
                        reports: []
                      },
                      {
                        employeeId: "2548",
                        firstName: "Kalel",
                        lastName: "Lyons",
                        jobTitle: "Industrial Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2543",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2569",
                    firstName: "Wyatt",
                    lastName: "Norris",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2527",
                    reports: [
                      {
                        employeeId: "2575",
                        firstName: "Brock",
                        lastName: "Fox",
                        jobTitle: "Industrial Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2569",
                        reports: []
                      },
                      {
                        employeeId: "2572",
                        firstName: "Flynn",
                        lastName: "Rios",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2569",
                        reports: []
                      },
                      {
                        employeeId: "2573",
                        firstName: "Brylee",
                        lastName: "Charles",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2569",
                        reports: []
                      },
                      {
                        employeeId: "2570",
                        firstName: "Monserrat",
                        lastName: "Arnold",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2569",
                        reports: []
                      },
                      {
                        employeeId: "2571",
                        firstName: "Skyler",
                        lastName: "Mitchell",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2569",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2537",
                    firstName: "Amiya",
                    lastName: "Briggs",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2527",
                    reports: [
                      {
                        employeeId: "2541",
                        firstName: "Avalynn",
                        lastName: "Mcgrath",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2537",
                        reports: []
                      },
                      {
                        employeeId: "2540",
                        firstName: "Andy",
                        lastName: "Meyer",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2537",
                        reports: []
                      },
                      {
                        employeeId: "2538",
                        firstName: "Lewis",
                        lastName: "Glover",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2537",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2558",
                    firstName: "Jayleen",
                    lastName: "Wolfe",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2527",
                    reports: [
                      {
                        employeeId: "2567",
                        firstName: "Casen",
                        lastName: "Murphy",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2558",
                        reports: []
                      },
                      {
                        employeeId: "2566",
                        firstName: "Grayson",
                        lastName: "Boyd",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2558",
                        reports: []
                      },
                      {
                        employeeId: "2559",
                        firstName: "Selah",
                        lastName: "Henry",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2558",
                        reports: []
                      },
                      {
                        employeeId: "2565",
                        firstName: "Mario",
                        lastName: "Page",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2558",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2550",
                    firstName: "Anaya",
                    lastName: "Ware",
                    jobTitle: "Industrial Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2527",
                    reports: [
                      {
                        employeeId: "2555",
                        firstName: "Emelia",
                        lastName: "Holland",
                        jobTitle: "Industrial Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2550",
                        reports: []
                      },
                      {
                        employeeId: "2552",
                        firstName: "Amelia",
                        lastName: "Tapia",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2550",
                        reports: []
                      },
                      {
                        employeeId: "2553",
                        firstName: "Julia",
                        lastName: "Douglas",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2550",
                        reports: []
                      },
                      {
                        employeeId: "2554",
                        firstName: "Canaan",
                        lastName: "Henson",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2550",
                        reports: []
                      },
                      {
                        employeeId: "2551",
                        firstName: "Jamal",
                        lastName: "Christensen",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2550",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2528",
                    firstName: "Ayleen",
                    lastName: "Burgess",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2527",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2589",
                firstName: "Jared",
                lastName: "West",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2526",
                reports: [
                  {
                    employeeId: "2593",
                    firstName: "Ace",
                    lastName: "Ryan",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2589",
                    reports: [
                      {
                        employeeId: "2594",
                        firstName: "Legend",
                        lastName: "Gould",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2593",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2590",
                    firstName: "Margaret",
                    lastName: "Wise",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2589",
                    reports: []
                  },
                  {
                    employeeId: "2591",
                    firstName: "Emilia",
                    lastName: "King",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2589",
                    reports: []
                  },
                  {
                    employeeId: "2592",
                    firstName: "Mathias",
                    lastName: "Gonzales",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2589",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2638",
                firstName: "Steve",
                lastName: "Garcia",
                jobTitle: "Industrial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2526",
                reports: [
                  {
                    employeeId: "2640",
                    firstName: "Rosie",
                    lastName: "Alvarez",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2638",
                    reports: []
                  },
                  {
                    employeeId: "2641",
                    firstName: "Holden",
                    lastName: "Hood",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2638",
                    reports: []
                  },
                  {
                    employeeId: "2645",
                    firstName: "Aaron",
                    lastName: "Stanton",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2638",
                    reports: [
                      {
                        employeeId: "2646",
                        firstName: "Salma",
                        lastName: "Rowe",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2645",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2639",
                    firstName: "Zahra",
                    lastName: "Carey",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2638",
                    reports: []
                  },
                  {
                    employeeId: "2642",
                    firstName: "Talon",
                    lastName: "Bailey",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2638",
                    reports: []
                  },
                  {
                    employeeId: "2643",
                    firstName: "Ernesto",
                    lastName: "Chaney",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2638",
                    reports: [
                      {
                        employeeId: "2644",
                        firstName: "Alex",
                        lastName: "Dixon",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2643",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2603",
                firstName: "Roselyn",
                lastName: "Leonard",
                jobTitle: "Industrial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2526",
                reports: [
                  {
                    employeeId: "2604",
                    firstName: "Jacob",
                    lastName: "Gregory",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2603",
                    reports: []
                  },
                  {
                    employeeId: "2605",
                    firstName: "Adriana",
                    lastName: "Henderson",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2603",
                    reports: []
                  },
                  {
                    employeeId: "2607",
                    firstName: "Markus",
                    lastName: "Molina",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2603",
                    reports: [
                      {
                        employeeId: "2608",
                        firstName: "Noor",
                        lastName: "Montes",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2607",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2606",
                    firstName: "Anya",
                    lastName: "Suarez",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2603",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2596",
                firstName: "Gunner",
                lastName: "Gilmore",
                jobTitle: "Industrial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2526",
                reports: [
                  {
                    employeeId: "2600",
                    firstName: "Hallie",
                    lastName: "Frazier",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2596",
                    reports: []
                  },
                  {
                    employeeId: "2601",
                    firstName: "Armani",
                    lastName: "Parsons",
                    jobTitle: "Industrial Architecture Manager-IV",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2596",
                    reports: [
                      {
                        employeeId: "2602",
                        firstName: "Karter",
                        lastName: "Fitzpatrick",
                        jobTitle: "Architect-IV",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2601",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2597",
                    firstName: "Chase",
                    lastName: "Best",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2596",
                    reports: [
                      {
                        employeeId: "2598",
                        firstName: "Mckinley",
                        lastName: "Ibarra",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2597",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2610",
                firstName: "Gavin",
                lastName: "Conner",
                jobTitle: "Industrial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2526",
                reports: [
                  {
                    employeeId: "2613",
                    firstName: "Cadence",
                    lastName: "Buckley",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2610",
                    reports: []
                  },
                  {
                    employeeId: "2614",
                    firstName: "Madeleine",
                    lastName: "Stone",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2610",
                    reports: [
                      {
                        employeeId: "2615",
                        firstName: "Braylee",
                        lastName: "Skinner",
                        jobTitle: "Industrial Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2614",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2611",
                    firstName: "Hayden",
                    lastName: "Lowery",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2610",
                    reports: []
                  },
                  {
                    employeeId: "2612",
                    firstName: "Emmanuel",
                    lastName: "Craig",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2610",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2617",
                firstName: "Nyla",
                lastName: "Frazier",
                jobTitle: "Industrial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2526",
                reports: [
                  {
                    employeeId: "2624",
                    firstName: "Frida",
                    lastName: "Salinas",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2617",
                    reports: [
                      {
                        employeeId: "2626",
                        firstName: "Marjorie",
                        lastName: "Frey",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2624",
                        reports: []
                      },
                      {
                        employeeId: "2625",
                        firstName: "Omari",
                        lastName: "Page",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2624",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2619",
                    firstName: "Jamir",
                    lastName: "Sellers",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2617",
                    reports: []
                  },
                  {
                    employeeId: "2618",
                    firstName: "Amelie",
                    lastName: "Tanner",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2617",
                    reports: []
                  },
                  {
                    employeeId: "2622",
                    firstName: "Amirah",
                    lastName: "Andrade",
                    jobTitle: "Industrial Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2617",
                    reports: [
                      {
                        employeeId: "2623",
                        firstName: "Rachel",
                        lastName: "Blanchard",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2622",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2620",
                    firstName: "Jasper",
                    lastName: "Hancock",
                    jobTitle: "Industrial Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2617",
                    reports: [
                      {
                        employeeId: "2621",
                        firstName: "Macy",
                        lastName: "Potts",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2620",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2628",
                firstName: "Harlan",
                lastName: "Chen",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2526",
                reports: [
                  {
                    employeeId: "2632",
                    firstName: "Paulina",
                    lastName: "Ochoa",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2628",
                    reports: []
                  },
                  {
                    employeeId: "2630",
                    firstName: "Moises",
                    lastName: "Weiss",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2628",
                    reports: []
                  },
                  {
                    employeeId: "2629",
                    firstName: "Malik",
                    lastName: "Welch",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2628",
                    reports: []
                  },
                  {
                    employeeId: "2633",
                    firstName: "Marley",
                    lastName: "Hood",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2628",
                    reports: [
                      {
                        employeeId: "2634",
                        firstName: "Zuri",
                        lastName: "Harrington",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2633",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2635",
                    firstName: "Cynthia",
                    lastName: "Stevens",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2628",
                    reports: [
                      {
                        employeeId: "2636",
                        firstName: "Ellie",
                        lastName: "Ellison",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2635",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2631",
                    firstName: "Jordyn",
                    lastName: "Stanley",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2628",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2945",
            firstName: "Kamren",
            lastName: "Gilmore",
            jobTitle: "Fellow Architect",
            officeLocation: "Paris",
            department: "Commercial Architecture",
            managerEmployeeId: "2523",
            reports: [
              {
                employeeId: "3052",
                firstName: "Adelyn",
                lastName: "Charles",
                jobTitle: "Staff Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "2945",
                reports: [
                  {
                    employeeId: "3057",
                    firstName: "Grant",
                    lastName: "Ball",
                    jobTitle: "Commercial Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3052",
                    reports: [
                      {
                        employeeId: "3058",
                        firstName: "Keagan",
                        lastName: "Morris",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3057",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3056",
                    firstName: "Sylas",
                    lastName: "Lawson",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3052",
                    reports: []
                  },
                  {
                    employeeId: "3053",
                    firstName: "Moriah",
                    lastName: "Garcia",
                    jobTitle: "Commercial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3052",
                    reports: [
                      {
                        employeeId: "3054",
                        firstName: "Kareem",
                        lastName: "Strickland",
                        jobTitle: "Commercial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3053",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3036",
                firstName: "Eduardo",
                lastName: "Mendoza",
                jobTitle: "Commercial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "2945",
                reports: [
                  {
                    employeeId: "3041",
                    firstName: "Erin",
                    lastName: "Hill",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3036",
                    reports: [
                      {
                        employeeId: "3042",
                        firstName: "Ford",
                        lastName: "Alvarez",
                        jobTitle: "Commercial Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3041",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3037",
                    firstName: "Javier",
                    lastName: "Cross",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3036",
                    reports: []
                  },
                  {
                    employeeId: "3038",
                    firstName: "Kyson",
                    lastName: "Barry",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3036",
                    reports: []
                  },
                  {
                    employeeId: "3040",
                    firstName: "Stanley",
                    lastName: "Roberts",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3036",
                    reports: []
                  },
                  {
                    employeeId: "3039",
                    firstName: "Emiliano",
                    lastName: "Reeves",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3036",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3027",
                firstName: "Major",
                lastName: "Glover",
                jobTitle: "Senior Staff Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "2945",
                reports: []
              },
              {
                employeeId: "2946",
                firstName: "Allie",
                lastName: "Burnett",
                jobTitle: "Senior Director of Commercial Architecture",
                officeLocation: "Beijing",
                department: "Commercial Architecture",
                managerEmployeeId: "2945",
                reports: [
                  {
                    employeeId: "3008",
                    firstName: "Kristen",
                    lastName: "Booth",
                    jobTitle: "Commercial Architecture Director",
                    officeLocation: "Beijing",
                    department: "Commercial Architecture",
                    managerEmployeeId: "2946",
                    reports: [
                      {
                        employeeId: "3022",
                        firstName: "Terry",
                        lastName: "Pitts",
                        jobTitle: "Commercial Architecture Manager-V",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3008",
                        reports: []
                      },
                      {
                        employeeId: "3018",
                        firstName: "Jesse",
                        lastName: "Le",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3008",
                        reports: []
                      },
                      {
                        employeeId: "3021",
                        firstName: "Andrea",
                        lastName: "Silva",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3008",
                        reports: []
                      },
                      {
                        employeeId: "3020",
                        firstName: "Molly",
                        lastName: "Cannon",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3008",
                        reports: []
                      },
                      {
                        employeeId: "3009",
                        firstName: "Arturo",
                        lastName: "Gay",
                        jobTitle: "Commercial Architecture Manager-VI",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3008",
                        reports: []
                      },
                      {
                        employeeId: "3024",
                        firstName: "Ciara",
                        lastName: "Cowan",
                        jobTitle: "Commercial Architecture Manager-V",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3008",
                        reports: []
                      },
                      {
                        employeeId: "3019",
                        firstName: "Russell",
                        lastName: "Dunlap",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3008",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2996",
                    firstName: "Riley",
                    lastName: "Lynch",
                    jobTitle: "Commercial Architecture Manager-VI",
                    officeLocation: "Beijing",
                    department: "Commercial Architecture",
                    managerEmployeeId: "2946",
                    reports: [
                      {
                        employeeId: "2997",
                        firstName: "Leo",
                        lastName: "Hardy",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2996",
                        reports: []
                      },
                      {
                        employeeId: "3004",
                        firstName: "Lucca",
                        lastName: "Bridges",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2996",
                        reports: []
                      },
                      {
                        employeeId: "3006",
                        firstName: "Lia",
                        lastName: "Williams",
                        jobTitle: "Commercial Architecture Manager-III",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2996",
                        reports: []
                      },
                      {
                        employeeId: "3005",
                        firstName: "Layla",
                        lastName: "Reese",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2996",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2960",
                    firstName: "Mitchell",
                    lastName: "Arnold",
                    jobTitle: "Staff Architect",
                    officeLocation: "Beijing",
                    department: "Commercial Architecture",
                    managerEmployeeId: "2946",
                    reports: [
                      {
                        employeeId: "2970",
                        firstName: "Tucker",
                        lastName: "Morgan",
                        jobTitle: "Commercial Architecture Manager-V",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2960",
                        reports: []
                      },
                      {
                        employeeId: "2968",
                        firstName: "Rex",
                        lastName: "Levy",
                        jobTitle: "Architect-III",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2960",
                        reports: []
                      },
                      {
                        employeeId: "2963",
                        firstName: "Caroline",
                        lastName: "Castillo",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2960",
                        reports: []
                      },
                      {
                        employeeId: "2961",
                        firstName: "Estelle",
                        lastName: "Owens",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2960",
                        reports: []
                      },
                      {
                        employeeId: "2966",
                        firstName: "Lacey",
                        lastName: "Norris",
                        jobTitle: "Commercial Architecture Manager-II",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2960",
                        reports: []
                      },
                      {
                        employeeId: "2962",
                        firstName: "Claudia",
                        lastName: "Leblanc",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2960",
                        reports: []
                      },
                      {
                        employeeId: "2964",
                        firstName: "Dillon",
                        lastName: "Rich",
                        jobTitle: "Commercial Architecture Manager-II",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2960",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2947",
                    firstName: "Karlee",
                    lastName: "Stanton",
                    jobTitle: "Commercial Architecture Manager-VI",
                    officeLocation: "Beijing",
                    department: "Commercial Architecture",
                    managerEmployeeId: "2946",
                    reports: [
                      {
                        employeeId: "2956",
                        firstName: "Johnathan",
                        lastName: "Kelly",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2947",
                        reports: []
                      },
                      {
                        employeeId: "2948",
                        firstName: "Jemma",
                        lastName: "Washington",
                        jobTitle: "Commercial Architecture Manager-VI",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2947",
                        reports: []
                      },
                      {
                        employeeId: "2957",
                        firstName: "Tanner",
                        lastName: "Pineda",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2947",
                        reports: []
                      },
                      {
                        employeeId: "2958",
                        firstName: "Ariadne",
                        lastName: "Chan",
                        jobTitle: "Commercial Architecture Manager-II",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2947",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2974",
                    firstName: "Jose",
                    lastName: "Daniels",
                    jobTitle: "Commercial Architecture Manager-VII",
                    officeLocation: "Beijing",
                    department: "Commercial Architecture",
                    managerEmployeeId: "2946",
                    reports: [
                      {
                        employeeId: "2977",
                        firstName: "Michelle",
                        lastName: "Bowman",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2974",
                        reports: []
                      },
                      {
                        employeeId: "2975",
                        firstName: "Aubrielle",
                        lastName: "Khan",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2974",
                        reports: []
                      },
                      {
                        employeeId: "2980",
                        firstName: "Meilani",
                        lastName: "Hawkins",
                        jobTitle: "Commercial Architecture Manager-IV",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2974",
                        reports: []
                      },
                      {
                        employeeId: "2976",
                        firstName: "Emory",
                        lastName: "Gill",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2974",
                        reports: []
                      },
                      {
                        employeeId: "2978",
                        firstName: "Konnor",
                        lastName: "Mann",
                        jobTitle: "Commercial Architecture Manager-IV",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2974",
                        reports: []
                      },
                      {
                        employeeId: "2982",
                        firstName: "Malachi",
                        lastName: "Palmer",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2974",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2986",
                    firstName: "Skye",
                    lastName: "Ryan",
                    jobTitle: "Commercial Architecture Manager-VII",
                    officeLocation: "Beijing",
                    department: "Commercial Architecture",
                    managerEmployeeId: "2946",
                    reports: [
                      {
                        employeeId: "2991",
                        firstName: "Amiya",
                        lastName: "Shah",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2986",
                        reports: []
                      },
                      {
                        employeeId: "2987",
                        firstName: "Franco",
                        lastName: "Maxwell",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2986",
                        reports: []
                      },
                      {
                        employeeId: "2990",
                        firstName: "Gage",
                        lastName: "Hess",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2986",
                        reports: []
                      },
                      {
                        employeeId: "2993",
                        firstName: "Kyle",
                        lastName: "Jacobs",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2986",
                        reports: []
                      },
                      {
                        employeeId: "2989",
                        firstName: "Giovani",
                        lastName: "Pittman",
                        jobTitle: "Senior Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2986",
                        reports: []
                      },
                      {
                        employeeId: "2992",
                        firstName: "Alisson",
                        lastName: "Mayer",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2986",
                        reports: []
                      },
                      {
                        employeeId: "2988",
                        firstName: "Natalie",
                        lastName: "Tran",
                        jobTitle: "Staff Architect",
                        officeLocation: "Beijing",
                        department: "Commercial Architecture",
                        managerEmployeeId: "2986",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3028",
                firstName: "Triston",
                lastName: "Irwin",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "2945",
                reports: [
                  {
                    employeeId: "3033",
                    firstName: "Bethany",
                    lastName: "Pugh",
                    jobTitle: "Commercial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3028",
                    reports: [
                      {
                        employeeId: "3034",
                        firstName: "Elijah",
                        lastName: "Whitney",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3033",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3030",
                    firstName: "Jamie",
                    lastName: "Miller",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3028",
                    reports: []
                  },
                  {
                    employeeId: "3031",
                    firstName: "Vincenzo",
                    lastName: "Flores",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3028",
                    reports: []
                  },
                  {
                    employeeId: "3032",
                    firstName: "Ryleigh",
                    lastName: "Browning",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3028",
                    reports: []
                  },
                  {
                    employeeId: "3029",
                    firstName: "Noah",
                    lastName: "Barnett",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3028",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3059",
                firstName: "Cristopher",
                lastName: "Cole",
                jobTitle: "Commercial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "2945",
                reports: [
                  {
                    employeeId: "3061",
                    firstName: "Joanna",
                    lastName: "Love",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3059",
                    reports: []
                  },
                  {
                    employeeId: "3063",
                    firstName: "Macy",
                    lastName: "Howe",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3059",
                    reports: []
                  },
                  {
                    employeeId: "3064",
                    firstName: "Ada",
                    lastName: "Macdonald",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3059",
                    reports: [
                      {
                        employeeId: "3065",
                        firstName: "Addilynn",
                        lastName: "Adkins",
                        jobTitle: "Commercial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3064",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3060",
                    firstName: "Spencer",
                    lastName: "Casey",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3059",
                    reports: []
                  },
                  {
                    employeeId: "3062",
                    firstName: "Deandre",
                    lastName: "Stephenson",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3059",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3044",
                firstName: "Jairo",
                lastName: "Merritt",
                jobTitle: "Staff Architect",
                officeLocation: "Paris",
                department: "Commercial Architecture",
                managerEmployeeId: "2945",
                reports: [
                  {
                    employeeId: "3049",
                    firstName: "Beckham",
                    lastName: "Allen",
                    jobTitle: "Commercial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3044",
                    reports: [
                      {
                        employeeId: "3050",
                        firstName: "Chad",
                        lastName: "Dougherty",
                        jobTitle: "Commercial Architecture Manager-I",
                        officeLocation: "Paris",
                        department: "Commercial Architecture",
                        managerEmployeeId: "3049",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3048",
                    firstName: "Nickolas",
                    lastName: "Joyce",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3044",
                    reports: []
                  },
                  {
                    employeeId: "3047",
                    firstName: "Caylee",
                    lastName: "Glenn",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3044",
                    reports: []
                  },
                  {
                    employeeId: "3046",
                    firstName: "Enzo",
                    lastName: "Bolton",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3044",
                    reports: []
                  },
                  {
                    employeeId: "3045",
                    firstName: "Hallie",
                    lastName: "Carter",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Commercial Architecture",
                    managerEmployeeId: "3044",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "3067",
            firstName: "Alaya",
            lastName: "Villa",
            jobTitle: "Senior Director of Residential Architecture",
            officeLocation: "Paris",
            department: "Residential Architecture",
            managerEmployeeId: "2523",
            reports: [
              {
                employeeId: "3133",
                firstName: "Alvaro",
                lastName: "Rios",
                jobTitle: "Residential Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "3067",
                reports: [
                  {
                    employeeId: "3134",
                    firstName: "Davion",
                    lastName: "Cooley",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3133",
                    reports: [
                      {
                        employeeId: "3135",
                        firstName: "Makai",
                        lastName: "Kirby",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3134",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3151",
                firstName: "Gunner",
                lastName: "Ware",
                jobTitle: "Residential Architecture Manager-V",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "3067",
                reports: [
                  {
                    employeeId: "3152",
                    firstName: "Duncan",
                    lastName: "Stanley",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3151",
                    reports: []
                  },
                  {
                    employeeId: "3153",
                    firstName: "Cristopher",
                    lastName: "Cordova",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3151",
                    reports: []
                  },
                  {
                    employeeId: "3154",
                    firstName: "Alicia",
                    lastName: "Kaufman",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3151",
                    reports: []
                  },
                  {
                    employeeId: "3155",
                    firstName: "Abraham",
                    lastName: "Bray",
                    jobTitle: "Architect-II",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3151",
                    reports: [
                      {
                        employeeId: "3156",
                        firstName: "Leonidas",
                        lastName: "Love",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3155",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3146",
                firstName: "Hunter",
                lastName: "Dunn",
                jobTitle: "Residential Architecture Manager-V",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "3067",
                reports: [
                  {
                    employeeId: "3148",
                    firstName: "Eve",
                    lastName: "Savage",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3146",
                    reports: []
                  },
                  {
                    employeeId: "3149",
                    firstName: "Denver",
                    lastName: "Gamble",
                    jobTitle: "Residential Architecture Manager-I",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3146",
                    reports: [
                      {
                        employeeId: "3150",
                        firstName: "Augustine",
                        lastName: "Hays",
                        jobTitle: "Architect-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3149",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3147",
                    firstName: "Dominick",
                    lastName: "Jefferson",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3146",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3139",
                firstName: "Belen",
                lastName: "Conway",
                jobTitle: "Senior Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "3067",
                reports: [
                  {
                    employeeId: "3140",
                    firstName: "Soren",
                    lastName: "Krueger",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3139",
                    reports: []
                  },
                  {
                    employeeId: "3141",
                    firstName: "Isabelle",
                    lastName: "Lutz",
                    jobTitle: "Architect-II",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3139",
                    reports: [
                      {
                        employeeId: "3142",
                        firstName: "Kassidy",
                        lastName: "May",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3141",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3130",
                firstName: "Brooke",
                lastName: "Boyer",
                jobTitle: "Residential Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "3067",
                reports: [
                  {
                    employeeId: "3131",
                    firstName: "Naya",
                    lastName: "Soto",
                    jobTitle: "Residential Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3130",
                    reports: [
                      {
                        employeeId: "3132",
                        firstName: "Kolton",
                        lastName: "Shelton",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3131",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3136",
                firstName: "Stevie",
                lastName: "Bradford",
                jobTitle: "Senior Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "3067",
                reports: [
                  {
                    employeeId: "3137",
                    firstName: "Alina",
                    lastName: "Benitez",
                    jobTitle: "Residential Architecture Manager-I",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3136",
                    reports: [
                      {
                        employeeId: "3138",
                        firstName: "Draven",
                        lastName: "Huerta",
                        jobTitle: "Architect-I",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3137",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3143",
                firstName: "Denver",
                lastName: "Brooks",
                jobTitle: "Staff Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "3067",
                reports: [
                  {
                    employeeId: "3144",
                    firstName: "Pearl",
                    lastName: "Kaufman",
                    jobTitle: "Residential Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3143",
                    reports: [
                      {
                        employeeId: "3145",
                        firstName: "Royce",
                        lastName: "Michael",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3144",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3068",
                firstName: "Lennon",
                lastName: "Wilson",
                jobTitle: "Senior Director of Residential Architecture",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "3067",
                reports: [
                  {
                    employeeId: "3101",
                    firstName: "Jemma",
                    lastName: "Glover",
                    jobTitle: "Residential Architecture Manager-VII",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3068",
                    reports: [
                      {
                        employeeId: "3104",
                        firstName: "Raven",
                        lastName: "Mooney",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3101",
                        reports: []
                      },
                      {
                        employeeId: "3103",
                        firstName: "Estelle",
                        lastName: "Golden",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3101",
                        reports: []
                      },
                      {
                        employeeId: "3107",
                        firstName: "Alison",
                        lastName: "Olsen",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3101",
                        reports: []
                      },
                      {
                        employeeId: "3105",
                        firstName: "Nico",
                        lastName: "Moon",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3101",
                        reports: []
                      },
                      {
                        employeeId: "3102",
                        firstName: "Ean",
                        lastName: "Mcconnell",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3101",
                        reports: []
                      },
                      {
                        employeeId: "3106",
                        firstName: "Freya",
                        lastName: "Sparks",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3101",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3087",
                    firstName: "Marleigh",
                    lastName: "Boyer",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3068",
                    reports: [
                      {
                        employeeId: "3089",
                        firstName: "Margaret",
                        lastName: "Coleman",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3087",
                        reports: []
                      },
                      {
                        employeeId: "3090",
                        firstName: "Ishaan",
                        lastName: "Mcmillan",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3087",
                        reports: []
                      },
                      {
                        employeeId: "3091",
                        firstName: "Sidney",
                        lastName: "Reeves",
                        jobTitle: "Residential Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3087",
                        reports: []
                      },
                      {
                        employeeId: "3088",
                        firstName: "Sharon",
                        lastName: "Shepherd",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3087",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3120",
                    firstName: "Desmond",
                    lastName: "Bird",
                    jobTitle: "Senior Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3068",
                    reports: [
                      {
                        employeeId: "3123",
                        firstName: "Alianna",
                        lastName: "Hodges",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3120",
                        reports: []
                      },
                      {
                        employeeId: "3122",
                        firstName: "Anne",
                        lastName: "Chapman",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3120",
                        reports: []
                      },
                      {
                        employeeId: "3125",
                        firstName: "Conor",
                        lastName: "Combs",
                        jobTitle: "Residential Architecture Manager-IV",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3120",
                        reports: []
                      },
                      {
                        employeeId: "3127",
                        firstName: "Alanna",
                        lastName: "Bowen",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3120",
                        reports: []
                      },
                      {
                        employeeId: "3121",
                        firstName: "Jack",
                        lastName: "Greene",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3120",
                        reports: []
                      },
                      {
                        employeeId: "3124",
                        firstName: "Kamren",
                        lastName: "Hurst",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3120",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3078",
                    firstName: "Sam",
                    lastName: "Mitchell",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3068",
                    reports: [
                      {
                        employeeId: "3083",
                        firstName: "Harley",
                        lastName: "Cunningham",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3078",
                        reports: []
                      },
                      {
                        employeeId: "3082",
                        firstName: "Nathaly",
                        lastName: "Campbell",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3078",
                        reports: []
                      },
                      {
                        employeeId: "3080",
                        firstName: "Leanna",
                        lastName: "Lloyd",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3078",
                        reports: []
                      },
                      {
                        employeeId: "3084",
                        firstName: "Peyton",
                        lastName: "Russo",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3078",
                        reports: []
                      },
                      {
                        employeeId: "3079",
                        firstName: "Eugene",
                        lastName: "Wilkins",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3078",
                        reports: []
                      },
                      {
                        employeeId: "3081",
                        firstName: "Winston",
                        lastName: "Randall",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3078",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3069",
                    firstName: "Emerson",
                    lastName: "Phelps",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3068",
                    reports: [
                      {
                        employeeId: "3072",
                        firstName: "Camilo",
                        lastName: "Cabrera",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3069",
                        reports: []
                      },
                      {
                        employeeId: "3070",
                        firstName: "Elliana",
                        lastName: "Chambers",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3069",
                        reports: []
                      },
                      {
                        employeeId: "3075",
                        firstName: "Kate",
                        lastName: "Zamora",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3069",
                        reports: []
                      },
                      {
                        employeeId: "3074",
                        firstName: "Westin",
                        lastName: "Graves",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3069",
                        reports: []
                      },
                      {
                        employeeId: "3071",
                        firstName: "Immanuel",
                        lastName: "Braun",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3069",
                        reports: []
                      },
                      {
                        employeeId: "3073",
                        firstName: "Zavier",
                        lastName: "Hayden",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3069",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3093",
                    firstName: "Anabella",
                    lastName: "Meyer",
                    jobTitle: "Residential Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3068",
                    reports: [
                      {
                        employeeId: "3097",
                        firstName: "Ally",
                        lastName: "Brown",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3093",
                        reports: []
                      },
                      {
                        employeeId: "3096",
                        firstName: "Holly",
                        lastName: "Johns",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3093",
                        reports: []
                      },
                      {
                        employeeId: "3094",
                        firstName: "Raul",
                        lastName: "Rowe",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3093",
                        reports: []
                      },
                      {
                        employeeId: "3095",
                        firstName: "Gilbert",
                        lastName: "Solis",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3093",
                        reports: []
                      },
                      {
                        employeeId: "3098",
                        firstName: "Russell",
                        lastName: "Chavez",
                        jobTitle: "Residential Architecture Manager-V",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3093",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3110",
                    firstName: "Musa",
                    lastName: "Ballard",
                    jobTitle: "Senior Staff Architect",
                    officeLocation: "Paris",
                    department: "Residential Architecture",
                    managerEmployeeId: "3068",
                    reports: [
                      {
                        employeeId: "3111",
                        firstName: "Lilianna",
                        lastName: "Bolton",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3110",
                        reports: []
                      },
                      {
                        employeeId: "3114",
                        firstName: "Wayne",
                        lastName: "Howard",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3110",
                        reports: []
                      },
                      {
                        employeeId: "3115",
                        firstName: "Toby",
                        lastName: "Hudson",
                        jobTitle: "Residential Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3110",
                        reports: []
                      },
                      {
                        employeeId: "3113",
                        firstName: "Hunter",
                        lastName: "Randolph",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3110",
                        reports: []
                      },
                      {
                        employeeId: "3117",
                        firstName: "Milana",
                        lastName: "Wilkerson",
                        jobTitle: "Residential Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3110",
                        reports: []
                      },
                      {
                        employeeId: "3112",
                        firstName: "Josue",
                        lastName: "Christensen",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Residential Architecture",
                        managerEmployeeId: "3110",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "2524",
            firstName: "Gerardo",
            lastName: "Collins",
            jobTitle: "Residential Architecture Manager-VII",
            officeLocation: "Paris",
            department: "Residential Architecture",
            managerEmployeeId: "2523",
            reports: [
              {
                employeeId: "2525",
                firstName: "Alan",
                lastName: "Hughes",
                jobTitle: "Senior Architect",
                officeLocation: "Paris",
                department: "Residential Architecture",
                managerEmployeeId: "2524",
                reports: []
              }
            ]
          },
          {
            employeeId: "2840",
            firstName: "Tucker",
            lastName: "Schmidt",
            jobTitle: "Vice President of Industrial Architecture",
            officeLocation: "Paris",
            department: "Industrial Architecture",
            managerEmployeeId: "2523",
            reports: [
              {
                employeeId: "2920",
                firstName: "Alicia",
                lastName: "Cuevas",
                jobTitle: "Staff Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2840",
                reports: [
                  {
                    employeeId: "2923",
                    firstName: "Leo",
                    lastName: "Jensen",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2920",
                    reports: []
                  },
                  {
                    employeeId: "2921",
                    firstName: "Landyn",
                    lastName: "Solis",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2920",
                    reports: []
                  },
                  {
                    employeeId: "2922",
                    firstName: "Nathanael",
                    lastName: "Randall",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2920",
                    reports: []
                  },
                  {
                    employeeId: "2924",
                    firstName: "Jaelyn",
                    lastName: "Hunt",
                    jobTitle: "Industrial Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2920",
                    reports: [
                      {
                        employeeId: "2925",
                        firstName: "Marina",
                        lastName: "Cook",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2924",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2926",
                firstName: "Rivka",
                lastName: "Wagner",
                jobTitle: "Industrial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2840",
                reports: [
                  {
                    employeeId: "2927",
                    firstName: "Kaylee",
                    lastName: "Campbell",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2926",
                    reports: []
                  },
                  {
                    employeeId: "2929",
                    firstName: "Darius",
                    lastName: "Abbott",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2926",
                    reports: [
                      {
                        employeeId: "2930",
                        firstName: "Konnor",
                        lastName: "Mitchell",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2929",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2928",
                    firstName: "Phoebe",
                    lastName: "Mack",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2926",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2908",
                firstName: "Bruce",
                lastName: "Brewer",
                jobTitle: "Staff Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2840",
                reports: [
                  {
                    employeeId: "2909",
                    firstName: "Thaddeus",
                    lastName: "Strong",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2908",
                    reports: []
                  },
                  {
                    employeeId: "2910",
                    firstName: "Marcel",
                    lastName: "Parker",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2908",
                    reports: []
                  },
                  {
                    employeeId: "2911",
                    firstName: "Micheal",
                    lastName: "Wiley",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2908",
                    reports: []
                  },
                  {
                    employeeId: "2912",
                    firstName: "Houston",
                    lastName: "Weiss",
                    jobTitle: "Industrial Architecture Manager-III",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2908",
                    reports: [
                      {
                        employeeId: "2913",
                        firstName: "Tobias",
                        lastName: "Houston",
                        jobTitle: "Architect-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2912",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2914",
                firstName: "Craig",
                lastName: "Marks",
                jobTitle: "Staff Architect",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2840",
                reports: [
                  {
                    employeeId: "2917",
                    firstName: "Mario",
                    lastName: "Fleming",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2914",
                    reports: []
                  },
                  {
                    employeeId: "2916",
                    firstName: "Kyla",
                    lastName: "Burns",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2914",
                    reports: []
                  },
                  {
                    employeeId: "2918",
                    firstName: "Ernest",
                    lastName: "Cortez",
                    jobTitle: "Industrial Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2914",
                    reports: [
                      {
                        employeeId: "2919",
                        firstName: "Urijah",
                        lastName: "Knapp",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2918",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2915",
                    firstName: "Thea",
                    lastName: "Mcmahon",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2914",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2841",
                firstName: "Asher",
                lastName: "Bailey",
                jobTitle: "Senior Director of Industrial Architecture",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2840",
                reports: [
                  {
                    employeeId: "2895",
                    firstName: "Caroline",
                    lastName: "Woodward",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2841",
                    reports: [
                      {
                        employeeId: "2896",
                        firstName: "Summer",
                        lastName: "Johnson",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2895",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2842",
                    firstName: "Adan",
                    lastName: "Meadows",
                    jobTitle: "Senior Director of Industrial Architecture",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2841",
                    reports: [
                      {
                        employeeId: "2852",
                        firstName: "Fernando",
                        lastName: "Mercado",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2842",
                        reports: []
                      },
                      {
                        employeeId: "2865",
                        firstName: "Jacqueline",
                        lastName: "House",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2842",
                        reports: []
                      },
                      {
                        employeeId: "2843",
                        firstName: "Lexi",
                        lastName: "Mccarty",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2842",
                        reports: []
                      },
                      {
                        employeeId: "2858",
                        firstName: "Ashlynn",
                        lastName: "Francis",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2842",
                        reports: []
                      },
                      {
                        employeeId: "2875",
                        firstName: "Alianna",
                        lastName: "Shelton",
                        jobTitle: "Industrial Architecture Manager-VI",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2842",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2885",
                    firstName: "Danna",
                    lastName: "Cruz",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2841",
                    reports: [
                      {
                        employeeId: "2886",
                        firstName: "Harlan",
                        lastName: "Parker",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2885",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2898",
                    firstName: "Brodie",
                    lastName: "Woods",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2841",
                    reports: [
                      {
                        employeeId: "2899",
                        firstName: "Landry",
                        lastName: "Mckenzie",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2898",
                        reports: []
                      },
                      {
                        employeeId: "2900",
                        firstName: "Estella",
                        lastName: "Vega",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2898",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2892",
                    firstName: "Felicity",
                    lastName: "Thompson",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2841",
                    reports: [
                      {
                        employeeId: "2893",
                        firstName: "Gibson",
                        lastName: "Buck",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2892",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2902",
                    firstName: "Devin",
                    lastName: "Harrell",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2841",
                    reports: [
                      {
                        employeeId: "2903",
                        firstName: "Dallas",
                        lastName: "Lin",
                        jobTitle: "Staff Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2902",
                        reports: []
                      },
                      {
                        employeeId: "2905",
                        firstName: "Skye",
                        lastName: "Hogan",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2902",
                        reports: []
                      },
                      {
                        employeeId: "2904",
                        firstName: "Greta",
                        lastName: "Steele",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2902",
                        reports: []
                      },
                      {
                        employeeId: "2906",
                        firstName: "Alonzo",
                        lastName: "Knox",
                        jobTitle: "Industrial Architecture Manager-III",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2902",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2890",
                    firstName: "Brecken",
                    lastName: "Spencer",
                    jobTitle: "Industrial Architecture Manager-V",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2841",
                    reports: [
                      {
                        employeeId: "2891",
                        firstName: "Kelly",
                        lastName: "Hubbard",
                        jobTitle: "Senior Architect",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2890",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2887",
                    firstName: "Jason",
                    lastName: "Morton",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2841",
                    reports: [
                      {
                        employeeId: "2888",
                        firstName: "Emilee",
                        lastName: "Cuevas",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2887",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "2937",
                firstName: "Cristian",
                lastName: "Benton",
                jobTitle: "Industrial Architecture Manager-VII",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2840",
                reports: [
                  {
                    employeeId: "2938",
                    firstName: "Lilyana",
                    lastName: "Bond",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2937",
                    reports: []
                  },
                  {
                    employeeId: "2942",
                    firstName: "Jordynn",
                    lastName: "Atkinson",
                    jobTitle: "Industrial Architecture Manager-VI",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2937",
                    reports: [
                      {
                        employeeId: "2943",
                        firstName: "Ariella",
                        lastName: "Shepherd",
                        jobTitle: "Industrial Architecture Manager-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2942",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2941",
                    firstName: "Kylen",
                    lastName: "Bean",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2937",
                    reports: []
                  },
                  {
                    employeeId: "2939",
                    firstName: "Crosby",
                    lastName: "Mcknight",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2937",
                    reports: []
                  },
                  {
                    employeeId: "2940",
                    firstName: "Yahya",
                    lastName: "Vang",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2937",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "2931",
                firstName: "Wade",
                lastName: "Saunders",
                jobTitle: "Industrial Architecture Manager-VI",
                officeLocation: "Paris",
                department: "Industrial Architecture",
                managerEmployeeId: "2840",
                reports: [
                  {
                    employeeId: "2935",
                    firstName: "Rene",
                    lastName: "Whitney",
                    jobTitle: "Industrial Architecture Manager-II",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2931",
                    reports: [
                      {
                        employeeId: "2936",
                        firstName: "Heidi",
                        lastName: "Hubbard",
                        jobTitle: "Architect-II",
                        officeLocation: "Paris",
                        department: "Industrial Architecture",
                        managerEmployeeId: "2935",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "2934",
                    firstName: "Annalee",
                    lastName: "Pearson",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2931",
                    reports: []
                  },
                  {
                    employeeId: "2933",
                    firstName: "Ashley",
                    lastName: "Cole",
                    jobTitle: "Staff Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2931",
                    reports: []
                  },
                  {
                    employeeId: "2932",
                    firstName: "Paula",
                    lastName: "Matthews",
                    jobTitle: "Senior Architect",
                    officeLocation: "Paris",
                    department: "Industrial Architecture",
                    managerEmployeeId: "2931",
                    reports: []
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        employeeId: "3157",
        firstName: "Cole",
        lastName: "Everett",
        jobTitle: "Fellow Account Manager",
        officeLocation: "Paris",
        department: "Sales",
        managerEmployeeId: "1",
        reports: [
          {
            employeeId: "3571",
            firstName: "Daleyza",
            lastName: "Pitts",
            jobTitle: "Vice President of Sales / Europe",
            officeLocation: "Paris",
            department: "Sales / Europe",
            managerEmployeeId: "3157",
            reports: [
              {
                employeeId: "3676",
                firstName: "Grady",
                lastName: "Dunlap",
                jobTitle: "Sales / Belgium Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Belgium",
                managerEmployeeId: "3571",
                reports: [
                  {
                    employeeId: "3681",
                    firstName: "Angel",
                    lastName: "Edwards",
                    jobTitle: "Sales / Belgium Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3676",
                    reports: [
                      {
                        employeeId: "3682",
                        firstName: "Jasiah",
                        lastName: "Kemp",
                        jobTitle: "Sales / Belgium Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3681",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3687",
                    firstName: "Maximilian",
                    lastName: "Rojas",
                    jobTitle: "Sales / Belgium Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3676",
                    reports: [
                      {
                        employeeId: "3688",
                        firstName: "Millie",
                        lastName: "Ruiz",
                        jobTitle: "Sales / Belgium Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3687",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3684",
                    firstName: "Adeline",
                    lastName: "Hutchinson",
                    jobTitle: "Sales / Belgium Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3676",
                    reports: [
                      {
                        employeeId: "3685",
                        firstName: "Johnathan",
                        lastName: "Mccarty",
                        jobTitle: "Sales / Belgium Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3684",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3678",
                    firstName: "Walker",
                    lastName: "Gamble",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3676",
                    reports: [
                      {
                        employeeId: "3679",
                        firstName: "Kassidy",
                        lastName: "Vaughn",
                        jobTitle: "Sales / Belgium Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3678",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3677",
                    firstName: "Alani",
                    lastName: "Crawford",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3676",
                    reports: []
                  },
                  {
                    employeeId: "3690",
                    firstName: "Ford",
                    lastName: "Torres",
                    jobTitle: "Sales / Belgium Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3676",
                    reports: [
                      {
                        employeeId: "3694",
                        firstName: "Graham",
                        lastName: "Rush",
                        jobTitle: "Sales / Belgium Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3690",
                        reports: []
                      },
                      {
                        employeeId: "3693",
                        firstName: "Andi",
                        lastName: "Kennedy",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3690",
                        reports: []
                      },
                      {
                        employeeId: "3691",
                        firstName: "Andrew",
                        lastName: "Sanders",
                        jobTitle: "Sales / Belgium Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3690",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3616",
                firstName: "Katalina",
                lastName: "Ramirez",
                jobTitle: "Sales / Italy Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Italy",
                managerEmployeeId: "3571",
                reports: [
                  {
                    employeeId: "3620",
                    firstName: "Kassidy",
                    lastName: "Welch",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Italy",
                    managerEmployeeId: "3616",
                    reports: [
                      {
                        employeeId: "3621",
                        firstName: "Pierce",
                        lastName: "Drake",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3620",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3617",
                    firstName: "Joshua",
                    lastName: "Shelton",
                    jobTitle: "Sales / Italy Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Italy",
                    managerEmployeeId: "3616",
                    reports: [
                      {
                        employeeId: "3618",
                        firstName: "Zoe",
                        lastName: "Wade",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3617",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3632",
                    firstName: "Lilith",
                    lastName: "Mejia",
                    jobTitle: "Sales / Italy Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Italy",
                    managerEmployeeId: "3616",
                    reports: [
                      {
                        employeeId: "3633",
                        firstName: "Nickolas",
                        lastName: "Chang",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3632",
                        reports: []
                      },
                      {
                        employeeId: "3635",
                        firstName: "Zariyah",
                        lastName: "Carlson",
                        jobTitle: "Sales / Italy Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3632",
                        reports: []
                      },
                      {
                        employeeId: "3634",
                        firstName: "Brent",
                        lastName: "Murillo",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3632",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3627",
                    firstName: "Ashlynn",
                    lastName: "Duffy",
                    jobTitle: "Sales / Italy Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Italy",
                    managerEmployeeId: "3616",
                    reports: [
                      {
                        employeeId: "3629",
                        firstName: "Billy",
                        lastName: "Murray",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3627",
                        reports: []
                      },
                      {
                        employeeId: "3628",
                        firstName: "Daniela",
                        lastName: "Sawyer",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3627",
                        reports: []
                      },
                      {
                        employeeId: "3630",
                        firstName: "Marvin",
                        lastName: "Holt",
                        jobTitle: "Sales / Italy Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3627",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3623",
                    firstName: "Ramon",
                    lastName: "Hancock",
                    jobTitle: "Sales / Italy Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Italy",
                    managerEmployeeId: "3616",
                    reports: [
                      {
                        employeeId: "3625",
                        firstName: "Eli",
                        lastName: "Bullock",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3623",
                        reports: []
                      },
                      {
                        employeeId: "3624",
                        firstName: "Graysen",
                        lastName: "Krueger",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Italy",
                        managerEmployeeId: "3623",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3657",
                firstName: "Heather",
                lastName: "Doyle",
                jobTitle: "Sales / UK Director",
                officeLocation: "Paris",
                department: "Sales / UK",
                managerEmployeeId: "3571",
                reports: [
                  {
                    employeeId: "3666",
                    firstName: "Romeo",
                    lastName: "Skinner",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3657",
                    reports: [
                      {
                        employeeId: "3667",
                        firstName: "Carl",
                        lastName: "Terry",
                        jobTitle: "Sales / UK Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3666",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3659",
                    firstName: "Titan",
                    lastName: "Estes",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3657",
                    reports: []
                  },
                  {
                    employeeId: "3664",
                    firstName: "Adriana",
                    lastName: "Payne",
                    jobTitle: "Account Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3657",
                    reports: [
                      {
                        employeeId: "3665",
                        firstName: "Zaire",
                        lastName: "Hart",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3664",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3660",
                    firstName: "Faye",
                    lastName: "Bradshaw",
                    jobTitle: "Sales / UK Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3657",
                    reports: [
                      {
                        employeeId: "3661",
                        firstName: "Nala",
                        lastName: "Reed",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3660",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3662",
                    firstName: "Jadiel",
                    lastName: "Gutierrez",
                    jobTitle: "Sales / UK Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3657",
                    reports: [
                      {
                        employeeId: "3663",
                        firstName: "Maci",
                        lastName: "Guzman",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3662",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3658",
                    firstName: "Briggs",
                    lastName: "Williamson",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3657",
                    reports: []
                  },
                  {
                    employeeId: "3671",
                    firstName: "Summer",
                    lastName: "Kerr",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3657",
                    reports: [
                      {
                        employeeId: "3674",
                        firstName: "Derek",
                        lastName: "Chandler",
                        jobTitle: "Sales / UK Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3671",
                        reports: []
                      },
                      {
                        employeeId: "3672",
                        firstName: "Dennis",
                        lastName: "Davidson",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3671",
                        reports: []
                      },
                      {
                        employeeId: "3673",
                        firstName: "Jakob",
                        lastName: "Forbes",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3671",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3669",
                    firstName: "Aleena",
                    lastName: "Dickson",
                    jobTitle: "Sales / UK Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3657",
                    reports: [
                      {
                        employeeId: "3670",
                        firstName: "Hector",
                        lastName: "Hooper",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3669",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3637",
                firstName: "Kohen",
                lastName: "Phelps",
                jobTitle: "Sales / Belgium Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Belgium",
                managerEmployeeId: "3571",
                reports: [
                  {
                    employeeId: "3648",
                    firstName: "Kingston",
                    lastName: "Lang",
                    jobTitle: "Sales / Belgium Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3637",
                    reports: [
                      {
                        employeeId: "3650",
                        firstName: "Barrett",
                        lastName: "Bowers",
                        jobTitle: "Sales / Belgium Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3648",
                        reports: []
                      },
                      {
                        employeeId: "3649",
                        firstName: "Noe",
                        lastName: "Perkins",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3648",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3640",
                    firstName: "Jessie",
                    lastName: "Nash",
                    jobTitle: "Sales / Belgium Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3637",
                    reports: [
                      {
                        employeeId: "3641",
                        firstName: "Ray",
                        lastName: "Harrison",
                        jobTitle: "Sales / Belgium Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3640",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3646",
                    firstName: "Nayeli",
                    lastName: "Rojas",
                    jobTitle: "Sales / Belgium Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3637",
                    reports: [
                      {
                        employeeId: "3647",
                        firstName: "Amaris",
                        lastName: "Klein",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3646",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3652",
                    firstName: "Eduardo",
                    lastName: "Hamilton",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3637",
                    reports: [
                      {
                        employeeId: "3654",
                        firstName: "Tanner",
                        lastName: "Joyce",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3652",
                        reports: []
                      },
                      {
                        employeeId: "3653",
                        firstName: "Brett",
                        lastName: "Pruitt",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3652",
                        reports: []
                      },
                      {
                        employeeId: "3655",
                        firstName: "Tabitha",
                        lastName: "Snow",
                        jobTitle: "Sales / Belgium Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3652",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3638",
                    firstName: "Celeste",
                    lastName: "Huber",
                    jobTitle: "Account Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3637",
                    reports: [
                      {
                        employeeId: "3639",
                        firstName: "Rocky",
                        lastName: "Levy",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3638",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3643",
                    firstName: "Tatum",
                    lastName: "Klein",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3637",
                    reports: [
                      {
                        employeeId: "3644",
                        firstName: "Margot",
                        lastName: "Gallagher",
                        jobTitle: "Sales / Belgium Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3643",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3696",
                firstName: "Mariana",
                lastName: "Solomon",
                jobTitle: "Sales / UK Director",
                officeLocation: "Paris",
                department: "Sales / UK",
                managerEmployeeId: "3571",
                reports: [
                  {
                    employeeId: "3713",
                    firstName: "Brent",
                    lastName: "Jimenez",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3696",
                    reports: [
                      {
                        employeeId: "3715",
                        firstName: "Ashton",
                        lastName: "Poole",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3713",
                        reports: []
                      },
                      {
                        employeeId: "3716",
                        firstName: "Mathew",
                        lastName: "Williams",
                        jobTitle: "Sales / UK Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3713",
                        reports: []
                      },
                      {
                        employeeId: "3714",
                        firstName: "Zane",
                        lastName: "Ferguson",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3713",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3718",
                    firstName: "Mallory",
                    lastName: "Jefferson",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3696",
                    reports: [
                      {
                        employeeId: "3723",
                        firstName: "Brittany",
                        lastName: "Mcdaniel",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3718",
                        reports: []
                      },
                      {
                        employeeId: "3721",
                        firstName: "Adonis",
                        lastName: "Frank",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3718",
                        reports: []
                      },
                      {
                        employeeId: "3724",
                        firstName: "Abdullah",
                        lastName: "Richardson",
                        jobTitle: "Sales / UK Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3718",
                        reports: []
                      },
                      {
                        employeeId: "3719",
                        firstName: "Carlee",
                        lastName: "Erickson",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3718",
                        reports: []
                      },
                      {
                        employeeId: "3722",
                        firstName: "Ramon",
                        lastName: "Parks",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3718",
                        reports: []
                      },
                      {
                        employeeId: "3720",
                        firstName: "Rocky",
                        lastName: "Lucas",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3718",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3709",
                    firstName: "Muhammad",
                    lastName: "Howell",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3696",
                    reports: [
                      {
                        employeeId: "3711",
                        firstName: "Cayden",
                        lastName: "Wise",
                        jobTitle: "Sales / UK Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3709",
                        reports: []
                      },
                      {
                        employeeId: "3710",
                        firstName: "Eileen",
                        lastName: "Roman",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3709",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3703",
                    firstName: "Maximo",
                    lastName: "Blake",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3696",
                    reports: [
                      {
                        employeeId: "3704",
                        firstName: "Desmond",
                        lastName: "Waller",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3703",
                        reports: []
                      },
                      {
                        employeeId: "3706",
                        firstName: "King",
                        lastName: "Rodgers",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3703",
                        reports: []
                      },
                      {
                        employeeId: "3707",
                        firstName: "Drew",
                        lastName: "Dodson",
                        jobTitle: "Sales / UK Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3703",
                        reports: []
                      },
                      {
                        employeeId: "3705",
                        firstName: "Jared",
                        lastName: "Dillon",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3703",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3697",
                    firstName: "Coen",
                    lastName: "Hendrix",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3696",
                    reports: [
                      {
                        employeeId: "3701",
                        firstName: "Meilani",
                        lastName: "Pitts",
                        jobTitle: "Sales / UK Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3697",
                        reports: []
                      },
                      {
                        employeeId: "3699",
                        firstName: "Mia",
                        lastName: "Douglas",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3697",
                        reports: []
                      },
                      {
                        employeeId: "3698",
                        firstName: "Virginia",
                        lastName: "Arnold",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3697",
                        reports: []
                      },
                      {
                        employeeId: "3700",
                        firstName: "Janessa",
                        lastName: "Lucas",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3697",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3572",
                firstName: "Rodolfo",
                lastName: "Hawkins",
                jobTitle: "Sales / Belgium Director",
                officeLocation: "Paris",
                department: "Sales / Belgium",
                managerEmployeeId: "3571",
                reports: [
                  {
                    employeeId: "3586",
                    firstName: "Clara",
                    lastName: "Conley",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3572",
                    reports: [
                      {
                        employeeId: "3587",
                        firstName: "Ricky",
                        lastName: "Harrell",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3586",
                        reports: []
                      },
                      {
                        employeeId: "3588",
                        firstName: "Emilio",
                        lastName: "Ibarra",
                        jobTitle: "Sales / Belgium Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3586",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3577",
                    firstName: "Jensen",
                    lastName: "Burnett",
                    jobTitle: "Sales / Belgium Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3572",
                    reports: [
                      {
                        employeeId: "3578",
                        firstName: "Scarlett",
                        lastName: "Pham",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3577",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3590",
                    firstName: "Amara",
                    lastName: "Hurst",
                    jobTitle: "Sales / Belgium Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3572",
                    reports: [
                      {
                        employeeId: "3591",
                        firstName: "Kannon",
                        lastName: "Best",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3590",
                        reports: []
                      },
                      {
                        employeeId: "3593",
                        firstName: "Tatum",
                        lastName: "Becker",
                        jobTitle: "Sales / Belgium Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3590",
                        reports: []
                      },
                      {
                        employeeId: "3592",
                        firstName: "Brandon",
                        lastName: "Ford",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3590",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3579",
                    firstName: "Tara",
                    lastName: "Valdez",
                    jobTitle: "Sales / Belgium Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3572",
                    reports: [
                      {
                        employeeId: "3580",
                        firstName: "Kiley",
                        lastName: "Zuniga",
                        jobTitle: "Sales / Belgium Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3579",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3573",
                    firstName: "Nixon",
                    lastName: "Ortega",
                    jobTitle: "Sales / Belgium Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3572",
                    reports: [
                      {
                        employeeId: "3574",
                        firstName: "Skyler",
                        lastName: "Daugherty",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3573",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3582",
                    firstName: "Esmeralda",
                    lastName: "Tucker",
                    jobTitle: "Sales / Belgium Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3572",
                    reports: [
                      {
                        employeeId: "3584",
                        firstName: "Osvaldo",
                        lastName: "Osborne",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3582",
                        reports: []
                      },
                      {
                        employeeId: "3583",
                        firstName: "Brady",
                        lastName: "Buck",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3582",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3575",
                    firstName: "April",
                    lastName: "Wong",
                    jobTitle: "Sales / Belgium Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Belgium",
                    managerEmployeeId: "3572",
                    reports: [
                      {
                        employeeId: "3576",
                        firstName: "Alayna",
                        lastName: "Maynard",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Belgium",
                        managerEmployeeId: "3575",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3595",
                firstName: "Willie",
                lastName: "Atkinson",
                jobTitle: "Sales / UK Director",
                officeLocation: "Paris",
                department: "Sales / UK",
                managerEmployeeId: "3571",
                reports: [
                  {
                    employeeId: "3610",
                    firstName: "Jaxton",
                    lastName: "Haney",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3595",
                    reports: [
                      {
                        employeeId: "3613",
                        firstName: "Trinity",
                        lastName: "Arellano",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3610",
                        reports: []
                      },
                      {
                        employeeId: "3611",
                        firstName: "Arian",
                        lastName: "Davenport",
                        jobTitle: "Sales / UK Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3610",
                        reports: []
                      },
                      {
                        employeeId: "3614",
                        firstName: "Cali",
                        lastName: "Simon",
                        jobTitle: "Sales / UK Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3610",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3606",
                    firstName: "Hamza",
                    lastName: "Proctor",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3595",
                    reports: [
                      {
                        employeeId: "3607",
                        firstName: "Melvin",
                        lastName: "Warren",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3606",
                        reports: []
                      },
                      {
                        employeeId: "3608",
                        firstName: "Autumn",
                        lastName: "Carey",
                        jobTitle: "Sales / UK Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3606",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3596",
                    firstName: "Jasmine",
                    lastName: "Joseph",
                    jobTitle: "Sales / UK Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3595",
                    reports: [
                      {
                        employeeId: "3597",
                        firstName: "Case",
                        lastName: "Vasquez",
                        jobTitle: "Sales / UK Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3596",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3602",
                    firstName: "Jolene",
                    lastName: "Whitaker",
                    jobTitle: "Sales / UK Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3595",
                    reports: [
                      {
                        employeeId: "3604",
                        firstName: "Abigail",
                        lastName: "Finley",
                        jobTitle: "Sales / UK Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3602",
                        reports: []
                      },
                      {
                        employeeId: "3603",
                        firstName: "Dalary",
                        lastName: "Mcneil",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3602",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3599",
                    firstName: "Lola",
                    lastName: "Robles",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / UK",
                    managerEmployeeId: "3595",
                    reports: [
                      {
                        employeeId: "3600",
                        firstName: "Heath",
                        lastName: "Lozano",
                        jobTitle: "Sales / UK Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / UK",
                        managerEmployeeId: "3599",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "3886",
            firstName: "Ellison",
            lastName: "Dougherty",
            jobTitle: "Distinguished Account Manager",
            officeLocation: "Paris",
            department: "Sales / Asia",
            managerEmployeeId: "3157",
            reports: [
              {
                employeeId: "3947",
                firstName: "Jerry",
                lastName: "Morgan",
                jobTitle: "Sales / Uzbekistan Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Uzbekistan",
                managerEmployeeId: "3886",
                reports: [
                  {
                    employeeId: "3956",
                    firstName: "Ana",
                    lastName: "Fields",
                    jobTitle: "Sales / Uzbekistan Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Uzbekistan",
                    managerEmployeeId: "3947",
                    reports: [
                      {
                        employeeId: "3957",
                        firstName: "Victoria",
                        lastName: "Maddox",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Uzbekistan",
                        managerEmployeeId: "3956",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3955",
                    firstName: "Jaylene",
                    lastName: "Frederick",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Uzbekistan",
                    managerEmployeeId: "3947",
                    reports: []
                  },
                  {
                    employeeId: "3948",
                    firstName: "Judah",
                    lastName: "Soto",
                    jobTitle: "Sales / Uzbekistan Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Uzbekistan",
                    managerEmployeeId: "3947",
                    reports: [
                      {
                        employeeId: "3952",
                        firstName: "Jamie",
                        lastName: "Fischer",
                        jobTitle: "Sales / Uzbekistan Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Uzbekistan",
                        managerEmployeeId: "3948",
                        reports: []
                      },
                      {
                        employeeId: "3949",
                        firstName: "Paris",
                        lastName: "Mendez",
                        jobTitle: "Sales / Uzbekistan Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Uzbekistan",
                        managerEmployeeId: "3948",
                        reports: []
                      },
                      {
                        employeeId: "3951",
                        firstName: "Henrik",
                        lastName: "Villanueva",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Uzbekistan",
                        managerEmployeeId: "3948",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3954",
                    firstName: "Santana",
                    lastName: "Erickson",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Uzbekistan",
                    managerEmployeeId: "3947",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3969",
                firstName: "Mohammed",
                lastName: "Sharp",
                jobTitle: "Sales / India Manager-VI",
                officeLocation: "Paris",
                department: "Sales / India",
                managerEmployeeId: "3886",
                reports: [
                  {
                    employeeId: "3972",
                    firstName: "Kenna",
                    lastName: "French",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3969",
                    reports: []
                  },
                  {
                    employeeId: "3973",
                    firstName: "Harlan",
                    lastName: "Roberts",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3969",
                    reports: []
                  },
                  {
                    employeeId: "3971",
                    firstName: "Izabella",
                    lastName: "Middleton",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3969",
                    reports: []
                  },
                  {
                    employeeId: "3970",
                    firstName: "Andy",
                    lastName: "Thornton",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3969",
                    reports: []
                  },
                  {
                    employeeId: "3974",
                    firstName: "Maleah",
                    lastName: "Livingston",
                    jobTitle: "Sales / India Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3969",
                    reports: [
                      {
                        employeeId: "3975",
                        firstName: "Alyson",
                        lastName: "Santiago",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / India",
                        managerEmployeeId: "3974",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3887",
                firstName: "Jayce",
                lastName: "Harper",
                jobTitle: "Senior Director of Sales / China",
                officeLocation: "Paris",
                department: "Sales / China",
                managerEmployeeId: "3886",
                reports: [
                  {
                    employeeId: "3941",
                    firstName: "Kyndall",
                    lastName: "Kim",
                    jobTitle: "Sales / China Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3887",
                    reports: [
                      {
                        employeeId: "3943",
                        firstName: "Aldo",
                        lastName: "Sellers",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3941",
                        reports: []
                      },
                      {
                        employeeId: "3942",
                        firstName: "Rosie",
                        lastName: "Nolan",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3941",
                        reports: []
                      },
                      {
                        employeeId: "3944",
                        firstName: "Otis",
                        lastName: "Cobb",
                        jobTitle: "Sales / China Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3941",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3929",
                    firstName: "Blair",
                    lastName: "Collins",
                    jobTitle: "Sales / China Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3887",
                    reports: [
                      {
                        employeeId: "3930",
                        firstName: "Faith",
                        lastName: "Travis",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3929",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3931",
                    firstName: "Kenzie",
                    lastName: "Arroyo",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3887",
                    reports: [
                      {
                        employeeId: "3932",
                        firstName: "Dangelo",
                        lastName: "Ellis",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3931",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3935",
                    firstName: "Ayaan",
                    lastName: "Frazier",
                    jobTitle: "Sales / China Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3887",
                    reports: [
                      {
                        employeeId: "3936",
                        firstName: "Mary",
                        lastName: "Kidd",
                        jobTitle: "Sales / China Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3935",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3888",
                    firstName: "Kolten",
                    lastName: "Powers",
                    jobTitle: "Sales / China Director",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3887",
                    reports: [
                      {
                        employeeId: "3890",
                        firstName: "Moises",
                        lastName: "Arroyo",
                        jobTitle: "Sales / China Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3888",
                        reports: []
                      },
                      {
                        employeeId: "3895",
                        firstName: "Steve",
                        lastName: "Rubio",
                        jobTitle: "Sales / China Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3888",
                        reports: []
                      },
                      {
                        employeeId: "3900",
                        firstName: "Rowen",
                        lastName: "Bailey",
                        jobTitle: "Sales / China Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3888",
                        reports: []
                      },
                      {
                        employeeId: "3905",
                        firstName: "Andi",
                        lastName: "Cox",
                        jobTitle: "Sales / China Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3888",
                        reports: []
                      },
                      {
                        employeeId: "3889",
                        firstName: "Davian",
                        lastName: "Larson",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3888",
                        reports: []
                      },
                      {
                        employeeId: "3909",
                        firstName: "Rhea",
                        lastName: "Vaughan",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3888",
                        reports: []
                      },
                      {
                        employeeId: "3914",
                        firstName: "Jase",
                        lastName: "Sims",
                        jobTitle: "Sales / China Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3888",
                        reports: []
                      },
                      {
                        employeeId: "3920",
                        firstName: "Lochlan",
                        lastName: "Evans",
                        jobTitle: "Sales / China Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3888",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3933",
                    firstName: "Jerome",
                    lastName: "Werner",
                    jobTitle: "Sales / China Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3887",
                    reports: [
                      {
                        employeeId: "3934",
                        firstName: "Paloma",
                        lastName: "Harding",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3933",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3938",
                    firstName: "Hattie",
                    lastName: "Curtis",
                    jobTitle: "Sales / China Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3887",
                    reports: [
                      {
                        employeeId: "3939",
                        firstName: "Alani",
                        lastName: "Melton",
                        jobTitle: "Sales / China Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3938",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3990",
                firstName: "Peter",
                lastName: "Riggs",
                jobTitle: "Sales / China Manager-VII",
                officeLocation: "Paris",
                department: "Sales / China",
                managerEmployeeId: "3886",
                reports: [
                  {
                    employeeId: "3991",
                    firstName: "Gunnar",
                    lastName: "Burns",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3990",
                    reports: []
                  },
                  {
                    employeeId: "3996",
                    firstName: "Anna",
                    lastName: "Abbott",
                    jobTitle: "Sales / China Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3990",
                    reports: [
                      {
                        employeeId: "3997",
                        firstName: "Bridget",
                        lastName: "Glass",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3996",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3992",
                    firstName: "Roy",
                    lastName: "Luna",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3990",
                    reports: []
                  },
                  {
                    employeeId: "3993",
                    firstName: "Angelique",
                    lastName: "Kaiser",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3990",
                    reports: []
                  },
                  {
                    employeeId: "3998",
                    firstName: "Kayden",
                    lastName: "Moyer",
                    jobTitle: "Sales / China Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3990",
                    reports: [
                      {
                        employeeId: "4000",
                        firstName: "Joshua",
                        lastName: "Jacobson",
                        jobTitle: "Sales / China Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3998",
                        reports: []
                      },
                      {
                        employeeId: "3999",
                        firstName: "King",
                        lastName: "Hunt",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3998",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3994",
                    firstName: "Jemma",
                    lastName: "Holden",
                    jobTitle: "Sales / China Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / China",
                    managerEmployeeId: "3990",
                    reports: [
                      {
                        employeeId: "3995",
                        firstName: "Karlie",
                        lastName: "Stark",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / China",
                        managerEmployeeId: "3994",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3958",
                firstName: "Danielle",
                lastName: "Gardner",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Paris",
                department: "Sales / Kazakhstan",
                managerEmployeeId: "3886",
                reports: [
                  {
                    employeeId: "3961",
                    firstName: "Ismael",
                    lastName: "Padilla",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Kazakhstan",
                    managerEmployeeId: "3958",
                    reports: []
                  },
                  {
                    employeeId: "3962",
                    firstName: "Dorothy",
                    lastName: "West",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Kazakhstan",
                    managerEmployeeId: "3958",
                    reports: []
                  },
                  {
                    employeeId: "3959",
                    firstName: "Keagan",
                    lastName: "Curtis",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Kazakhstan",
                    managerEmployeeId: "3958",
                    reports: []
                  },
                  {
                    employeeId: "3966",
                    firstName: "Sincere",
                    lastName: "Wilkinson",
                    jobTitle: "Sales / Kazakhstan Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Kazakhstan",
                    managerEmployeeId: "3958",
                    reports: [
                      {
                        employeeId: "3967",
                        firstName: "Mira",
                        lastName: "Lowery",
                        jobTitle: "Sales / Kazakhstan Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Kazakhstan",
                        managerEmployeeId: "3966",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3963",
                    firstName: "Abram",
                    lastName: "Long",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Kazakhstan",
                    managerEmployeeId: "3958",
                    reports: []
                  },
                  {
                    employeeId: "3965",
                    firstName: "Jaziel",
                    lastName: "Clayton",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Kazakhstan",
                    managerEmployeeId: "3958",
                    reports: []
                  },
                  {
                    employeeId: "3964",
                    firstName: "Axton",
                    lastName: "Horn",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Kazakhstan",
                    managerEmployeeId: "3958",
                    reports: []
                  },
                  {
                    employeeId: "3960",
                    firstName: "Benjamin",
                    lastName: "Page",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Kazakhstan",
                    managerEmployeeId: "3958",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3946",
                firstName: "Jeffery",
                lastName: "Oconnor",
                jobTitle: "Senior Account Manager",
                officeLocation: "Paris",
                department: "Sales / China",
                managerEmployeeId: "3886",
                reports: []
              },
              {
                employeeId: "3977",
                firstName: "Zavier",
                lastName: "Goodman",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Paris",
                department: "Sales / India",
                managerEmployeeId: "3886",
                reports: [
                  {
                    employeeId: "3986",
                    firstName: "Anabelle",
                    lastName: "Mullins",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3977",
                    reports: []
                  },
                  {
                    employeeId: "3978",
                    firstName: "Dwayne",
                    lastName: "Khan",
                    jobTitle: "Sales / India Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3977",
                    reports: [
                      {
                        employeeId: "3981",
                        firstName: "Veda",
                        lastName: "Hensley",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / India",
                        managerEmployeeId: "3978",
                        reports: []
                      },
                      {
                        employeeId: "3979",
                        firstName: "Dominik",
                        lastName: "Zavala",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / India",
                        managerEmployeeId: "3978",
                        reports: []
                      },
                      {
                        employeeId: "3982",
                        firstName: "Vivienne",
                        lastName: "Riggs",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / India",
                        managerEmployeeId: "3978",
                        reports: []
                      },
                      {
                        employeeId: "3983",
                        firstName: "Alessandro",
                        lastName: "Fletcher",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / India",
                        managerEmployeeId: "3978",
                        reports: []
                      },
                      {
                        employeeId: "3980",
                        firstName: "Meadow",
                        lastName: "Choi",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / India",
                        managerEmployeeId: "3978",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3985",
                    firstName: "Boden",
                    lastName: "Mcmillan",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3977",
                    reports: []
                  },
                  {
                    employeeId: "3988",
                    firstName: "Arabella",
                    lastName: "Spears",
                    jobTitle: "Sales / India Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3977",
                    reports: [
                      {
                        employeeId: "3989",
                        firstName: "Katelynn",
                        lastName: "Bird",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / India",
                        managerEmployeeId: "3988",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3987",
                    firstName: "Dario",
                    lastName: "Huffman",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / India",
                    managerEmployeeId: "3977",
                    reports: []
                  }
                ]
              }
            ]
          },
          {
            employeeId: "3158",
            firstName: "Aaron",
            lastName: "Hahn",
            jobTitle: "Fellow Account Manager",
            officeLocation: "Paris",
            department: "Sales / Middle East",
            managerEmployeeId: "3157",
            reports: [
              {
                employeeId: "3281",
                firstName: "Rhys",
                lastName: "Gray",
                jobTitle: "Principal Account Manager",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3158",
                reports: [
                  {
                    employeeId: "3295",
                    firstName: "Evan",
                    lastName: "Malone",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3281",
                    reports: [
                      {
                        employeeId: "3296",
                        firstName: "Kenia",
                        lastName: "Moses",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3295",
                        reports: []
                      },
                      {
                        employeeId: "3297",
                        firstName: "Emory",
                        lastName: "Walter",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3295",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3286",
                    firstName: "Kaylin",
                    lastName: "Gibbs",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3281",
                    reports: [
                      {
                        employeeId: "3289",
                        firstName: "Izaiah",
                        lastName: "Whitaker",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3286",
                        reports: []
                      },
                      {
                        employeeId: "3288",
                        firstName: "Bonnie",
                        lastName: "Booth",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3286",
                        reports: []
                      },
                      {
                        employeeId: "3287",
                        firstName: "Emerson",
                        lastName: "Scott",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3286",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3282",
                    firstName: "Ezequiel",
                    lastName: "Stephenson",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3281",
                    reports: [
                      {
                        employeeId: "3283",
                        firstName: "Ryan",
                        lastName: "Wiley",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3282",
                        reports: []
                      },
                      {
                        employeeId: "3284",
                        firstName: "Bella",
                        lastName: "Cochran",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3282",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3299",
                    firstName: "Lillianna",
                    lastName: "Owen",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3281",
                    reports: [
                      {
                        employeeId: "3300",
                        firstName: "Annabelle",
                        lastName: "Chang",
                        jobTitle: "Sales / Libya Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3299",
                        reports: []
                      },
                      {
                        employeeId: "3304",
                        firstName: "Khalil",
                        lastName: "Hester",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3299",
                        reports: []
                      },
                      {
                        employeeId: "3303",
                        firstName: "Kade",
                        lastName: "Mcclure",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3299",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3291",
                    firstName: "Aidan",
                    lastName: "Copeland",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3281",
                    reports: [
                      {
                        employeeId: "3292",
                        firstName: "Karlee",
                        lastName: "Krueger",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3291",
                        reports: []
                      },
                      {
                        employeeId: "3293",
                        firstName: "Madelyn",
                        lastName: "Krause",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3291",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3249",
                firstName: "Brayden",
                lastName: "Fletcher",
                jobTitle: "Senior Director of Sales / Saudi Arabia",
                officeLocation: "Paris",
                department: "Sales / Saudi Arabia",
                managerEmployeeId: "3158",
                reports: [
                  {
                    employeeId: "3267",
                    firstName: "Dylan",
                    lastName: "Miranda",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3249",
                    reports: [
                      {
                        employeeId: "3270",
                        firstName: "Jemma",
                        lastName: "Baldwin",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3267",
                        reports: []
                      },
                      {
                        employeeId: "3271",
                        firstName: "Joy",
                        lastName: "Solis",
                        jobTitle: "Sales / Saudi Arabia Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3267",
                        reports: []
                      },
                      {
                        employeeId: "3268",
                        firstName: "Lachlan",
                        lastName: "Schaefer",
                        jobTitle: "Sales / Saudi Arabia Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3267",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3273",
                    firstName: "London",
                    lastName: "Sosa",
                    jobTitle: "Sales / Saudi Arabia Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3249",
                    reports: [
                      {
                        employeeId: "3277",
                        firstName: "Deshawn",
                        lastName: "Johns",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3273",
                        reports: []
                      },
                      {
                        employeeId: "3275",
                        firstName: "Salma",
                        lastName: "Figueroa",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3273",
                        reports: []
                      },
                      {
                        employeeId: "3274",
                        firstName: "Landen",
                        lastName: "Mcmahon",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3273",
                        reports: []
                      },
                      {
                        employeeId: "3278",
                        firstName: "Odin",
                        lastName: "Fields",
                        jobTitle: "Sales / Saudi Arabia Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3273",
                        reports: []
                      },
                      {
                        employeeId: "3276",
                        firstName: "Sara",
                        lastName: "Ibarra",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3273",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3262",
                    firstName: "Lawson",
                    lastName: "Bennett",
                    jobTitle: "Sales / Saudi Arabia Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3249",
                    reports: [
                      {
                        employeeId: "3264",
                        firstName: "Phoebe",
                        lastName: "Duncan",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3262",
                        reports: []
                      },
                      {
                        employeeId: "3265",
                        firstName: "Arden",
                        lastName: "Cooley",
                        jobTitle: "Sales / Saudi Arabia Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3262",
                        reports: []
                      },
                      {
                        employeeId: "3263",
                        firstName: "Bonnie",
                        lastName: "Berry",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3262",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3250",
                    firstName: "Heavenly",
                    lastName: "Rhodes",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3249",
                    reports: [
                      {
                        employeeId: "3252",
                        firstName: "Charlee",
                        lastName: "Fuentes",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3250",
                        reports: []
                      },
                      {
                        employeeId: "3251",
                        firstName: "Kelvin",
                        lastName: "Lowery",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3250",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3258",
                    firstName: "Jillian",
                    lastName: "Best",
                    jobTitle: "Sales / Saudi Arabia Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3249",
                    reports: [
                      {
                        employeeId: "3259",
                        firstName: "Terrance",
                        lastName: "Oliver",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3258",
                        reports: []
                      },
                      {
                        employeeId: "3260",
                        firstName: "Renata",
                        lastName: "Williams",
                        jobTitle: "Sales / Saudi Arabia Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3258",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3254",
                    firstName: "Jaziel",
                    lastName: "Marshall",
                    jobTitle: "Sales / Saudi Arabia Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3249",
                    reports: [
                      {
                        employeeId: "3256",
                        firstName: "Ricky",
                        lastName: "Newton",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3254",
                        reports: []
                      },
                      {
                        employeeId: "3255",
                        firstName: "Thomas",
                        lastName: "Herman",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3254",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3191",
                firstName: "Sariyah",
                lastName: "Rubio",
                jobTitle: "Senior Director of Sales / Libya",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3158",
                reports: [
                  {
                    employeeId: "3200",
                    firstName: "Abdiel",
                    lastName: "Powers",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3191",
                    reports: [
                      {
                        employeeId: "3201",
                        firstName: "Kylee",
                        lastName: "Mercer",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3200",
                        reports: []
                      },
                      {
                        employeeId: "3202",
                        firstName: "Caiden",
                        lastName: "Santana",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3200",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3192",
                    firstName: "Aisha",
                    lastName: "Fischer",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3191",
                    reports: [
                      {
                        employeeId: "3193",
                        firstName: "Braxton",
                        lastName: "Butler",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3192",
                        reports: []
                      },
                      {
                        employeeId: "3194",
                        firstName: "Cristopher",
                        lastName: "Todd",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3192",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3214",
                    firstName: "Nathaly",
                    lastName: "Summers",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3191",
                    reports: [
                      {
                        employeeId: "3217",
                        firstName: "Adelaide",
                        lastName: "Walton",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3214",
                        reports: []
                      },
                      {
                        employeeId: "3216",
                        firstName: "Tatiana",
                        lastName: "Wilcox",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3214",
                        reports: []
                      },
                      {
                        employeeId: "3218",
                        firstName: "Zachary",
                        lastName: "Lutz",
                        jobTitle: "Sales / Libya Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3214",
                        reports: []
                      },
                      {
                        employeeId: "3215",
                        firstName: "Jazlyn",
                        lastName: "Ryan",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3214",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3204",
                    firstName: "Nikolai",
                    lastName: "Flores",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3191",
                    reports: [
                      {
                        employeeId: "3205",
                        firstName: "Raylan",
                        lastName: "Brooks",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3204",
                        reports: []
                      },
                      {
                        employeeId: "3206",
                        firstName: "Nathaniel",
                        lastName: "Floyd",
                        jobTitle: "Sales / Libya Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3204",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3196",
                    firstName: "Dallas",
                    lastName: "Contreras",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3191",
                    reports: [
                      {
                        employeeId: "3198",
                        firstName: "Daniela",
                        lastName: "Black",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3196",
                        reports: []
                      },
                      {
                        employeeId: "3197",
                        firstName: "Walter",
                        lastName: "Foley",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3196",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3208",
                    firstName: "Peyton",
                    lastName: "Mccormick",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3191",
                    reports: [
                      {
                        employeeId: "3212",
                        firstName: "Ezra",
                        lastName: "Raymond",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3208",
                        reports: []
                      },
                      {
                        employeeId: "3211",
                        firstName: "Rachel",
                        lastName: "Mccann",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3208",
                        reports: []
                      },
                      {
                        employeeId: "3209",
                        firstName: "Kai",
                        lastName: "Foley",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3208",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3159",
                firstName: "Cole",
                lastName: "Griffith",
                jobTitle: "Senior Director of Sales / Libya",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3158",
                reports: [
                  {
                    employeeId: "3160",
                    firstName: "Alfonso",
                    lastName: "Nolan",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3159",
                    reports: []
                  },
                  {
                    employeeId: "3166",
                    firstName: "Kassidy",
                    lastName: "Leblanc",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3159",
                    reports: [
                      {
                        employeeId: "3167",
                        firstName: "Arturo",
                        lastName: "King",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3166",
                        reports: []
                      },
                      {
                        employeeId: "3169",
                        firstName: "Remy",
                        lastName: "Howard",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3166",
                        reports: []
                      },
                      {
                        employeeId: "3168",
                        firstName: "Virginia",
                        lastName: "Farrell",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3166",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3180",
                    firstName: "Marlon",
                    lastName: "Peters",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3159",
                    reports: [
                      {
                        employeeId: "3182",
                        firstName: "Susan",
                        lastName: "Gay",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3180",
                        reports: []
                      },
                      {
                        employeeId: "3181",
                        firstName: "Aileen",
                        lastName: "Vargas",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3180",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3184",
                    firstName: "Ernesto",
                    lastName: "Schroeder",
                    jobTitle: "Sales / Libya Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3159",
                    reports: [
                      {
                        employeeId: "3188",
                        firstName: "Brennan",
                        lastName: "Ochoa",
                        jobTitle: "Sales / Libya Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3184",
                        reports: []
                      },
                      {
                        employeeId: "3187",
                        firstName: "Serena",
                        lastName: "Riggs",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3184",
                        reports: []
                      },
                      {
                        employeeId: "3186",
                        firstName: "Dominic",
                        lastName: "Madden",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3184",
                        reports: []
                      },
                      {
                        employeeId: "3185",
                        firstName: "Lance",
                        lastName: "Mendez",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3184",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3161",
                    firstName: "Kinley",
                    lastName: "Schneider",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3159",
                    reports: [
                      {
                        employeeId: "3164",
                        firstName: "Brecken",
                        lastName: "Griffith",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3161",
                        reports: []
                      },
                      {
                        employeeId: "3162",
                        firstName: "Zaria",
                        lastName: "Klein",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3161",
                        reports: []
                      },
                      {
                        employeeId: "3163",
                        firstName: "Zayden",
                        lastName: "Mcdowell",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3161",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3171",
                    firstName: "Kalani",
                    lastName: "Sandoval",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3159",
                    reports: [
                      {
                        employeeId: "3174",
                        firstName: "Juan",
                        lastName: "Monroe",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3171",
                        reports: []
                      },
                      {
                        employeeId: "3173",
                        firstName: "Aarav",
                        lastName: "Shepherd",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3171",
                        reports: []
                      },
                      {
                        employeeId: "3172",
                        firstName: "Lauren",
                        lastName: "Manning",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3171",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3176",
                    firstName: "Travis",
                    lastName: "Marshall",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3159",
                    reports: [
                      {
                        employeeId: "3177",
                        firstName: "Skyler",
                        lastName: "Valenzuela",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3176",
                        reports: []
                      },
                      {
                        employeeId: "3178",
                        firstName: "Journey",
                        lastName: "Rasmussen",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3176",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3220",
                firstName: "Mateo",
                lastName: "Moss",
                jobTitle: "Senior Director of Sales / Egypt",
                officeLocation: "Paris",
                department: "Sales / Egypt",
                managerEmployeeId: "3158",
                reports: [
                  {
                    employeeId: "3221",
                    firstName: "Kaelyn",
                    lastName: "Gonzalez",
                    jobTitle: "Sales / Egypt Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3220",
                    reports: [
                      {
                        employeeId: "3229",
                        firstName: "Pierce",
                        lastName: "Owens",
                        jobTitle: "Sales / Egypt Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3221",
                        reports: []
                      },
                      {
                        employeeId: "3232",
                        firstName: "Colette",
                        lastName: "Kaufman",
                        jobTitle: "Sales / Egypt Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3221",
                        reports: []
                      },
                      {
                        employeeId: "3222",
                        firstName: "Adelaide",
                        lastName: "Perez",
                        jobTitle: "Sales / Egypt Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3221",
                        reports: []
                      },
                      {
                        employeeId: "3226",
                        firstName: "Jude",
                        lastName: "Shaw",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3221",
                        reports: []
                      },
                      {
                        employeeId: "3224",
                        firstName: "Miguel",
                        lastName: "Harrison",
                        jobTitle: "Sales / Egypt Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3221",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3245",
                    firstName: "Sariah",
                    lastName: "Kemp",
                    jobTitle: "Sales / Egypt Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3220",
                    reports: [
                      {
                        employeeId: "3246",
                        firstName: "Kelsey",
                        lastName: "Hartman",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3245",
                        reports: []
                      },
                      {
                        employeeId: "3247",
                        firstName: "Steve",
                        lastName: "Morrow",
                        jobTitle: "Sales / Egypt Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3245",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3241",
                    firstName: "Brantlee",
                    lastName: "Rivers",
                    jobTitle: "Sales / Egypt Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3220",
                    reports: [
                      {
                        employeeId: "3242",
                        firstName: "Derek",
                        lastName: "Ray",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3241",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3239",
                    firstName: "Lilith",
                    lastName: "Rivera",
                    jobTitle: "Sales / Egypt Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3220",
                    reports: [
                      {
                        employeeId: "3240",
                        firstName: "Evelynn",
                        lastName: "Turner",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3239",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3238",
                    firstName: "Rayna",
                    lastName: "Hoover",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3220",
                    reports: []
                  },
                  {
                    employeeId: "3237",
                    firstName: "Demi",
                    lastName: "Vang",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3220",
                    reports: []
                  },
                  {
                    employeeId: "3243",
                    firstName: "Sierra",
                    lastName: "Nixon",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3220",
                    reports: [
                      {
                        employeeId: "3244",
                        firstName: "Jefferson",
                        lastName: "Nunez",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3243",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "3727",
            firstName: "Max",
            lastName: "Krueger",
            jobTitle: "Vice President of Sales / Middle East",
            officeLocation: "Paris",
            department: "Sales / Middle East",
            managerEmployeeId: "3157",
            reports: [
              {
                employeeId: "3763",
                firstName: "Spencer",
                lastName: "Mcclure",
                jobTitle: "Sales / Saudi Arabia Director",
                officeLocation: "Paris",
                department: "Sales / Saudi Arabia",
                managerEmployeeId: "3727",
                reports: [
                  {
                    employeeId: "3764",
                    firstName: "Hugh",
                    lastName: "Koch",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3763",
                    reports: [
                      {
                        employeeId: "3766",
                        firstName: "Eileen",
                        lastName: "Hodges",
                        jobTitle: "Sales / Saudi Arabia Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3764",
                        reports: []
                      },
                      {
                        employeeId: "3765",
                        firstName: "Jessa",
                        lastName: "Wagner",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3764",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3773",
                    firstName: "Sasha",
                    lastName: "Klein",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3763",
                    reports: [
                      {
                        employeeId: "3777",
                        firstName: "Archer",
                        lastName: "Humphrey",
                        jobTitle: "Sales / Saudi Arabia Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3773",
                        reports: []
                      },
                      {
                        employeeId: "3774",
                        firstName: "Evelynn",
                        lastName: "Duran",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3773",
                        reports: []
                      },
                      {
                        employeeId: "3776",
                        firstName: "Alianna",
                        lastName: "Bailey",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3773",
                        reports: []
                      },
                      {
                        employeeId: "3775",
                        firstName: "Rohan",
                        lastName: "Parrish",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3773",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3780",
                    firstName: "Zariah",
                    lastName: "Calderon",
                    jobTitle: "Sales / Saudi Arabia Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3763",
                    reports: [
                      {
                        employeeId: "3781",
                        firstName: "Laura",
                        lastName: "Chaney",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3780",
                        reports: []
                      },
                      {
                        employeeId: "3783",
                        firstName: "Dante",
                        lastName: "Castaneda",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3780",
                        reports: []
                      },
                      {
                        employeeId: "3784",
                        firstName: "Veda",
                        lastName: "Stone",
                        jobTitle: "Sales / Saudi Arabia Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3780",
                        reports: []
                      },
                      {
                        employeeId: "3782",
                        firstName: "Ethan",
                        lastName: "Tate",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3780",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3768",
                    firstName: "Charli",
                    lastName: "Riddle",
                    jobTitle: "Sales / Saudi Arabia Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3763",
                    reports: [
                      {
                        employeeId: "3769",
                        firstName: "Sienna",
                        lastName: "Arias",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3768",
                        reports: []
                      },
                      {
                        employeeId: "3771",
                        firstName: "Amari",
                        lastName: "Horn",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3768",
                        reports: []
                      },
                      {
                        employeeId: "3770",
                        firstName: "Emilee",
                        lastName: "Daugherty",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3768",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3786",
                    firstName: "Mira",
                    lastName: "Ball",
                    jobTitle: "Sales / Saudi Arabia Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3763",
                    reports: [
                      {
                        employeeId: "3789",
                        firstName: "Holly",
                        lastName: "Hines",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3786",
                        reports: []
                      },
                      {
                        employeeId: "3787",
                        firstName: "Ember",
                        lastName: "Boone",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3786",
                        reports: []
                      },
                      {
                        employeeId: "3788",
                        firstName: "Talon",
                        lastName: "Jefferson",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3786",
                        reports: []
                      },
                      {
                        employeeId: "3790",
                        firstName: "Jerome",
                        lastName: "Dillon",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3786",
                        reports: []
                      },
                      {
                        employeeId: "3791",
                        firstName: "Paisley",
                        lastName: "Irwin",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3786",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3820",
                firstName: "Achilles",
                lastName: "Whitney",
                jobTitle: "Sales / Libya Director",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3727",
                reports: [
                  {
                    employeeId: "3847",
                    firstName: "Raiden",
                    lastName: "Patton",
                    jobTitle: "Account Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3820",
                    reports: [
                      {
                        employeeId: "3848",
                        firstName: "Natalee",
                        lastName: "Prince",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3847",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3842",
                    firstName: "Miles",
                    lastName: "Johnston",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3820",
                    reports: []
                  },
                  {
                    employeeId: "3849",
                    firstName: "Jillian",
                    lastName: "Cooke",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3820",
                    reports: [
                      {
                        employeeId: "3850",
                        firstName: "Denise",
                        lastName: "Brown",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3849",
                        reports: []
                      },
                      {
                        employeeId: "3851",
                        firstName: "Kallie",
                        lastName: "Hardy",
                        jobTitle: "Sales / Libya Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3849",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3821",
                    firstName: "Libby",
                    lastName: "Bradford",
                    jobTitle: "Sales / Libya Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3820",
                    reports: [
                      {
                        employeeId: "3827",
                        firstName: "Analia",
                        lastName: "Novak",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3821",
                        reports: []
                      },
                      {
                        employeeId: "3829",
                        firstName: "Chace",
                        lastName: "Meadows",
                        jobTitle: "Sales / Libya Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3821",
                        reports: []
                      },
                      {
                        employeeId: "3825",
                        firstName: "Hazel",
                        lastName: "Galvan",
                        jobTitle: "Sales / Libya Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3821",
                        reports: []
                      },
                      {
                        employeeId: "3823",
                        firstName: "Briley",
                        lastName: "Daniels",
                        jobTitle: "Sales / Libya Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3821",
                        reports: []
                      },
                      {
                        employeeId: "3822",
                        firstName: "Janiyah",
                        lastName: "Randolph",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3821",
                        reports: []
                      },
                      {
                        employeeId: "3836",
                        firstName: "Royal",
                        lastName: "Calhoun",
                        jobTitle: "Sales / Libya Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3821",
                        reports: []
                      },
                      {
                        employeeId: "3831",
                        firstName: "Abdiel",
                        lastName: "Jackson",
                        jobTitle: "Sales / Libya Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3821",
                        reports: []
                      },
                      {
                        employeeId: "3833",
                        firstName: "Leilani",
                        lastName: "Roach",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3821",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3843",
                    firstName: "Kamila",
                    lastName: "Campbell",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3820",
                    reports: []
                  },
                  {
                    employeeId: "3845",
                    firstName: "Roland",
                    lastName: "Solomon",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3820",
                    reports: [
                      {
                        employeeId: "3846",
                        firstName: "Hope",
                        lastName: "Cisneros",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3845",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3841",
                    firstName: "Madalyn",
                    lastName: "Johns",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3820",
                    reports: []
                  },
                  {
                    employeeId: "3844",
                    firstName: "Gustavo",
                    lastName: "Leonard",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3820",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3728",
                firstName: "Addilyn",
                lastName: "Rivas",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3727",
                reports: []
              },
              {
                employeeId: "3853",
                firstName: "Neil",
                lastName: "Hull",
                jobTitle: "Senior Director of Sales / Egypt",
                officeLocation: "Paris",
                department: "Sales / Egypt",
                managerEmployeeId: "3727",
                reports: [
                  {
                    employeeId: "3869",
                    firstName: "Saige",
                    lastName: "Raymond",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3853",
                    reports: [
                      {
                        employeeId: "3870",
                        firstName: "Giancarlo",
                        lastName: "Hunt",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3869",
                        reports: []
                      },
                      {
                        employeeId: "3872",
                        firstName: "Zariyah",
                        lastName: "Allison",
                        jobTitle: "Sales / Egypt Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3869",
                        reports: []
                      },
                      {
                        employeeId: "3871",
                        firstName: "Kole",
                        lastName: "Horne",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3869",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3860",
                    firstName: "Rory",
                    lastName: "Harrison",
                    jobTitle: "Sales / Egypt Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3853",
                    reports: [
                      {
                        employeeId: "3862",
                        firstName: "Abdiel",
                        lastName: "Contreras",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3860",
                        reports: []
                      },
                      {
                        employeeId: "3863",
                        firstName: "Asher",
                        lastName: "Villarreal",
                        jobTitle: "Sales / Egypt Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3860",
                        reports: []
                      },
                      {
                        employeeId: "3861",
                        firstName: "Mackenzie",
                        lastName: "Francis",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3860",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3854",
                    firstName: "Izaiah",
                    lastName: "Mueller",
                    jobTitle: "Sales / Egypt Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3853",
                    reports: [
                      {
                        employeeId: "3855",
                        firstName: "Iris",
                        lastName: "Huffman",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3854",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3880",
                    firstName: "Douglas",
                    lastName: "Hays",
                    jobTitle: "Sales / Egypt Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3853",
                    reports: [
                      {
                        employeeId: "3883",
                        firstName: "Annalee",
                        lastName: "Norton",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3880",
                        reports: []
                      },
                      {
                        employeeId: "3882",
                        firstName: "Dax",
                        lastName: "Lindsey",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3880",
                        reports: []
                      },
                      {
                        employeeId: "3881",
                        firstName: "Alina",
                        lastName: "Ramirez",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3880",
                        reports: []
                      },
                      {
                        employeeId: "3884",
                        firstName: "Braxton",
                        lastName: "Riley",
                        jobTitle: "Sales / Egypt Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3880",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3865",
                    firstName: "Vincent",
                    lastName: "Norris",
                    jobTitle: "Sales / Egypt Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3853",
                    reports: [
                      {
                        employeeId: "3866",
                        firstName: "Oscar",
                        lastName: "Stewart",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3865",
                        reports: []
                      },
                      {
                        employeeId: "3867",
                        firstName: "Gracie",
                        lastName: "Perez",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3865",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3874",
                    firstName: "Charlie",
                    lastName: "Wheeler",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3853",
                    reports: [
                      {
                        employeeId: "3878",
                        firstName: "Lindsey",
                        lastName: "Hunt",
                        jobTitle: "Sales / Egypt Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3874",
                        reports: []
                      },
                      {
                        employeeId: "3876",
                        firstName: "Thomas",
                        lastName: "Hammond",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3874",
                        reports: []
                      },
                      {
                        employeeId: "3877",
                        firstName: "Chandler",
                        lastName: "Nielsen",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3874",
                        reports: []
                      },
                      {
                        employeeId: "3875",
                        firstName: "Madyson",
                        lastName: "Joyce",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3874",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3857",
                    firstName: "Lyric",
                    lastName: "Warren",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3853",
                    reports: [
                      {
                        employeeId: "3858",
                        firstName: "Raelynn",
                        lastName: "Clements",
                        jobTitle: "Sales / Egypt Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3857",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3793",
                firstName: "Bryson",
                lastName: "Nichols",
                jobTitle: "Sales / Libya Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3727",
                reports: [
                  {
                    employeeId: "3811",
                    firstName: "Zuri",
                    lastName: "Mays",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3793",
                    reports: []
                  },
                  {
                    employeeId: "3817",
                    firstName: "Lennon",
                    lastName: "Baird",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3793",
                    reports: [
                      {
                        employeeId: "3818",
                        firstName: "Deborah",
                        lastName: "Herman",
                        jobTitle: "Sales / Libya Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3817",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3816",
                    firstName: "Kassandra",
                    lastName: "Mayo",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3793",
                    reports: []
                  },
                  {
                    employeeId: "3813",
                    firstName: "Jakob",
                    lastName: "Avery",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3793",
                    reports: []
                  },
                  {
                    employeeId: "3815",
                    firstName: "Jasmine",
                    lastName: "Roy",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3793",
                    reports: []
                  },
                  {
                    employeeId: "3810",
                    firstName: "Angel",
                    lastName: "Pittman",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3793",
                    reports: []
                  },
                  {
                    employeeId: "3814",
                    firstName: "Collins",
                    lastName: "Medina",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3793",
                    reports: []
                  },
                  {
                    employeeId: "3794",
                    firstName: "Jadon",
                    lastName: "Dodson",
                    jobTitle: "Sales / Libya Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3793",
                    reports: [
                      {
                        employeeId: "3797",
                        firstName: "Mariah",
                        lastName: "Fuller",
                        jobTitle: "Sales / Libya Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3794",
                        reports: []
                      },
                      {
                        employeeId: "3806",
                        firstName: "Andre",
                        lastName: "Sweeney",
                        jobTitle: "Sales / Libya Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3794",
                        reports: []
                      },
                      {
                        employeeId: "3803",
                        firstName: "Kaiya",
                        lastName: "Cortez",
                        jobTitle: "Sales / Libya Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3794",
                        reports: []
                      },
                      {
                        employeeId: "3795",
                        firstName: "Kellen",
                        lastName: "Randall",
                        jobTitle: "Sales / Libya Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3794",
                        reports: []
                      },
                      {
                        employeeId: "3801",
                        firstName: "Omari",
                        lastName: "Estes",
                        jobTitle: "Sales / Libya Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3794",
                        reports: []
                      },
                      {
                        employeeId: "3799",
                        firstName: "Kenzie",
                        lastName: "Hart",
                        jobTitle: "Sales / Libya Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3794",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3812",
                    firstName: "Hayden",
                    lastName: "Clarke",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3793",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3729",
                firstName: "Yasmin",
                lastName: "Berry",
                jobTitle: "Senior Director of Sales / Libya",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3727",
                reports: [
                  {
                    employeeId: "3755",
                    firstName: "Benson",
                    lastName: "Hurley",
                    jobTitle: "Sales / Libya Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3729",
                    reports: [
                      {
                        employeeId: "3757",
                        firstName: "Joslyn",
                        lastName: "Parrish",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3755",
                        reports: []
                      },
                      {
                        employeeId: "3756",
                        firstName: "Zaylee",
                        lastName: "Silva",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3755",
                        reports: []
                      },
                      {
                        employeeId: "3758",
                        firstName: "Rodrigo",
                        lastName: "Espinoza",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3755",
                        reports: []
                      },
                      {
                        employeeId: "3760",
                        firstName: "Cassius",
                        lastName: "Townsend",
                        jobTitle: "Sales / Libya Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3755",
                        reports: []
                      },
                      {
                        employeeId: "3759",
                        firstName: "Davian",
                        lastName: "Nolan",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3755",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3735",
                    firstName: "Allyson",
                    lastName: "Knox",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3729",
                    reports: [
                      {
                        employeeId: "3736",
                        firstName: "Alessandro",
                        lastName: "Lucas",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3735",
                        reports: []
                      },
                      {
                        employeeId: "3737",
                        firstName: "Aylin",
                        lastName: "Moses",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3735",
                        reports: []
                      },
                      {
                        employeeId: "3738",
                        firstName: "Eliana",
                        lastName: "Bender",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3735",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3730",
                    firstName: "Dominique",
                    lastName: "Sanford",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3729",
                    reports: [
                      {
                        employeeId: "3732",
                        firstName: "Konnor",
                        lastName: "Castro",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3730",
                        reports: []
                      },
                      {
                        employeeId: "3733",
                        firstName: "Tiana",
                        lastName: "Kelly",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3730",
                        reports: []
                      },
                      {
                        employeeId: "3731",
                        firstName: "Azalea",
                        lastName: "Quinn",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3730",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3748",
                    firstName: "Lilith",
                    lastName: "Carpenter",
                    jobTitle: "Sales / Libya Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3729",
                    reports: [
                      {
                        employeeId: "3749",
                        firstName: "Ivan",
                        lastName: "Avila",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3748",
                        reports: []
                      },
                      {
                        employeeId: "3752",
                        firstName: "Amia",
                        lastName: "Camacho",
                        jobTitle: "Sales / Libya Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3748",
                        reports: []
                      },
                      {
                        employeeId: "3751",
                        firstName: "Jacoby",
                        lastName: "Garza",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3748",
                        reports: []
                      },
                      {
                        employeeId: "3750",
                        firstName: "Javon",
                        lastName: "Campbell",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3748",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3740",
                    firstName: "Alejandra",
                    lastName: "Franco",
                    jobTitle: "Sales / Libya Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3729",
                    reports: [
                      {
                        employeeId: "3745",
                        firstName: "Angelique",
                        lastName: "Delacruz",
                        jobTitle: "Sales / Libya Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3740",
                        reports: []
                      },
                      {
                        employeeId: "3743",
                        firstName: "Raina",
                        lastName: "Henson",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3740",
                        reports: []
                      },
                      {
                        employeeId: "3744",
                        firstName: "Marissa",
                        lastName: "Rodgers",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3740",
                        reports: []
                      },
                      {
                        employeeId: "3741",
                        firstName: "Romina",
                        lastName: "Fernandez",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3740",
                        reports: []
                      },
                      {
                        employeeId: "3742",
                        firstName: "Catalina",
                        lastName: "Herrera",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3740",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "3306",
            firstName: "Pierce",
            lastName: "Hudson",
            jobTitle: "Senior Director of Developing Markets",
            officeLocation: "Paris",
            department: "Developing Markets",
            managerEmployeeId: "3157",
            reports: [
              {
                employeeId: "3416",
                firstName: "Dahlia",
                lastName: "Mendoza",
                jobTitle: "Sales / Elbonia Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Elbonia",
                managerEmployeeId: "3306",
                reports: [
                  {
                    employeeId: "3426",
                    firstName: "Santos",
                    lastName: "Meyers",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  },
                  {
                    employeeId: "3422",
                    firstName: "Jaylah",
                    lastName: "Spencer",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  },
                  {
                    employeeId: "3417",
                    firstName: "Lacey",
                    lastName: "Case",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  },
                  {
                    employeeId: "3421",
                    firstName: "Mya",
                    lastName: "Chung",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  },
                  {
                    employeeId: "3435",
                    firstName: "Aurelia",
                    lastName: "Brown",
                    jobTitle: "Sales / Elbonia Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: [
                      {
                        employeeId: "3436",
                        firstName: "Brayan",
                        lastName: "Waters",
                        jobTitle: "Sales / Elbonia Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3435",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3425",
                    firstName: "Khalil",
                    lastName: "Klein",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  },
                  {
                    employeeId: "3433",
                    firstName: "Mikayla",
                    lastName: "Garner",
                    jobTitle: "Sales / Elbonia Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: [
                      {
                        employeeId: "3434",
                        firstName: "Leia",
                        lastName: "Sandoval",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3433",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3423",
                    firstName: "Lilliana",
                    lastName: "Reid",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  },
                  {
                    employeeId: "3419",
                    firstName: "Phoenix",
                    lastName: "Wilcox",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  },
                  {
                    employeeId: "3420",
                    firstName: "Xavier",
                    lastName: "Atkins",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  },
                  {
                    employeeId: "3424",
                    firstName: "Colby",
                    lastName: "Leblanc",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  },
                  {
                    employeeId: "3431",
                    firstName: "Audrey",
                    lastName: "Mooney",
                    jobTitle: "Sales / Elbonia Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: [
                      {
                        employeeId: "3432",
                        firstName: "Tatum",
                        lastName: "Jones",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3431",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3429",
                    firstName: "Sawyer",
                    lastName: "Strong",
                    jobTitle: "Sales / Elbonia Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: [
                      {
                        employeeId: "3430",
                        firstName: "Jon",
                        lastName: "Randolph",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3429",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3427",
                    firstName: "Hattie",
                    lastName: "Harmon",
                    jobTitle: "Sales / Elbonia Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: [
                      {
                        employeeId: "3428",
                        firstName: "Ashley",
                        lastName: "Austin",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3427",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3438",
                    firstName: "Charlie",
                    lastName: "Glover",
                    jobTitle: "Sales / Elbonia Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: [
                      {
                        employeeId: "3439",
                        firstName: "Junior",
                        lastName: "Horne",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3438",
                        reports: []
                      },
                      {
                        employeeId: "3441",
                        firstName: "Malaya",
                        lastName: "Oneal",
                        jobTitle: "Sales / Elbonia Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3438",
                        reports: []
                      },
                      {
                        employeeId: "3440",
                        firstName: "Carla",
                        lastName: "Moon",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3438",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3418",
                    firstName: "Julie",
                    lastName: "Yang",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3416",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3359",
                firstName: "Jaime",
                lastName: "Freeman",
                jobTitle: "Sales / Lugash Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Lugash",
                managerEmployeeId: "3306",
                reports: [
                  {
                    employeeId: "3379",
                    firstName: "Lacey",
                    lastName: "Paul",
                    jobTitle: "Sales / Lugash Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3359",
                    reports: [
                      {
                        employeeId: "3380",
                        firstName: "Ingrid",
                        lastName: "Vance",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3379",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3360",
                    firstName: "Laura",
                    lastName: "Duncan",
                    jobTitle: "Sales / Lugash Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3359",
                    reports: [
                      {
                        employeeId: "3371",
                        firstName: "Kash",
                        lastName: "Duran",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3360",
                        reports: []
                      },
                      {
                        employeeId: "3370",
                        firstName: "Taya",
                        lastName: "Gamble",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3360",
                        reports: []
                      },
                      {
                        employeeId: "3361",
                        firstName: "Marquis",
                        lastName: "Barajas",
                        jobTitle: "Sales / Lugash Manager-VII",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3360",
                        reports: []
                      },
                      {
                        employeeId: "3372",
                        firstName: "Jasiah",
                        lastName: "Ho",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3360",
                        reports: []
                      },
                      {
                        employeeId: "3369",
                        firstName: "Zuri",
                        lastName: "House",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3360",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3374",
                    firstName: "Dana",
                    lastName: "Mckee",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3359",
                    reports: []
                  },
                  {
                    employeeId: "3381",
                    firstName: "Musa",
                    lastName: "Parker",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3359",
                    reports: [
                      {
                        employeeId: "3383",
                        firstName: "Eliana",
                        lastName: "Obrien",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3381",
                        reports: []
                      },
                      {
                        employeeId: "3382",
                        firstName: "Sonny",
                        lastName: "Steele",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3381",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3375",
                    firstName: "Ares",
                    lastName: "Flowers",
                    jobTitle: "Sales / Lugash Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3359",
                    reports: [
                      {
                        employeeId: "3376",
                        firstName: "Ember",
                        lastName: "Francis",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3375",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3377",
                    firstName: "Micheal",
                    lastName: "Reyes",
                    jobTitle: "Sales / Lugash Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3359",
                    reports: [
                      {
                        employeeId: "3378",
                        firstName: "Case",
                        lastName: "Cabrera",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3377",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3307",
                firstName: "Jasmin",
                lastName: "Wood",
                jobTitle: "Sales / Elbonia Director",
                officeLocation: "Paris",
                department: "Sales / Elbonia",
                managerEmployeeId: "3306",
                reports: [
                  {
                    employeeId: "3328",
                    firstName: "Damien",
                    lastName: "Vazquez",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3307",
                    reports: [
                      {
                        employeeId: "3329",
                        firstName: "Ephraim",
                        lastName: "Flores",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3328",
                        reports: []
                      },
                      {
                        employeeId: "3330",
                        firstName: "Emmet",
                        lastName: "Orr",
                        jobTitle: "Sales / Elbonia Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3328",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3308",
                    firstName: "Brooklynn",
                    lastName: "Downs",
                    jobTitle: "Sales / Elbonia Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3307",
                    reports: [
                      {
                        employeeId: "3309",
                        firstName: "Kannon",
                        lastName: "Rush",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3308",
                        reports: []
                      },
                      {
                        employeeId: "3312",
                        firstName: "Azariah",
                        lastName: "Graves",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3308",
                        reports: []
                      },
                      {
                        employeeId: "3315",
                        firstName: "Bailey",
                        lastName: "Lara",
                        jobTitle: "Sales / Elbonia Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3308",
                        reports: []
                      },
                      {
                        employeeId: "3311",
                        firstName: "Lucille",
                        lastName: "Watson",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3308",
                        reports: []
                      },
                      {
                        employeeId: "3317",
                        firstName: "Melina",
                        lastName: "Holloway",
                        jobTitle: "Sales / Elbonia Manager-VI",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3308",
                        reports: []
                      },
                      {
                        employeeId: "3313",
                        firstName: "Jaylah",
                        lastName: "Evans",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3308",
                        reports: []
                      },
                      {
                        employeeId: "3310",
                        firstName: "Haven",
                        lastName: "Hayes",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3308",
                        reports: []
                      },
                      {
                        employeeId: "3314",
                        firstName: "Jayde",
                        lastName: "Blackwell",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3308",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3326",
                    firstName: "Cordelia",
                    lastName: "Martinez",
                    jobTitle: "Sales / Elbonia Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3307",
                    reports: [
                      {
                        employeeId: "3327",
                        firstName: "Alena",
                        lastName: "Landry",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3326",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3321",
                    firstName: "Simone",
                    lastName: "Durham",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3307",
                    reports: []
                  },
                  {
                    employeeId: "3322",
                    firstName: "Michelle",
                    lastName: "Barnes",
                    jobTitle: "Sales / Elbonia Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3307",
                    reports: [
                      {
                        employeeId: "3323",
                        firstName: "Juan",
                        lastName: "Fry",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3322",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3324",
                    firstName: "Gia",
                    lastName: "Lawson",
                    jobTitle: "Sales / Elbonia Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Elbonia",
                    managerEmployeeId: "3307",
                    reports: [
                      {
                        employeeId: "3325",
                        firstName: "Ignacio",
                        lastName: "Glass",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Elbonia",
                        managerEmployeeId: "3324",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3385",
                firstName: "Harlee",
                lastName: "Long",
                jobTitle: "Senior Director of Sales / Lugash",
                officeLocation: "Paris",
                department: "Sales / Lugash",
                managerEmployeeId: "3306",
                reports: [
                  {
                    employeeId: "3397",
                    firstName: "Annabella",
                    lastName: "Livingston",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3385",
                    reports: [
                      {
                        employeeId: "3398",
                        firstName: "Damien",
                        lastName: "Hall",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3397",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3386",
                    firstName: "Noemi",
                    lastName: "Francis",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3385",
                    reports: []
                  },
                  {
                    employeeId: "3410",
                    firstName: "Lila",
                    lastName: "Woodard",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3385",
                    reports: [
                      {
                        employeeId: "3411",
                        firstName: "Caylee",
                        lastName: "Livingston",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3410",
                        reports: []
                      },
                      {
                        employeeId: "3413",
                        firstName: "Justin",
                        lastName: "Martinez",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3410",
                        reports: []
                      },
                      {
                        employeeId: "3414",
                        firstName: "April",
                        lastName: "Acosta",
                        jobTitle: "Sales / Lugash Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3410",
                        reports: []
                      },
                      {
                        employeeId: "3412",
                        firstName: "Kayla",
                        lastName: "Nguyen",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3410",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3390",
                    firstName: "Khloe",
                    lastName: "Calderon",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3385",
                    reports: [
                      {
                        employeeId: "3392",
                        firstName: "Kolten",
                        lastName: "Davenport",
                        jobTitle: "Sales / Lugash Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3390",
                        reports: []
                      },
                      {
                        employeeId: "3391",
                        firstName: "Eden",
                        lastName: "Hahn",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3390",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3394",
                    firstName: "Augustine",
                    lastName: "Lyons",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3385",
                    reports: [
                      {
                        employeeId: "3395",
                        firstName: "Leonel",
                        lastName: "King",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3394",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3405",
                    firstName: "Kadence",
                    lastName: "Andrews",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3385",
                    reports: [
                      {
                        employeeId: "3407",
                        firstName: "Theo",
                        lastName: "Owen",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3405",
                        reports: []
                      },
                      {
                        employeeId: "3406",
                        firstName: "Royal",
                        lastName: "Weaver",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3405",
                        reports: []
                      },
                      {
                        employeeId: "3408",
                        firstName: "Ernest",
                        lastName: "Cuevas",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3405",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3400",
                    firstName: "Chad",
                    lastName: "Vang",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3385",
                    reports: [
                      {
                        employeeId: "3402",
                        firstName: "Ridge",
                        lastName: "Orr",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3400",
                        reports: []
                      },
                      {
                        employeeId: "3401",
                        firstName: "Sophie",
                        lastName: "Patton",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3400",
                        reports: []
                      },
                      {
                        employeeId: "3403",
                        firstName: "Alexander",
                        lastName: "Freeman",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3400",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3387",
                    firstName: "Jordyn",
                    lastName: "Saunders",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3385",
                    reports: [
                      {
                        employeeId: "3388",
                        firstName: "Cade",
                        lastName: "Meyer",
                        jobTitle: "Sales / Lugash Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3387",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3332",
                firstName: "Nathalie",
                lastName: "Kelley",
                jobTitle: "Sales / Lugash Director",
                officeLocation: "Paris",
                department: "Sales / Lugash",
                managerEmployeeId: "3306",
                reports: [
                  {
                    employeeId: "3338",
                    firstName: "Aryan",
                    lastName: "Wilkins",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3332",
                    reports: [
                      {
                        employeeId: "3341",
                        firstName: "Nathaniel",
                        lastName: "Savage",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3338",
                        reports: []
                      },
                      {
                        employeeId: "3340",
                        firstName: "Finnegan",
                        lastName: "Joseph",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3338",
                        reports: []
                      },
                      {
                        employeeId: "3339",
                        firstName: "Scarlette",
                        lastName: "Pineda",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3338",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3352",
                    firstName: "Ximena",
                    lastName: "Ritter",
                    jobTitle: "Sales / Lugash Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3332",
                    reports: [
                      {
                        employeeId: "3353",
                        firstName: "Mariah",
                        lastName: "Kaiser",
                        jobTitle: "Sales / Lugash Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3352",
                        reports: []
                      },
                      {
                        employeeId: "3357",
                        firstName: "Marshall",
                        lastName: "Hanna",
                        jobTitle: "Sales / Lugash Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3352",
                        reports: []
                      },
                      {
                        employeeId: "3356",
                        firstName: "Zendaya",
                        lastName: "Blackwell",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3352",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3348",
                    firstName: "Judson",
                    lastName: "Richmond",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3332",
                    reports: [
                      {
                        employeeId: "3349",
                        firstName: "Ayleen",
                        lastName: "Mccarthy",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3348",
                        reports: []
                      },
                      {
                        employeeId: "3350",
                        firstName: "Jolene",
                        lastName: "Moran",
                        jobTitle: "Sales / Lugash Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3348",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3333",
                    firstName: "Kensington",
                    lastName: "Buck",
                    jobTitle: "Sales / Lugash Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3332",
                    reports: [
                      {
                        employeeId: "3334",
                        firstName: "Armani",
                        lastName: "Quinn",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3333",
                        reports: []
                      },
                      {
                        employeeId: "3335",
                        firstName: "Axton",
                        lastName: "Kim",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3333",
                        reports: []
                      },
                      {
                        employeeId: "3336",
                        firstName: "Jonathan",
                        lastName: "Clarke",
                        jobTitle: "Account Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3333",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3343",
                    firstName: "Cristopher",
                    lastName: "Finley",
                    jobTitle: "Sales / Lugash Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Lugash",
                    managerEmployeeId: "3332",
                    reports: [
                      {
                        employeeId: "3345",
                        firstName: "Yadiel",
                        lastName: "Holland",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3343",
                        reports: []
                      },
                      {
                        employeeId: "3344",
                        firstName: "Aileen",
                        lastName: "Gray",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3343",
                        reports: []
                      },
                      {
                        employeeId: "3346",
                        firstName: "Arielle",
                        lastName: "Davis",
                        jobTitle: "Sales / Lugash Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Lugash",
                        managerEmployeeId: "3343",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            employeeId: "3443",
            firstName: "Abdullah",
            lastName: "Farmer",
            jobTitle: "Vice President of Sales / Middle East",
            officeLocation: "Paris",
            department: "Sales / Middle East",
            managerEmployeeId: "3157",
            reports: [
              {
                employeeId: "3462",
                firstName: "Isabela",
                lastName: "Mcdonald",
                jobTitle: "Sales / Egypt Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Egypt",
                managerEmployeeId: "3443",
                reports: [
                  {
                    employeeId: "3467",
                    firstName: "Gibson",
                    lastName: "Jenkins",
                    jobTitle: "Sales / Egypt Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3462",
                    reports: [
                      {
                        employeeId: "3468",
                        firstName: "Leonard",
                        lastName: "Mccall",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3467",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3465",
                    firstName: "Kassandra",
                    lastName: "Mcgrath",
                    jobTitle: "Sales / Egypt Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3462",
                    reports: [
                      {
                        employeeId: "3466",
                        firstName: "Anthony",
                        lastName: "Cantu",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3465",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3469",
                    firstName: "Alijah",
                    lastName: "Bowen",
                    jobTitle: "Sales / Egypt Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3462",
                    reports: [
                      {
                        employeeId: "3470",
                        firstName: "Quintin",
                        lastName: "Levy",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3469",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3473",
                    firstName: "Sharon",
                    lastName: "Mccoy",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3462",
                    reports: [
                      {
                        employeeId: "3474",
                        firstName: "Amalia",
                        lastName: "Cervantes",
                        jobTitle: "Sales / Egypt Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3473",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3463",
                    firstName: "Austin",
                    lastName: "Morton",
                    jobTitle: "Sales / Egypt Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3462",
                    reports: [
                      {
                        employeeId: "3464",
                        firstName: "Vanessa",
                        lastName: "Morgan",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3463",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3476",
                    firstName: "Allison",
                    lastName: "Hopkins",
                    jobTitle: "Sales / Egypt Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3462",
                    reports: [
                      {
                        employeeId: "3478",
                        firstName: "Tatum",
                        lastName: "French",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3476",
                        reports: []
                      },
                      {
                        employeeId: "3479",
                        firstName: "Calvin",
                        lastName: "Fischer",
                        jobTitle: "Sales / Egypt Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3476",
                        reports: []
                      },
                      {
                        employeeId: "3477",
                        firstName: "Roman",
                        lastName: "Luna",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3476",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3471",
                    firstName: "Zander",
                    lastName: "Dodson",
                    jobTitle: "Account Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3462",
                    reports: [
                      {
                        employeeId: "3472",
                        firstName: "Kairi",
                        lastName: "Harrington",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3471",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3481",
                firstName: "April",
                lastName: "Hickman",
                jobTitle: "Senior Staff Account Manager",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3443",
                reports: [
                  {
                    employeeId: "3494",
                    firstName: "Madyson",
                    lastName: "Dunn",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3481",
                    reports: []
                  },
                  {
                    employeeId: "3496",
                    firstName: "Laurel",
                    lastName: "Shields",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3481",
                    reports: []
                  },
                  {
                    employeeId: "3482",
                    firstName: "Zander",
                    lastName: "Sosa",
                    jobTitle: "Sales / Libya Manager-VII",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3481",
                    reports: [
                      {
                        employeeId: "3490",
                        firstName: "Travis",
                        lastName: "Cooper",
                        jobTitle: "Sales / Libya Manager-V",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3482",
                        reports: []
                      },
                      {
                        employeeId: "3483",
                        firstName: "Valentino",
                        lastName: "Mclean",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3482",
                        reports: []
                      },
                      {
                        employeeId: "3487",
                        firstName: "Brianna",
                        lastName: "Peters",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3482",
                        reports: []
                      },
                      {
                        employeeId: "3486",
                        firstName: "Sandra",
                        lastName: "Hill",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3482",
                        reports: []
                      },
                      {
                        employeeId: "3488",
                        firstName: "Lilith",
                        lastName: "Combs",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3482",
                        reports: []
                      },
                      {
                        employeeId: "3485",
                        firstName: "Danny",
                        lastName: "Berry",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3482",
                        reports: []
                      },
                      {
                        employeeId: "3484",
                        firstName: "Jayceon",
                        lastName: "Duran",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3482",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3497",
                    firstName: "Baylor",
                    lastName: "Blair",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3481",
                    reports: [
                      {
                        employeeId: "3498",
                        firstName: "Allen",
                        lastName: "Nichols",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3497",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3495",
                    firstName: "Samantha",
                    lastName: "Holloway",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3481",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3444",
                firstName: "Ian",
                lastName: "Barnett",
                jobTitle: "Sales / Egypt Manager-VII",
                officeLocation: "Paris",
                department: "Sales / Egypt",
                managerEmployeeId: "3443",
                reports: [
                  {
                    employeeId: "3457",
                    firstName: "Imani",
                    lastName: "Cross",
                    jobTitle: "Sales / Egypt Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3444",
                    reports: [
                      {
                        employeeId: "3460",
                        firstName: "Logan",
                        lastName: "Love",
                        jobTitle: "Sales / Egypt Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3457",
                        reports: []
                      },
                      {
                        employeeId: "3458",
                        firstName: "Kole",
                        lastName: "Marsh",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3457",
                        reports: []
                      },
                      {
                        employeeId: "3459",
                        firstName: "Jaziel",
                        lastName: "Holden",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3457",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3446",
                    firstName: "Rivka",
                    lastName: "Castaneda",
                    jobTitle: "Sales / Egypt Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3444",
                    reports: [
                      {
                        employeeId: "3447",
                        firstName: "Charlotte",
                        lastName: "Dawson",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3446",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3445",
                    firstName: "Mason",
                    lastName: "Rojas",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3444",
                    reports: []
                  },
                  {
                    employeeId: "3450",
                    firstName: "Gael",
                    lastName: "Mendoza",
                    jobTitle: "Sales / Egypt Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3444",
                    reports: [
                      {
                        employeeId: "3451",
                        firstName: "Jayde",
                        lastName: "Blanchard",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3450",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3452",
                    firstName: "Khaleesi",
                    lastName: "Oconnell",
                    jobTitle: "Sales / Egypt Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3444",
                    reports: [
                      {
                        employeeId: "3453",
                        firstName: "Juan",
                        lastName: "Foster",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3452",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3454",
                    firstName: "Damari",
                    lastName: "Garrett",
                    jobTitle: "Sales / Egypt Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3444",
                    reports: [
                      {
                        employeeId: "3455",
                        firstName: "Rebekah",
                        lastName: "Padilla",
                        jobTitle: "Sales / Egypt Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3454",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3448",
                    firstName: "Esme",
                    lastName: "Kidd",
                    jobTitle: "Sales / Egypt Manager-III",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3444",
                    reports: [
                      {
                        employeeId: "3449",
                        firstName: "Henley",
                        lastName: "Eaton",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3448",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3516",
                firstName: "Urijah",
                lastName: "Roach",
                jobTitle: "Sales / Libya Director",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3443",
                reports: [
                  {
                    employeeId: "3520",
                    firstName: "Ridge",
                    lastName: "Price",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3516",
                    reports: []
                  },
                  {
                    employeeId: "3521",
                    firstName: "Anton",
                    lastName: "Melton",
                    jobTitle: "Sales / Libya Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3516",
                    reports: [
                      {
                        employeeId: "3522",
                        firstName: "Braiden",
                        lastName: "Torres",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3521",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3519",
                    firstName: "Keaton",
                    lastName: "Mccall",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3516",
                    reports: []
                  },
                  {
                    employeeId: "3517",
                    firstName: "Jaylah",
                    lastName: "Marks",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3516",
                    reports: []
                  },
                  {
                    employeeId: "3528",
                    firstName: "Frida",
                    lastName: "Marquez",
                    jobTitle: "Sales / Libya Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3516",
                    reports: [
                      {
                        employeeId: "3529",
                        firstName: "Esteban",
                        lastName: "Lozano",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3528",
                        reports: []
                      },
                      {
                        employeeId: "3530",
                        firstName: "Rudy",
                        lastName: "King",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3528",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3525",
                    firstName: "Aurelia",
                    lastName: "Caldwell",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3516",
                    reports: [
                      {
                        employeeId: "3526",
                        firstName: "Maya",
                        lastName: "Ward",
                        jobTitle: "Sales / Libya Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3525",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3523",
                    firstName: "Steven",
                    lastName: "Shepherd",
                    jobTitle: "Sales / Libya Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3516",
                    reports: [
                      {
                        employeeId: "3524",
                        firstName: "Brittany",
                        lastName: "Mcmillan",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3523",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3518",
                    firstName: "Rory",
                    lastName: "Gillespie",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3516",
                    reports: []
                  }
                ]
              },
              {
                employeeId: "3549",
                firstName: "Fernando",
                lastName: "Schmitt",
                jobTitle: "Sales / Egypt Director",
                officeLocation: "Paris",
                department: "Sales / Egypt",
                managerEmployeeId: "3443",
                reports: [
                  {
                    employeeId: "3550",
                    firstName: "Landyn",
                    lastName: "Spears",
                    jobTitle: "Sales / Egypt Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3549",
                    reports: [
                      {
                        employeeId: "3551",
                        firstName: "Johnny",
                        lastName: "Salinas",
                        jobTitle: "Sales / Egypt Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3550",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3561",
                    firstName: "Holden",
                    lastName: "Everett",
                    jobTitle: "Sales / Egypt Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3549",
                    reports: [
                      {
                        employeeId: "3564",
                        firstName: "Violet",
                        lastName: "Kennedy",
                        jobTitle: "Sales / Egypt Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3561",
                        reports: []
                      },
                      {
                        employeeId: "3562",
                        firstName: "Camilla",
                        lastName: "Ferrell",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3561",
                        reports: []
                      },
                      {
                        employeeId: "3563",
                        firstName: "Josephine",
                        lastName: "Higgins",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3561",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3556",
                    firstName: "Briley",
                    lastName: "Nichols",
                    jobTitle: "Sales / Egypt Manager-VI",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3549",
                    reports: [
                      {
                        employeeId: "3557",
                        firstName: "Thatcher",
                        lastName: "Graves",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3556",
                        reports: []
                      },
                      {
                        employeeId: "3558",
                        firstName: "Caleb",
                        lastName: "Brewer",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3556",
                        reports: []
                      },
                      {
                        employeeId: "3559",
                        firstName: "Kohen",
                        lastName: "Simpson",
                        jobTitle: "Sales / Egypt Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3556",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3553",
                    firstName: "Omari",
                    lastName: "Ibarra",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3549",
                    reports: [
                      {
                        employeeId: "3554",
                        firstName: "Immanuel",
                        lastName: "Austin",
                        jobTitle: "Account Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3553",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3566",
                    firstName: "Samuel",
                    lastName: "Dixon",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Egypt",
                    managerEmployeeId: "3549",
                    reports: [
                      {
                        employeeId: "3568",
                        firstName: "Heather",
                        lastName: "Arnold",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3566",
                        reports: []
                      },
                      {
                        employeeId: "3567",
                        firstName: "Ronald",
                        lastName: "Suarez",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3566",
                        reports: []
                      },
                      {
                        employeeId: "3569",
                        firstName: "Nathalia",
                        lastName: "Glass",
                        jobTitle: "Sales / Egypt Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Egypt",
                        managerEmployeeId: "3566",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3532",
                firstName: "Jayden",
                lastName: "Shepherd",
                jobTitle: "Principal Account Manager",
                officeLocation: "Paris",
                department: "Sales / Libya",
                managerEmployeeId: "3443",
                reports: [
                  {
                    employeeId: "3542",
                    firstName: "Katalina",
                    lastName: "Robles",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3532",
                    reports: [
                      {
                        employeeId: "3543",
                        firstName: "Leyla",
                        lastName: "Fuller",
                        jobTitle: "Account Manager-I",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3542",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3534",
                    firstName: "Zaylee",
                    lastName: "Suarez",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3532",
                    reports: []
                  },
                  {
                    employeeId: "3533",
                    firstName: "Hugo",
                    lastName: "Dickson",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3532",
                    reports: []
                  },
                  {
                    employeeId: "3536",
                    firstName: "Devon",
                    lastName: "Serrano",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3532",
                    reports: [
                      {
                        employeeId: "3537",
                        firstName: "Finn",
                        lastName: "Fry",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3536",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3538",
                    firstName: "River",
                    lastName: "Lambert",
                    jobTitle: "Sales / Libya Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3532",
                    reports: [
                      {
                        employeeId: "3539",
                        firstName: "Seth",
                        lastName: "Hardin",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3538",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3535",
                    firstName: "Pablo",
                    lastName: "Gutierrez",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3532",
                    reports: []
                  },
                  {
                    employeeId: "3540",
                    firstName: "Regina",
                    lastName: "Hodge",
                    jobTitle: "Sales / Libya Manager-IV",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3532",
                    reports: [
                      {
                        employeeId: "3541",
                        firstName: "Felicity",
                        lastName: "Atkinson",
                        jobTitle: "Account Manager-IV",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3540",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3545",
                    firstName: "Santiago",
                    lastName: "Underwood",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Libya",
                    managerEmployeeId: "3532",
                    reports: [
                      {
                        employeeId: "3546",
                        firstName: "Ezequiel",
                        lastName: "Melton",
                        jobTitle: "Staff Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3545",
                        reports: []
                      },
                      {
                        employeeId: "3547",
                        firstName: "Lila",
                        lastName: "Lester",
                        jobTitle: "Sales / Libya Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Libya",
                        managerEmployeeId: "3545",
                        reports: []
                      }
                    ]
                  }
                ]
              },
              {
                employeeId: "3500",
                firstName: "Louie",
                lastName: "Shepard",
                jobTitle: "Principal Account Manager",
                officeLocation: "Paris",
                department: "Sales / Saudi Arabia",
                managerEmployeeId: "3443",
                reports: [
                  {
                    employeeId: "3505",
                    firstName: "Kylan",
                    lastName: "Dickerson",
                    jobTitle: "Sales / Saudi Arabia Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3500",
                    reports: [
                      {
                        employeeId: "3506",
                        firstName: "Travis",
                        lastName: "Hebert",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3505",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3503",
                    firstName: "Marilyn",
                    lastName: "Cabrera",
                    jobTitle: "Sales / Saudi Arabia Manager-V",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3500",
                    reports: [
                      {
                        employeeId: "3504",
                        firstName: "Jensen",
                        lastName: "Trujillo",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3503",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3502",
                    firstName: "Thea",
                    lastName: "Mccormick",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3500",
                    reports: []
                  },
                  {
                    employeeId: "3501",
                    firstName: "Roger",
                    lastName: "Saunders",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3500",
                    reports: []
                  },
                  {
                    employeeId: "3512",
                    firstName: "Lennon",
                    lastName: "Wise",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3500",
                    reports: [
                      {
                        employeeId: "3513",
                        firstName: "Cherish",
                        lastName: "Pratt",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3512",
                        reports: []
                      },
                      {
                        employeeId: "3514",
                        firstName: "Landry",
                        lastName: "Stafford",
                        jobTitle: "Sales / Saudi Arabia Manager-III",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3512",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3509",
                    firstName: "Jaelyn",
                    lastName: "Solomon",
                    jobTitle: "Staff Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3500",
                    reports: [
                      {
                        employeeId: "3510",
                        firstName: "Mariana",
                        lastName: "Richards",
                        jobTitle: "Sales / Saudi Arabia Manager-II",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3509",
                        reports: []
                      }
                    ]
                  },
                  {
                    employeeId: "3507",
                    firstName: "Aubree",
                    lastName: "Reid",
                    jobTitle: "Senior Account Manager",
                    officeLocation: "Paris",
                    department: "Sales / Saudi Arabia",
                    managerEmployeeId: "3500",
                    reports: [
                      {
                        employeeId: "3508",
                        firstName: "Kase",
                        lastName: "Logan",
                        jobTitle: "Senior Account Manager",
                        officeLocation: "Paris",
                        department: "Sales / Saudi Arabia",
                        managerEmployeeId: "3507",
                        reports: []
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];


export {employees};