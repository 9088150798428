import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {db} from './db';
import logo from './assets/Zero.png';

const Navigation = ({brand, links}) => {
    const navLinks = links.map((link, i) => <a key={i} href={link.href}>{link.name}</a>);

    return (
        <div className="navigation-container">
            <div className="navigation-brand">
                <a href={brand.href}><img src={brand.img} alt={brand.name}/></a>
            </div>
            <div className="navigation-links">
                {navLinks}
            </div>
        </div>
    );
};

const Departments = ({departments, colors}) => {
    let totalCount = 0;
    departments.forEach(d => totalCount += d.count);

    const deps = departments.map((d, i) => {
        console.log(d.count/totalCount*100);
        const dStyle = {
            paddingTop: `${i*3+1}em`,
            borderLeft: `1px solid ${colors[i]}`,
            borderTop: `5px solid ${colors[i]}`,
            width: `${d.count/totalCount*100}%`
        };

        return (
            <div className="department" key={d.employeeId} style={dStyle}>
                <div><b>{d.department}</b></div>
                <div>{d.firstName} {d.lastName}</div>
                <div>{d.count} employees</div>
            </div>
        );
    });

    return (
        <div className="departments-container">
            {deps}
        </div>
    );
};


const App = () => {
    const colors = ['#bd10e0', '#4a90e2', '#50e3c2', '#b8e986', '#7ed321', '#417505', '#f8e71c', '#f5a623', '#9b9b9b']
    const brand = {
        name: 'Zero',
        img: `${logo}`,
        href: '#'
    };
    const navLinks = [
        {
            name: 'About',
            href: '#'
        },
        {
            name: 'FAQs',
            href: '#'
        }
    ]

    return (
        <>
            <Navigation brand={brand} links={navLinks} />
            <div className="page-container">
                <h1>Departments</h1>
                <Departments 
                    departments={db.slice(0,colors.length)}
                    colors={colors}
                />
            </div>
        </>
    );
}

ReactDOM.render(<App />, document.getElementById('root'));